import { useEffect, useRef, useState } from 'react'
import { historicalRecord, taskResult, createTask } from '../../api/newFunctionRequest'
import { useAppSelector } from '@/hooks'
import { setTaskList, setImageRecordList, updateImageRecord } from '../../slices/NewFunctionSlice'
import { useDispatch } from 'react-redux'
function useNewFunction(params) {
    const { generationType } = params
    const dispatch = useDispatch()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const vistorAuthToken = useAppSelector(state => {
        return state.app && state.app.vistorAuthToken
    })
    const groupTaskList = useAppSelector(state => {
        return state.newFunctionSlice && state.newFunctionSlice.groupTaskList
    })

    const timerRef = useRef(null)

    useEffect(() => {
        if (groupTaskList.length > 0) {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
            groupTaskList.map(item => {
                taskResult({ authToken: generationType == 'vistor' ? vistorAuthToken : authToken, taskGroupIds: [item] }).then(res => {
                    if (res.code == 1) {
                        dispatch(updateImageRecord(res.data.groupResults))
                    } else {
                        clearInterval(timerRef.current)
                    }
                })
            })
            timerRef.current = setInterval(async function () {
                groupTaskList.map(item => {
                    taskResult({ authToken: generationType == 'vistor' ? vistorAuthToken : authToken, taskGroupIds: [item] }).then(res => {
                        if (res.code == 1) {
                            dispatch(updateImageRecord(res.data.groupResults))
                        } else {
                            clearInterval(timerRef.current)
                        }
                    })
                })
            }, 4000)
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }
    }, [groupTaskList])
}

export default useNewFunction
