import { Dropdown, MenuProps, Spin, theme } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import TabHeader, { TabHeaderItem } from '../TabHeader'
import { useTranslation } from 'react-i18next'
import { ModelListTab } from '../modal/ModelListModal'
import { useAppSelector } from '@/hooks'
import { IModelItem } from '@/slices/GenerateImageSlice'
import { fetchSd_loraParentList } from '@/slices/GeneratedImageThunk'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import { useCommonSlice } from '@/slices/commonSlice'

const { useToken } = theme

interface IParentDropdownProps {
    modelId: string
    children: ReactNode
    onOpenChange?: (open: boolean) => void
    onSelectParent: (parent?: IModelItem) => void
}
const ParentDropdown: React.FC<IParentDropdownProps> = props => {
    const { token } = useToken()
    const state = useAppSelector(state => state.generateImageSlice.stableDiffusion)
    const dispatch = useDispatch<AppDispatch>()

    const { isPendingThunk } = useCommonSlice()

    const { t } = useTranslation()
    const Tabs = [
        { name: t('generatedImage.public'), tab: ModelListTab.Public },
        { name: t('generatedImage.private'), tab: ModelListTab.Private },
    ]

    const [parentSearch, setParentSearch] = useState('')
    const [isOpen, setIsisOpen] = useState(false)
    const [parentList, setParentList] = useState<IModelItem[]>([])

    const [curTab, setCutTab] = useState<TabHeaderItem>(Tabs[0])

    useEffect(() => {
        if (props.modelId && isOpen) {
            dispatch(fetchSd_loraParentList({ modelId: props.modelId }))
                .unwrap()
                .then(data => {
                    setParentList(data)
                })
        }
    }, [props.modelId, isOpen])

    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    }

    const menuStyle = {
        boxShadow: 'none',
        height: '16rem',
        width: '29rem',
        overflow: 'auto',
    }

    const isPublicMode = curTab.tab == ModelListTab.Public ? 1 : 0

    const items: MenuProps['items'] = parentList
        .filter(parent => {
            if (parent.isPublic != isPublicMode) {
                return false
            }

            if (parentSearch) {
                return parent.modelName.toLowerCase().includes(parentSearch.toLowerCase())
            } else {
                return true
            }
        })
        .map((parent, i) => {
            return {
                key: parent.modelId,
                data: parent,
                label: (
                    <button className="h-5">
                        <span>{parent.modelName}</span>
                        {parent.isRecommend == 1 && (
                            <span className="ml-2 m-bg-gradient px-2 rounded-full text-xs">{t('generatedImage.Recommended')}</span>
                        )}
                    </button>
                ),
            }
        })

    return (
        <Dropdown
            // autoAdjustOverflow={false}
            destroyPopupOnHide={true}
            placement="bottomLeft"
            trigger={['click']}
            menu={{
                items,
                onClick: ({ key, keyPath, domEvent }) => {
                    // onClickHandle(cellData[parseInt(key)])
                    const selectedParent = items.find((v, i) => v?.key == key)
                    props.onSelectParent((selectedParent as any)?.data)
                },
            }}
            onOpenChange={e => {
                setIsisOpen(e)
                if (props.onOpenChange) {
                    props.onOpenChange(e)
                }
            }}
            dropdownRender={menu => {
                return (
                    <div className="p-1" style={contentStyle}>
                        <div className="w-full flex flex-col p-1">
                            <input
                                placeholder={`${t('generatedImage.search')}`}
                                autoComplete="on"
                                type="search"
                                className="w-full h-10 rounded-full bg-toolsBgColor outline-none px-4"
                                value={parentSearch}
                                onChange={e => {
                                    setParentSearch(e.target.value)
                                }}
                            />
                        </div>
                        <TabHeader
                            tabValue={curTab}
                            className="my-1 ml-5 space-x-0 lg:space-x-10 text-sm font-normal"
                            tabs={Tabs}
                            onChange={function (tab: TabHeaderItem): void {
                                setCutTab(tab)
                            }}
                        />
                        <Spin spinning={isPendingThunk(fetchSd_loraParentList)}>
                            {React.cloneElement(menu as React.ReactElement, { style: menuStyle, className: 'dark-thin-scrollbar' })}
                        </Spin>
                    </div>
                )
            }}
        >
            {props.children}
        </Dropdown>
    )
}
export default ParentDropdown
