import { checkResourceComplete, createResource, deleteResource, getOssAssumeRoleToken } from '@/api/stableDiffusionRequest'
import { uploadFiles2OSS } from '@/api/uploadFiles'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { ConfigProvider, Image, Spin, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import './editDataset.css'
import { setResourcesListItem } from '@/slices/StableDiffusionSlice'
import { useTranslation } from 'react-i18next'
import { EditOutlined, EllipsisOutlined, SettingOutlined, InboxOutlined } from '@ant-design/icons'
import AIImageUpload from '@/pages/AIImage/AIImageUpload'
import { RcFile, UploadFile } from 'antd/es/upload'
import UploadPicCom from './UploadPicCom'
const { Dragger } = Upload
export default function EditDataset(props) {
    const { setSelectedBtn, getResources } = props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const resourcesListItem = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.resourcesListItem
    })
    const [picList, setPicList] = useState([])

    const [fileList, setFileList] = useState<UploadFile[]>([])

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        liseDir(resourcesListItem)
    }, [])
    const liseDir = async resource => {
        const getOssAssumeRoleTokenResult = await getOssAssumeRoleToken({
            dataId: resource.resourceId,
            dataType: 1,
            authToken: authToken,
        })
        if (getOssAssumeRoleTokenResult.code == 1) {
            const client = uploadFiles2OSS(getOssAssumeRoleTokenResult.data)
            try {
                let result = await client.list({
                    prefix: resource.ossFileDir,
                    // 设置正斜线（/）为文件夹的分隔符。
                    delimiter: '/',
                })
                let picObj = result.objects.map(async function (obj) {
                    const result = await client.signatureUrl(obj.name, { expires: 3600 })
                    return {
                        uid: obj.etag,
                        name: obj.name.split('/')[4],
                        status: 'done',
                        url: result,
                    }
                })

                Promise.all(picObj).then(res => {
                    setFileList(res)
                })
            } catch (e) {
                console.log(e)
            }
        }
    }
    const confirm = resourceId => {
        deleteResource({ authToken, resourceId }).then((res: any) => {
            if (res.code == 1) {
                // getResources({ authToken })
            } else {
                // message.error(res.message)
            }
        })
    }
    const delOSSImg = async (resource, fileName) => {
        console.log('🚀 ~ file: ResourcesTab.tsx:210 ~ liseDir ~ resource:', resource.ossFileDir)
        const getOssAssumeRoleTokenResult = await getOssAssumeRoleToken({
            dataId: resource.resourceId,
            dataType: 1,
            authToken: authToken,
        })
        if (getOssAssumeRoleTokenResult.code == 1) {
            const client = uploadFiles2OSS(getOssAssumeRoleTokenResult.data)

            try {
                let result = await client.delete(resource.ossFileDir + fileName)
                console.log(result)
                // // 指定多个待删除Object的名称。Object名称需填写不包含Bucket名称在内的Object的完整路径。
                // let result = await client.deleteMulti([resource.ossFileDir + fileName])
                // console.log(result)
            } catch (e) {
                console.log(e)
            }
        }
    }
    const back = () => {
        dispatch(setResourcesListItem(null))
    }
    const [ossClient, setOSSClient] = useState(null)
    const uploadFileToOss = async (client, file, data) => {
        if (file.originFileObj) {
            try {
                const filePath = `${data.dataDir}${file.name}`
                let result = await client.put(filePath, file.originFileObj, {})
                console.log('🚀 ~ file: EditDataset.tsx:108 ~ uploadFileToOss ~ result:', result)
                message.success('Upload Success')
            } catch (err: any) {
                message.error(err)
            }
        }
    }
    const uploadResourceBtn = async () => {
        setLoading(true)
        getOssAssumeRoleToken({
            dataId: resourcesListItem?.resourceId,
            dataType: 1,
            authToken: authToken,
        }).then(res => {
            console.log('🚀 ~ file: App.tsx:48 ~ getOssAssumeRoleToken ~ res:', res)
            const client = uploadFiles2OSS(res.data)
            setOSSClient(client)

            let uploadQueue = fileList.map(async item => {
                let result = uploadFileToOss(client, item, res.data)
                console.log('🚀 ~ file: CreateResources.tsx:44 ~ uploadQueue ~ result:', result)
                return result
            })
            Promise.all(uploadQueue).then(res => {
                console.log('res: ', res)
                checkComplete()
                // setFileList([])
                // setResourceName('')
                // message.success(res.message)
                // setUploading(false)
            })
            // getResources()
            // message.success(res.message)
            // setOSSInfo(res.data)
        })
    }

    const checkComplete = async () => {
        const result = await checkResourceComplete({ authToken: authToken, resourceId: resourcesListItem?.resourceId })
        if (result.code == 1) {
            getResources()
            setLoading(false)
            message.success(result.message)
        } else {
            setLoading(false)

            message.error(result.message)
        }
    }
    return (
        <>
            <button className="py-3 px-10 rounded-full bg-gradient-to-l from-btnBgColorFrom  to-btnBgColorTo hover:bg-black" onClick={back}>
                {t('Training&Datasets.BACK')}
            </button>
            <h3 className="my-3">{t('Training&Datasets.DIRNAME')}</h3>
            <input
                className="block w-full resize-none min-w-0 flex-1 p-2 rounded-md bg-gray-bg outline-none"
                placeholder={resourcesListItem?.name}
                disabled
                // value={state.negativePrompt}
                // onChange={e => updateState({ negativePrompt: e.target.value })}
            />
            <h3 className="my-3">{t('Training&Datasets.DESCRIPTION')}</h3>
            <input
                className="block w-full resize-none min-w-0 flex-1 p-2 rounded-md bg-gray-bg outline-none"
                placeholder={resourcesListItem?.description}
                disabled

                // placeholder={t('generatedImage.negativePromptPlaceHolder')!}
                // value={state.negativePrompt}
                // onChange={e => updateState({ negativePrompt: e.target.value })}
            />
            <div className="upload-container">
                <UploadPicCom fileList={fileList} setFileList={setFileList} delOSSImg={delOSSImg} />
            </div>
            <div className="flex justify-between mt-6">
                <div className="flex">
                    <button className="py-3 px-10 rounded-full bg-gradient-to-l from-btnBgColorFrom  to-btnBgColorTo hover:bg-black mr-10">
                        {t('Training&Datasets.DELETE')}
                    </button>

                    <button
                        className="overflow-hidden rounded-full bg-gradient-to-l from-btnBgColorFrom  to-btnBgColorTo hover:bg-black"
                        onClick={uploadResourceBtn}
                    >
                        <Spin spinning={loading}>
                            <div className="w-full h-full py-3 px-10 ">{t('Training&Datasets.UPLOAD')}</div>
                        </Spin>
                    </button>
                </div>
                <div>
                    <button
                        className="py-3 px-10 rounded-full bg-gradient-to-l from-btnBgColorFrom  to-btnBgColorTo hover:bg-black"
                        onClick={() => setSelectedBtn(9)}
                    >
                        {t('Training&Datasets.TRAINMODEL')}
                    </button>
                </div>
            </div>
        </>
    )
}
