import React, { useEffect, useState } from 'react'
import Training from './Training'
import YourDatasets from './YourDatasets'
import JobStatus from './JobStaatus'
import { useTranslation } from 'react-i18next'
import { pageEvent, timeEvent, trackEvent } from '@/lib/analyse'
import { Head } from '@/components/Head'

function index(params) {
    const { t } = useTranslation()
    const btnList = [
        // { name: 'txt2img', index: 0 },
        // { name: 'img2img', index: 1 },
        // { name: 'Extras', index: 3 },
        // { name: 'Checkpoint Merger', index: 4 },
        // { name: 'Train', index: 5 },
        // { name: '3D Openpose', index: 6 },
        // { name: 'Additional Network', index: 7 },
        { name: t('Training&Datasets.title1'), index: 8 },
        { name: t('Training&Datasets.title2'), index: 9 },
        { name: t('Training&Datasets.title3'), index: 10 },
    ]
    useEffect(() => {
        pageEvent({ page: 'training' })
        timeEvent('training page time')
        return () => {
            trackEvent('training page time')
        }
    }, [])

    const [selectedBtn, setSelectedBtn] = useState(8)
    return (
        <main className="flex-1 overflow-y-auto focus:outline-none bg-black ">
            <Head title={'Training - MinisterAI'} />

            <div className="bg-black w-full flex flex-col min-h-screen">
                <div className=" mx-auto w-full  px-5 flex-grow text-white">
                    <div>
                        <div className=" text-2xl font-bold h-[60px] py-[14px]">Training & Datasets</div>
                        <div className="flex space-x-3">
                            {btnList.map((btnItem, index) => {
                                return (
                                    <button
                                        className={
                                            'relative py-4 text-gray-400  hover:text-white w-[150px] text-lg ' +
                                            (selectedBtn == btnItem.index ? 'text-white' : 'text-gray-400')
                                        }
                                        key={index}
                                        onClick={() => {
                                            message.error(t('Training&Datasets.WAITONLINETEXT'))
                                            // setSelectedBtn(btnItem.index)
                                        }}
                                    >
                                        {btnItem.name}
                                        {selectedBtn == btnItem.index && (
                                            <div className=" absolute bottom-0 left-0 right-0 m-bg-gradient w-full h-0.5"></div>
                                        )}
                                    </button>
                                )
                            })}
                        </div>
                        <hr className="flex-1 border-[#C9CBD14D]/30" />
                        <div className="mt-2">
                            {selectedBtn == 8 && <YourDatasets setSelectedBtn={setSelectedBtn}></YourDatasets>}
                            {selectedBtn == 9 && <Training></Training>}
                            {selectedBtn == 10 && <JobStatus></JobStatus>}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default index
