import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AntProvider from './AntProvider'
import SDModelListSearch from './SdModelListSearch'
import TabHeader, { TabHeaderItem } from '../AIImage/TabHeader'
import { ModelListTab } from '../AIImage/modal/ModelListModal'
import SdModelListContent, { ISdModelListContentRef } from './SdModelListContent'
import { useDispatch } from 'react-redux'
import { fetchCurTabModelList, setCurTab, setExitModelList, setUploadingState } from '@/slices/sdModelListSlice'
import { useAppSelector } from '@/hooks'
import DivBoundaryLoader, { IDivBoundaryLoaderRef } from './DivBoundaryLoader'
import upload_sm_icon from '../../assets/model_upload_sm_icon.png'
import upload_btn_animation from '../../assets/upload_btn_animation.png'
import { AppDispatch } from '@/store'
import loading_animation from '../../assets/loading.png'
import { useMyTranslation } from '@/components/TranslationConfig/TranslationConfig'
import { modelGetUploadingTaskInfo } from './api/apiModel'
import { Tooltip, message } from 'antd'
import { useAliyunOSSContext } from '@/lib/AliyunOSS'
import WelcomePage from '../Chat/WelcomePage'
import { fetchNoticeList, setIsShowWelcomePage } from '@/slices/AppSlice'
import Notification from '@/pages/sdModelList/Components/Notification'
import { useHistory, useLocation } from 'react-router-dom'
import { useCommonSlice } from '@/slices/commonSlice'
import { pageEvent, timeEvent, trackEvent, googleTrackEvent } from '@/lib/analyse'
import { Head } from '@/components/Head'

interface SdModelListProps {}
const SdModelList: React.FC<SdModelListProps> = props => {
    // const { t } = useTranslation()
    const { t } = useMyTranslation()
    const Tabs = [
        { name: t('generatedImage.public'), tab: ModelListTab.Public },
        { name: t('generatedImage.private'), tab: ModelListTab.Private },
    ]
    const divBoundaryLoaderRef = useRef<IDivBoundaryLoaderRef>(null)
    const dispatch = useDispatch<AppDispatch>()
    const state = useAppSelector(state => state.sdModelListSlice)
    const authToken = useAppSelector(state => state.app?.authToken)
    const noticeList = useAppSelector(state => state.app?.noticeList)
    const history = useHistory()

    const { isPendingThunk } = useCommonSlice()

    // const publicContentRef = useRef<ISdModelListContentRef>(null)
    // const privateContentRef = useRef<ISdModelListContentRef>(null)
    const { cancel, setOSSProgress } = useAliyunOSSContext()
    const location = useLocation()

    useEffect(() => {
        if (location?.state?.tab != undefined) {
            dispatch(setCurTab(Tabs[location?.state?.tab]))
        } else if (!state.curTab) {
            dispatch(setCurTab(Tabs[0]))
        }
        // console.log('🚀 ~ file: SdModelList.tsx:56 ~ location:', location)
    }, [location])

    useEffect(() => {
        if (authToken) {
            refreshLoadingBtn()
            loadImages()
        }

        return () => {
            dispatch(setExitModelList({}))
        }
    }, [authToken])

    function refreshLoadingBtn() {
        modelGetUploadingTaskInfo({ authToken }).then(res => {
            if (res.code == 1) {
                //taskStatus -1失败 1进行中 2成功 3部署完成
                if (res.data.taskStatus != 1) {
                    //没有下载
                    dispatch(setUploadingState({ uploading: false, taskType: res.data.taskType }))
                } else {
                    //有下载
                    if (res.data.taskType == 2 && res.data.taskStatus == 1) {
                        // copyling 下载
                        dispatch(setUploadingState({ uploading: true, taskType: 2 }))
                    }
                }
            }
        })
    }
    useEffect(() => {
        if (authToken) {
            dispatch(fetchNoticeList({ authToken }))
        }
    }, [authToken])

    const [showMore, setShowMore] = useState({ public: true, private: true })
    const [curModelList, haveCurMore] = useMemo(() => {
        if (state.curTab?.tab == ModelListTab.Public) {
            return [state.publicModelList, showMore.public]
        } else if (state.curTab?.tab == ModelListTab.Private) {
            return [state.privateModelList, showMore.private]
        } else {
            return [state.publicModelList, showMore.public]
        }
    }, [state.curTab, state.publicModelList, state.privateModelList, showMore])

    const loadImages = useCallback(
        (lastModelId?: string) => {
            // if (isPendingThunk(fetchCurTabModelList)) {
            //     return
            // }
            dispatch(fetchCurTabModelList({ lastModelId }))
                .unwrap()
                .then(res => {
                    if (res.publicModelList) {
                        setShowMore(more => {
                            return { ...more, public: res.publicModelList.length < 20 ? false : true }
                        })
                    }
                    if (res.privateModelList) {
                        setShowMore(more => {
                            return { ...more, private: res.privateModelList.length < 20 ? false : true }
                        })
                    }
                })
        },
        [state.requestStatus, isPendingThunk],
    )
    const isShowWelcomePage = useAppSelector(state => {
        return state.app && state.app.isShowWelcomePage
    })
    const [isPhone, setIsPhone] = useState(false)
    useEffect(() => {
        whatScreen()
        pageEvent({ page: 'models' })
        // dispatch(setIsShowWelcomePage(true))
        // console.log(isPhone)
        timeEvent('models page time')
        return () => {
            trackEvent('models page time')
        }
    }, [])
    useEffect(() => {
        if (isPhone) {
            if (window.sessionStorage.getItem('isWelcome') == '1') {
                dispatch(setIsShowWelcomePage(false))
                // setIsShowWelcomePage(false)
            } else {
                dispatch(setIsShowWelcomePage(true))
            }
        }
    }, [isPhone])
    function whatScreen() {
        var width: any = document.querySelector('html')?.offsetWidth
        var title = document.querySelector('title')
        if (width <= 768) {
            // title.innerHTML = '极小屏幕-' + width
            setIsPhone(true)
        } else {
            if (width > 768 && width <= 992) {
                // title.innerHTML = '小屏幕-' + width
            } else {
                if (width > 992 && width <= 1200) {
                    // title.innerHTML = '普通屏幕-' + width
                } else {
                    if (width > 1200) {
                        // title.innerHTML = '大屏幕-' + width
                    }
                }
            }
        }
    }
    return (
        <>
            <Head title={'Models - MinisterAI'} />
            {isPhone && isShowWelcomePage ? (
                <WelcomePage setIsShowWelcomePage={setIsShowWelcomePage} />
            ) : (
                <div className="w-full h-full flex flex-col lg:h-full bg-black text-white">
                    <DivBoundaryLoader
                        ref={divBoundaryLoaderRef}
                        className={`overflow-y-auto grow`}
                        onReachBottom={() => {
                            if (isPendingThunk(fetchCurTabModelList)) {
                                return
                            }

                            const lastObj = curModelList[curModelList.length - 1]
                            if (lastObj && haveCurMore) {
                                loadImages(lastObj.modelId)
                            }
                        }}
                    >
                        <div className="sticky pt-5 top-0 z-10 lg:pb-2 bg-black mx-auto w-full max-w-[122rem] px-2 space-y-1 lg:space-y-4 lg:px-8">
                            {noticeList?.length > 0 && <Notification />}

                            <h2 className="hidden lg:block text-2xl ml-1">{t('modelShop.model')}</h2>

                            <SDModelListSearch />
                            <div className="flex items-center justify-between">
                                <TabHeader
                                    tabValue={state.curTab}
                                    className="lg:ml-4 space-x-0 lg:space-x-10 text-lg font-normal"
                                    buttonClassName={isActice => {
                                        if (isActice) {
                                            return 'px-6 lg:px-0 p-2 lg:p-0 bg-menuBgColor lg:bg-black rounded-t-lg'
                                        } else {
                                            return 'px-6 lg:px-0 p-2 lg:p-0 rounded-t-lg'
                                        }
                                    }}
                                    lineClassName="hidden lg:block"
                                    tabs={Tabs}
                                    onChange={function (tab: TabHeaderItem): void {
                                        trackEvent('models button event', { button_type: tab.name })
                                        googleTrackEvent('models button event', tab.name)
                                        divBoundaryLoaderRef.current?.scrollTop()
                                        dispatch(setCurTab(tab))
                                        refreshLoadingBtn()
                                        loadImages()
                                    }}
                                />
                                <Tooltip
                                    title={(function () {
                                        if (state.uploadingState.uploading) {
                                            if (state.uploadingState.taskType == 2) {
                                                return t('modelShop.uploadModelLinkStateTip')
                                            } else {
                                                return t('modelShop.uploadModelStateTip')
                                            }
                                        } else {
                                            return undefined
                                        }
                                    })()}
                                >
                                    <button
                                        onClick={e => {
                                            if (state.uploadingState.taskType == 2 && state.uploadingState.uploading) {
                                                message.error(t('modelShop.uploadModelLinkStateTip'))
                                                return
                                            }
                                            trackEvent('models button event', { button_type: 'upload' })
                                            googleTrackEvent('models button event', 'upload')
                                            history.push('/tools/ModelList/ModelUpload')
                                            // cancel()
                                            // setOSSProgress(0)
                                            // dispatch(setDetailModelId(undefined))
                                            // dispatch(setDetailType(DetailType.Upload))
                                        }}
                                        className={`relative overflow-clip m-bg-gradient rounded-full sm:ml-12 h-8 px-8 space-x-3 flex items-center`}
                                    >
                                        <img src={upload_sm_icon} className="w-4 inline-block " />
                                        <span className="text-base font-medium">{t('modelShop.upload')}</span>
                                        {state.uploadingState.uploading && (
                                            <img src={upload_btn_animation} className="w-full h-full absolute object-cover" />
                                        )}
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="mx-auto w-full max-w-[122rem] px-2 space-y-1 lg:space-y-4 lg:px-8">
                            <div className="p-2 bg-menuBgColor lg:bg-black lg:p-0 rounded-tr-lg rounded-b-lg">
                                {/* <div className="p-2 bg-menuBgColor lg:bg-black lg:p-0 lg:mt-4 rounded-tr-lg rounded-b-lg min-h-full"> */}
                                <SdModelListContent modelList={curModelList} />
                                {curModelList.length < 1 && !isPendingThunk(fetchCurTabModelList) && (
                                    <div className="h-[400px] flex justify-center items-center">
                                        <div className="text-white/60 text-center">{t('generatedImage.emptyText')}</div>
                                    </div>
                                )}
                                {haveCurMore && (
                                    <div key="macy-container-model-loading" className="flex justify-center items-center mb-5">
                                        {isPendingThunk(fetchCurTabModelList) && <img src={loading_animation} className="h-20" />}
                                    </div>
                                )}
                            </div>
                        </div>
                    </DivBoundaryLoader>
                </div>
            )}
        </>
    )
}

export default SdModelList
