import React, { useState } from 'react'

import chatGPTIcon from '../../assets/chatGPT1.png'
import AIImageIcon from '../assets/AIImage1.png'
import Midjourney from '@/pages/Midjourney'
import midjourney_phone from '../assets/midjourney_phone.png'
import traningIcon from '../assets/traningPhone.png'
import wechatIcon from '../../assets/wechatIcon.png'
import discordIcon from '../../assets/discord.png'
import wechatGroup from '../../assets/wechat-group.jpg'
import { usePageState } from '@/hooks'
import { useTranslation } from 'react-i18next'
import languageIconEN from '../../assets/language-en.png'
import languageIconCN from '../../assets/language-cn.png'
import logoutIcon from '../../assets/logout.png'
import Cookies from 'js-cookie'
import { gapi, loadAuth2 } from 'gapi-script'
import { HashRouter as Router, Route, useHistory, Switch, Redirect, useLocation, NavLink } from 'react-router-dom'
import chatGPTBtn from '../../assets/chatGPT_btn.png'
import StableDiffusionBtn from '../../assets/StableDiffusion_btn.png'
import MidjourneyBtn from '../../assets/Midjourney_btn.png'
import TrainingComingSoon from '../../assets/Training_comingsoon.png'
import TrainingBtn from '../../assets/Training_btn.png'
import { AppDispatch } from '@/store'
import { useDispatch } from 'react-redux'
import { setIsShowWelcomePage } from '@/slices/AppSlice'
import twitterIcon from '../../assets/twitter_icon.png'
import modelIcon from '../../assets/model_welcome_icon.png'
import watersIcon from '../../assets/waters_phone.png'
import { getChatTabState, getOverseaUrl } from '@/helpers'
import GlobalStorage from '@/lib/globalStorage'
import GPURental_btn from '@/assets/GPURental_btn.png'
export default function WelcomePage(props) {
    const dispatch = useDispatch<AppDispatch>()
    const { t, i18n } = useTranslation()
    const history = useHistory()

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.substring(0, 2))
    const logout = () => {
        GlobalStorage.remove('user_remember')
        GlobalStorage.remove('auth_token')

        if (GlobalStorage.get('login_type') == 'google') {
            GlobalStorage.remove('login_type')
            var auth2 = gapi.auth2.getAuthInstance()
            auth2.signOut().then(() => {
                history.push('/')
            })
        } else {
            GlobalStorage.remove('login_type')
            history.push('/')
        }

        // window.location.reload()
    }
    const changeLanguage = () => {
        if (currentLanguage == 'zh') {
            i18n.changeLanguage('en')
            setCurrentLanguage('en')
        } else if (i18n.language == 'en') {
            i18n.changeLanguage('zh')
            setCurrentLanguage('zh')
        }
    }
    const hrefPath = href => {
        dispatch(setIsShowWelcomePage(false))
        if (href == '/tools/TrainingAndDatasets') {
            return
        } else if (href == '/tools/chat') {
            dispatch(setIsShowWelcomePage(false))
            window.sessionStorage.setItem('isWelcome', '1')
            history.push(href)

            // setIsShowWelcomePage(false)
        } else {
            history.push(href)
            dispatch(setIsShowWelcomePage(false))
            window.sessionStorage.setItem('isWelcome', '1')
        }
    }
    return (
        <div className="flex justify-between flex-col">
            <div className="grid grid-cols-2 items-center gap-5 px-5 mt-5 pt-14 text-white ">
                <div
                    className="flex justify-center flex-col items-center font-thin md:text-xl text-base text-center bg-menuBgColor rounded-3xl py-4 min-h-[120px]"
                    onClick={() => hrefPath('/tools/Waters')}
                >
                    <img className="w-14" src={watersIcon} alt="" />
                    <p className="pt-2">Waters</p>
                </div>
                <div
                    className="flex justify-center flex-col items-center font-thin md:text-xl text-base text-center bg-menuBgColor rounded-3xl py-4 min-h-[120px]"
                    onClick={() => hrefPath('/tools/ModelList')}
                >
                    <img className="w-14" src={modelIcon} alt="" />
                    {/* <img className="w-14" src={TrainingBtn} alt="" /> */}
                    <p className="pt-2">Model</p>
                    {/* <p>ChatGPT</p> */}
                </div>

                <div
                    className="flex justify-center flex-col items-center font-thin md:text-xl text-base text-center bg-menuBgColor rounded-3xl py-4 min-h-[120px]"
                    onClick={() => hrefPath('/tools/AIImage')}
                >
                    <img className="w-14" src={StableDiffusionBtn} alt="" />
                    <p className="pt-2">Stable Diffusion</p>
                </div>

                {/* <div
                    className="flex justify-center flex-col items-center font-thin md:text-xl text-base text-center bg-menuBgColor rounded-3xl py-4"
                    onClick={() => hrefPath('/tools/midjourney')}
                >
                    <img className="w-14" src={MidjourneyBtn} alt="" />
                    <p className="pt-2">Midjourney</p>
                </div> */}

                <div
                    className="flex justify-center flex-col items-center font-thin md:text-xl text-base text-center bg-menuBgColor rounded-3xl py-4 min-h-[120px]"
                    onClick={() => hrefPath('/tools/TrainingAndDatasets')}
                >
                    <img className="w-36" src={TrainingComingSoon} alt="" />
                    {/* <img className="w-14" src={TrainingBtn} alt="" /> */}
                    <p className="pt-2">Training</p>
                    {/* <p>ChatGPT</p> */}
                </div>
                <div
                    className="flex justify-center flex-col items-center font-thin md:text-xl text-base text-center bg-menuBgColor rounded-3xl py-4 min-h-[120px]"
                    onClick={() => hrefPath('/tools/rental')}
                >
                    <img className="w-14" src={GPURental_btn} alt="" />
                    <p className="pt-2"> GPU Rental</p>
                </div>
                {getChatTabState() && (
                    <div
                        className="flex justify-center flex-col items-center font-thin md:text-xl text-base text-center bg-menuBgColor rounded-3xl py-4 min-h-[120px]"
                        onClick={() => hrefPath('/tools/chat')}
                    >
                        <img className="w-14" src={chatGPTBtn} alt="" />
                        <p className="pt-2"> {'ChatGPT'}</p>
                    </div>
                )}
            </div>

            <div className="relative">
                <div className="fixed w-full lg:absolute z-20 bottom-0 left-0 right-0 h-16 mx-auto pt-2 px-5 bg-pageBgColor">
                    <div className="w-full block"></div>
                    <div className="flex justify-between items-center w-full">
                        <div className="flex">
                            {/* <a className="flex justify-center items-center rounded-full w-8 h-8 mr-5 cursor-pointer">
                                <div className="group relative flex flex-col items-center">
                                    <img src={wechatIcon} alt="" />
                                    <div className="absolute bottom-0 left-0 mb-8 hidden flex-col items-center group-hover:flex z-[100]">
                                        <img className="w-[180px] max-w-fit" src={wechatGroup} alt="" />
                                    </div>
                                </div>
                            </a> */}
                            {getOverseaUrl() ? (
                                <>
                                    <a
                                        href="https://twitter.com/mst__ai"
                                        target="_blank"
                                        className="flex justify-center items-center rounded-full w-8 h-8 mr-5 cursor-pointer"
                                    >
                                        <div className="group relative flex flex-col items-center">
                                            <img src={twitterIcon} alt="" />

                                            {/* <div className="absolute bottom-0 left-0 mb-6 hidden flex-col items-center group-hover:flex w-6 text-center">
                                        <span className="whitespace-no-wrap leading-1 relative z-10 rounded-md bg-white p-2 text-xs text-gray-grayText shadow-lg">
                                            Discord
                                        </span>
                                    </div> */}
                                        </div>
                                    </a>
                                    <a
                                        href="https://discord.gg/ministers"
                                        target="_blank"
                                        className="flex justify-center items-center rounded-full w-8 h-8 mr-5 cursor-pointer"
                                    >
                                        <div className="group relative flex flex-col items-center">
                                            <img src={discordIcon} alt="" />

                                            {/* <div className="absolute bottom-0 left-0 mb-6 hidden flex-col items-center group-hover:flex w-6 text-center">
                                        <span className="whitespace-no-wrap leading-1 relative z-10 rounded-md bg-white p-2 text-xs text-gray-grayText shadow-lg">
                                            Discord
                                        </span>
                                    </div> */}
                                        </div>
                                    </a>
                                </>
                            ) : (
                                ''
                            )}
                            <a className="flex justify-center items-center rounded-full w-8 h-8 mr-5 cursor-pointer">
                                <div className="group relative flex flex-col items-center" onClick={changeLanguage}>
                                    {currentLanguage == 'zh' && <img src={languageIconCN} alt="" />}
                                    {currentLanguage == 'en' && <img src={languageIconEN} alt="" />}
                                    <div className="absolute bottom-0 left-0 mb-6 hidden flex-col items-center group-hover:flex w-max text-center">
                                        <span className="whitespace-no-wrap leading-1 relative z-10 rounded-md bg-white p-2 text-xs text-gray-grayText shadow-lg">
                                            {t('language.btnTip')}
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <a className="flex justify-center items-center rounded-full w-8 h-8 cursor-pointer">
                            <div className="group relative flex flex-col items-center" onClick={logout}>
                                <img src={logoutIcon} alt="" />

                                {/* <div className="absolute bottom-0 left-0 mb-6 hidden flex-col items-center group-hover:flex w-6 text-center">
                                        <span className="whitespace-no-wrap leading-1 relative z-10 rounded-md bg-white p-2 text-xs text-gray-grayText shadow-lg">
                                            Logout
                                        </span>
                                    </div> */}
                            </div>
                        </a>
                    </div>
                </div>
                <div>
                    <div className="w-full block"></div>
                    <div className="w-full h-16"></div>
                </div>
            </div>
        </div>
    )
}
