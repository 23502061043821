import React, { memo, useEffect, useState } from 'react'

interface ISegmentedPromptTextAreaProps {
    className: string
    placeholder: string
    value: string
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}
const SegmentedPromptTextArea: React.FC<ISegmentedPromptTextAreaProps> = props => {
    const { placeholder, value, onChange, className } = props

    const [text, setText] = useState(value)

    useEffect(() => {
        setText(value)
    }, [value])
    return (
        <div className="relative w-full">
            <div className={'whitespace-break-spaces invisible ' + className}>{`${text}+\n`}</div>
            <textarea
                placeholder={placeholder}
                value={text}
                onChange={e => setText(e.target.value)}
                onBlur={onChange}
                className={
                    'dark-thin-scrollbar absolute inset-0 w-full h-full block overflow-y-auto rounded-md resize-none min-w-0 outline-none ' +
                    className
                }
            />
        </div>
    )
}
export default memo(SegmentedPromptTextArea)
