import React, { useRef } from 'react'
import './CustomInputRangeStyle.css'
function CustomInputRange(params) {
    const { title, value, setValue, min, max, step } = params

    let inputEle = useRef()
    const handleRnageChange = e => {
        let newValue = e.target.value ? e.target.value : 0
        if (newValue < min) {
            newValue = min
        }
        if (newValue > max) {
            newValue = max
        }
        const percent = ((newValue - min) / (max - min)) * 100
        const bgStr = 'linear-gradient(to right, #f56dbc 0%, #f56dbc ' + percent + '%, #fff ' + percent + '%, #fff 100%)'
        setValue(newValue)
        inputEle.current.style.background = bgStr
    }

    return (
        <div className="custom-input-range">
            <div className="title">
                <span>{title}</span>
                <input type="number" value={value} onChange={handleRnageChange} />
            </div>
            <input ref={inputEle} type="range" value={value.toString()} onChange={handleRnageChange} min={min} max={max} step={step} />
        </div>
    )
}
CustomInputRange.defaultProps = {
    min: 0,
    max: 100,
    step: 1,
}
export default CustomInputRange
