import { configureStore } from '@reduxjs/toolkit'
// import accountReducer from "./slices/AccountSlice";
import appReducer from './slices/AppSlice'
import pendingTransactionsReducer from './slices/PendingTxnsSlice'
import messagesReducer from './slices/MessagesSlice'
import stableDiffusionSlice from './slices/StableDiffusionSlice'
import generateImageSlice from './slices/GenerateImageSlice'
import ChatGPTSlice from './pages/Chat/store/ChatGPTSlice'
import sdModelListSlice from './slices/sdModelListSlice'
import commonSlice from './slices/commonSlice'
import { sdApi } from './slices/sdApi'
import newFunctionSlice from './slices/NewFunctionSlice'

// reducers are named automatically based on the name field in the slice
// exported in slice files by default as nameOfSlice.reducer

const store = configureStore({
    reducer: {
        //   we'll have state.account, state.bonding, etc, each handled by the corresponding
        // reducer imported from the slice file
        // account: accountReducer
        app: appReducer,
        pendingTransactions: pendingTransactionsReducer,
        messages: messagesReducer,
        stableDiffusionSlice: stableDiffusionSlice,
        generateImageSlice: generateImageSlice,
        ChatGPTSlice: ChatGPTSlice,
        sdModelListSlice: sdModelListSlice,
        commonSlice: commonSlice,
        [sdApi.reducerPath]: sdApi.reducer,
        newFunctionSlice: newFunctionSlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(sdApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
