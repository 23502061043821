import React, { PropsWithChildren, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

export interface IDivBoundaryLoaderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    onReachBottom?: (e: Event) => void
}

export interface IDivBoundaryLoaderRef {
    scrollTop: Function
    divElement: HTMLDivElement
}

const DivBoundaryLoader = forwardRef<{}, PropsWithChildren<IDivBoundaryLoaderProps>>((props, forwardRef) => {
    const { children, onReachBottom, ...fallProps } = props
    const ref = useRef<HTMLDivElement>(null)

    // const [isB, setIsB] = useState(false)

    useImperativeHandle(forwardRef, () => ({
        divElement: ref.current,
        scrollTop: () => {
            if (ref.current) {
                ref.current.scrollTop = 0
            }
        },
    }))

    useEffect(() => {
        function handle(e) {
            const { clientHeight, scrollHeight, scrollTop } = e.target
            const isBottom = scrollTop + clientHeight + 1 > scrollHeight
            // console.log(scrollTop, clientHeight, scrollHeight, isBottom)
            // console.log(window.innerHeight, window.scrollY, document.documentElement.scrollHeight)

            if (isBottom) {
                onReachBottom?.(e)
            }
        }
        if (ref.current) {
            ref.current.addEventListener('scroll', handle)
            // ref.current.addEventListener('touchmove', handle)
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('scroll', handle)
                // ref.current.removeEventListener('touchmove', handle)
            }
        }
    }, [ref, onReachBottom])

    return (
        <div ref={ref} {...fallProps}>
            {children}
        </div>
    )
})

export default DivBoundaryLoader
