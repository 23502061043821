import { models } from '@/api/request'
import { createSelector, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface IOpenaiModels {
    id: number
    model: 'GPT-3.5' | 'GPT-4'
    modelName: 'gpt-3.5-turbo' | 'gpt-4'
    showName: '(Default)GPT-3.5' | 'GPT-4'
    state: number
}

interface IChatGPTModelLists {
    openaiModels: IOpenaiModels
}
interface ChatGPTState {
    openaiModels: IOpenaiModels[]
}

const initialState: ChatGPTState = {
    // chatGPTModelLists: [],
    openaiModels: [],
}
export const fetchChatGPTModel = createAsyncThunk('app/fetchCheckAuthTokenValid', async ({ authToken }: any, { dispatch }) => {
    const result = await models({ authToken })
    if (result.code == 1) {
        dispatch(setChatGPTModel(result.data.openaiModels))
    }
})
const ChatGPTSlice = createSlice({
    name: 'ChatGPTModel',
    initialState: initialState,
    reducers: {
        setChatGPTModel(state, action) {
            state.openaiModels = action.payload
        },
    },
    extraReducers(builder) {},
})

export const { setChatGPTModel } = ChatGPTSlice.actions
export default ChatGPTSlice.reducer
