/*
 * @Author: jianwen.Wang
 * @Date: 2022-01-25 17:28:22
 * @LastEditTime: 2023-06-16 14:33:52
 * @LastEditors: yunpeng.li yunpeng.li@sdbean.com
 */
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import { IBaseAsyncThunk } from './interfaces'

// import { addresses } from '../constants'
// import { BigNumberish, ethers } from 'ethers'
import { RootState } from '../store'
import { setAll } from '../helpers'
import { checkAuthTokenValid, noticeList } from '@/api/apiAccount'
import { countryarea, promptHelperJson } from '@/api/apiCommon'
import { message } from 'antd'
import { NoticeListResponse } from '@/pages/sdModelList'
import GlobalStorage from '@/lib/globalStorage'

export interface Message {
    content: string
    role: string
    date?: Date
}
export interface Session {
    chatHistoryRecord: Array<Message>
    session: string | undefined
    model?: string
    modelId?: number
}

export interface IAppData {
    readonly loading: boolean
    userName: string
    password: string
    authToken: string | undefined
    vistorAuthToken: string | undefined
    uniqueCode: string | undefined
    isValid: boolean
    sessionList: Array<Session>
    currentSession: Session | null
    selectExample: string
    menuOpen: boolean
    showLogin: boolean
    isShowWelcomePage: boolean
    countryArea: { country: string; area: string }[]
    noticeList: NoticeListResponse[] | null
    promptHelpers: Array<any>
    promptHelpersLanguage: string
    host: string
}
const initialState: IAppData = {
    loading: false,
    userName: '',
    password: '',
    authToken: '',
    vistorAuthToken: '',
    uniqueCode: '',
    isValid: false,
    sessionList: [],
    currentSession: null,
    selectExample: '',
    menuOpen: false,
    showLogin: false,
    isShowWelcomePage: false,
    countryArea: [],
    noticeList: null,
    promptHelpers:[],
    promptHelpersLanguage: '',
    host: ''
}
export const loadAppDetails = createAsyncThunk('app/loadAppDetails', async ({}:any, { dispatch }) => {

    let promptHelpersLanguage = window.location.host.includes('mst.ai') ? "EN": "CN"
    const res: any = await promptHelperJson({})
    let promptHelpers = res.promptHelpers
    let host = window.location.host.includes('mst.ai') ? "ai": "xyz"

    return {promptHelpersLanguage, promptHelpers, host} as IAppData
    
})

export const fetchCheckAuthTokenValid = createAsyncThunk('app/fetchCheckAuthTokenValid', async ({ authToken }: any, { dispatch }) => {
    const result = await checkAuthTokenValid({ authToken })
    if (result.code == 1) {
        dispatch(setIsValid(result.data.isValid))
        // if (result.data.isValid) {
        //     setAuthToken(GlobalStorage.get('auth_token'))
        // } else {
        //     GlobalStorage.remove('auth_token')
        //     // window.location.reload()
        // }
    }
})

export const fetchCountryArea = createAsyncThunk('app/fetchCountryArea', async ({}: any, { dispatch }) => {
    const result = await countryarea({})
    if (result.code == 1) {
        // console.log('🚀 ~ file: AppSlice.ts:89 ~ fetchCountryArea ~ result.data.areaList:', result.data.areaList)

        dispatch(setCountryArea(result.data.areaList))
    } else {
        message.error(result.message)
    }
})
export const fetchNoticeList = createAsyncThunk('app/fetchNoticeList', async ({ authToken }: any, { dispatch }) => {
    const result = await noticeList({ authToken })
    // console.log('🚀 ~ file: AppSlice.ts:96 ~ fetchNoticeList ~ result:', result)
    if (result.code == 1) {
        dispatch(setNoticeList(result.data.notices))
    }
})
const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload
        },
        fetchAppSuccess(state, action) {
            setAll(state, action.payload)
        },
        setpPassword(state, action) {
            state.password = action.payload
        },
        setUserName(state, action) {
            state.userName = action.payload
        },
        setAuthToken(state, action) {
            state.authToken = action.payload
        },
        setVistorAuthToken(state, action) {
            state.vistorAuthToken = action.payload
        },
        setUniqueCode(state, action) {
            state.uniqueCode = action.payload
        },
        setIsValid(state, action) {
            state.isValid = action.payload
            state.authToken = GlobalStorage.get('auth_token')
            // setAuthToken(GlobalStorage.get('auth_token'))
        },
        setCurrentSession(state, action) {
            state.currentSession = action.payload
        },
        addSession(state, action) {
            state.sessionList = [...state.sessionList, action.payload]
        },

        setSessionModel(state, action) {
            let arr = state.sessionList
            let obj = state.currentSession?.session
            let newArr: any = []
            arr.forEach(item => {
                if (item.session == obj) {
                    newArr.push({ ...item, model: action.payload.value, modelId: action.payload.label })
                } else {
                    newArr.push(item)
                }
            })
            state.sessionList = newArr
        },
        setSessionList(state, action) {
            state.sessionList = action.payload
        },
        setSelectExample(state, action) {
            state.selectExample = action.payload
        },
        updateSessionList(state, action) {
            state.sessionList.map((session, index) => {
                if (session.session == action.payload.session) {
                    state.sessionList[index].chatHistoryRecord = action.payload.chatHistoryRecord
                }
            })
        },

        setMenuOpen(state, action) {
            state.menuOpen = action.payload
        },
        setShowLogin(state, action) {
            state.showLogin = action.payload
        },
        setIsShowWelcomePage(state, action) {
            state.isShowWelcomePage = action.payload
        },
        setNoticeList(state, action) {
            state.noticeList = action.payload
        },
        setCountryArea(state, action) {
            state.countryArea = action.payload
        },
        setPromptHelpers(state, action) {
            state.promptHelpers = action.payload
        },
        setPromptHelpersLanguage(state, action) {
            state.promptHelpersLanguage = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadAppDetails.pending, state => {
                // state.loading = true
            })
            .addCase(loadAppDetails.fulfilled, (state, action) => {
                setAll(state, action.payload)
                state.loading = false
            })
            .addCase(loadAppDetails.rejected, (state, { error }) => {
                state.loading = false
            })
    },
})
const baseInfo = (state: RootState) => state.app
export default appSlice.reducer
export const {
    fetchAppSuccess,
    setLoading,
    setpPassword,
    setUserName,
    setAuthToken,
    setVistorAuthToken,
    setUniqueCode,
    setIsValid,
    setCurrentSession,
    addSession,
    updateSessionList,
    setSessionList,
    setSelectExample,
    setMenuOpen,
    setShowLogin,
    setIsShowWelcomePage,
    setSessionModel,
    setCountryArea,
    setNoticeList,
    setPromptHelpersLanguage,
    setPromptHelpers
} = appSlice.actions

export const getAppState = createSelector(baseInfo, app => app)
