import { AppDispatch } from '@/store'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import Macy from 'macy'
import { ISdModelListItem, setMacy } from '@/slices/sdModelListSlice'
import { useAppSelector } from '@/hooks'
import SdModelListContentItem from './SdModelListContentItem'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { debounce } from '@mui/material'

export interface ISdModelListContentRef {}
export interface ISdModelListContentProps {
    className?: string
    modelList: ISdModelListItem[]
}
const SdModelListContent = React.forwardRef<ISdModelListContentRef, ISdModelListContentProps>((props, ref) => {
    const dispatch = useDispatch<AppDispatch>()
    const { t, i18n } = useTranslation()
    const state = useAppSelector(state => state.sdModelListSlice)
    const macyContainerRef = useRef(null)
    const { modelList } = props

    useEffect(() => {
        if (state.macy) {
            // state.macy.runOnImageLoad(function () {
            //     // console.log('I only get called when all images are loaded', state.modelList)
            //     state.macy.recalculate(true, true)
            // })

            state.macy.runOnImageLoad(function () {
                debounce(() => {
                    // console.log('Every time an image loads I get fired')
                    state.macy.recalculate(true)
                }, 300)
            }, true)
        }
    }, [modelList, state.macy])

    // useEffect(() => {
    //     if (state.errorToast) {
    //         message.error(state.errorToast)
    //     }
    // }, [state.errorToast])

    useEffect(() => {
        const macy = Macy({
            container: macyContainerRef.current,
            trueOrder: false,
            waitForImages: false,
            mobileFirst: true,
            breakAt: {
                2100: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 6,
                },
                1700: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 5,
                },
                1300: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 4,
                },
                900: {
                    margin: {
                        x: 12,
                        y: 12,
                    },
                    columns: 3,
                },
                0: {
                    margin: {
                        x: 8,
                        y: 8,
                    },
                    columns: 2,
                },
            },
        })

        macy.on(macy.constants.EVENT_IMAGE_COMPLETE, function (ctx) {})

        dispatch(setMacy(macy))
        return () => {}
    }, [])

    return (
        <div className={props.className}>
            <div ref={macyContainerRef} className="min-h-0">
                {modelList.map((model, index) => (
                    <SdModelListContentItem key={model.modelId} model={model} index={index} />
                ))}
            </div>
        </div>
    )
})

export default SdModelListContent
