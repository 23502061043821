import React from 'react'
import ListItem from './ListItem'
import { Button, ConfigProvider, Avatar, Card, message } from 'antd'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { deleteResource } from '@/api/stableDiffusionRequest'
import { useTranslation } from 'react-i18next'
import loading_static from '../../../assets/loading_static.png'

export default function CreateResources(props) {
    const { showModal, getResources } = props
    const { t } = useTranslation()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const resources = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.resourcesList
    })
    const deleteResourceBtn = resourceId => {
        console.log('🚀 ~ file: CreateResources.tsx:19 ~ deleteResourceBtn ~  resourceId:', resourceId)
        deleteResource({ authToken, resourceId }).then((res: any) => {
            if (res.code == 1) {
                getResources({ authToken })
            } else {
                message.error(res.message)
            }
        })
    }

    return (
        <div>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#6481fc',
                        colorBgBase: '#171717',
                        colorTextBase: '#fff',
                        controlTmpOutline: '#393939',
                    },
                    components: {
                        Button: {
                            colorPrimary: '#00b96b',
                            colorBorder: '#c84ec4',
                        },
                        Card: {},
                    },
                }}
            >
                <button
                    className="block m-bg-gradient h-[40px] w-[150px] rounded-[20px] text-base font-medium disabled:from-gray-500 disabled:to-gray-500 text-white/90"
                    onClick={showModal}
                >
                    {t('Training&Datasets.CREATE_NEW_RESOURCES')}
                </button>
                {resources.length > 0 ? (
                    <div className="mt-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 gap-4">
                        {resources.map((item, index) => {
                            return <ListItem resourceInfo={item} deleteResourceBtn={deleteResourceBtn} key={index} />
                        })}
                        {/* {resources.map((item, index) => {
                        return <ListItem resourceInfo={item} deleteResourceBtn={deleteResourceBtn} key={index} />
                    })}
                    {resources.map((item, index) => {
                        return <ListItem resourceInfo={item} deleteResourceBtn={deleteResourceBtn} key={index} />
                    })}
                    {resources.map((item, index) => {
                        return <ListItem resourceInfo={item} deleteResourceBtn={deleteResourceBtn} key={index} />
                    })} */}
                    </div>
                ) : (
                    <div className="h-[40vh] flex flex-col justify-center items-center text-gray-500">
                        <img className="w-32" src={loading_static} />
                        <span className="mt-4">{t('Training&Datasets.generateProgressStatus')}</span>
                    </div>
                )}
            </ConfigProvider>
        </div>
    )
}
