import { Checkbox, Divider, Dropdown, Image as AntImage, MenuProps, Spin, Tooltip } from 'antd'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RcFile } from 'antd/es/upload'

import InputSlider from './InputSlider'
import TooltipInfo from '@/pages/Midjourney/ToolTipInfo'
import { useAppSelector } from '@/hooks'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import model_modal_title_arrow from '../../../assets/modelModal/model_modal_title_arrow.png'
import deleteIcon from '../../../assets/aiimage_delete.png'
import AIImageUpload from '../AIImageUpload'
import { IGenerateStateDiffusionState, setStableDiffusionState, updateSd_controlNets, updateSd_modelConfig } from '@/slices/GenerateImageSlice'
import { t } from 'i18next'
import { useAliyunOSSContext } from '@/lib/AliyunOSS'
import { getUuid } from '@/helpers'
import { blobToBase64 } from '@/api/uploadFiles'
import ImageCanvas from './ImageCanvas'
import { ReadStream } from 'fs'
import { getControlnetConfig } from '@/api/stableDiffusionRequest'
import { useMyTranslation } from '@/components/TranslationConfig/TranslationConfig'

const TipInfo: React.FC<{ title: string; className?: string }> = ({ title, className }) => {
    return (
        <Tooltip title={title}>
            <div data-tool-target="tooltip-default" className={className}>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 16.8334C13.4978 16.8334 16.3334 13.9978 16.3334 10.5C16.3334 7.00222 13.4978 4.16669 10 4.16669C6.50222 4.16669 3.66669 7.00222 3.66669 10.5C3.66669 13.9978 6.50222 16.8334 10 16.8334Z"
                        stroke="white"
                        strokeOpacity="0.5"
                        strokeWidth="1.2"
                        strokeLinecap="square"
                    ></path>
                    <path
                        d="M9.25 7.75C9.25 7.94891 9.32902 8.13968 9.46967 8.28033C9.61032 8.42098 9.80109 8.5 10 8.5C10.1989 8.5 10.3897 8.42098 10.5303 8.28033C10.671 8.13968 10.75 7.94891 10.75 7.75C10.75 7.55109 10.671 7.36032 10.5303 7.21967C10.3897 7.07902 10.1989 7 10 7C9.80109 7 9.61032 7.07902 9.46967 7.21967C9.32902 7.36032 9.25 7.55109 9.25 7.75Z"
                        fill="white"
                        fillOpacity="0.5"
                    ></path>
                    <path
                        d="M10.375 9.5H9.625C9.55625 9.5 9.5 9.55625 9.5 9.625V13.875C9.5 13.9437 9.55625 14 9.625 14H10.375C10.4438 14 10.5 13.9437 10.5 13.875V9.625C10.5 9.55625 10.4438 9.5 10.375 9.5Z"
                        fill="white"
                        fillOpacity="0.5"
                    ></path>
                </svg>
            </div>
        </Tooltip>
    )
}
export default function ControlNetSetting() {
    const { requestOssClient, multipartUpload, defaultUpload, setOSSProgress } = useAliyunOSSContext()
    const { t } = useMyTranslation()

    const [controlNetCollapsed, setControlNetCollapsed] = useState<boolean>(true)
    const state = useAppSelector(state => {
        return state.generateImageSlice.stableDiffusion
    })

    const authToken = useAppSelector(state => {
        return state.app?.authToken
    })
    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (authToken) {
            getControlnetConfig({ authToken }).then(res => {
                console.log(res)
                if (res.code == 1) {
                    setControlNetType(res.data.controlTypes)
                    setControlNetTypeName(res.data.controlTypes[0].name)
                    setControlNetTypePreprocessor(res.data.controlTypes[0].preprocessors)
                    setControlNetParams(res.data.controlTypes[0].subParameters)
                    setControlNetTypePreprocessorName(
                        res.data.controlTypes[0].preprocessors.length != 0 ? res.data.controlTypes[0].preprocessors[0].dicName : '--',
                    )
                    setControlMode(res.data.controlModes)
                    setControlModeName(res.data.controlModes[0].dicName)
                    setResizeMode(res.data.resizeModes)
                    setResizeModeName(res.data.resizeModes[0].dicName)
                }
            })
        }
    }, [authToken])
    async function handleUploadFile(e) {
        const files = [e]
        if (files && files.length > 0) {
            const f = files[0]

            try {
                let ossInfo = await requestOssClient(2)

                updateState({ uploadControlNetProgress: 1 })

                const fileExtension = f.name.split('.').pop().toLowerCase()
                const newFileName = getUuid() + '.' + fileExtension
                const filePath = ossInfo.dataDir
                console.log('🚀 ~ file: ControlNetSetting.tsx:98 ~ handleUploadFile ~ filePath:', filePath)
                let result = await defaultUpload({ client: ossInfo.client, file: f, filePath: filePath, fileName: newFileName })
                console.log('🚀 ~ file: ControlNetSetting.tsx:100 ~ handleUploadFile ~ result:', result)

                updateState({ uploadControlNetProgress: 0 })
                blobToBase64(f, base64String => {
                    updateState({ controlNetSingleImgUrl: { url: result.name, base64StringOrUrl: base64String } })
                    // updateState({ uploadResponse: { url: result.name, base64StringOrUrl: base64String } })
                })
            } catch (error) {
                updateState({ uploadControlNetProgress: 0 })
            }
        }
    }

    const [controlNetType, setControlNetType] = useState([])
    const controlNetItem: MenuProps['items'] = controlNetType.map((item, i) => {
        return {
            key: String(i),
            label: (
                <div className="flex items-center h-5">
                    <span>{item.name}</span>
                    <TipInfo title={t(item.desc)} className="inline-block" />
                </div>
            ),
        }
    })
    const [controlNetTypeName, setControlNetTypeName] = useState('')
    const [controlNetTypePreprocessor, setControlNetTypePreprocessor] = useState<any>([])
    const controlNetTypePreprocessorItem: MenuProps['items'] = controlNetTypePreprocessor.map((item, i) => {
        return {
            key: String(i),
            label: (
                <div className="flex items-center h-5">
                    <span>{t(item.dicName)}</span>
                </div>
            ),
        }
    })

    const [controlNetTypePreprocessorName, setControlNetTypePreprocessorName] = useState('')
    const [controlNetParams, setControlNetParams] = useState<any[]>([])
    const [controlMode, setControlMode] = useState([])
    const controlModeItem: MenuProps['items'] = controlMode.map((item, i) => {
        return {
            key: String(i),
            label: (
                <div className="flex items-center h-5">
                    <span>{t(`generatedImage.ControlNet.${item.dicName}`)}</span>
                </div>
            ),
        }
    })
    const [controlModeName, setControlModeName] = useState('')
    const [resizeMode, setResizeMode] = useState([])
    const resizeModeItem: MenuProps['items'] = resizeMode.map((item, i) => {
        return {
            key: String(i),
            label: (
                <div className="flex items-center h-5">
                    <span>{t(`generatedImage.ControlNet.${item.dicName}`)}</span>
                </div>
            ),
        }
    })
    const [resizeModeName, setResizeModeName] = useState('')

    return (
        <div className="mt-4">
            <Divider className="my-0" />
            <div
                className="text-sm h-10 flex justify-between items-center"
                onClick={e => {
                    setControlNetCollapsed(pre => !pre)
                }}
            >
                {/* <span>{t('generatedImage.AdvancedSetting')}</span> */}
                <span>ControlNet</span>

                <img className={`w-4 transition-transform ${controlNetCollapsed ? 'rotate-0' : 'rotate-180'}`} src={model_modal_title_arrow} />
            </div>
            <div className={`space-y-1 overflow-clip transition-all ${controlNetCollapsed ? 'max-h-0 opacity-0' : 'max-h-[50rem] opacity-100'}`}>
                <Spin spinning={state.uploadControlNetProgress > 0}>
                    <div className="relative">
                        {state.controlNetSingleImgUrl.url && state.controlNetSingleImgUrl.url.length > 0 ? (
                            <ImageCanvas controlNetTypeName={controlNetTypeName} />
                        ) : (
                            <AIImageUpload
                                handleFile={function (file: string | Blob | RcFile): void {
                                    handleUploadFile(file)
                                }}
                            />
                        )}
                    </div>
                </Spin>
                <div className="flex justify-between items-center text-xs">
                    <div>
                        <Checkbox
                            checked={state.isConcontrolNet}
                            onChange={e => {
                                updateState({ isConcontrolNet: !state.isConcontrolNet })
                            }}
                        >
                            <span className="whitespace-nowrap">{t('generatedImage.ControlNet.Enable')}</span>
                        </Checkbox>
                    </div>
                    <Dropdown
                        placement="bottomLeft"
                        trigger={['click']}
                        menu={{
                            className: 'dark-thin-scrollbar',
                            style: { height: '12rem', overflowY: 'auto' },
                            items: controlNetItem,
                            onClick: ({ key, keyPath, domEvent }) => {
                                dispatch(
                                    updateSd_controlNets({
                                        singleImgUrl: '',
                                        pixelPerfect: true,
                                        preprocessorId: controlNetType[parseInt(key)].preprocessors[0].systemDicId,
                                        weight: 1, //0~2 默认1
                                        startingControlStep: 0, //0-1 默认0
                                        endingControlStep: 1, //0~1 默认1
                                        controlMode: 0, // 默认0
                                        resizeMode: 1, // 0-Just Resize 1-Crop and Resize 2-Resize and Fill 默认1

                                        maskImgUrl: '',
                                        cannyLowThreshold: 100, //1-255 默认100
                                        cannyHighThreshold: 200, //1-255 默认100
                                        mlsdValueThreshold: 0.1, //0.01-20 默认1
                                        mlsdDistanceThreshold: 0.1, //0.01-20 默认1
                                        downSamplingRate: 1, //1-8 默认1
                                        styleFidelity: 0.5, //0-1 默认0.5
                                    }),
                                )
                                updateState({ controlNetMaskImgUrl: { url: '', base64StringOrUrl: '' } })

                                setControlNetTypeName(controlNetType[parseInt(key)].name)
                                setControlNetTypePreprocessor(
                                    controlNetType[parseInt(key)].preprocessors ? controlNetType[parseInt(key)].preprocessors : [],
                                )

                                setControlNetTypePreprocessorName(
                                    controlNetType[parseInt(key)].preprocessors ? controlNetType[parseInt(key)].preprocessors[0].dicName : '',
                                )
                                setControlNetParams(controlNetType[parseInt(key)].subParameters ? controlNetType[parseInt(key)].subParameters : [])

                                dispatch(updateSd_controlNets({ preprocessorId: controlNetType[parseInt(key)].preprocessors[0].systemDicId }))
                            },
                        }}
                    >
                        <div className="flex justify-between items-center bg-black rounded-md w-full">
                            <div className="text-xs h-[30px] leading-[30px] px-[11px]">{controlNetTypeName}</div>
                            <div>
                                <img className="scale-75 mr-1" src={model_modal_title_arrow} />
                            </div>
                        </div>
                    </Dropdown>
                </div>
                {/* {controlNetTypeName == 'OpenPose' && (
                    <button
                        className="w-56 h-11 m-bg-gradient rounded-lg px-3 shrink-0 m-0.5 text-base"
                        onClick={() => {
                            console.log('sssss')
                        }}
                    >
                    
                        3D Openpose
                    </button>
                )} */}
                {controlNetTypeName != 'IP2P' && (
                    <div className="text-xs">
                        <div className="mr-1 mb-2 text-xs text-[#4A4A4A]">Preprocessor</div>
                        <Dropdown
                            placement="bottomLeft"
                            trigger={['click']}
                            menu={{
                                className: 'dark-thin-scrollbar',

                                style: { overflowY: 'auto' },
                                items: controlNetTypePreprocessorItem,
                                onClick: ({ key, keyPath, domEvent }) => {
                                    setControlNetTypePreprocessorName(controlNetTypePreprocessor[parseInt(key)].dicName)
                                    dispatch(updateSd_controlNets({ preprocessorId: controlNetTypePreprocessor[parseInt(key)].systemDicId }))
                                },
                            }}
                        >
                            <div className="flex justify-between items-center bg-black rounded-md">
                                <div className="text-xs h-[30px] leading-[30px] px-[11px] truncate">{controlNetTypePreprocessorName}</div>
                                <div>
                                    <img className="scale-75 mr-1" src={model_modal_title_arrow} />
                                </div>
                            </div>
                        </Dropdown>
                    </div>
                )}

                {/* Steps */}
                <div className="flex justify-between items-center text-xs">
                    <span className="flex items-center whitespace-nowrap w-3/6">
                        <span>{t('generatedImage.ControlNet.ControlWeight')}</span>
                    </span>
                    <InputSlider
                        min={0}
                        max={2}
                        step={0.05}
                        value={state.controlNets.weight}
                        onChange={function (newValue: number): void {
                            dispatch(updateSd_controlNets({ weight: newValue }))
                        }}
                    />
                </div>
                <div className="flex justify-between items-center text-xs">
                    <span className="flex items-center whitespace-nowrap w-3/6">
                        <span>{t('generatedImage.ControlNet.StartingControlStep')}</span>
                    </span>
                    <InputSlider
                        min={0}
                        max={1}
                        step={0.01}
                        value={state.controlNets.startingControlStep}
                        onChange={function (newValue: number): void {
                            dispatch(updateSd_controlNets({ startingControlStep: newValue }))
                        }}
                    />
                </div>
                <div className="flex justify-between items-center text-xs">
                    <span className="flex items-center whitespace-nowrap w-3/6">
                        <span>{t('generatedImage.ControlNet.EndingControlStep')}</span>
                    </span>
                    <InputSlider
                        min={0}
                        max={1}
                        step={0.01}
                        value={state.controlNets.endingControlStep}
                        onChange={function (newValue: number): void {
                            dispatch(updateSd_controlNets({ endingControlStep: newValue }))
                        }}
                    />
                </div>
                {controlNetParams.map((item, index) => {
                    return (
                        <div className="flex justify-between items-center text-xs" key={index}>
                            <span className="flex items-center w-20">
                                <span>{t(item.name)}</span>
                            </span>
                            <InputSlider
                                min={
                                    item.name == 'Canny Low Threshold' || item.name == 'Canny High Threshold' || item.name == 'Down Sampling Rate'
                                        ? 1
                                        : item.name == 'MLSD Value Threshold' || item.name == 'MLSD Distance Threshold'
                                        ? 0.01
                                        : 0
                                }
                                max={
                                    item.name == 'MLSD Value Threshold'
                                        ? 2
                                        : item.name == 'MLSD Distance Threshold'
                                        ? 20
                                        : item.name == 'Down Sampling Rate'
                                        ? 8
                                        : item.name == 'Style Fidelity'
                                        ? 1
                                        : 255
                                }
                                step={item.name == 'Canny Low Threshold' || item.name == 'Canny High Threshold' ? 1 : 0.01}
                                value={state.controlNets[item.key]}
                                onChange={function (newValue: number): void {
                                    dispatch(updateSd_controlNets({ [item.key]: newValue }))
                                }}
                            />
                        </div>
                    )
                })}
                <div className="text-xs">
                    <div className="mr-1 mb-2 text-xs text-[#4A4A4A]">
                        <span>{t('generatedImage.ControlNet.ControlMode')}</span>
                    </div>
                    <Dropdown
                        placement="bottomLeft"
                        trigger={['click']}
                        menu={{
                            className: 'dark-thin-scrollbar',
                            style: { overflowY: 'auto' },
                            items: controlModeItem,
                            onClick: ({ key, keyPath, domEvent }) => {
                                setControlModeName(controlMode[parseInt(key)].dicName)
                                dispatch(updateSd_controlNets({ controlMode: controlMode[parseInt(key)].systemDicId }))
                            },
                        }}
                    >
                        <div className="flex justify-between items-center bg-black rounded-md">
                            <div className="text-xs h-[30px] leading-[30px] px-[11px]">
                                {<span>{t(`generatedImage.ControlNet.${controlModeName}`)}</span>}
                            </div>
                            <div>
                                <img className="scale-75 mr-1" src={model_modal_title_arrow} />
                            </div>
                        </div>
                    </Dropdown>
                </div>
                <div className="text-xs">
                    <div className="mr-1 mb-2 text-xs text-[#4A4A4A]">
                        <span>{t('generatedImage.ControlNet.ResizeMode')}</span>
                    </div>
                    <Dropdown
                        placement="bottomLeft"
                        trigger={['click']}
                        menu={{
                            className: 'dark-thin-scrollbar',
                            style: { overflowY: 'auto' },
                            items: resizeModeItem,
                            onClick: ({ key, keyPath, domEvent }) => {
                                setResizeModeName(resizeMode[parseInt(key)].dicName)
                                dispatch(updateSd_controlNets({ resizeMode: resizeMode[parseInt(key)].systemDicId }))
                            },
                        }}
                    >
                        <div className="flex justify-between items-center bg-black rounded-md">
                            <div className="text-xs h-[30px] leading-[30px] px-[11px]">{t(`generatedImage.ControlNet.${resizeModeName}`)}</div>
                            <div>
                                <img className="scale-75 mr-1" src={model_modal_title_arrow} />
                            </div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}
