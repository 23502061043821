import React, { useCallback, useEffect, useRef, useState } from 'react'
import Macy from 'macy'
import { ISdModelListItem } from '@/slices/sdModelListSlice'
import { useTranslation } from 'react-i18next'
import { sdHomeModelList } from '@/api/request'
import { getWaterHistoryImageList } from '@/api/newFunctionRequest'
import { message } from 'antd'
import { useAppSelector } from '@/hooks'

export interface IHomeModelListProps {
    // modelList: ISdModelListItem[]
}
const HomeWatersHistoryList: React.FC<IHomeModelListProps> = props => {
    const macyContainerRef = useRef(null)
    const [macy, setMacy] = useState<any>()
    const [modelList, setModelList] = useState<{ canV: number; taskId: string; url: string }[]>([])
    const vistorAuthToken = useAppSelector(state => {
        return state.app && state.app.vistorAuthToken
    })
    useEffect(() => {
        let getFun = async () => {
            const res = await getWaterHistoryImageList({ page: 1, authToken: vistorAuthToken })
            if (res.code == 1) {
                setModelList(res.data.images)
            } else {
                message.error(res.message)
            }
        }
        if (vistorAuthToken) {
            getFun()
        }
    }, [vistorAuthToken])

    useEffect(() => {
        if (modelList.length > 0 && macy) {
            // state.macy.runOnImageLoad(function () {
            //     // console.log('I only get called when all images are loaded', state.modelList)
            //     state.macy.recalculate(true, true)
            // })

            macy.runOnImageLoad(function () {
                macy.recalculate(true)
            }, true)
        }
    }, [modelList, macy])

    useEffect(() => {
        const macy = Macy({
            container: macyContainerRef.current,
            trueOrder: false,
            waitForImages: false,
            mobileFirst: true,
            breakAt: {
                // 2100: {
                //     margin: {
                //         x: 16,
                //         y: 16,
                //     },
                //     columns: 6,
                // },
                1700: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 5,
                },
                1300: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 4,
                },
                900: {
                    margin: {
                        x: 12,
                        y: 12,
                    },
                    columns: 3,
                },
                0: {
                    margin: {
                        x: 8,
                        y: 8,
                    },
                    columns: 2,
                },
            },
        })

        macy.on(macy.constants.EVENT_IMAGE_COMPLETE, function (ctx) {})

        setMacy(macy)
        return () => {}
    }, [])

    return (
        <div>
            <div ref={macyContainerRef} className="">
                {modelList.map((item, index) => (
                    <div key={index} className="w-0">
                        <div
                            className="group relative rounded-lg bg-gray-bg overflow-hidden"
                            // key={model.pathUrl}
                            // onClick={() => {
                            //     //  showPreview(true, index)
                            // }}
                        >
                            <img
                                src={item.url + '?x-oss-process=image/resize,w_700/quality,Q_80'}
                                className="rounded-lg lg:rounded-none block w-full"
                                alt=""
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HomeWatersHistoryList
