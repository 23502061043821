import { Modal, Input, message, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { Button, ConfigProvider, Avatar, Card } from 'antd'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons'
import CreateResources from '@/components/TrainingAndDatasets/YourDatasets/CreateResources'
import { createResource, getOssAssumeRoleToken, getResourcesList } from '@/api/stableDiffusionRequest'
import { setResourcesList, setResourcesListItem } from '@/slices/StableDiffusionSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import EditDataset from '@/components/TrainingAndDatasets/YourDatasets/EditDataset'
import CreateResourcesModal from '@/components/TrainingAndDatasets/YourDatasets/CreateResourcesModal'
import { useTranslation } from 'react-i18next'
export default function Index(props) {
    const { setSelectedBtn } = props
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)
    const dispatch = useAppDispatch()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const resourcesListItem = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.resourcesListItem
    })
    async function getResources(token) {
        if (token) {
            const res = await getResourcesList({ authToken })
            if (res.code == 1) {
                dispatch(setResourcesList(res.data.resources))
            }
        }
    }
    useEffect(() => {
        getResources(authToken)
    }, [authToken])
    const [resourceName, setResourceName] = useState('')
    const [description, setDescription] = useState('')

    const createResourceBtn = async () => {
        if (resourceName && description) {
            const createResourceResult = await createResource({ authToken: authToken, name: resourceName, description })
            if (createResourceResult.code == 1) {
                console.log('🚀 ~ file: index.jsx:34 ~ createResourceBtn ~ createResourceResult:', createResourceResult)
                message.success(createResourceResult.message)

                handleCancel()
                setResourceName('')
                setDescription('')
                // setResourceId(res.data.resourceId)
                const getOssAssumeRoleTokenResult = await getOssAssumeRoleToken({
                    dataId: createResourceResult.data.resourceId,
                    dataType: 1,
                    authToken: authToken,
                })
                if (getOssAssumeRoleTokenResult.code == 1) {
                    console.log('🚀 ~ file: index.jsx:46 ~ createResourceBtn ~ getOssAssumeRoleTokenResult:', getOssAssumeRoleTokenResult)
                    dispatch(setResourcesListItem({ ...createResourceResult.data, name: resourceName, description: description }))

                    getResources(authToken)
                    // setUploading(true)
                    // const client = uploadFiles2OSS(getOssAssumeRoleTokenResult.data)
                    // let uploadQueue = fileList.map(async item => {
                    //     let result = uploadFileToOss(client, item, getOssAssumeRoleTokenResult.data)
                    //     console.log('🚀 ~ file: CreateResources.tsx:44 ~ uploadQueue ~ result:', result)
                    //     return result
                    // })
                    // console.log('🚀 ~ file: CreateResources.tsx:47 ~ uploadQueue ~ uploadQueue:', uploadQueue)
                    // Promise.all(uploadQueue).then(res => {
                    //     console.log('res: ', res)
                    //     setFileList([])
                    //     setResourceName('')
                    //     message.success(getOssAssumeRoleTokenResult.message)
                    //     setUploading(false)
                    // })
                }
            } else {
                message.error(createResourceResult.message)
            }
        } else {
            message.warning('请输入资源名称')
        }
    }

    const showModal = e => {
        message.error(t('Training&Datasets.WAITONLINETEXT'))
        // console.log(e)
        // setOpen(true)
    }
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        // <Spin tip="Loading" size="large">
        <div>
            {resourcesListItem !== null ? (
                <EditDataset setSelectedBtn={setSelectedBtn} getResources={getResources} />
            ) : (
                <CreateResources showModal={showModal} getResources={getResources} />
            )}
            <CreateResourcesModal
                title={t('Training&Datasets.CREATE_NEW_RESOURCES')}
                open={open}
                onOk={createResourceBtn}
                // onOk={() => {
                //     message.error('暂未开放')
                // }}
                onCancel={handleCancel}
                footer={false}
                onChange={setResourceName}
                setDescription={setDescription}
                resourceName={resourceName}
                description={description}
            />
        </div>
        // </Spin>
    )
}
