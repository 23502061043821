import { useState } from 'react'
import ModelSelect from './ModelSelect'
import ModelCreate from './ModelCreate'
import { useTranslation } from 'react-i18next'

export default function TrainingModel(params) {
    const { t } = useTranslation()
    const btnList = [
        // { name: 'Select', index: 0 },
        { name: t('training.model.createTitle'), index: 1 },
    ]
    const [selectedBtn, setSelectedBtn] = useState(1)

    return (
        <div className="flex flex-col p-2 mt-2 rounded w-full lg:w-1/3">
            <div>{t('training.model.title')}</div>
            <div className="tabs flex flex-col mt-2">
                <div className="flex border-b-2 border-trainBorderColor flex-wrap dark:border-gray-700">
                    {btnList.map((btnItem, index) => {
                        return (
                            <button
                                className={selectedBtn == btnItem.index ? 'm-tab-btn-selected' : 'm-tab-btn'}
                                key={btnItem.index}
                                onClick={() => {
                                    setSelectedBtn(btnItem.index)
                                }}
                            >
                                {btnItem.name}{' '}
                            </button>
                        )
                    })}
                </div>
                <div className=" tabitem p-2 border-2 border-t-0 border-trainBorderColor relative flex flex-col">
                    {/* {selectedBtn == 'Select' && <ModelSelect></ModelSelect>} */}
                    {selectedBtn == 1 && <ModelCreate></ModelCreate>}
                </div>
            </div>
        </div>
    )
}
