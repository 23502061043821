import { useAppSelector } from '@/hooks'
import closeImage from '../../../../assets/photoPreview/close.png'
import { setDetailType } from '@/slices/sdModelListSlice'
import { setNoticeList } from '@/slices/AppSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import styles from './index.module.css'
export default function index() {
    const dispatch = useDispatch<AppDispatch>()
    const noticeList = useAppSelector(state => state.app && state.app.noticeList)
    return (
        <div className={styles.notice}>
            <div className={styles.notice__inner}>
                <span className={styles.notice__item + ' ' + styles['notice__item-first']}>{noticeList[0].content}</span>
                <button
                    className="absolute top-3 right-3"
                    onClick={() => {
                        dispatch(setNoticeList(null))
                        // setModelDetail({})
                    }}
                >
                    <img src={closeImage} className="w-[14px] h-[14px]" alt="" />
                </button>
            </div>
        </div>
    )
}
