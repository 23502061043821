import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '@/hooks'
import deleteIcon from '../../../assets/aiimage_delete.png'
import cancel_icon from '../../../assets/sd/controlNet/cancel.png'
import back_icon from '../../../assets/sd/controlNet/back.png'
import erase_icon from '../../../assets/sd/controlNet/erase.png'
import pen_icon from '../../../assets/sd/controlNet/pen.png'

import { IGenerateStateDiffusionState, setStableDiffusionState } from '@/slices/GenerateImageSlice'
import { AppDispatch } from '@/store'
import { useDispatch } from 'react-redux'
import CanvasDraw from 'react-canvas-draw'
import { reverseColorsBase64Image } from '@/helpers'
export default function ImageCanvas(props) {
    const dispatch = useDispatch<AppDispatch>()
    const { controlNetTypeName } = props
    const state = useAppSelector(state => {
        return state.generateImageSlice.stableDiffusion
    })
    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))

    const containerRef = useRef<HTMLDivElement | null>(null)

    const [canvasProps, setCanvasProps] = useState({
        canvasWidth: 400,
        canvasHeight: 400,
        hideGrid: true,
        brushRadius: 10,
        lazyRadius: 0,
        brushColor: '#000',
        onChange: () => getDateUrl(containerRef.current),
    })
    useEffect(() => {
        var image = new Image()
        image.src = state.controlNetSingleImgUrl.base64StringOrUrl
        image.onload = function () {
            setCanvasProps({
                ...canvasProps,
                canvasWidth: 240,
                canvasHeight: Math.ceil((this.height * 240) / this.width),
            })
        }
    }, [])

    const getDateUrl = ctx => {
        reverseColorsBase64Image(ctx.getDataURL()).then(res => {
            updateState({ controlNetMaskImgUrl: { url: '', base64StringOrUrl: res } })
        })
    }
    return (
        <>
            <div className="absolute right-[4px] top-[4px] text-white/90 flex space-x-1 z-20">
                <button
                    onClick={e => {
                        e.stopPropagation()
                        updateState({ controlNetSingleImgUrl: { url: '', base64StringOrUrl: '' } })
                    }}
                >
                    <img src={cancel_icon} />
                </button>
            </div>

            {controlNetTypeName == 'Inpaint' ? (
                <>
                    <div className="absolute right-[4px] top-[44px] text-white/90 flex space-x-1 z-20">
                        <button
                            onClick={e => {
                                containerRef.current?.eraseAll()
                            }}
                        >
                            <img src={erase_icon} />
                        </button>
                    </div>
                    <div className="absolute right-[4px] top-[83px] text-white/90 flex space-x-1 z-20">
                        <button
                            onClick={e => {
                                containerRef.current?.undo()
                            }}
                        >
                            <img src={back_icon} />
                        </button>
                    </div>

                    <CanvasDraw
                        className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-menuBgColor"
                        style={{ background: 'rgba(0,0,0,0)' }}
                        {...canvasProps}
                        ref={canvasDraw => (containerRef.current = canvasDraw)}
                    />
                    <img src={state.controlNetSingleImgUrl.base64StringOrUrl} alt="" />
                </>
            ) : (
                <img src={state.controlNetSingleImgUrl.base64StringOrUrl} alt="" />
            )}
        </>
    )
}
