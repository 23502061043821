import React, { useEffect, useState, forwardRef } from 'react'

import preImage from '../../assets/photoPreview/pre.png'
import nextImage from '../../assets/photoPreview/next.png'
import closeImage from '../../assets/photoPreview/close.png'
import refreshImage from '../../assets/photoPreview/refresh.png'
import copyImage from '../../assets/photoPreview/copy.png'
import copyWhiteImage from '../../assets/photoPreview/copy_white.png'
import useModelImage from '../../assets/photoPreview/useModel.png'
import deleteImage from '../../assets/photoPreview/delete.png'
import downloadImage from '../../assets/photoPreview/download.png'
import deleteWhiteImage from '../../assets/photoPreview/delete_white.png'
import downloadWhiteImage from '../../assets/photoPreview/download_white.png'
import largeImage from '../../assets/photoPreview/enlarge1.png'
import largWwhiteImage from '../../assets/photoPreview/enlarge_white.png'
import useInitImage from '../../assets/photoPreview/useInit.png'
import useInitWhiteImage from '../../assets/photoPreview/useInitWhite.png'
import variationImage from '../../assets/photoPreview/variation.png'
import variationWhiteImage from '../../assets/photoPreview/variationWhite.png'
import generateImageImage from '../../assets/photoPreview/generateImage.png'
import generateImageWhiteImage from '../../assets/photoPreview/generationImageWhite.png'
import seedImage from '../../assets/photoPreview/seed.png'
import { useMjLoading } from '../../pages/Midjourney/MjHooks'
import { useStLoading } from '../../pages/AIImage/StHooks'
import { message, Popover, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { historicalRecordDelete, historicalRecordDetail } from '../../api/request'
import { useAppSelector } from '@/hooks'
import moment from 'moment'
import { setSd_selectedModel, setSd_imageItemDetailShow, setStableDiffusionState, setSd_historyDetailParams } from '@/slices/GenerateImageSlice'
import { useDispatch } from 'react-redux'
import DeleteModel from '../../components/DeleteModal'
import { useHistory } from 'react-router-dom'

function index(props) {
    const { hiddenActionButton, setOpen, imageEditorM, imageObj, type, deleteImageM } = props
    const dispatch = useDispatch()
    const isDraw = useMjLoading()
    const { loadingItem, spinning } = useStLoading()
    const { t } = useTranslation()
    const [showImageEditBar, setShowImageEditBar] = useState(false)
    const [isPhone, setIsPhone] = useState(false)
    const [imageObjDetail, setImageObjDetail] = useState({})
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })

    const history = useHistory()

    const updateState = s => dispatch(setStableDiffusionState(s))
    const requestImageActions = useAppSelector(state => state.generateImageSlice.stableDiffusion.requestImageActions)

    const close = () => {
        setOpen?.(false)
        dispatch(setSd_imageItemDetailShow({ show: false }))
    }

    useEffect(() => {
        if (imageObjDetail) {
            if (imageObjDetail.mjDrawTaskMode == 'preview' || imageObjDetail.mjDrawTaskMode == 'variation') {
                let dateBegin = new Date(imageObjDetail.createTime).getTime()
                var dateNow = new Date().getTime()
                if (dateNow - dateBegin < 3600 * 1000) {
                    setShowImageEditBar(true)
                } else {
                    setShowImageEditBar(false)
                }
            } else {
                setShowImageEditBar(false)
            }
        }
    }, [imageObjDetail])

    function whatScreen() {
        var width = document.querySelector('html')?.offsetWidth
        if (width <= 1024) {
            setIsPhone(true)
        }
    }

    const btnList = [
        { name: 'U1', value: 1, mode: 'upscale' },
        { name: 'U2', value: 2, mode: 'upscale' },
        { name: 'U3', value: 3, mode: 'upscale' },
        { name: 'U4', value: 4, mode: 'upscale' },
        { name: 'R', value: 0, mode: 'preview' },
        { name: 'V1', value: 1, mode: 'variation' },
        { name: 'V2', value: 2, mode: 'variation' },
        { name: 'V3', value: 3, mode: 'variation' },
        { name: 'V4', value: 4, mode: 'variation' },
    ]

    const handelImageEditClick = btnObj => {
        if (isDraw.spinning) {
            message.error(t('generatedImage.historyRecordEditImageError'))
            return
        }
        const param = { mode: btnObj.mode, rootTaskId: imageObjDetail.taskId, rootPic: imageObjDetail.pathUrl, selectedIndex: btnObj.value }
        imageEditorM(param)
        close()
    }

    const handelEnlageImageClick = () => {
        if (spinning) {
            message.error(t('generatedImage.historyRecordEditImageError'))
            return
        }
        // enlargeImageM(imageObjDetail.taskId, imageObjDetail.width, imageObjDetail.height, imageObjDetail.pathUrl)
        history.push('/tools/AIImage', { upscale: [imageObjDetail.taskId, imageObjDetail.width, imageObjDetail.height, imageObjDetail.pathUrl] })
        // requestImageActions.upscale(imageObjDetail.taskId, imageObjDetail.width, imageObjDetail.height, imageObjDetail.pathUrl)
        close()
    }

    const deleteImageClick = async () => {
        setShowDeleteModel(true)
    }

    const copyPrompt = async () => {
        try {
            await navigator.clipboard.writeText(imageObjDetail.originPrompt)
            message.success(t('common.copySuccess'))
        } catch (err) {
            message.error(t('common.copyError'))
        }
    }

    const copySeed = async () => {
        try {
            await navigator.clipboard.writeText(imageObjDetail.mjDrawSeedId)
            message.success(t('common.copySuccess'))
        } catch (err) {
            message.error(t('common.copyError'))
        }
    }

    const useModel = async () => {
        let loraModels = []
        if (imageObjDetail.sdUseLora) {
            imageObjDetail.loraModels.map(item => {
                loraModels.push({
                    modelId: item.loraModelId,
                    weight: item.loraWeight,
                    modelName: item.modelName,
                    modelIcon: item.icon,
                    modelDrawIcon: item.drawIcon,
                })
            })
        }
        const model = {
            modelId: imageObjDetail.taskModelId,
            modelName: imageObjDetail.modelName,
            modelIcon: imageObjDetail.icon,
            modelDrawIcon: imageObjDetail.drawIcon,
            // modelDrawIcon
            // isPublic: imageObjDetail.modelIsPublic,
            // taskId: imageObjDetail.taskId,
            // modelType: imageObjDetail.modelType,
            subUsedModels: loraModels,
        }
        console.log('🚀 ~ file: index.jsx:153 ~ useModel ~ model:', model)
        // dispatch(setStableDiffusionRecentModel(model))
        dispatch(setSd_selectedModel(model))
        close()
    }

    const useImageInit = () => {
        // setInputUrl(imageObjDetail.pathUrl, imageObjDetail.pathUrl)
        history.push('/tools/AIImage', { inputImage: imageObjDetail.pathUrl })
        // updateState({ uploadResponse: { url: imageObjDetail.pathUrl, base64StringOrUrl: imageObjDetail.pathUrl } })
        close()
    }

    const generateImageClick = () => {
        console.log('🚀 ~ file: index.jsx:153 ~ useModel ~ imageObjDetail:', imageObjDetail)
        history.push('/tools/AIImage', { historyDetailParams: imageObjDetail })
        // dispatch(setSd_historyDetailParams(imageObjDetail))
        close()
    }

    const variationClick = () => {
        console.log('🚀 ~ file: index.jsx:153 ~ useModel ~ imageObjDetail:', imageObjDetail)
        // history.push('/tools/AIImage')
        history.push('/tools/AIImage', { variation: [imageObjDetail.taskId] })
        // requestImageActions.variation?.(imageObjDetail.taskId)
        close()
    }

    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const deleteModelCancelBtnClick = () => {
        setShowDeleteModel(false)
    }
    const deleteModelSureBtnClick = () => {
        deleteImageM({ taskId: imageObjDetail.taskId, taskImgId: imageObjDetail.taskImageId, authToken: authToken })
        setShowDeleteModel(false)
        close()
    }

    const [detailLoading, setDetailLoading] = useState(false)
    const historicalRecordDetailM = async () => {
        try {
            setDetailLoading(true)
            const res = await historicalRecordDetail({ taskId: imageObj.taskId, taskImgId: imageObj.taskImageId, authToken })
            console.log('🚀 ~ file: index.jsx:184 ~ historicalRecordDetailM ~ res:', res)
            if (res.code == 1) {
                setImageObjDetail(res.data.tasks[0])
            }
        } finally {
            setDetailLoading(false)
        }
    }

    useEffect(() => {
        whatScreen()
        historicalRecordDetailM()
    }, [])
    return (
        <div className={`fixed w-full top-0 left-0 z-[1000]`}>
            <DeleteModel
                open={showDeleteModel}
                title={t('preview.deleteTitle')}
                subtitle={t('preview.deleteSubtitle')}
                cancelM={deleteModelCancelBtnClick}
                sureM={deleteModelSureBtnClick}
            ></DeleteModel>
            <div
                onClick={e => {
                    if (e.target == e.currentTarget) {
                        close()
                    }
                }}
                className="flex justify-center items-center  text-[#ffffff] min-h-screen bg-gray-950 bg-opacity-70"
            >
                <Spin spinning={detailLoading}>
                    <div
                        className={`${
                            imageObjDetail.taskId ? 'block' : 'hidden'
                        } flex flex-col lg:flex-row lg:justify-between lg:items-start items-center p-2 w-[100vw] h-[100vh] max-h-[100vh] overflow-y-auto lg:w-auto lg:h-[52vw]  lg:max-h-[600px]   bg-[#1B1B1B] relative`}
                    >
                        {isPhone ? (
                            <button
                                className="w-[28px] h-[28px] bg-[#000000] bg-opacity-70 rounded-sm flex items-center justify-center absolute top-3 right-3"
                                onClick={() => {
                                    close()
                                }}
                            >
                                <img src={closeImage} className="w-[11px]" alt="" />
                            </button>
                        ) : (
                            <button
                                className=" absolute top-3 right-3"
                                onClick={() => {
                                    close()
                                }}
                            >
                                <img src={closeImage} className="w-[14px] h-[14px]" alt="" />
                            </button>
                        )}

                        <div className="flex flex-none w-full lg:w-auto lg:h-full text-[0]">
                            <img className="w-full h-full max-h-full object-contain block" src={imageObjDetail.pathUrl} alt="" />
                        </div>
                        <div className="w-full flex flex-col flex-1 justify-between lg:flex-auto lg:w-full lg:h-full lg:ml-2">
                            <div className="overflow-y-auto flex flex-col space-y-2 my-2 ">
                                <span className="text-[20px]">Prompt</span>
                                <div className="overflow-y-auto w-full lg:max-w-[300px] bg-[#0B0B0B] rounded-md p-2 flex flex-col items-center mt-[6px]">
                                    <div className="shrink w-full text-left  max-h-[30vw] overflow-y-auto dark-thin-scrollbar">
                                        <div className="text-[11px]">{imageObjDetail.originPrompt}</div>
                                        {imageObjDetail.negativePrompt && (
                                            <div className="mt-4 text-[9px] text-[#757575]">
                                                <div>negative prompt: </div>
                                                <div>{imageObjDetail.negativePrompt}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="flex flex-row items-center justify-center border-gradual w-[50%] h-[30px] rounded-md mt-3 mb-1 cursor-pointer"
                                        onClick={copyPrompt}
                                    >
                                        <img className="w-[11px]" src={copyWhiteImage} alt="" />
                                        <span className=" text-[11px] font-normal ml-2">{t('preview.copyPrompt')}</span>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-x-7 gap-y-4  text-sm font-normal py-5">
                                    {type == 'sd' && (
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-[#646464]">{t('preview.dimensions')}</span>
                                            <span>
                                                {(imageObjDetail.taskType == 4 || imageObjDetail.sdUseHires == 1
                                                    ? imageObjDetail.targetWidth
                                                    : imageObjDetail.width) +
                                                    'x' +
                                                    (imageObjDetail.taskType == 4 || imageObjDetail.sdUseHires == 1
                                                        ? imageObjDetail.targetHeight
                                                        : imageObjDetail.height)}
                                            </span>
                                        </div>
                                    )}
                                    <div className="flex flex-col space-y-2">
                                        <div className="flex items-center">
                                            <span className="text-[#646464]">{t('preview.seed')}</span>
                                            {type == 'mj' && imageObjDetail.mjDrawSeedId && (
                                                <Popover content={<div className="flex items-center justify-center">{t('preview.copySeedTip')}</div>}>
                                                    <button className="p-2" onClick={copySeed}>
                                                        <img className=" w-[11px] h-[11px]" src={copyImage} alt="" />
                                                    </button>
                                                </Popover>
                                            )}
                                        </div>
                                        <span>{type == 'sd' && (imageObjDetail.seed ? imageObjDetail.seed : '--')}</span>
                                    </div>
                                    <div className="flex flex-col space-y-2">
                                        <span className="text-[#646464]">{t('preview.dateCreated')}</span>
                                        <span>{moment(imageObjDetail.createTime).format('YYYY/MM/DD HH:mm')}</span>
                                    </div>
                                    <div className="flex flex-col space-y-2">
                                        <span className="text-[#646464]">{t('preview.Steps')}</span>
                                        <span>{imageObjDetail.samplingStep}</span>
                                    </div>
                                    <div className="flex flex-col space-y-2">
                                        <span className="text-[#646464]">{t('generatedImage.CFGScale')}</span>
                                        <span>{imageObjDetail.cfgScale}</span>
                                    </div>
                                    <div className="flex flex-col space-y-2">
                                        <Popover
                                            color="black"
                                            placement="bottomLeft"
                                            content={
                                                <ul className="flex flex-col text-sm space-y-2">
                                                    <li>
                                                        <div className="text-[#646464] text-xs">{t('generatedImage.Sampler')}</div>
                                                        <div>{imageObjDetail.samplingMethod}</div>
                                                    </li>
                                                    <li>
                                                        <div className="text-[#646464] text-xs">{t('generatedImage.ClipSkip')}</div>
                                                        <div>{imageObjDetail.clipSkip}</div>
                                                    </li>
                                                    <li>
                                                        <div className="text-[#646464] text-xs">{t('generatedImage.ENSD')}</div>
                                                        <div>{imageObjDetail.ensd}</div>
                                                    </li>
                                                    {imageObjDetail.sdUseHires == 1 && (
                                                        <>
                                                            <li>
                                                                <div className="text-[#646464] text-xs">{t('generatedImage.hires.Upscaler')}</div>
                                                                <div>{imageObjDetail.hiresUpscale}</div>
                                                            </li>
                                                            <li>
                                                                <div className="text-[#646464] text-xs">{t('generatedImage.hires.HiresSteps')}</div>
                                                                <div>{imageObjDetail.hiresUpscaleSteps}</div>
                                                            </li>
                                                            <li>
                                                                <div className="text-[#646464] text-xs">
                                                                    {t('generatedImage.hires.DenoisingStrength')}
                                                                </div>
                                                                <div>{imageObjDetail.hiresUpscaleDs}</div>
                                                            </li>
                                                            <li>
                                                                <div className="text-[#646464] text-xs">{t('generatedImage.hires.UpscalerBy')}</div>
                                                                <div>{imageObjDetail.hiresUpscaleBy}</div>
                                                            </li>
                                                        </>
                                                    )}
                                                </ul>
                                            }
                                        >
                                            <button className="m-bg-gradient bg-clip-text text-transparent text-left ml-0">
                                                {t('preview.MoreInfo')}
                                            </button>
                                        </Popover>
                                        <span></span>
                                    </div>
                                    <div className="flex flex-col space-y-2">
                                        <div className="flex items-center">
                                            <span className="text-[#646464]">{t('preview.modelName')}</span>
                                            {((imageObjDetail.loraModels && imageObjDetail.loraModels.length > 0) || imageObjDetail.vaeModel) && (
                                                <Popover
                                                    color="black"
                                                    content={
                                                        <ul className="flex flex-col text-sm space-y-2">
                                                            <li className="space-y-2">
                                                                <div className="text-[#646464] text-xs">Main model</div>
                                                                <div
                                                                    className=" cursor-pointer"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            '/tools/ModelList/ModelDetail/' + imageObjDetail.taskModelId,
                                                                            '_blank',
                                                                        )
                                                                    }}
                                                                >
                                                                    {type == 'sd' ? imageObjDetail.modelName : imageObjDetail.mjExtraParam}
                                                                </div>
                                                            </li>
                                                            {imageObjDetail.loraModels && imageObjDetail.loraModels.length > 0 && (
                                                                <li className="space-y-2">
                                                                    <div className="text-[#646464] text-xs">Findtaned Model</div>
                                                                    {imageObjDetail.loraModels.map((loraModelItem, index) => {
                                                                        return (
                                                                            <div
                                                                                className=" cursor-pointer"
                                                                                key={loraModelItem.loraModelId}
                                                                                onClick={() => {
                                                                                    window.open(
                                                                                        '/tools/ModelList/ModelDetail/' + loraModelItem.loraModelId,
                                                                                        '_blank',
                                                                                    )
                                                                                }}
                                                                            >
                                                                                {loraModelItem.modelName}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </li>
                                                            )}
                                                            {imageObjDetail.vaeModel && (
                                                                <li className="space-y-2">
                                                                    <div className="text-[#646464] text-xs">VAE</div>
                                                                    <div
                                                                    // className=" cursor-pointer"
                                                                    // onClick={() => {
                                                                    //     window.open(
                                                                    //         '/tools/ModelList/ModelDetail/' + imageObjDetail.vaeModel.vaeModelId,
                                                                    //         '_block',
                                                                    //     )
                                                                    // }}
                                                                    >
                                                                        {imageObjDetail.vaeModel.vaeName}
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    }
                                                >
                                                    <div
                                                        className="flex flex-row items-center justify-center border-gradual w-[27px] h-[20px] rounded-md cursor-pointer mx-2"
                                                        // onClick={copyPrompt}
                                                    >
                                                        <span className=" text-[11px] font-normal">···</span>
                                                    </div>
                                                </Popover>
                                            )}
                                        </div>
                                        <span>{type == 'sd' ? imageObjDetail.modelName : imageObjDetail.mjExtraParam}</span>
                                    </div>
                                </div>
                                {showImageEditBar && (
                                    <div className="w-full grid grid-cols-5 gap-2">
                                        {btnList.map((btnItem, index) => {
                                            return (
                                                <div key={index} className="">
                                                    {btnItem.name == 'R' ? (
                                                        <div
                                                            className=" h-[25px]  rounded flex items-center justify-center leading-[25px] cursor-pointer bg-gradient-to-l from-btnBgColorFrom  to-btnBgColorTo"
                                                            onClick={() => {
                                                                handelImageEditClick(btnItem)
                                                            }}
                                                            key={btnItem.name}
                                                        >
                                                            <img src={refreshImage} className="w-[14px] h-[15px]" alt="" />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className=" bg-[#333333] text-center rounded text-xs leading-[25px] cursor-pointer bg-gradient-to-l from-btnBgColorFrom  to-btnBgColorTo"
                                                            onClick={() => {
                                                                handelImageEditClick(btnItem)
                                                            }}
                                                            key={btnItem.name}
                                                        >
                                                            {btnItem.name}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className={`flex items-end justify-between w-full  ${hiddenActionButton ? 'hidden' : ''}`}>
                                <div className=" block space-y-2  lg:flex lg:flex-row lg:space-y-0 lg:items-center lg:space-x-3">
                                    {isPhone ? (
                                        <a
                                            className=" inline-flex flex-row items-center justify-center border-gradual px-3 h-[30px] rounded-md"
                                            href={imageObjDetail.pathUrl}
                                            target="_blank"
                                        >
                                            <img className="w-[15px]" src={downloadWhiteImage} alt="" />
                                            <span className=" text-[11px] font-normal ml-2">{t('preview.btnDownloadTip')}</span>
                                        </a>
                                    ) : (
                                        <Popover content={<div className="flex items-center justify-center">{t('preview.btnDownloadTip')}</div>}>
                                            <a
                                                href={imageObjDetail.pathUrl}
                                                target="_blank"
                                                className="w-[28px] h-[28px] flex items-center justify-center"
                                            >
                                                <img src={downloadImage} className="w-full" alt="" />
                                            </a>
                                        </Popover>
                                    )}

                                    {type == 'sd' &&
                                        (isPhone ? (
                                            <button
                                                className="flex flex-row items-center justify-center border-gradual h-[30px] rounded-md px-3 "
                                                onClick={() => {
                                                    useImageInit()
                                                }}
                                            >
                                                <img className="w-[15px]" src={useInitWhiteImage} alt="" />
                                                <span className=" text-[13px] font-normal ml-2">{t('preview.btnUseImageTip')}</span>
                                            </button>
                                        ) : (
                                            <Popover content={<div className="flex items-center justify-center">{t('preview.btnUseImageTip')}</div>}>
                                                <button
                                                    className="w-[28px] h-[28px] flex items-center justify-center"
                                                    onClick={() => {
                                                        useImageInit()
                                                    }}
                                                >
                                                    <img src={useInitImage} className="w-full" alt="" />
                                                </button>
                                            </Popover>
                                        ))}

                                    {type == 'sd' &&
                                        (isPhone ? (
                                            <button
                                                className="flex flex-row items-center justify-center border-gradual h-[30px] rounded-md px-3 "
                                                onClick={() => {
                                                    generateImageClick()
                                                }}
                                            >
                                                <img className="w-[15px]" src={generateImageWhiteImage} alt="" />
                                                <span className=" text-[13px] font-normal ml-2">{t('preview.btnGenerateImageTip')}</span>
                                            </button>
                                        ) : (
                                            <Popover
                                                content={<div className="flex items-center justify-center">{t('preview.btnGenerateImageTip')}</div>}
                                            >
                                                <button
                                                    className="w-[28px] h-[28px] flex items-center justify-center"
                                                    onClick={() => {
                                                        generateImageClick()
                                                    }}
                                                >
                                                    <img src={generateImageImage} className="w-full" alt="" />
                                                </button>
                                            </Popover>
                                        ))}

                                    {type == 'sd' &&
                                        (isPhone ? (
                                            <button
                                                disabled={imageObjDetail.canV != 1 ? true : false}
                                                className={`flex flex-row items-center justify-center border-gradual h-[30px] rounded-md px-3 ${
                                                    imageObjDetail.canV != 1 ? 'grayscale' : ''
                                                }`}
                                                onClick={() => {
                                                    variationClick()
                                                }}
                                            >
                                                <img className="w-[15px]" src={variationWhiteImage} alt="" />
                                                <span className=" text-[13px] font-normal ml-2">{t('preview.btnVariationsTip')}</span>
                                            </button>
                                        ) : (
                                            <Popover
                                                content={<div className="flex items-center justify-center">{t('preview.btnVariationsTip')}</div>}
                                            >
                                                <button
                                                    disabled={imageObjDetail.canV != 1 ? true : false}
                                                    className={`w-[28px] h-[28px] flex items-center justify-center ${
                                                        imageObjDetail.canV != 1 ? 'grayscale' : ''
                                                    }`}
                                                    onClick={() => {
                                                        variationClick()
                                                    }}
                                                >
                                                    <img src={variationImage} className="w-full" alt="" />
                                                </button>
                                            </Popover>
                                        ))}
                                    {type == 'sd' &&
                                        (isPhone ? (
                                            <button
                                                disabled={imageObjDetail.taskType == 4 ? true : false}
                                                className={`flex flex-row items-center justify-center border-gradual px-3 h-[30px] rounded-md ${
                                                    imageObjDetail.taskType == 4 ? 'grayscale' : ''
                                                }`}
                                                onClick={() => {
                                                    handelEnlageImageClick()
                                                }}
                                            >
                                                <img className="w-[15px]" src={largWwhiteImage} alt="" />
                                                <span className=" text-[13px] font-normal ml-2">{t('preview.btnEnlargeTip')}</span>
                                            </button>
                                        ) : (
                                            <Popover content={<div className="flex items-center justify-center">{t('preview.btnEnlargeTip')}</div>}>
                                                <button
                                                    disabled={imageObjDetail.taskType == 4 ? true : false}
                                                    className={`w-[28px] h-[28px] flex items-center justify-center  ${
                                                        imageObjDetail.taskType == 4 ? 'grayscale' : ''
                                                    }`}
                                                    onClick={() => {
                                                        handelEnlageImageClick()
                                                    }}
                                                >
                                                    <img src={largeImage} className="w-full" alt="" />
                                                </button>
                                            </Popover>
                                        ))}
                                </div>
                                {deleteImageM && (
                                    <div className="flex items-center">
                                        {isPhone ? (
                                            <button
                                                className="flex flex-row items-center justify-center border-gradual px-3 space-x-3 h-[30px] rounded-md"
                                                onClick={deleteImageClick}
                                            >
                                                <img className="w-[15px]" src={deleteWhiteImage} alt="" />
                                                <span className=" text-[13px] font-normal ml-2">{t('preview.btnDeleteTip')}</span>
                                            </button>
                                        ) : (
                                            <Popover content={<div className="flex items-center justify-center">{t('preview.btnDeleteTip')}</div>}>
                                                <button className="w-[28px] h-[28px] flex items-center justify-center" onClick={deleteImageClick}>
                                                    <img src={deleteImage} className="w-full" alt="" />
                                                </button>
                                            </Popover>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default index
