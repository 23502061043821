import React, { forwardRef, useDeferredValue, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Macy from 'macy'
import { Popover } from 'antd'
import vActive from '../../../assets/v1.6/V-active.png'
import waters_s from '../../../assets/waters_s.png'

import vGray from '../../../assets/v1.6/V-gray.png'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/hooks'
import { shallowEqual, useDispatch } from 'react-redux'
import { Waters_UVSButtonClick, fetchWaters_similarImages, updateSimilarImageByIndex } from '@/slices/NewFunctionSlice'
import { debounce } from '@mui/material'
import { AppDispatch, RootState } from '@/store'
import { useCommonSlice } from '@/slices/commonSlice'
import OneImagePreview from '@/components/PhotoPreview/OneImagePreview'

export interface IWatersSimilarWaterFallRef {
    loadMore: () => void
}
export interface IWatersSimilarWaterFallProps {
    onS?: () => void
}
const WatersSimilarWaterfall = forwardRef<IWatersSimilarWaterFallRef, IWatersSimilarWaterFallProps>((props, ref) => {
    const { t } = useTranslation()
    const macyContainerRef = useRef<any>()
    const macyRef = useRef<any>()
    const dispatch = useDispatch<AppDispatch>()
    const { onS } = props

    const { isPendingThunk } = useCommonSlice()

    const authToken = useAppSelector(state => state.app?.authToken)
    const similarImages = useAppSelector(state => state.newFunctionSlice?.similarImages)
    // const similarImages = useDeferredValue(_similarImages)
    const similarState = useAppSelector(state => state.newFunctionSlice?.similarState, shallowEqual)

    useImperativeHandle(ref, () => ({
        loadMore: async () => {
            await requestData(similarState.page + 1, similarState.taskId)
        },
    }))

    async function requestData(page: number = 1, taskId?: string) {
        if (taskId) {
            await dispatch(fetchWaters_similarImages({ page, taskId }))
        }
    }

    useEffect(() => {
        if (macyRef.current) {
            macyRef.current.runOnImageLoad(function () {
                debounce(() => {
                    macyRef.current.recalculate(true)
                }, 300)
            })
        }
    }, [similarImages])

    useEffect(() => {
        if (authToken && !isPendingThunk(fetchWaters_similarImages)) {
            // requestData()
        }
    }, [authToken])

    useEffect(() => {
        const macy = Macy({
            container: macyContainerRef.current,
            trueOrder: false,
            waitForImages: false,
            mobileFirst: true,
            breakAt: {
                1300: {
                    margin: {
                        x: 10,
                        y: 10,
                    },
                    columns: 6,
                },
                900: {
                    margin: {
                        x: 10,
                        y: 10,
                    },
                    columns: 4,
                },
                0: {
                    margin: {
                        x: 5,
                        y: 5,
                    },
                    columns: 2,
                },
            },
        })

        macy.on(macy.constants.EVENT_IMAGE_COMPLETE, function (ctx) {})
        macyRef.current = macy

        return () => {}
    }, [])

    const [showImagePreview, setShowImagePreview] = useState(false)
    const [imagePreviewUrl, setImagePreviewUrl] = useState('')

    const host = useAppSelector(state => {
        return state.app && state.app.host
    })

    return (
        <div>
            {showImagePreview && <OneImagePreview setOpen={setShowImagePreview} imageUrl={imagePreviewUrl}></OneImagePreview>}
            {similarImages.length < 1 && !isPendingThunk(fetchWaters_similarImages) && (
                <div className="h-[400px] flex justify-center items-center">
                    <div className="text-white/60 text-center">{t('generatedImage.emptyText')}</div>
                </div>
            )}
            <div ref={macyContainerRef} className="min-h-0 overflow-hidden">
                {similarImages.map((model, index) => (
                    <div
                        className="w-0 cursor-pointer"
                        key={model.taskId}
                        onClick={e => {
                            setShowImagePreview(true)
                            setImagePreviewUrl(model.url + (host == 'xyz' ? '?x-oss-process=style/waters3' : ''))
                        }}
                    >
                        <div className="group relative leading-none ">
                            <img className="rounded-2xl" src={model.url + (host == 'xyz' ? '?x-oss-process=style/waters2' : '')} />
                            <div className="opacity-0 group-hover:opacity-100 absolute left-[11px] bottom-[11px] space-x-2">
                                <Popover content={<div className="flex items-center justify-center">{t('preview.btnVariationsTip')}</div>}>
                                    <button
                                        disabled={model.canV !== 1}
                                        onClick={e => {
                                            e.stopPropagation()
                                            dispatch(Waters_UVSButtonClick({ type: 2, authToken: authToken!, taskId: model.taskId }))
                                            dispatch(updateSimilarImageByIndex({ index, item: { canV: 0 } }))
                                        }}
                                    >
                                        <img className="w-[30px] lg:w-[38px]" src={model.canV == 1 ? vActive : vGray} alt="" />
                                    </button>
                                </Popover>
                            </div>
                            <div className="opacity-0 group-hover:opacity-100 absolute right-[11px] bottom-[11px] space-x-2">
                                <Popover content={<div className="flex items-center justify-center">{t('preview.btnSimilaTip')}</div>}>
                                    <button
                                        onClick={async e => {
                                            e.stopPropagation()
                                            onS?.()
                                            requestData(1, model.taskId)
                                        }}
                                    >
                                        <img className="w-[30px] lg:w-[38px]" src={waters_s} alt="" />
                                    </button>
                                </Popover>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
})
export default WatersSimilarWaterfall
