import { getResourcesList, getModelList, createModelTask } from '@/api/stableDiffusionRequest'
import { useAppSelector } from '@/hooks'
import { setResourcesList, setModelTaskID } from '@/slices/StableDiffusionSlice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { message } from 'antd'
import './indexStyle.css'
import CustomInputRange from '@/components/TrainingAndDatasets/Training/CustomInputRange'
import { useTranslation } from 'react-i18next'
import BackdropSpinner from '@/components/Spinner/BackdropSpinner'

export default function InputSettings(params) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [basicOpen, setBasicOpen] = useState(true)
    const [conceptOpen, setConceptOpen] = useState(true)
    const [generalUseLora, setGeneralUseLora] = useState(0)
    const [loraModelList, setLoraModelList] = useState([])
    const [selectedLoraModelID, setSelectedLoraModelID] = useState(undefined)

    const [filewordInstanceToken, setFilewordInstanceToken] = useState('')

    const [resourceDataList, setResourceDataList] = useState([])
    const [selectedResourceID, setSelectedResourceID] = useState(undefined)

    const [classCGFScale, setClassCGFScale] = useState(1)
    const [classStep, setClassStep] = useState(10)

    const [modelType, setModelType] = useState('lora')

    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })

    const selectedModel = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.selectedModel
    })
    const modelName = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.modelName
    })
    const modelImage = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.modelImage
    })
    const resourcesListItem = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.resourcesListItem
    })

    useEffect(() => {
        async function getData() {
            const res = await getResourcesList({ authToken: authToken })
            if (res.code == 1) {
                if (res.data.resources.length > 0) {
                    setResourceDataList(res.data.resources)
                    if (resourcesListItem) {
                        res.data.resources.map(item => {
                            if (item.resourceId == resourcesListItem.resourceId) {
                                setSelectedResourceID(item.resourceId)
                            }
                        })
                    } else {
                        setSelectedResourceID(res.data.resources[0].resourceId)
                    }
                }
            }
        }
        if (authToken) {
            getData()
        }
    }, [authToken, resourcesListItem])

    useEffect(() => {
        async function getModleData(model) {
            const res = await getModelList({
                authToken,
                includePrivate: 1,
                includePublic: 0,
                modelType: [2],
                onlySuccess: 1,
                srcModelId: model.modelId,
                srcModelIsPublic: model.isPublic,
            })
            if (res.code == 1) {
                if (res.data.models.length > 0) {
                    setLoraModelList(res.data.models)
                    setSelectedLoraModelID(res.data.models[0].modelId)
                } else {
                    setLoraModelList([])
                    setSelectedLoraModelID(undefined)
                    setGeneralUseLora(0)
                }
            }
        }
        if (selectedModel) {
            getModleData(selectedModel)
        }
    }, [selectedModel])

    const training = async () => {
        if (!modelName) {
            message.error(t('training.tip.tipModelName'))
            return
        }
        if (!modelImage) {
            message.error(t('training.tip.tipModelImage'))
            return
        }
        if (!selectedModel) {
            message.error(t('training.tip.tipModel'))
            return
        }
        if (generalUseLora == 1) {
            if (!selectedLoraModelID) {
                message.error(t('training.tip.tipLoarModel'))
                return
            }
        }
        if (!selectedResourceID) {
            message.error(t('training.tip.tipDataset'))
            return
        }
        if (!filewordInstanceToken) {
            message.error(t('training.tip.tipInstanceToken'))
            return
        }
        let param = {
            authToken,
            resourceList: [
                { resourceId: selectedResourceID, prompt: filewordInstanceToken, classGuidanceScale: classCGFScale, classInferSteps: classStep },
            ],
            modelName,
            modelType,
            modelImageUrl: modelImage,
            useLora: generalUseLora,
            loraModelId: selectedLoraModelID,
            srcModelId: selectedModel.modelId,
            srcModelIsPublic: selectedModel.isPublic,
        }
        setLoading(true)
        const res = await createModelTask(param)
        setLoading(false)
        if (res.code == 1) {
            dispatch(setModelTaskID(res.data.taskId))
        } else {
            message.error(res.message)
        }
    }
    return (
        <div className="flex flex-col p-2">
            <BackdropSpinner show={loading}></BackdropSpinner>
            <div className="p-2 mt-2 border border-trainBorderColor  rounded-lg flex flex-col transition">
                <div>
                    <div
                        className="w-full flex justify-between cursor-pointer"
                        onClick={() => {
                            setBasicOpen(!basicOpen)
                        }}
                    >
                        <span className="m-select-title">{t('training.input.basic')}</span> <span className="transition">▼</span>
                    </div>
                    {basicOpen && (
                        <div className={'p-2'}>
                            <span className="m-select-title mt-3">{t('training.input.general')}</span>
                            <div className="flex flex-col p-2 rounded mt-2 border border-trainBorderColor">
                                <div className="custom-checkbox my-2">
                                    <input
                                        type="checkbox"
                                        id="useLora"
                                        onChange={e => {
                                            if (loraModelList.length <= 0) {
                                                message.error(t('training.tip.tipNoLoraModel'))
                                                return
                                            }
                                            setGeneralUseLora(e.target.value == 1 ? 0 : 1)
                                        }}
                                        value={generalUseLora}
                                        checked={generalUseLora == 1}
                                    />
                                    <label htmlFor="useLora">{t('training.input.useLora')}</label>
                                </div>
                            </div>
                            {generalUseLora == 1 && (
                                <div className="flex flex-col rounded mt-2">
                                    <span className="m-select-title">{t('training.input.loraModel')}</span>
                                    <div className="flex w-full">
                                        <select
                                            className="!w-full custom-select"
                                            title=""
                                            onChange={e => {
                                                setSelectedLoraModelID(e.target.value)
                                            }}
                                            value={selectedLoraModelID}
                                        >
                                            {loraModelList.map((loraModel, loraModelIndex) => {
                                                return (
                                                    <option
                                                        value={loraModel.modelId}
                                                        key={loraModelIndex}
                                                        // selected={selectedLoraModelID == loraModel.modelId}
                                                    >
                                                        {loraModel.name}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="p-2 mt-6 border border-trainBorderColor  rounded-lg flex flex-col  transition">
                <div>
                    <div
                        className="w-full flex justify-between cursor-pointer"
                        onClick={() => {
                            setConceptOpen(!conceptOpen)
                        }}
                    >
                        <span className="m-select-title">{t('training.input.concepts')}</span> <span className="transition">▼</span>
                    </div>
                    {conceptOpen && (
                        <div className={'p-2'}>
                            <div className="flex flex-col rounded mt-2">
                                <span className="m-select-title">{t('training.input.datasets')}</span>
                                <div className="flex w-full">
                                    <select
                                        className="!w-full custom-select"
                                        title=""
                                        value={selectedResourceID}
                                        onChange={e => {
                                            setSelectedResourceID(e.target.value)
                                        }}
                                    >
                                        {resourceDataList.map((resourceData, resourceDataIndex) => {
                                            if (resourceData.isCompleted == 1) {
                                                return (
                                                    <option
                                                        value={resourceData.resourceId}
                                                        key={resourceDataIndex}
                                                        // selected={selectedResourceID == resourceData.resourceId}
                                                    >
                                                        {resourceData.name}
                                                    </option>
                                                )
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="mt-2">
                                <span className="m-select-title">{t('training.input.filewords')}</span>
                                <div className="flex flex-col w-full p-2  rounded border border-trainBorderColor custom-textarea">
                                    <span className="m-select-title">{t('training.input.instanceToken')}</span>
                                    <textarea
                                        className="w-full m-input-base"
                                        type="text"
                                        value={filewordInstanceToken}
                                        placeholder={t('training.input.instanceTokenPlaceholder')}
                                        onChange={e => {
                                            setFilewordInstanceToken(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>

                            <span className="m-select-title mt-3">{t('training.input.classImageGeneration')}</span>
                            <div className="flex flex-col w-full justify-between p-2 mt-2  rounded border border-trainBorderColor">
                                <CustomInputRange
                                    title={t('training.input.classificationCFGScale')}
                                    value={classCGFScale}
                                    setValue={setClassCGFScale}
                                    min={1}
                                    max={12}
                                    step={0.1}
                                ></CustomInputRange>
                                <CustomInputRange
                                    title={t('training.input.classificationSteps')}
                                    value={classStep}
                                    setValue={setClassStep}
                                    min={10}
                                    max={200}
                                    step={1}
                                ></CustomInputRange>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <span className="m-select-title mt-3">{t('training.input.modelType')}</span>
            <div className="flex flex-col p-2 rounded mt-2 border border-trainBorderColor">
                <div className="custom-checkbox my-2">
                    <input
                        type="checkbox"
                        id="lora"
                        onChange={e => {
                            setModelType(e.target.value)
                        }}
                        value="lora"
                        checked={modelType == 'lora'}
                    />
                    <label htmlFor="lora">lora</label>
                </div>
                {/* <div className="custom-checkbox my-2">
                    <input
                        type="checkbox"
                        id="model"
                        onChange={e => {
                            setModelType(e.target.value)
                        }}
                        value="model"
                        checked={modelType == 'model'}
                    />
                    <label htmlFor="model">model</label>
                </div> */}
            </div>
            <button
                className="m-btn-base mt-6"
                onClick={() => {
                    training()
                }}
            >
                {t('training.input.train')}
            </button>
        </div>
    )
}
