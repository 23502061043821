import { midJourneyCreateTask } from '@/api/request'
import { useAppSelector } from '@/hooks'
import { GeneratedMode, IMjImageListItem } from '@/slices/GenerateImageSlice'
import { fetchMj_taskResult } from '@/slices/GeneratedImageThunk'
import { AppDispatch } from '@/store'
import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export type MJCreateTaskParams = {
    mode: GeneratedMode
    rootTaskId?: string
    modeIndex?: number

    prompt?: string
    extraParam?: string
    imageUrl?: string

    //local
    extra: {
        isReload?: boolean
    }
}

export type MJCreateTaskRes = {
    params: MJCreateTaskParams
    curTaskId: string
    successData?: any
    errorMessage?: string
}

export function useMjRequestDraw({
    beforeStart,
    start,
    polling,
    end,
}: {
    beforeStart?: (params: MJCreateTaskParams) => void
    start?: (result: Exclude<MJCreateTaskRes, 'errorMessage'>) => void
    polling?: (result: Exclude<MJCreateTaskRes, 'errorMessage'>) => void
    end?: (result: MJCreateTaskRes) => void
}) {
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })

    const { t } = useTranslation()

    const dispatch = useDispatch<AppDispatch>()

    const abortControllerRef = useRef<{ abort: (reason?: string) => void }>()

    const createTask = async (params: MJCreateTaskParams) => {
        const { mode, rootTaskId, modeIndex, extra } = params

        try {
            beforeStart && beforeStart(params)

            const res = (await midJourneyCreateTask({
                authToken: authToken,
                prompt: params.prompt,
                extraParam: params.extraParam,
                imageUrl: params.imageUrl,
                mjParam: {
                    discordMidTaskMode: mode,
                    discordMidSelectIndex: modeIndex,
                    taskId: rootTaskId,
                },
            })) as any

            if (res.code == 1) {
                const curTaskId = res.data.taskId

                requestDetailDrawResult(params, curTaskId)

                const result = { params, successData: res.data, curTaskId }
                start && start(result)
            } else {
                const errResult = { params, errorMessage: res.message, curTaskId: '' }
                end && end(errResult)
            }
        } catch (e) {
            const errResult = { params, errorMessage: t('generatedImage.errorServiceBusy'), curTaskId: '' }
            end && end(errResult)
        }
    }

    async function requestDetailDrawResult(params: MJCreateTaskParams, curTaskId: string) {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort()
        }

        const promise = dispatch(
            fetchMj_taskResult({
                params,
                curTaskId,
                resultHandle: { beforeStart, start, polling, end },
            }),
        )
        abortControllerRef.current = promise
    }

    return { createTask, requestDetailDrawResult, abortControllerRef }
}

export function useMjLoading() {
    const state = useAppSelector(state => {
        return state.generateImageSlice.midjourney
    })

    return useMemo(() => {
        let spinning = state.seedLoading || state.generating
        let spinningItem: IMjImageListItem | undefined
        if (!spinning) {
            for (const obj of state.generatedImageList) {
                if (obj.detailState.generating) {
                    spinningItem = obj
                    spinning = true
                    break
                }
            }
        }

        return { spinning, spinningItem }
    }, [state])
}
