import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/hooks'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import DropdownList from '@/pages/sdModelList/DropdownList'
import { updateSd_modalState } from '@/slices/GenerateImageSlice'
import { ModelListModalContext } from './ModelListModal'

interface ISDModelListMModalSearchProps {
    curSearch?: string
    fetchFunction: Function
}
const SDModelListMModalSearch: React.FC<ISDModelListMModalSearchProps> = ({ fetchFunction, curSearch }) => {
    const { t } = useTranslation()
    const state = useAppSelector(state => state.generateImageSlice.stableDiffusion)
    const modalState = useAppSelector(state => state.generateImageSlice.stableDiffusion.modalState)
    const dispatch = useDispatch<AppDispatch>()
    const authToken = useAppSelector(state => state.app?.authToken)
    const [searchText, setSearchText] = useState(modalState.curSearch ?? '')

    useEffect(() => {
        setSearchText(modalState.curSearch ?? '')
    }, [modalState.curSearch])

    const { modelId } = useContext(ModelListModalContext)

    const showTypes = useMemo(() => {
        if (modelId) {
            const loras = modalState.types.filter(v => v.data.systemDicId == 3 || v.data.systemDicId == 6)
            // const loraType = modalState.types.find(v => v.data.systemDicId == 3)
            return loras.length > 0 ? [modalState.types[0], ...loras] : modalState.types
        } else {
            return modalState.types.filter(v => v.data.systemDicId != 3)
        }
    }, [modelId, modalState.types])

    return (
        <div className="mt-2 w-full flex flex-col lg:flex-row space-y-1 lg:space-y-0 lg:space-x-2 justify-center items-center">
            {/* search */}
            <div className="lg:flex-1 shrink w-full flex">
                <form className="w-full lg:max-w-3xl h-10 flex">
                    <input
                        autoComplete="on"
                        type="search"
                        className="w-full outline-none px-4 rounded-full bg-gray-bg"
                        value={searchText}
                        placeholder={`${t('modelShop.SearchModels')}`}
                        onChange={e => {
                            setSearchText(e.target.value)
                            if (e.target.value.length < 1) {
                                dispatch(updateSd_modalState({ curSearch: e.target.value }))
                                fetchFunction()
                            }
                        }}
                        onBlur={e => {
                            dispatch(updateSd_modalState({ curSearch: e.target.value }))
                        }}
                    />
                    <button
                        onClick={e => {
                            e.preventDefault()
                            fetchFunction()
                        }}
                        className="m-bg-gradient rounded-full px-4 lg:px-8 shrink-0 ml-2 text-xs lg:text-base font-medium"
                    >
                        {t('generatedImage.search')}
                    </button>
                </form>
                {/* mobile sort */}
                <span className="ml-2 shrink-0 flex lg:hidden justify-center">
                    <DropdownList
                        value={modalState.curSort}
                        onClickHandle={item => {
                            dispatch(updateSd_modalState({ curSort: item }))
                            fetchFunction()
                        }}
                        className="w-full bg-gray-bg"
                        cellData={modalState.sorts}
                    />
                </span>
            </div>

            {/* sort */}
            <div className="group w-full lg:flex-1 lg:min-w-[44rem] flex flex-col justify-items-stretch space-y-1  lg:flex-row lg:items-center lg:justify-end">
                <span className="hidden lg:flex justify-center">
                    <DropdownList
                        value={modalState.curSort}
                        onClickHandle={item => {
                            dispatch(updateSd_modalState({ curSort: item }))
                            fetchFunction()
                        }}
                        className="w-full bg-gray-bg"
                        cellData={modalState.sorts}
                    />
                </span>
                <span className="flex items-center justify-between lg:justify-start">
                    <span className="mx-2 lg:ml-4 lg:mr-2 text-xs lg:text-sm whitespace-nowrap">{t('modelShop.Category')}</span>
                    <DropdownList
                        value={modalState.curCategory}
                        onClickHandle={item => {
                            dispatch(updateSd_modalState({ curCategory: item }))
                            fetchFunction()
                        }}
                        className="min-w-[8rem] lg:w-full bg-gray-bg"
                        cellData={modalState.categorys}
                    />
                </span>
                <span className=" flex items-center justify-between lg:justify-start">
                    <span className="mx-2 lg:ml-4 lg:mr-2 text-xs lg:text-sm whitespace-nowrap">{t('modelShop.type')}</span>
                    <DropdownList
                        value={modalState.curType}
                        onClickHandle={item => {
                            dispatch(updateSd_modalState({ curType: item }))
                            fetchFunction()
                        }}
                        className="min-w-[8rem] lg:w-full bg-gray-bg"
                        cellData={showTypes}
                    />
                </span>
            </div>
        </div>
    )
}
export default SDModelListMModalSearch
