import { useAppSelector } from '@/hooks'
import { AnyAction, AsyncThunk, createSlice, isAsyncThunkAction } from '@reduxjs/toolkit'
import { UnknownAsyncThunkAction } from '@reduxjs/toolkit/dist/matchers'
import { useCallback } from 'react'

export function useCommonSlice() {
    const state = useAppSelector(state => state.commonSlice)
    const isPendingThunk = useCallback(
        (thunk: AsyncThunk<any, any, any>) => {
            return state.requests[thunk.typePrefix] == 'pending'
        },
        [state.requests],
    )
    return { isPendingThunk }
}

export type ThunkActionStatus = 'idle' | 'pending' | 'fulfilled' | 'rejected'
interface ICommonSliceState {
    requests: { [p: string]: ThunkActionStatus }
}
const initialState: ICommonSliceState = {
    requests: {},
}
const commonSlice = createSlice({
    name: 'commonSlice',
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addMatcher(
            isAsyncThunkAction,
            // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
            (state, action) => {
                const arr = action.type.split('/')
                arr.pop()
                const typePrefix = arr.join('/')
                if (action.type.endsWith('pending')) {
                    state.requests[typePrefix] = 'pending'
                } else if (action.type.endsWith('fulfilled')) {
                    // state.requests[typePrefix] = 'fulfilled'
                    delete state.requests[typePrefix]
                } else if (action.type.endsWith('rejected')) {
                    // state.requests[typePrefix] = 'rejected'
                    delete state.requests[typePrefix]
                }
            },
        )
    },
})

export default commonSlice.reducer
