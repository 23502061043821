import { getResourcesList } from '@/api/stableDiffusionRequest'
import { useAppSelector } from '@/hooks'
import { setResourcesList } from '@/slices/StableDiffusionSlice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './indexStyle.css'

import CustomInputRange from '@/components/TrainingAndDatasets/Training/CustomInputRange'

export default function InputConcepts(params) {
    const dispatch = useDispatch()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })

    useEffect(() => {
        async function getResources() {
            const res = await getResourcesList({ authToken: authToken })
            if (res.code == 1) {
                setResourcesList(res.data.resources)
            }
        }
        if (authToken) {
            getResources()
        }
    }, [authToken])

    const btnList = [
        { name: 'Concept1', index: 0 },
        { name: 'Concept2', index: 1 },
        { name: 'Concept3', index: 2 },
        { name: 'Concept4', index: 3 },
    ]
    const [selectedBtn, setSelectedBtn] = useState('Concept1')
    const [concepts, setConcepts] = useState([{ name: 'Concept1' }, { name: 'Concept2' }, { name: 'Concept3' }, { name: 'Concept4' }])

    const [resourcesList, setResourcesList] = useState([])

    const handleResourceSelectedChange = (e, conceptsItem) => {
        conceptsItem.resourceId = e.target.value
    }

    const handleSampleImagePromptChange = (e, conceptsItem) => {
        conceptsItem.sampleImagePrompt = e.target.value
    }
    const handleSampleNegativePromptChange = (e, conceptsItem) => {
        conceptsItem.sampleNegativePrompt = e.target.value
    }

    const createModel = () => {
        console.log('🚀 ~ file: InputConcepts.jsx:52 ~ concepts ~ concepts:', concepts)
    }

    const [rangeValue, setRangeValue] = useState(0)
    const handleRnageChange = e => {
        console.log('🚀 ~ file: InputConcepts.jsx:53 ~ handleRnageChange ~ e:', e.target.value)
        setRangeValue(e.target.value)
    }

    return (
        <div className="flex flex-col p-2">
            <div className="tabs flex flex-col mt-2">
                <div className="flex border-b-2 border-trainBorderColor flex-wrap dark:border-gray-700">
                    {btnList.map(btnItem => {
                        return (
                            <button
                                className={selectedBtn == btnItem.name ? 'm-tab-btn-selected' : 'm-tab-btn'}
                                key={btnItem.index}
                                onClick={() => {
                                    setSelectedBtn(btnItem.name)
                                }}
                            >
                                {btnItem.name}{' '}
                            </button>
                        )
                    })}
                </div>
                {/* <button
                    className="m-btn-base mt-2"
                    onClick={() => {
                        createModel()
                    }}
                >
                    Create Model
                </button> */}
                <div className="tabitem p-2 border-2 border-t-0 border-trainBorderColor relative flex flex-col">
                    {concepts.map((conceptsItem, index) => {
                        return (
                            <div className={conceptsItem.name == selectedBtn ? 'visible' : 'hidden'} key={index}>
                                <div className="flex flex-col p-2 ">
                                    <span className="m-select-title">Resources</span>
                                    <div className="flex w-full" key={index}>
                                        <select
                                            className="!w-full m-select-base"
                                            title=""
                                            onChange={e => {
                                                handleResourceSelectedChange(e, conceptsItem)
                                            }}
                                        >
                                            <option>resource</option>
                                            {resourcesList.map((resourceItem, resourceIndex) => {
                                                if (resourceItem.isCompleted == 1) {
                                                    return (
                                                        <option value={resourceItem.resourceId} key={resourceIndex}>
                                                            {resourceItem.name}
                                                        </option>
                                                    )
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <span className="m-select-title mt-3">Sample Prompts</span>
                                    <div className="flex flex-col w-full justify-between p-2 mt-2  rounded border border-trainBorderColor">
                                        <div>
                                            <span className="m-select-title">Sample Image Prompt</span>
                                            <textarea
                                                className="w-full m-input-base "
                                                type="text"
                                                placeholder="Leave blank to use instance prompt. Optionally use [filewords] to base sample captions on instance images."
                                                onChange={e => {
                                                    handleSampleImagePromptChange(e, conceptsItem)
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span className="m-select-title">Sample Negative Prompt</span>
                                            <textarea
                                                className="w-full m-input-base "
                                                type="text"
                                                onChange={e => {
                                                    handleSampleNegativePromptChange(e, conceptsItem)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <span className="m-select-title mt-3">Class Image Generation</span>
                                    <div className="flex flex-col w-full justify-between p-2 mt-2  rounded border border-trainBorderColor">
                                        <div>
                                            <div className="flex flex-row justify-between">
                                                <span className="m-select-title">Class Images Per Instance Image</span>
                                                <input className="w-[80px] m-input-number" type="number" />
                                            </div>
                                            <input className="w-full " type="range" />
                                        </div>
                                        <div>
                                            <div className="flex flex-row justify-between">
                                                <span className="m-select-title">Classification CFG Scale</span>
                                                <input className="w-[80px] m-input-number" type="number" />
                                            </div>
                                            <input className="w-full " type="range" />
                                        </div>
                                        <div>
                                            <div className="flex flex-row justify-between">
                                                <span className="m-select-title">Classification Steps</span>
                                                <input className="w-[80px] m-input-number" type="number" />
                                            </div>
                                            <input className="w-full " type="range" />
                                        </div>
                                    </div>
                                    <span className="m-select-title mt-3">Simple Image Generation</span>
                                    <div className="flex flex-col w-full justify-between p-2 mt-2  rounded border border-trainBorderColor">
                                        <div>
                                            <div className="flex flex-row justify-between">
                                                <span className="m-select-title">Number of Samples to Generate</span>
                                                <input className="w-[80px] m-input-number" type="number" />
                                            </div>
                                            <input className="w-full " type="range" />
                                        </div>
                                        <div>
                                            <span className="m-select-title">Sample Seed</span>
                                            <input className="w-full m-input-number p-2 my-1" type="number" />
                                        </div>

                                        <CustomInputRange title={'Sample CFG Scale'} value={rangeValue} setValue={setRangeValue}></CustomInputRange>
                                        {/* <div className="custom-range">
                                            <div className="flex flex-row justify-between">
                                                <span className="m-select-title">Sample CFG Scale</span>
                                                <input className="w-[80px] m-input-number" type="number" />
                                            </div>
                                            <input className="w-full" type="range" value={rangeValue} onChange={handleRnageChange} />
                                        </div> */}
                                        <div>
                                            <div className="flex flex-row justify-between">
                                                <span className="m-select-title">Sample Steps</span>
                                                <input className="w-[80px] m-input-number" type="number" />
                                            </div>
                                            <input className="w-full " type="range" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
