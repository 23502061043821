import { useCallback, useState } from 'react'
import { modelFileState, modelFileDeploy } from '../api/apiModel'
import { fetchModelDeployState, fetchModelDeployStatePoll } from '@/slices/sdModelListSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'

export function useDelpoyAction(authToken?: string) {
    const [deployStatus, setDeployStatus] = useState(2)
    const dispatch = useDispatch<AppDispatch>()

    const fetchDeployStatePoll = useCallback(
        async (modelIds: string[]) => {
            const dispatchPromise = dispatch(fetchModelDeployStatePoll({ authToken: authToken!, modelList: modelIds }))
            dispatchPromise.unwrap().then(res => {
                setDeployStatus(res.resultStatus)
            })
            return { dispatchPromise }
        },
        [authToken],
    )

    const fetchDeployState = useCallback(
        async (modelIds: string[], poll = false) => {
            let resultStatus: number = 2
            // 0未部署 1部署中 2完成

            const dispatchPromise = dispatch(fetchModelDeployState({ authToken: authToken!, modelList: modelIds }))
            const delpoyStateRes = await dispatchPromise.unwrap()

            if (delpoyStateRes.code == 1) {
                const needToDeployIds: string[] = []
                const deployingIds: string[] = []

                for (const item of delpoyStateRes.data.modelStateList) {
                    if (item.deployStatus == 0) {
                        needToDeployIds.push(item.modelId)
                        resultStatus = Math.min(resultStatus, 0)
                    } else if (item.deployStatus == 1) {
                        deployingIds.push(item.modelId)
                        resultStatus = Math.min(resultStatus, 1)
                    }
                }
                setDeployStatus(resultStatus)
                return { dispatchPromise, resultStatus, needToDeployIds, deployingIds }
            } else {
                throw new Error(delpoyStateRes.message)
            }
        },
        [authToken],
    )

    const fetchToDeploy = useCallback(
        async (modelIds: string[]) => {
            let canGenerate = true
            const delpoyStateRes = await modelFileDeploy({ authToken: authToken!, modelIds })
            if (delpoyStateRes.code == 1) {
                const deployingIds: string[] = []
                for (const item of delpoyStateRes.data.results) {
                    if (item.deployStatus == 0) {
                        throw new Error(item.message)
                    } else if (item.deployStatus == 1) {
                        canGenerate = false
                        deployingIds.push(item.modelId)
                    }
                }
                if (canGenerate) {
                    setDeployStatus(2)
                } else if (deployingIds.length > 0) {
                    setDeployStatus(1)
                }
                return canGenerate
            } else {
                throw new Error(delpoyStateRes.message)
            }
        },
        [authToken],
    )

    return { fetchDeployState, fetchToDeploy, deployStatus, fetchDeployStatePoll }
}
