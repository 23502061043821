/*
 * @Author: jianwen.Wang
 * @Date: 2022-05-30 16:38:26
 * @LastEditTime: 2022-06-01 14:25:12
 * @LastEditors: jiawen.wang
 */

import React, { useEffect, useRef, useState } from 'react'
// import styles from './select.scss'
import './select.css'

import Position from './Position'

export default function index(props: any) {
    const [mbSelectStatus, setMbSelectStatus] = useState<HTMLDivElement | null>(null)

    const { defaultValue, onChange, getContainer, onSearch, setSearchStr } = props
    // 控制下拉框显示/隐藏
    const [visible, setVisible] = useState(false)
    // 当前选中的值
    const [data, setData] = useState({ value: defaultValue, label: defaultValue })
    // 是否设置默认值
    const inputRef = useRef(null)
    // 查找defaultValue对应的label并展示出来
    useEffect(() => {
        const i = props.children.findIndex((n: any) => n.props.label === defaultValue)

        if (i > -1) {
            setData({ value: props.children[i].props.value, label: props.children[i].props.label })
        }
    }, [defaultValue, props.children])

    function handleSelect(data: any) {
        setData(data)
        onChange && onChange(data)
        setVisible(false)
        setSearchStr('')
    }

    const newData = useRef<any>(data)
    useEffect(() => {
        newData.current = data
        onChange && onChange(newData.current)
    }, [data])
    function handleInput(e: any) {
        setData({
            ...data,
            label: e.target.value,
        })
        setVisible(false)
        setSearchStr('')
    }
    function bindBodyClick(e: any) {
        if (e.target === inputRef.current) return
        setVisible(false)
        setSearchStr('')
    }

    useEffect(() => {
        document.addEventListener('click', bindBodyClick, false)
        return () => {
            document.removeEventListener('click', bindBodyClick, false)
        }
    }, [visible])

    const getBorder = () => {
        if (mbSelectStatus) {
            mbSelectStatus.classList.add('mb_select-ipt-blod-border')
        }
    }

    return (
        <>
            <div
                id="area"
                ref={ref => {
                    setMbSelectStatus(ref)
                }}
                className="mst_mb_select mb_select-ipt-default-border cursor-pointer"
            >
                <div
                    ref={inputRef}
                    onClick={event => {
                        event.preventDefault()
                        setVisible(true)

                        getBorder()
                    }}
                >
                    {data.value}
                </div>
                {/* <input
                    className="mb_select-ipt"
                    value={data.label}
                    onFocus={() => {
                        setVisible(true)
                        getBorder()
                    }}
                    onBlur={() => {
                        if (mbSelectStatus) {
                            mbSelectStatus.classList.remove('mb_select-ipt-blod-border')
                        }
                    }}
                    ref={inputRef}
                    onChange={e => {
                        handleInput(e)
                    }}
                /> */}
                {/* <div className="arrow" onClick={() => setVisible(true)}></div> */}
            </div>
            {/* <Position onNotVisibleArea={() => setVisible(false)} getContainer={getContainer} targetRef={inputRef}>
                {React.Children.map(props.children, child =>
                    React.cloneElement(child, {
                        defaultValue: data.value,
                        handleSelect,
                    }),
                )}
            </Position> */}
            {visible && (
                <Position onNotVisibleArea={() => setVisible(false)} getContainer={getContainer} targetRef={inputRef}>
                    <div className="relative w-11/12 my-3 m-auto flex justify-center items-center border-gradual text-white">
                        <input
                            onClick={e => {
                                e.stopPropagation()
                            }}
                            onKeyUp={e => {
                                e.stopPropagation()
                                onSearch(e)
                            }}
                            className="w-full py-1 px-4 outline-none "
                            type="text"
                        />
                    </div>
                    {React.Children.map(props.children, child =>
                        React.cloneElement(child, {
                            defaultValue: data.value,
                            handleSelect,
                        }),
                    )}
                </Position>
            )}
        </>
    )
}
