import { getOssAssumeRoleToken } from '@/api/stableDiffusionRequest'
import { uploadFiles2OSS } from '@/api/uploadFiles'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { setResourcesListItem } from '@/slices/StableDiffusionSlice'
import React, { useEffect, useState } from 'react'
import delIcon from '../../../assets/del.png'

export default function ListItem(props) {
    const { resourceInfo, deleteResourceBtn } = props
    const dispatch = useAppDispatch()
    const editBtn = () => {
        dispatch(setResourcesListItem(resourceInfo))
    }
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const [picList, setPicList] = useState<any>([])
    useEffect(() => {
        liseDir(resourceInfo)
    }, [])
    const liseDir = async resource => {
        const getOssAssumeRoleTokenResult = await getOssAssumeRoleToken({
            dataId: resource.resourceId,
            dataType: 1,
            authToken: authToken,
        })
        if (getOssAssumeRoleTokenResult.code == 1) {
            const client = uploadFiles2OSS(getOssAssumeRoleTokenResult.data)
            try {
                let result = await client.list({
                    prefix: resource.ossFileDir,
                    // 设置正斜线（/）为文件夹的分隔符。
                    delimiter: '/',
                    'max-keys': 3,
                })
                let picObj = result.objects.map(async function (obj) {
                    const result = await client.signatureUrl(obj.name, { expires: 3600 })
                    return {
                        ...obj,
                        imgUrl: result,
                    }
                })
                Promise.all(picObj).then(res => {
                    setPicList(res)
                })
            } catch (e) {
                console.log(e)
            }
        }
    }
    return (
        <div className="relative block w-full lg:max-w-sm rounded-lg bg-gray-900 shadow-lg text-white dark:bg-neutral-700">
            <div className="list-item-img-box rounded-t-lg cursor-pointer" data-te-ripple-init data-te-ripple-color="light" onClick={editBtn}>
                {picList.map(item => {
                    return <img className="list-item-img" src={item.imgUrl} alt="" key={item.name} />
                })}
                <button
                    onClick={event => {
                        event.stopPropagation()
                        deleteResourceBtn(resourceInfo.resourceId)
                    }}
                    type="button"
                    className="absolute bottom-1 right-1 text-white justify-center rounded-full font-medium px-4 py-3 hover:bg-pageBgColor"
                >
                    <img src={delIcon} alt="addIcon" />
                </button>
            </div>

            <div className="p-6">
                <h5 className="mb-2 text-xl font-medium leading-tight text-white truncate">{resourceInfo.name}</h5>
                <p className="mb-4 text-base text-white truncate">{resourceInfo.description}</p>

                {/* <button type="button" className="m-btn-base m-bg-gradient mr-3" data-te-ripple-init data-te-ripple-color="light" onClick={editBtn}>
                    Edit Dataset
                </button>
                <button type="button" className="m-btn-base m-bg-gradient" data-te-ripple-init data-te-ripple-color="light">
                    Train Model
                </button> */}
            </div>
        </div>
    )
}
