import { Popover, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import deleteImage from '../../../assets/photoPreview/delete.png'
import downloadImage from '../../../assets/photoPreview/download.png'
import useInitImage from '../../../assets/photoPreview/useInit.png'
import variationImage from '../../../assets/photoPreview/variation.png'
import largeImage from '../../../assets/photoPreview/enlarge1.png'
import generateImageImage from '../../../assets/photoPreview/generateImage.png'
import {
    IGenerateStateDiffusionState,
    IStImageListItem,
    setSd_historyDetailParams,
    setSd_imageItemDetailShow,
    setStableDiffusionState,
} from '@/slices/GenerateImageSlice'
import { useAppSelector } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import { fetchSd_deleteImageRecord } from '@/slices/GeneratedImageThunk'
import { sdApi, useLazyGetSdRecordDetailQuery } from '@/slices/sdApi'

interface ISdImageCellProps {
    model: IStImageListItem
}
const SdImageCell: React.FC<ISdImageCellProps> = props => {
    const { model } = props
    const imageUrl = model.generatedImageList?.[0]
    const dispatch = useDispatch<AppDispatch>()
    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))
    const requestImageActions = useAppSelector(state => state.generateImageSlice.stableDiffusion.requestImageActions)

    const authToken = useAppSelector(state => state.app?.authToken)
    const [trigger, result, lastPromiseInfo] = useLazyGetSdRecordDetailQuery()

    const { t } = useTranslation()

    async function requestDetail() {
        const res = await trigger({ taskId: model.taskId, authToken }, true)
        console.log('🚀 ~ file: SdImageCell.tsx:42 ~ requestDetail ~ res:', res)

        if (res.data) {
            dispatch(setSd_historyDetailParams({ ...res.data.tasks[0] }))
        }
    }
    return (
        <div
            className="relative block overflow-hidden rounded-lg bg-gray-bg group cursor-pointer"
            key={model.taskId}
            style={{ aspectRatio: `${model.generatedImageWidth}/${model.generatedImageHeight}` }}
        >
            <div
                className="absolute top-0 left-0 right-0 bottom-0 hidden group-hover:flex group-hover:bg-black/60 flex-col-reverse p-3"
                onClick={() => {
                    dispatch(setSd_imageItemDetailShow({ show: true, taskId: model.taskId }))
                }}
            >
                <div className="w-full flex justify-between  ">
                    <div className="flex space-x-3 items-center">
                        <Popover content={<div className="flex items-center justify-center">{t('preview.btnDownloadTip')}</div>}>
                            <button
                                className="w-[32px] h-[32px] flex items-center justify-center"
                                onClick={e => {
                                    e.stopPropagation()
                                    window.open(model.generatedImageList[0], '_block')
                                }}
                            >
                                <img className="w-full" src={downloadImage} alt="" />
                            </button>
                        </Popover>
                        <Popover content={<div className="flex items-center justify-center">{t('preview.btnUseImageTip')}</div>}>
                            <button
                                className="w-[32px] h-[32px] flex items-center justify-center"
                                onClick={e => {
                                    e.stopPropagation()
                                    updateState({ uploadResponse: { url: imageUrl, base64StringOrUrl: imageUrl } })
                                }}
                            >
                                <img src={useInitImage} className="w-full" alt="" />
                            </button>
                        </Popover>
                        <Popover content={<div className="flex items-center justify-center">{t('preview.btnGenerateImageTip')}</div>}>
                            <Spin spinning={result.isFetching}>
                                <button
                                    className="w-[32px] h-[32px] flex items-center justify-center"
                                    onClick={e => {
                                        e.stopPropagation()
                                        requestDetail()
                                    }}
                                >
                                    <img src={generateImageImage} className="w-full" alt="" />
                                </button>
                            </Spin>
                        </Popover>
                        <Popover content={<div className="flex items-center justify-center">{t('preview.btnVariationsTip')}</div>}>
                            <button
                                disabled={model.taskType == 4 ? true : false}
                                className={`w-[32px] h-[32px] flex items-center justify-center ${model.taskType == 4 ? 'grayscale' : ''}`}
                                onClick={e => {
                                    e.stopPropagation()
                                    requestImageActions.variation?.(model.taskId)
                                }}
                            >
                                <img src={variationImage} className="w-full" alt="" />
                            </button>
                        </Popover>

                        {model.taskType != 4 && (
                            <Popover content={<div className="flex items-center justify-center">{t('preview.btnEnlargeTip')}</div>}>
                                <button
                                    // disabled={model.taskType == 4 ? true : false}
                                    className={`w-[32px] h-[32px] flex items-center justify-center`}
                                    onClick={e => {
                                        e.stopPropagation()
                                        requestImageActions.upscale?.(model.taskId, model.generatedImageWidth, model.generatedImageHeight, imageUrl)
                                    }}
                                >
                                    <img src={largeImage} className="w-full" alt="" />
                                </button>
                            </Popover>
                        )}
                    </div>
                    {/* <Popover content={<div className="flex items-center justify-center">{t('preview.btnDeleteTip')}</div>}>
                        <button
                            className="w-[32px] h-[32px] flex items-center justify-center justify-self-end"
                            onClick={e => {
                                e.stopPropagation()
                                dispatch(fetchSd_deleteImageRecord(model.taskId))
                            }}
                        >
                            <img src={deleteImage} className="w-full" alt="" />
                        </button>
                    </Popover> */}
                </div>
            </div>

            <img src={imageUrl + '?x-oss-process=image/resize,w_300/quality,q_50'} className=" cursor-pointer" alt="" />
        </div>
    )
}

export default SdImageCell
