import { useState } from 'react'
import InputConcepts from './InputConcepts'
import InputSettings from './InputSettings'
import { useTranslation } from 'react-i18next'
export default function TrainInput(params) {
    const { t } = useTranslation()
    const btnList = [
        { name: t('training.input.setting'), index: 0 },
        // { name: 'Concepts', index: 1 },
        // { name: 'Saving', index: 2 },
        // { name: 'Generate', index: 3 },
        // { name: 'Testing', index: 4 },
    ]
    const [selectedBtn, setSelectedBtn] = useState(0)
    return (
        <div className="flex flex-col  p-2 rounded mt-2 w-full lg:w-1/3">
            <div>{t('training.input.title')}</div>
            <div className=" flex flex-col mt-2">
                <div className="flex border-b-2 border-trainBorderColor flex-wrap ">
                    {btnList.map((btnItem, index) => {
                        return (
                            <button
                                className={selectedBtn == btnItem.index ? 'm-tab-btn-selected' : 'm-tab-btn'}
                                key={btnItem.index}
                                onClick={() => {
                                    setSelectedBtn(btnItem.index)
                                }}
                            >
                                {btnItem.name}{' '}
                            </button>
                        )
                    })}
                </div>
                <div className=" p-2 border-2 border-t-0 border-trainBorderColor relative flex flex-col">
                    {selectedBtn == 1 && <InputConcepts></InputConcepts>}
                    {selectedBtn == 0 && <InputSettings></InputSettings>}
                </div>
            </div>
        </div>
    )
}
