// import { getImageUrl } from '@/helpers'
import { useAppSelector } from '@/hooks'
import { fetchCountryArea, setCountryArea, setMenuOpen } from '@/slices/AppSlice'
import { AppDispatch } from '@/store'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getOverseaUrl } from '@/helpers'
import beianIcon from '@/assets/beian.png'
import { message } from 'antd'
import ReactMarkdown from 'react-markdown'
import components = ReactMarkdown.propTypes.components
import { Simulate } from 'react-dom/test-utils'

export default function Gov(props) {
    const [time, setTime] = useState(0)
    useEffect(() => {
        const InterVal = setInterval(() => {
            setTime(time + 1)
        }, 800)
        // window.sessionStorage.setItem('header-serve', response.headers.server)
    }, [])

    return getOverseaUrl() ? (
        <div></div>
    ) : (
        <div className="w-full pt-10 pb-5 flex flex-col justify-center items-center text-white">
            <div>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010302000870" target="_blank" className="underline">
                    增值电信业务经营许可证：辽B2-20170046
                </a>
                {`  ${'  |  '}  `}
                <a href="https://img.mst.xyz/img/20230617175946.png" target="_blank" className="underline">
                    辽B2-20170046-3
                </a>
            </div>
            <div className="w-full flex text-white  space-x-2 justify-center items-center">
                <img className=" w-5 h-5" src={beianIcon} alt="" />
                <a href="https://beian.miit.gov.cn" target="_blank" className="underline">
                    辽公网安备 21010302000870号
                </a>
            </div>
        </div>
    )
}
