// import { login, loginWithGoogle, registerWithCloudflareCode, resetPassword } from '@/api/request'
import { login, loginWithGoogle, registerWithCloudflareCode, resetPassword } from '@/api/apiAccount'

import { validateTrunstileCode, phoneVerificationCodeCloudflare } from '@/api/apiCommon'
import { message } from 'antd'
import { fetchCountryArea, setAuthToken, setShowLogin } from '@/slices/AppSlice'
import { AppDispatch } from '@/store'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import icon_hd from '../../assets/login-icon.png'
import phone_icon from '../../assets/phone_icon.png'
import password_icon from '../../assets/password_icon.png'
import register_icon from '../../assets/register_icon.png'
import invitation_icon from '../../assets/login&reg/invitation.png'

import showPasswordOpen from '../../assets/showPasswordOpen.png'
import showPasswordClose from '../../assets/showPasswordClose.png'
import btn_google_signin_light_normal_web from '../../assets/PPC/btn_google_signin_light_normal_web.png'
import btn_google_signin_light_normal_web_2x from '../../assets/PPC/btn_google_signin_light_normal_web@2x.png'
import google_svg_logo from '../../assets/PPC/btn_google_light_normal_ios.svg'
import Cookies from 'js-cookie'
import { useHistory, useLocation } from 'react-router-dom'
import BackdropSpinner from '@/components/Spinner/BackdropSpinner'
import { gapi, loadAuth2 } from 'gapi-script'
import styles from './LoginPop.module.css'
import Turnstile from 'react-turnstile'
import { useTranslation } from 'react-i18next'
import md5 from 'js-md5'
import { Select, Form, Input, ConfigProvider, Popover, Tooltip } from 'antd'
import PhoneNumInput from '@/components/Login/PhoneNumInput'
import { getOverseaUrl, getUserBrowserLang } from '@/helpers'
import { trackEvent, trackIdentify, googleTrackEvent } from '@/lib/analyse'
import GlobalStorage from '@/lib/globalStorage'
import { deleteAllWatersImageRecord } from '../../slices/NewFunctionSlice'
import { useAppSelector } from '@/hooks'
const { Option } = Select

enum UIType {
    Login,
    Register,
    ResetPassword,
}

const AUTH2_CLIENTID = import.meta.env.VITE_GOOGLE_AUTH2_KEY

const LoginPop: React.FC = props => {
    const TURNSTILE_KEY = import.meta.env.VITE_TURNSTILE_KEY
    const dispatch = useDispatch<AppDispatch>()
    const { t, i18n } = useTranslation()
    const VALIDATE_CODE_TEXT = t('login.send')
    const [username, setUsername] = useState('')
    const [code, setCode] = useState('')
    const [codeTimerDesc, setCodeTimerDesc] = useState(VALIDATE_CODE_TEXT)

    const [pwd, setPwd] = useState('')
    const [isRemember, setIsRemember] = useState(false)

    const [errorMsg, setErrorMsg] = useState('')

    const [curType, setCurType] = useState(UIType.Login)

    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const history = useHistory()

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.substring(0, 2))
    const [phoneArea, setPhoneArea] = useState(getUserBrowserLang() == 'zh' ? '+86' : '+1')

    const [isDomain, setIsDomain] = useState(window.location.host.includes('mst.ai')) // 判断当前domain
    const vistorAuthToken = useAppSelector(state => {
        return state.app && state.app.vistorAuthToken
    })
    const uniqueCode = useAppSelector(state => {
        return state.app && state.app.uniqueCode
    })

    const changeLanguage = () => {
        if (currentLanguage == 'zh') {
            i18n.changeLanguage('en')
            setCurrentLanguage('en')
            // setPhoneArea('+1')
        } else if (i18n.language == 'en') {
            i18n.changeLanguage('zh')
            setCurrentLanguage('zh')
            // setPhoneArea('+86')
        }
    }

    useEffect(() => {
        setErrorMsg('')
        if (curType != UIType.Login) {
            setPwd('')
            setUsername('')
            setCode('')
        }
    }, [curType])

    function changeShowPassword() {
        setShowPassword(!showPassword)
    }

    async function userlogin(password: string, userName: string) {
        const res: any = await login({ password: md5(password), userName: userName, phoneArea })
        if (res.code == 1) {
            trackIdentify('userName', userName)
            trackEvent('login', { 'Login Type': 'phone' })
            googleTrackEvent('login', 'phone')
            setErrorMsg('')
            dispatch(setAuthToken(res.data.token))
            GlobalStorage.set('auth_token', res.data.token)
            GlobalStorage.set('login_type', 'userInfo')
            dispatch(setShowLogin(false))
            dispatch(deleteAllWatersImageRecord({}))
            history.push('/tools/Waters')
        } else {
            GlobalStorage.remove('auth_token')
            GlobalStorage.remove('login_type')
            setErrorMsg(res.message ?? t('login.errorTip.checkFailure'))
            message.error(res.message ?? t('login.errorTip.checkFailure'))
        }
    }
    function useQuery() {
        const { search } = useLocation()

        return React.useMemo(() => new URLSearchParams(search), [search])
    }

    let query = useQuery()
    const [invitationCode, setInvitationCode] = useState(query.get('invitationCode') ? query.get('invitationCode') : '')

    async function googlelogin(idToken: string) {
        const params = { idTokenString: idToken, invitationCode: invitationCode, uniqueCode: uniqueCode }
        console.log('🚀 ~ file: LoginPop.tsx:129 ~ googlelogin ~ params:', params)

        const res: any = await loginWithGoogle(params)
        if (res.code == 1) {
            trackIdentify('userName', idToken)
            trackEvent('login', { 'Login Type': 'google' })
            googleTrackEvent('login', 'google')

            setErrorMsg('')
            dispatch(setAuthToken(res.data.token))
            GlobalStorage.set('auth_token', res.data.token)
            GlobalStorage.set('login_type', 'google')
            dispatch(setShowLogin(false))
            history.push('/tools/Waters')
        } else {
            GlobalStorage.remove('auth_token')
            GlobalStorage.remove('login_type')
            setErrorMsg(res.message ?? t('login.errorTip.checkFailure'))
            message.error(res.message ?? t('login.errorTip.checkFailure'))
        }
    }
    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        if (username.length < 1) {
            setErrorMsg(t('login.errorTip.providePhoneNumber') as string)
            message.error(t('login.errorTip.providePhoneNumber') as string)
            return
        }

        if (curType == UIType.Register || curType == UIType.ResetPassword) {
            if (code.length < 1) {
                setErrorMsg(t('login.errorTip.provideVerificationCode') as string)
                message.error(t('login.errorTip.provideVerificationCode') as string)
                return
            }
        }

        if (pwd.length < 1) {
            setErrorMsg(t('login.errorTip.providePassword') as string)
            message.error(t('login.errorTip.providePassword') as string)
            return
        }

        try {
            setLoading(true)
            if (curType == UIType.Login) {
                await userlogin(pwd, username)
            } else if (curType == UIType.Register) {
                await registerAndLoginWithCloudFare()
            } else {
                await resetPasswordAndLogin()
            }
            setLoading(false)
        } catch (e) {
            console.log('🚀 ~ file: LoginPop.tsx:164 ~ handleSubmit ~ e:', e)
            setLoading(false)
            setErrorMsg(t('login.errorTip.checkFailure') as string)
            message.error(t('login.errorTip.checkFailure') as string)
        }
    }
    const setAuth2 = async () => {
        if (getOverseaUrl()) {
            const auth2 = await loadAuth2(gapi, AUTH2_CLIENTID, '')
            if (auth2.isSignedIn.get()) {
                console.log(auth2.currentUser.get().getAuthResponse().id_token)
            }
            const element = document.getElementById('googleLoginBtn')
            if (element) {
                auth2.attachClickHandler(
                    element,
                    {},
                    googleUser => {
                        googlelogin(googleUser.getAuthResponse().id_token)
                    },
                    error => {
                        setErrorMsg(JSON.stringify(error))
                        console.error(error)
                        // message.error(JSON.stringify(error))
                    },
                )
            }
        }
    }
    useEffect(() => {
        setAuth2()
    }, [])

    const [cloudFareToken, setCloudFareToken] = useState('')
    const handleCloudFareToken = async token => {
        const res: any = await validateTrunstileCode({ cfTurnstileResponse: token })
        if (res.code == 1) {
            setCloudFareToken(token)
        } else {
            setCloudFareToken('')
            setErrorMsg(t('login.errorTip.checkFailure') as string)
            message.error(t('login.errorTip.checkFailure') as string)
        }
    }

    const registerAndLoginWithCloudFare = async () => {
        const res: any = await registerWithCloudflareCode({
            cfTurnstileResponse: cloudFareToken,
            password: md5(pwd),
            phoneNumber: username,
            phoneVerificationCode: code,
            userName: username,
            phoneArea,
            invitationCode: invitationCode,
            uniqueCode: uniqueCode,
        })
        if (res.code == 1) {
            trackEvent('register')
            googleTrackEvent('register', 'register')
            userlogin(pwd, username)
        } else if (res.code == 10011) {
            setErrorMsg(res.message ?? t('login.errorTip.codeInvalid'))
            message.error(res.message ?? t('login.errorTip.codeInvalid'))
        } else {
            setErrorMsg(res.message ?? t('login.errorTip.registerFailure'))
            message.error(res.message ?? t('login.errorTip.registerFailure'))
        }
    }

    const getVertufityCodeWithClouldFare = async () => {
        if (username.length < 1) {
            setErrorMsg(t('login.errorTip.providePhoneNumber') as string)
            message.error(t('login.errorTip.providePhoneNumber') as string)
            return
        }
        const res: any = await phoneVerificationCodeCloudflare({
            cfTurnstileResponse: cloudFareToken,
            phoneNumber: username,
            phoneArea,
        })

        if (res.code == 1) {
            let time = 60
            setCodeTimerDesc(time.toString())
            const interval = setInterval(() => {
                if (time < 0) {
                    clearInterval(interval)
                    setCodeTimerDesc(VALIDATE_CODE_TEXT)
                    return
                }

                setCodeTimerDesc(time.toString())
                time -= 1
            }, 1000)
        } else {
            setErrorMsg(res.message)
            message.error(res.message)
        }
    }

    const resetPasswordAndLogin = async () => {
        const res: any = await resetPassword({
            cfTurnstileResponse: cloudFareToken,
            password: md5(pwd),
            phoneNumber: username,
            phoneVerificationCode: code,
            userName: username,
            phoneArea,
        })
        if (res.code == 1) {
            userlogin(pwd, username)
        } else {
            setErrorMsg(res.message ?? t('login.errorTip.checkFailure'))
            message.error(res.message ?? t('login.errorTip.checkFailure'))
        }
    }
    const handleChange = event => {
        setPhoneArea(event.target.value)
    }

    const turnstileError = e => {
        console.log('🚀 ~ file: LoginPop.tsx:264 ~ turnstileError ~ e:', e)
        message.error(e)
    }

    const showLoginPop = () => {
        dispatch(setShowLogin(false))
    }

    const tipContent = <div className="flex items-center justify-center">{t('Verification by human-machine interaction is required')}</div>
    return (
        <div className=" fixed w-full top-0 z-[998]">
            <div className="flex justify-center items-center  text-[#ffffff] min-h-screen bg-gray-950 bg-opacity-70">
                <div className="w-full max-w-md p-6 bg-gray-900 relative max-h-[100vh] overflow-auto dark-thin-scrollbar" id="captchaBox">
                    <div
                        className="absolute top-3 right-3 border-primary-600 bg-primary-50 text-primary-200 group inline-block text-sm font-medium cursor-pointer"
                        onClick={showLoginPop}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-6 w-6"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    <BackdropSpinner show={loading} />
                    <div className="relative flex items-center justify-center space-x-2">
                        <img src={icon_hd}></img>
                    </div>
                    <div className="w-full mt-6 mb-6 text-[#ffffff] text-center text-2xl">
                        {curType == UIType.Login
                            ? t('login.signInYourAccount')
                            : curType == UIType.Register
                            ? t('login.signUpYourAccount')
                            : t('login.resetYourAccount')}
                    </div>
                    {getOverseaUrl() && (
                        <>
                            <div className="flex flex-col space-y-4">
                                <span>{t('login.signInWith')}</span>
                                <div id="googleLoginBtn" className={`flex flex-col w-full py-4 border-gradual rounded-full outline-none`}>
                                    <button className="w-full flex flex-row items-center justify-center">
                                        <svg
                                            width="21"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fab"
                                            data-icon="google"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 488 512"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                                            ></path>
                                        </svg>
                                        <span className="ml-2">{t('login.googleSignIn')}</span>
                                    </button>
                                </div>
                            </div>

                            {/* <div className="flex flex-col space-y-4">
                        <span>{t('login.signInWith')}</span>
                        <div id="googleLoginBtn">
                            <button className="w-full flex flex-row items-center justify-center">
                                <img src={google_svg_logo} alt="" />
                                <div>google</div>
                            </button>
                        </div>
                    </div> */}
                            <div className="flex items-center my-4">
                                <hr className="flex-1 border-gray-200" />
                                <span className="mx-16 text-base text-gray-500">{t('login.continue')}</span>
                                <hr className="flex-1 border-gray-200" />
                            </div>
                        </>
                    )}
                    <form id="captchaBoxForm" className={styles.captchaBoxForm} onSubmit={handleSubmit}>
                        {(curType == UIType.Register || curType == UIType.ResetPassword) && isDomain && (
                            <div className="space-y-4 mb-5 flex items-center justify-center">
                                <Turnstile sitekey={TURNSTILE_KEY} onVerify={token => handleCloudFareToken(token)} />
                            </div>
                        )}
                        <div className="space-y-4 mt-5">
                            <div className={`relative flex justify-center items-center border-gradual rounded-full space-x-4 px-6 text-white`}>
                                <img src={phone_icon} alt="" />
                                <PhoneNumInput setPhoneArea={setPhoneArea} phoneArea={phoneArea} />

                                <input
                                    className="w-full py-4 outline-none"
                                    type="text"
                                    autoComplete="username"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                />
                            </div>
                        </div>

                        {(curType == UIType.Register || curType == UIType.ResetPassword) && (
                            <div className="space-y-4 mt-5">
                                <div className={`relative flex justify-center items-center border-gradual rounded-full space-x-4 pl-6 pr-2`}>
                                    <img src={register_icon} alt="" />
                                    <input className="w-full py-4 outline-none" type="text" value={code} onChange={e => setCode(e.target.value)} />
                                    <div className="p-1">
                                        <input
                                            disabled={
                                                isDomain
                                                    ? cloudFareToken == ''
                                                        ? true
                                                        : codeTimerDesc == VALIDATE_CODE_TEXT
                                                        ? false
                                                        : true
                                                    : codeTimerDesc == VALIDATE_CODE_TEXT
                                                    ? false
                                                    : true
                                            }
                                            type="button"
                                            value={codeTimerDesc}
                                            className={`cursor-pointer py-2 min-w-[100px] text-sm font-extrabold ${styles['verfitity-code-btn']} disabled:cursor-not-allowed`}
                                            onClick={e => {
                                                getVertufityCodeWithClouldFare()
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* password */}
                        <div className="space-y-4 mt-5">
                            <div className={`relative flex justify-center items-center border-gradual rounded-full space-x-4 px-6`}>
                                <img src={password_icon} alt="" />
                                <input
                                    id={curType == UIType.Login ? 'current-password' : undefined}
                                    className="w-full py-4 outline-none"
                                    autoComplete={curType == UIType.Login ? 'current-password' : 'new-password'}
                                    type={showPassword ? 'text' : 'password'}
                                    value={pwd}
                                    onChange={e => setPwd(e.target.value)}
                                />
                                <img src={showPassword ? showPasswordClose : showPasswordOpen} onClick={changeShowPassword} alt="" className="w-5" />
                            </div>
                        </div>
                        {curType == UIType.Register && (
                            <div className="space-y-4 mt-5">
                                <div className={`relative flex justify-center items-center border-gradual space-x-4 px-6`}>
                                    <img src={invitation_icon} alt="" />
                                    <input
                                        className="w-full py-4 outline-none"
                                        placeholder={t('login.PleaseEnterTheInvitationCode')}
                                        value={invitationCode}
                                        onChange={e => setInvitationCode(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}

                        {curType == UIType.Login && (
                            <div
                                className="w-full mt-2 text-right text-sm font-normal text-indigo-400 cursor-pointer"
                                onClick={() => {
                                    setCurType(UIType.ResetPassword)
                                }}
                            >
                                {t('login.resetPassword')}
                            </div>
                        )}

                        <button type="submit" className={`w-full py-4 mt-6 font-extrabold rounded-full m-bg-gradient`}>
                            {curType == UIType.Login ? t('login.signIn') : curType == UIType.Register ? t('login.signUp') : t('login.resetConfirm')}
                        </button>
                    </form>
                    <button
                        className="relative w-full mt-6 mb-6 text-center text-xs sm:text-sm font-normal text-indigo-400"
                        onClick={() => {
                            setCurType(val => {
                                return val == UIType.Login ? UIType.Register : UIType.Login
                            })
                        }}
                    >
                        {curType == UIType.Login ? t('login.dontHaveAccount') : t('login.haveAccount')}
                    </button>
                    {/* <div className="w-full flex justify-center text-xs sm:text-sm">
                        <span>{t('common.contactUs')}&nbsp;</span>
                        <></>
                        <a className="text-white underline text-xs sm:text-sm" href="mailto:contact@mst.ai">
                            {`contact@mst.ai`}
                        </a>
                    </div> */}
                    {errorMsg.length > 0 && <div className="mt-3 flex justify-center text-red-500">{errorMsg}</div>}
                    <div className="w-5/6 mx-auto flex justify-between text-center mt-4 mb-20">
                        <a href="/serviceTerms" target="_blank" className="text-white underline text-xs sm:text-sm">
                            TERMS OF SERVICE
                        </a>
                        <a href="/privacyPolicy" target="_blank" className="text-white underline text-xs sm:text-sm">
                            PRIVACY POLICY
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoginPop
