import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropdownList from './DropdownList'
import { useAppSelector } from '@/hooks'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import {
    fetchModelListConfig,
    fetchPrivateModelList,
    fetchPublicModelList,
    setCurCategory,
    setCurSearch,
    setCurSort,
    setCurType,
} from '@/slices/sdModelListSlice'
import { ModelListTab } from '../AIImage/modal/ModelListModal'
import { trackEvent, googleTrackEvent } from '@/lib/analyse'

const SDModelListSearch: React.FC = props => {
    const { t } = useTranslation()
    const state = useAppSelector(state => state.sdModelListSlice)
    const dispatch = useDispatch<AppDispatch>()
    const authToken = useAppSelector(state => state.app?.authToken)
    const [searchText, setSearchText] = useState(state.curSearch ?? '')

    useEffect(() => {
        if (authToken) {
            dispatch(fetchModelListConfig(1))
            dispatch(fetchModelListConfig(2))
            dispatch(fetchModelListConfig(3))
        }
    }, [authToken])

    const showCategorys = useMemo(() => {
        const categorys = [...state.categorys]
        categorys.unshift({
            name: 'modelShop.all',
            data: {
                dicName: 'modelShop.all',
                systemDicId: 0,
            },
        })
        return categorys
    }, [state.categorys])
    useEffect(() => {
        dispatch(setCurCategory(showCategorys[0]))
    }, [showCategorys])

    const showTypes = useMemo(() => {
        const types = [...state.types]
        types.unshift({
            name: 'modelShop.all',
            data: {
                dicName: 'modelShop.all',
                systemDicId: 0,
            },
        })
        return types
    }, [state.types])
    useEffect(() => {
        dispatch(setCurType(showTypes[0]))
    }, [showTypes])

    const curFetchAction = useMemo(() => {
        if (state.curTab?.tab == ModelListTab.Public) {
            return fetchPublicModelList
        } else if (state.curTab?.tab == ModelListTab.Private) {
            return fetchPrivateModelList
        } else {
            return fetchPublicModelList
        }
    }, [state.curTab])

    return (
        <div className="w-full flex flex-col lg:flex-row space-y-1 lg:space-y-0 lg:space-x-2 justify-center items-center">
            {/* search */}
            <div className="lg:flex-1 shrink w-full flex">
                <form className="w-full lg:max-w-3xl h-10 flex ">
                    <input
                        autoComplete="on"
                        type="search"
                        className="w-full outline-none px-4 rounded-full bg-gray-bg"
                        placeholder={`${t('modelShop.SearchModels')}`}
                        value={searchText}
                        onChange={e => {
                            setSearchText(e.target.value)

                            if (e.target.value.length < 1) {
                                dispatch(setCurSearch(e.target.value))
                                trackEvent('models button event', { button_type: 'search' })
                                googleTrackEvent('models button event', 'search')
                                dispatch(curFetchAction())
                            }
                        }}
                        onBlur={e => {
                            dispatch(setCurSearch(e.target.value))
                        }}
                    />
                    <button
                        onClick={e => {
                            e.preventDefault()
                            dispatch(curFetchAction())
                        }}
                        className="m-bg-gradient rounded-full px-4 lg:px-8 shrink-0 ml-2 text-xs lg:text-base font-medium"
                    >
                        {t('generatedImage.search')}
                    </button>
                </form>
                {/* mobile sort */}
                <span className="ml-2 shrink-0 flex lg:hidden justify-center">
                    <DropdownList
                        value={state.curSort}
                        onClickHandle={item => {
                            dispatch(setCurSort(item))
                            dispatch(curFetchAction())
                        }}
                        className="w-full"
                        cellData={state.sorts}
                    />
                </span>
            </div>

            {/* sort */}
            <div className="group w-full lg:flex-1 lg:min-w-[44rem] flex flex-col justify-items-stretch space-y-1  lg:flex-row lg:items-center lg:justify-end">
                <span className="hidden lg:flex justify-center">
                    <DropdownList
                        value={state.curSort}
                        onClickHandle={item => {
                            dispatch(setCurSort(item))
                            dispatch(curFetchAction())
                        }}
                        className="w-full"
                        cellData={state.sorts}
                    />
                </span>
                <span className="flex items-center justify-between lg:justify-start">
                    <span className="mx-2 lg:ml-4 lg:mr-2 text-xs lg:text-sm whitespace-nowrap">{t('modelShop.Category')}</span>
                    <DropdownList
                        value={state.curCategory}
                        onClickHandle={item => {
                            dispatch(setCurCategory(item))
                            dispatch(curFetchAction())
                        }}
                        className="min-w-[8rem] lg:w-full"
                        cellData={showCategorys}
                    />
                </span>
                <span className=" flex items-center justify-between lg:justify-start">
                    <span className="mx-2 lg:ml-4 lg:mr-2 text-xs lg:text-sm whitespace-nowrap">{t('modelShop.type')}</span>
                    <DropdownList
                        value={state.curType}
                        onClickHandle={item => {
                            dispatch(setCurType(item))
                            dispatch(curFetchAction())
                        }}
                        className="min-w-[8rem] lg:w-full"
                        cellData={showTypes}
                    />
                </span>
            </div>
        </div>
    )
}
export default SDModelListSearch
