import FingerprintJS from '@fingerprintjs/fingerprintjs'
import GlobalStorage from './globalStorage'

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load({
    monitoring: false,
})

export async function getVisitorId() {
    // Get the visitor identifier when you need it.

    const preId = GlobalStorage.get('visitorId')
    if (preId) return preId

    const fp = await fpPromise
    const result = await fp.get()
    GlobalStorage.set('visitorId', result.visitorId)
    return result.visitorId
}
