import mixpanel from 'mixpanel-browser'
import * as amplitude from '@amplitude/analytics-browser'
import ReactGA from "react-ga4"



export function trackInit() {
    const isDebug = import.meta.env.VITE_APP_MODE !== 'prod'
    console.log('🚀 ~ file: App.tsx:21 ~ useEffect ~ isDebug:', isDebug)
    mixpanel.init('c272943f792c37bb88876214004da291', { debug: isDebug })
    mixpanel.set_config({ persistence: 'localStorage' })

    // amplitude.init('2983205b87eac213409fb989ba57b8da', undefined, isDebug ? { logLevel: amplitude.Types.LogLevel.Debug } : {})
    ReactGA.initialize([
        {
          trackingId: "G-45KVCZQXHB"
        },
        {
          trackingId: "G-1P32LHWMCF",
        },
      ]);
}

export function trackIdentify(key: string, value: string) {
    console.log('🚀 ~ file: analyse.ts:13 ~ trackIdentify ~ value:', value)
    mixpanel.identify(value)

    // const identifyObj = new amplitude.Identify()
    // identifyObj.set(key, value)
    // amplitude.identify(identifyObj)
}
export function trackEvent(type: string, properties?: { [p: string]: any }) {
    mixpanel.track(type, properties)
    // amplitude.track(type, properties)
}

export function googleTrackEvent(action: string, category: string, properties?: { [p: string]: any }) {
    ReactGA.event({...{
        action: action,
        category: category,
      }, ...properties});
}

export function googleSendEvent(page: string, category: string, properties?: { [p: string]: any }) {
    ReactGA.send({ hitType: "pageview", page: page, title: category });
}

export function pageEvent(properties?: { [p: string]: any }) {
    mixpanel.track_pageview(properties)
}

export function timeEvent(type: string) {
    mixpanel.time_event(type)
}
