import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter, BrowserRouter } from 'react-router-dom'

import App from './App'
import './index.css'
import store from './store'
import './locales/index'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import TranslationConfig from './components/TranslationConfig/TranslationConfig'
import loading_animation from './assets/loading.png'
import { HelmetProvider } from 'react-helmet-async'

import { AliyunOSSProvider } from './lib/AliyunOSS'
import AntProvider from './pages/sdModelList/AntProvider'
import { Provider } from 'react-redux'
import Spinner from './components/Spinner/Spinner'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from '@/lib/react-query'
import { CompatRouter } from 'react-router-dom-v5-compat'

// console.log('🚀 ~ file: main.tsx:12 ~ import.meta.env:', import.meta.env)

// if (import.meta.env.VITE_APP_MODE === 'prod' || import.meta.env.VITE_APP_MODE === 'coder') {
//     Sentry.init({
//         dsn: import.meta.env.VITE_APP_SENTRY_DNS,
//         // dsn: 'https://ab41c4abb9af4b7384b3a2e52ff80fb0@chat.53site.com/sentry/27',

//         // dsn: 'http://a1cef122eb914836a6fd0deb2d901831@sentry.53site.com:9000/25',
//         // 初始参数配置内容
//         integrations: [new Integrations.BrowserTracing()],

//         // We recommend adjusting this value in production, or using tracesSampler
//         // for finer control
//         // 触发异常后发送给 Sentry 的概率
//         tracesSampleRate: 1.0,
//         // 控制应捕获的面包屑(行为栈)的总量
//         maxBreadcrumbs: 20,
//         // 规定上下文数据结构的深度，默认为 3
//         normalizeDepth: 100,
//         // 版本信息
//         release: 'common@1.0.0',
//         // 环境信息
//         environment: import.meta.env.MODE,
//         // 钩子函数，在每次发送 event 前触发
//         beforeSend(event) {
//             // console.log('event: ', event)
//             // 网页应用刷新后设置的变量会消失，所以我选择在 beforeSend 触发时插入用户信息
//             // event.user = {
//             //     userNick: 'user:jiawen.wang',
//             // }
//             return event
//         },
//     })
// }

ReactDOM.createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
        <BrowserRouter>
            <CompatRouter>
                <React.Suspense
                // fallback={
                //     <div className="flex items-center justify-center w-screen h-screen text-white">
                //         <Spinner />
                //     </div>
                // }
                >
                    <QueryClientProvider client={queryClient}>
                        <TranslationConfig>
                            <AliyunOSSProvider>
                                <HelmetProvider>
                                    {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools position="bottom-right" />}
                                    {/* <AuthLoader
                                    renderLoading={() => (
                                        <div className="w-screen h-screen flex justify-center items-center">
                                            <Spinner />
                                        </div>
                                    )}
                                > */}
                                    <AntProvider>
                                        <App />
                                    </AntProvider>
                                    {/* </AuthLoader> */}
                                </HelmetProvider>
                            </AliyunOSSProvider>
                        </TranslationConfig>
                    </QueryClientProvider>
                </React.Suspense>
            </CompatRouter>
        </BrowserRouter>
    </Provider>,
)
