import NavBar from '@/components/public/NavBar'
import { subRoutes } from '@/router'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { HashRouter as Router, Route, useHistory, Switch, Redirect, useLocation, useRouteMatch } from 'react-router-dom'
import MyRoute from '../../components/MyRoute'
import Chat from '../Chat'
import { AppDispatch } from '@/store'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@/hooks'
import { fetchCheckAuthTokenValid, setAuthToken } from '@/slices/AppSlice'
import Header from '@/components/public/Header'
import GlobalStorage from '@/lib/globalStorage'

export default function index() {
    // const { path, url, params } = useRouteMatch()
    // 获取 path 参数, http://localhost:3000/a/:number
    // const { number } = useParams()
    // const dispatch = useDispatch<AppDispatch>()
    // const authToken = useAppSelector(state => {
    //     return state.app && state.app.authToken
    // })

    // useEffect(() => {
    //     console.log(123123123)
    //     dispatch(fetchCheckAuthTokenValid({}))
    //     // validAndLogin(authToken => dispatch(setAuthToken(authToken)))
    // }, [])
    return (
        <>
            {GlobalStorage.get('auth_token') ? (
                <Switch>
                    {subRoutes.map(item => {
                        return <MyRoute key={item.path} path={item.path} component={item.component} name={item.name} />
                    })}

                    <Redirect from="/tools" to="/tools/Waters" exact />
                    <Redirect to="/404" />
                </Switch>
            ) : (
                <Redirect to="/home" />
            )}
        </>
    )
}
