import { useState, useEffect, useRef, useImperativeHandle, forwardRef, momo } from 'react'
import Macy from 'macy'
import { useAppSelector } from '@/hooks'
import { historicalRecord, historicalRecordDelete, historicalRecordDetail } from '../../api/request'
import { Modal, Progress, message, Popover, FloatButton, Button } from 'antd'

import { useTranslation } from 'react-i18next'
import seed_icon from '../../assets/seed.png'
import enlarge_icon from '../../assets/photoPreview/enlarge.png'
import backToTop_icon from '../../assets/common/backToTop.png'
import delete_icon from '../../assets/common/delete_tip.png'
import close_icon from '../../assets/photoPreview/close.png'
import noHistoryRecord from '../../assets/photoPreview/noHistoryRecord.png'
// import imageBg_icon from '../../assets/photoPreview/imageCover.png'
import deleteImage from '../../assets/photoPreview/delete.png'
import downloadImage from '../../assets/photoPreview/download.png'
import useInitImage from '../../assets/photoPreview/useInit.png'
import variationImage from '../../assets/photoPreview/variation.png'
import generateImageImage from '../../assets/photoPreview/generateImage.png'
import largeImage from '../../assets/photoPreview/enlarge1.png'
import loading_pnd from '../../assets/loading.png'

import PhotoPreview from '../../components/PhotoPreview'
import { useStLoading } from './StHooks'

import DeleteModel from '../../components/DeleteModal'
import { useDispatch } from 'react-redux'
import { setSd_historyDetailParams } from '@/slices/GenerateImageSlice'
import { useLazyGetSdRecordDetailQuery } from '@/slices/sdApi'
import StickyHeader from './components/StickyHeader'

const ImageWaterFlow = forwardRef((params, ref) => {
    const { pageType, setInputUrl, imageEditorM, requestEnlarge, outContainerRef } = params
    const { loadingItem, spinning } = useStLoading()

    const historyRecorderRef = useRef(null)
    const { t } = useTranslation()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })

    const dispatch = useDispatch()
    const [macyInstance, setMacyInstance] = useState(null)

    const [isAtBottom, setIsAtBottom] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const containerRef = useRef(null)

    const [images, setImages] = useState([])
    const [taskImgId, setTaskImgId] = useState('0')

    const [showToTop, setShowToTop] = useState(false)
    const [showDeleteModel, setShowDeleteModel] = useState(false)

    const [selectDeleteImageObj, setSelectDeleteImageObj] = useState(null)
    const requestImageActions = useAppSelector(state => state.generateImageSlice.stableDiffusion.requestImageActions)
    useImperativeHandle(ref, () => ({
        childMethod() {
            loadImage(true)
        },
    }))

    useEffect(() => {
        if (authToken) {
            if (macyInstance) {
                loadImage()
            }
        }
    }, [authToken, macyInstance])

    useEffect(() => {
        if (images.length > 0) {
            if (macyInstance) {
                macyInstance.runOnImageLoad(function () {
                    macyInstance.recalculate(true, true)
                })
            }
        }
    }, [images])
    const loadImage = async isReload => {
        const res = await historicalRecord({ authToken, taskImgId: isReload ? '0' : taskImgId, drawType: pageType == 'sd' ? 1 : 2 })
        if (res.code == 1) {
            if (isReload) {
                if (res.data.tasks.length == 0) {
                    setHasMore(false)
                    setImages([])
                    setTaskImgId('0')
                } else if (res.data.tasks.length < 20) {
                    setHasMore(false)
                    setImages(res.data.tasks)
                    setTaskImgId('0')
                } else {
                    setHasMore(true)
                    setImages(res.data.tasks)
                    setTaskImgId(res.data.tasks[res.data.tasks.length - 1].taskImageId)
                }
            } else {
                if (res.data.tasks.length == 0) {
                    setHasMore(false)
                } else if (res.data.tasks.length < 20) {
                    setHasMore(false)
                    setImages([...images, ...res.data.tasks])
                } else {
                    setHasMore(true)
                    setTaskImgId(res.data.tasks[res.data.tasks.length - 1].taskImageId)
                    setImages([...images, ...res.data.tasks])
                }
            }
        }
    }

    useEffect(() => {
        if (isAtBottom && hasMore) {
            loadImage()
        }
    }, [isAtBottom, hasMore])

    useEffect(() => {
        if (containerRef.current) {
            let col = 4
            var width = document.querySelector('html')?.offsetWidth
            if (width <= 768) {
                col = 2
            }

            const macyInstance = Macy({
                container: containerRef.current,
                trueOrder: false,
                waitForImages: false,
                margin: 24,
                columns: col,
            })
            setMacyInstance(macyInstance)
        }
    }, [])

    useEffect(() => {
        // console.log('🚀 ~ file: ImageWaterFlow.jsx:133 ~ useEffect ~ container:', outContainerRef.current)

        // console.log('🚀 ~ file: ImageWaterFlow.jsx:154 ~ useEffect ~ outContainerRef.current:', outContainerRef.current)

        const handleScroll = e => {
            const container = e.target
            if (container.scrollTop > 2 * screen.height) {
                setShowToTop(true)
            } else {
                setShowToTop(false)
            }
            const isBottom = container.scrollTop + container.clientHeight + 1 > container.scrollHeight
            // console.log(
            //     '🚀 ~ file: ImageWaterFlow.jsx:140 ~ handleScroll ~ container.scrollTop:',
            //     container.scrollTop,
            //     container.clientHeight,
            //     container.scrollHeight,
            //     isBottom,
            //     container,
            // )
            setIsAtBottom(isBottom)

            // setIsAtBottom(container.scrollHeight - container.scrollTop === container.clientHeight)
        }
        if (outContainerRef.current) {
            outContainerRef.current.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (outContainerRef.current) {
                outContainerRef.current.removeEventListener('scroll', handleScroll)
            }
        }
    }, [outContainerRef.current])

    const [open, setOpen] = useState(false)
    const [previewIndex, setPreviewIndex] = useState(0)
    const showPreview = (show, index) => {
        setOpen(show)
        setPreviewIndex(index)
    }
    const enlargeImage = imageObj => {
        if (spinning) {
            message.error(t('generatedImage.historyRecordEditImageError'))
            return
        }
        requestEnlarge(imageObj.taskId, imageObj.width, imageObj.height, imageObj.pathUrl)
    }

    const useImageInit = imageObj => {
        setInputUrl(imageObj.pathUrl, imageObj.pathUrl)
    }

    const [trigger, result, lastPromiseInfo] = useLazyGetSdRecordDetailQuery()

    async function requestDetail(imageObj) {
        if (result.isFetching) return
        const res = await trigger({ taskId: imageObj.taskId, authToken }, true)
        console.log('🚀 ~ file: SdImageCell.tsx:42 ~ requestDetail ~ res:', res)

        if (res.data) {
            dispatch(setSd_historyDetailParams({ ...res.data.tasks[0] }))
        }
    }

    const generateImageClick = imageObj => {
        console.log('🚀 ~ file: ImageWaterFlow.jsx:186 ~ generateImageClick ~ imageObj:', imageObj)
        // dispatch(setSd_historyDetailParams(imageObj))
        requestDetail(imageObj)
    }

    const variationClick = imageObj => {
        console.log('🚀 ~ file: ImageWaterFlow.jsx:186 ~ generateImageClick ~ imageObj:', imageObj)
        requestImageActions.variation?.(imageObj.taskId)
    }

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [open])

    const deleteImageM = async param => {
        const res = await historicalRecordDelete(param)
        if (res.code == 1) {
            let newImages = [...images]
            images.map((imageObj, index) => {
                if (param.taskImgId == imageObj.taskImageId) {
                    newImages.splice(index, 1)
                }
            })
            setImages([...newImages])
        } else {
            message.error(res.message)
        }
    }

    const backToTop = () => {
        outContainerRef.current.scrollTop = 0
    }
    const deleteModelCancelBtnClick = () => {
        setShowDeleteModel(false)
    }
    const deleteModelSureBtnClick = () => {
        if (selectDeleteImageObj) {
            let param = {
                taskId: selectDeleteImageObj.taskId,
                taskImgId: selectDeleteImageObj.taskImageId,
                authToken: authToken,
            }
            deleteImageM(param)
            setShowDeleteModel(false)
        }
    }
    return (
        <div ref={historyRecorderRef} className="min-h-[500px]">
            {showToTop && (
                <FloatButton
                    shape="square"
                    tooltip={<div>{t('common.backToTop')}</div>}
                    onClick={backToTop}
                    icon={
                        <div>
                            <img src={backToTop_icon} alt="" />
                        </div>
                    }
                ></FloatButton>
            )}
            {open && (
                <PhotoPreview
                    open={open}
                    setOpen={setOpen}
                    images={images}
                    imageObj={images[previewIndex]}
                    index={previewIndex}
                    imageEditorM={imageEditorM}
                    enlargeImageM={requestEnlarge}
                    type={pageType}
                    deleteImageM={deleteImageM}
                    setInputUrl={setInputUrl}
                ></PhotoPreview>
            )}

            <DeleteModel
                open={showDeleteModel}
                title={t('preview.deleteTitle')}
                subtitle={t('preview.deleteSubtitle')}
                cancelM={deleteModelCancelBtnClick}
                sureM={deleteModelSureBtnClick}
            ></DeleteModel>

            <StickyHeader>
                <h2 className="text-2xl mb-8 font-bold text-white/90">{t('generatedImage.historyImages')}</h2>
            </StickyHeader>
            {images.length <= 0 && (
                <div className=" w-full py-28">
                    <img className=" mx-auto" src={noHistoryRecord} alt="" />
                </div>
            )}

            <div id="container-selector" ref={containerRef}>
                {images.map((image, index) => {
                    return (
                        <div className="relative block w-0 overflow-hidden rounded-lg bg-gray-bg group cursor-pointer" key={index}>
                            <div
                                className=" absolute top-0 left-0 right-0 bottom-0 hidden group-hover:flex group-hover:bg-black/60 flex-col-reverse p-3"
                                // style={{ backgroundImage: `url(${imageBg_icon})`, backgroundSize: '100% 100%' }}
                                onClick={() => {
                                    showPreview(true, index)
                                }}
                            >
                                <div className="w-full flex justify-between  ">
                                    <div className="flex  space-x-3">
                                        <Popover content={<div className="flex items-center justify-center">{t('preview.btnDownloadTip')}</div>}>
                                            <button
                                                className="w-[32px] h-[32px] flex items-center justify-center"
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    window.open(image.pathUrl, '_block')
                                                }}
                                            >
                                                <img className="w-full" src={downloadImage} alt="" />
                                            </button>
                                        </Popover>
                                        {pageType == 'sd' && (
                                            <Popover content={<div className="flex items-center justify-center">{t('preview.btnUseImageTip')}</div>}>
                                                <button
                                                    className="w-[32px] h-[32px] flex items-center justify-center"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        useImageInit(image)
                                                    }}
                                                >
                                                    <img src={useInitImage} className="w-full" alt="" />
                                                </button>
                                            </Popover>
                                        )}

                                        {pageType == 'sd' && (
                                            <Popover
                                                content={<div className="flex items-center justify-center">{t('preview.btnGenerateImageTip')}</div>}
                                            >
                                                <button
                                                    className="w-[32px] h-[32px] flex items-center justify-center"
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        generateImageClick(image)
                                                    }}
                                                >
                                                    <img src={generateImageImage} className="w-full" alt="" />
                                                </button>
                                            </Popover>
                                        )}

                                        {pageType == 'sd' && (
                                            <Popover
                                                content={<div className="flex items-center justify-center">{t('preview.btnVariationsTip')}</div>}
                                            >
                                                <button
                                                    disabled={image.canV != 1 ? true : false}
                                                    className={`w-[32px] h-[32px] flex items-center justify-center ${
                                                        image.canV != 1 ? 'grayscale' : ''
                                                    }`}
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        variationClick(image)

                                                        const newImages = images.slice()
                                                        newImages[index].canV = 0
                                                        setImages(newImages)
                                                    }}
                                                >
                                                    <img src={variationImage} className="w-full" alt="" />
                                                </button>
                                            </Popover>
                                        )}

                                        {pageType == 'sd' && image.taskType != 4 && (
                                            <Popover content={<div className="flex items-center justify-center">{t('preview.btnEnlargeTip')}</div>}>
                                                <button
                                                    className={`w-[32px] h-[32px] flex items-center justify-center`}
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        enlargeImage(image)
                                                    }}
                                                >
                                                    <img src={largeImage} className="w-full" alt="" />
                                                </button>
                                            </Popover>
                                        )}
                                    </div>
                                    <Popover content={<div className="flex items-center justify-center">{t('preview.btnDeleteTip')}</div>}>
                                        <button
                                            className="w-[32px] h-[32px] flex items-center justify-center justify-self-end"
                                            onClick={e => {
                                                e.stopPropagation()
                                                setShowDeleteModel(true)
                                                setSelectDeleteImageObj(image)
                                            }}
                                        >
                                            <img src={deleteImage} className="w-full" alt="" />
                                        </button>
                                    </Popover>
                                </div>
                                <div
                                    className="w-full text-white overflow-hidden text-ellipsis text-base break-words my-3"
                                    style={{ WebkitLineClamp: 3, display: '-webkit-box', WebkitBoxOrient: 'vertical' }}
                                >
                                    {image.originPrompt}
                                </div>
                            </div>

                            <img src={image.pathUrl + '?x-oss-process=image/resize,w_300/quality,q_50'} className=" cursor-pointer" alt="" />
                        </div>
                    )
                })}
            </div>

            {hasMore && (
                <div className="w-full flex items-center justify-center">
                    <img src={loading_pnd} alt="" />
                </div>
            )}
        </div>
    )
})

export default ImageWaterFlow
