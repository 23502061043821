import React, { useState, useEffect } from 'react'

// import '../../styles/markdownStyle.css'

import Cookies from 'js-cookie'
import { HashRouter as Router, Route, useHistory, Switch, Redirect, useLocation, Link } from 'react-router-dom'
import { routes, subRoutes } from '@/router'

import { vistorRegister } from '../../api/apiAccount'
import { getVisitorId } from '../../lib/fingerprint'
import LoginPop from './LoginPop'
import { fetchCountryArea, setAuthToken, setShowLogin, setVistorAuthToken, setUniqueCode } from '@/slices/AppSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { checkAuthTokenValid } from '@/api/apiAccount'

import pc_tip4 from '../../assets/PPC/pc-tip4.png'
import pc_tip4_title from '../../assets/PPC/pc-tip4-title.png'
import pc_tip5 from '../../assets/PPC/pc-tip5.png'
import pc_tip5_title from '../../assets/PPC/pc-tip5-title.png'

import phone_tip4 from '../../assets/phone/phone-tip4.png'
import phone_tip5 from '../../assets/phone/phone-tip5.png'

import footer_lang_cn from '../../assets/PPC/pc-footer-lang-cn.png'
import footer_lang_en from '../../assets/PPC/pc-footer-lang-en.png'
import footer_email from '../../assets/PPC/pc-footer-email.png'

import { AppDispatch } from '@/store'
import Gov from '@/components/public/Gov'
import HomeDcTwit from '@/components/public/HomeDcTwit'
import GlobalStorage from '@/lib/globalStorage'
import { pageEvent, timeEvent, trackEvent, googleTrackEvent } from '@/lib/analyse'
import _ from 'lodash'

// import WatersArea from './WatersArea'
import header_icon from '@/assets/home/logo.png'
import WatersArea from './WatersArea'
import HomeModelList from './HomeModelList'
import titleWaters from '../../assets/home/titleWaters.png'
import titleModels from '../../assets/home/titleModels.png'

// import required modules
import HomeWatersHistoryList from './HomeWatersHistoryList'
export default function index() {
    const history = useHistory()
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const showLoginPop = useAppSelector(state => {
        return state.app && state.app.showLogin
    })
    const [isLogin, setIsLogin] = useState(false)
    const [isPhone, setIsPhone] = useState(false)
    function useQuery() {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search])
    }

    let query = useQuery()
    useEffect(() => {
        dispatch(fetchCountryArea({}))
        whatScreen()
        pageEvent({ page: 'home' })
        const vistorAuthToken = GlobalStorage.get('vistor_auth_token')
        if (!vistorAuthToken) {
            async function getID() {
                const uniqueCode = await getVisitorId()
                const result = await vistorRegister({ uniqueCode })
                if (result.code == 1) {
                    dispatch(setUniqueCode(uniqueCode))
                    dispatch(setVistorAuthToken(result.data.token))
                }
            }
            getID()
        }
        if (GlobalStorage.get('auth_token')) {
            async function check() {
                const result = await checkAuthTokenValid({ authToken: GlobalStorage.get('auth_token') })
                if (result.code == 1) {
                    if (result.data.isValid) {
                        dispatch(setAuthToken(GlobalStorage.get('auth_token')))
                        setIsLogin(true)
                    } else {
                        GlobalStorage.remove('auth_token')
                        const uniqueCode = await getVisitorId()
                        const result = await vistorRegister({ uniqueCode })
                        if (result.code == 1) {
                            dispatch(setUniqueCode(uniqueCode))
                            dispatch(setVistorAuthToken(result.data.token))
                        }
                        setIsLogin(false)
                    }
                }
            }
            check()
        }
        timeEvent('home page time')
        if (query.get('invitationCode')) {
            googleTrackEvent('invitationCode', query.get('invitationCode') || '')
        }
        return () => {
            trackEvent('home page time')
        }
    }, [])

    const nowBtnClick = () => {
        if (isLogin) {
            dispatch(setShowLogin(false))
            history.push('/tools/Waters')
        } else {
            dispatch(setShowLogin(true))
        }
    }

    useEffect(() => {
        if (showLoginPop) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = ''
        }
    }, [showLoginPop])

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.substring(0, 2))

    const changeLanguage = () => {
        if (currentLanguage == 'zh') {
            i18n.changeLanguage('en')
            setCurrentLanguage('en')
        } else if (i18n.language == 'en') {
            i18n.changeLanguage('zh')
            setCurrentLanguage('zh')
        }
    }
    function whatScreen() {
        var width: any = document.querySelector('html')?.offsetWidth
        if (width <= 768) {
            // title.innerHTML = '极小屏幕-' + width
            setIsPhone(true)
        }
    }

    const content = () => {
        return (
            <>
                <div className="section w-full bg-contain bg-no-repeat">
                    <WatersArea></WatersArea>
                </div>
                <div className="section">
                    <h1 className="max-w-[1383px] flex items-center mx-auto mb-8">
                        <img className="w-[150px] mr-11" src={titleWaters} alt="" />

                        <span className="text-sm font-thin">{t('home.watersText')}</span>
                    </h1>
                    <div className="max-w-[1383px] mx-auto">
                        <HomeWatersHistoryList />
                    </div>
                </div>
                <div className="section">
                    <h1 className="max-w-[1383px] flex items-center mx-auto mb-8">
                        <img className="w-[150px] mr-11" src={titleModels} alt="" />

                        <span className="text-sm font-thin">{t('home.tip5')}</span>
                    </h1>
                    <div className="max-w-[1383px] mx-auto">
                        <HomeModelList />
                    </div>
                </div>
                <div className="section">
                    {/* <section className="max-w-[1383px] mx-auto flex justify-between items-center">
                        <img className="" src={isPhone ? phone_tip5 : pc_tip5} alt="" />
                        <div className="flex-1">
                            {!isPhone && <img className="block mx-auto" src={pc_tip5_title} alt="" />}
                            <p className="w-2/3 mt-8 mx-auto text-lg ">{t('home.tip5')}</p>
                        </div>
                    </section> */}
                    <section className="max-w-[1383px] mx-auto mt-36 mb-28 flex justify-between items-center flex-wrap">
                        {/* <img className="hidden lg:block" src={pc_tip2} alt="" /> */}
                        <img className="hidden lg:block" src={pc_tip5} alt="" />
                        <img className="block lg:hidden" src={phone_tip5} alt="" />

                        <div className="flex-1">
                            <img className="hidden lg:block mx-auto" src={pc_tip5_title} alt="" />
                            {/* {!isPhone && <img className="block mx-auto" src={pc_tip2_title} alt="" />} */}
                            <p className="float-right lg:float-none lg:w-2/3 w-11/12 mt-8 lg:mx-auto text-lg">{t('home.tip2')}</p>
                        </div>
                    </section>
                    <section className="max-w-[1383px] mx-auto mb-28 flex justify-between items-center flex-row-reverse flex-wrap">
                        {/* <img className="" src={isPhone ? (getOverseaUrl() ? phone_tip4 : phone_tip4) : pc_tip4} alt="" /> */}
                        <img className="hidden lg:block" src={pc_tip4} alt="" />
                        <img className="block lg:hidden" src={phone_tip4} alt="" />
                        <div className="flex-1">
                            <img className="hidden lg:block mx-auto" src={pc_tip4_title} alt="" />
                            {/* {!isPhone && <img className="block mx-auto" src={pc_tip4_title} alt="" />}
                            <p className="w-2/3 mt-8 mx-auto text-lg">{t('home.tip4')}</p> */}
                            <p className="lg:w-2/3 w-11/12 mt-8 lg:mx-auto text-lg">{t('home.tip4')}</p>
                        </div>
                    </section>
                </div>
            </>
        )
    }
    return (
        <div className="text-white">
            {showLoginPop && <LoginPop></LoginPop>}

            <header className="fixed w-full py-2 bg-black z-50 text-lg">
                <div className="max-w-[1383px] mx-auto">
                    <div className="flex justify-between items-center px-2">
                        <img className="w-[225px]" src={header_icon} alt="" />
                        <div className="flex">
                            <ul className="lg:flex hidden px-3">
                                <li className="flex items-center px-8 cursor-pointer" onClick={nowBtnClick}>
                                    GPU Rental
                                </li>
                                <li className="flex items-center px-8 cursor-pointer" onClick={nowBtnClick}>
                                    Waters
                                </li>
                                <li className="flex items-center px-8 cursor-pointer" onClick={nowBtnClick}>
                                    Models
                                </li>
                                <li className="flex items-center px-8 cursor-pointer" onClick={nowBtnClick}>
                                    Stable Diffusion
                                </li>
                                <li className="flex items-center px-8 cursor-pointer" onClick={nowBtnClick}>
                                    Training
                                </li>
                            </ul>
                            <button className="border-gradual lg:mx-6 px-7 py-2 text-sm rounded-full" onClick={nowBtnClick}>
                                {t('login.signIn')}
                            </button>
                        </div>
                    </div>

                    <ul className="flex lg:hidden justify-between w-full text-base px-2 pt-3">
                        <li className="flex items-center cursor-pointer" onClick={nowBtnClick}>
                            GPU Rental
                        </li>
                        <li className="flex items-center cursor-pointer" onClick={nowBtnClick}>
                            Waters
                        </li>
                        <li className="flex items-center cursor-pointer" onClick={nowBtnClick}>
                            Models
                        </li>
                        <li className="flex items-center cursor-pointer" onClick={nowBtnClick}>
                            Stable Diffusion
                        </li>
                        <li className="flex items-center cursor-pointer" onClick={nowBtnClick}>
                            Training
                        </li>
                    </ul>
                </div>
            </header>
            <main className="max-w-full">
                {content()}
                <footer className="bg-[#0e0e0e] text-[#764EEF] pt-[30px] pb-[70px]">
                    <div className="container mx-auto flex flex-row flex-wrap items-center justify-between lg:flex-nowrap lg:items-center lg:justify-center">
                        <div className="flex row py-5 lg:py-0 px-10 cursor-pointer" onClick={changeLanguage}>
                            <img src={currentLanguage == 'en' ? footer_lang_en : footer_lang_cn} alt="" />
                            <span className="pl-3 text-lg font-bold ">{t('home.footer.btn1')}</span>
                        </div>
                        <HomeDcTwit></HomeDcTwit>
                        <div className="flex row py-5 lg:py-0 px-10 cursor-pointer">
                            <img className="w-[33px]" src={footer_email} alt="" />
                            <a href="mailto:contact@mst.xyz">
                                <span className="pl-3 text-lg font-bold">{t('home.footer.btn4')}</span>
                            </a>
                        </div>
                        {/* <div className="group relative flex flex-col items-center">
                            <div className="flex row py-5 lg:py-0 px-10 cursor-pointer">
                                <img src={footer_wechat} alt="" />
                                <span className="pl-3 text-lg font-bold">{t('home.footer.btn4')}</span>
                            </div>
                            <div className="absolute bottom-0 left-0 mb-8 hidden flex-col items-center group-hover:flex z-[100]">
                                <img className="w-[180px] max-w-fit" src={wechatGroup} alt="" />
                            </div>
                        </div> */}
                    </div>
                    <div className="w-full px-10  mx-auto flex justify-center text-center mt-10 space-x-8">
                        <a href="/serviceTerms" target="_blank" className="text-white underline">
                            TERMS OF SERVICE
                        </a>
                        <a href="/privacyPolicy" target="_blank" className="text-white underline">
                            PRIVACY POLICY
                        </a>
                    </div>
                    <Gov></Gov>
                </footer>
            </main>
        </div>
    )
}
