import { getOverseaUrl } from '@/helpers'
import logo from '../../assets/icon.png'
function Index(params) {
    return (
        <main>
            <div className="w-full relative overflow-auto mx-auto bg-black text-white">
                <nav className=" fixed w-full left-0 top-0 z-50 flex items-center justify-center border-b border-b-[#2a2929] bg-black px-5">
                    <div className="w-full max-w-7xl flex ">
                        <a href="/" className="flex items-center flex-row py-[20px]">
                            <img src={logo} className="w-[40px] " alt="" />
                            <div className="px-3 text-2xl font-extrabold">MINISTERAI</div>
                        </a>
                    </div>
                </nav>
                <div className=" mt-[40px] flex flex-col items-center p-5">
                    <h1 className=" mx-auto mt-[50px]">Privacy Policy</h1>
                    <div className="mx-auto py-10">
                        <h2 className="py-5">Who we are</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            Our website address is:
                            {getOverseaUrl() ? <a href="https://mst.ai/">https://mst.ai/</a> : <a href="https://mst.xyz/">https://mst.xyz/</a>}
                        </p>
                        <h2 className="py-5">Disclaimer for MINISTERAI</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            All the information on this website – MINISTERAI – is published in good faith and for general information purpose only.
                            MINISTERAI does not make any warranties about the completeness, reliability and accuracy of this information. Any action
                            you take upon the information you find on this website{' '}
                            {getOverseaUrl() ? <a href="https://mst.ai/">MINISTERAI</a> : <a href="https://mst.xyz/">MINISTERAI</a>}, is strictly at
                            your own risk. MINISTERAI will not be liable for any losses and/or damages in connection with the use of our website.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide
                            only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These
                            links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content
                            may change without notice and may occur before we have the opportunity to remove a link which may have gone ‘bad’.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are
                            beyond our control. Please be sure to check the Privacy Policies of these sites as well as their “Terms of Service” before
                            engaging in any business or uploading any information.
                        </p>
                        <h2 className="py-5">Consent</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            By using our website, you hereby consent to our disclaimer and agree to its terms.
                        </p>
                        <h2 className="py-5">Update</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            Should we update, amend or make any changes to this document, those changes will be prominently posted here.
                        </p>
                        <h2 className="py-5">Cookies</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie
                            contains no personal data and is discarded when you close your browser.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            When you log in, we will also set up several cookies to save your login information and your screen display choices. Login
                            cookies last for 7 hours.
                        </p>
                        <h2 className="py-5">How long we retain your data</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            For users that register on our website (if any), we also store the personal information they provide in their user
                            profile. All users can see, edit, or delete their personal information at any time (except they cannot change their
                            username). Website administrators can also see and edit that information.
                        </p>
                        <h2 className="py-5">What rights you have over your data</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            If you have an account on this site, you can request to receive an exported file of the personal data we hold about you,
                            including any data you have provided to us. You can also request that we erase any personal data we hold about you. This
                            does not include any data we are obliged to keep for administrative, legal, or security purposes.
                        </p>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Index
