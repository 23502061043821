import axios from 'axios'
import Cookies from 'js-cookie'
import { message } from 'antd'
import GlobalStorage from '@/lib/globalStorage'
import { getI18n } from 'react-i18next'
export const API_URL = import.meta.env.VITE_API_URL
var service = axios.create({
    baseURL: '/', //所有的请求都会 带上 /api
    // timeout: 10000,
})

// //请求拦截器
service.interceptors.request.use(config => {
    const i18n = getI18n()
    const acceptLanguage = i18n.language == 'en' ? 'en-US,en;q=0.9' : 'zh-CN,zh;q=0.9'
    return {
        ...config,
        headers: {
            'Accept-Language': acceptLanguage,
            'X-AppStoreChannel': 1,
            'X-AppVersion': 1,
            'X-SystemChannel': 2,
        },
        // data: { ...config.data,},
        startTime: new Date().getTime(),
    }
})
// //响应拦截器
service.interceptors.response.use(
    response => {
        if (response && response.data) {
            switch (response.data.code) {
                case 0:
                    break
                case 5000:
                    break
                case 10001:
                    break
                case 10002:
                    break
                case 10001:
                    break
                default:
                    break
            }

            console.log(`${response.config.url}请求成功，耗时${new Date().getTime() - response.config.startTime}ms`)
            window.sessionStorage.setItem('header-serve', response.headers.server)

            if (response.data.code == 10002 || response.data.code == 10016) {
                message.config({
                    maxCount: 1,
                })

                message.error({ duration: 2, content: response.data.message }).then(() => {
                    GlobalStorage.remove('auth_token')
                    GlobalStorage.remove('login_type')
                    window.location.reload()
                })
            } else if (response.data.code == 5000 || response.data.code == 10002) {
                // message.error({ duration: 2, content: response.data.message }).then(() => {
                //     GlobalStorage.remove('auth_token')
                //     GlobalStorage.remove('login_type')

                //     window.location.reload()
                //     return Promise.resolve(response.data)
                // })
                return Promise.resolve(response.data)
            } else {
                return Promise.resolve(response.data)
            }
        } else {
            return Promise.reject('response 不存在')
        }
    },

    error => {
        console.log('-- error --')
        console.log(error)
        console.log('-- error --')
        return Promise.reject({
            success: false,
            msg: error,
        })
    },
)

export default service
