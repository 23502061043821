import { useEffect, useState } from 'react'

import { useAppSelector } from '@/hooks'
import { setIsShowWelcomePage } from '@/slices/AppSlice'
import WelcomePage from '../Chat/WelcomePage'
import { pageEvent, timeEvent, trackEvent } from '@/lib/analyse'
import { Head } from '@/components/Head'
import WaterWorkbench from './WatersWorkbench'
import WatersRecord from './WatersRecord'
import { useDispatch } from 'react-redux'
import useNewFunction from './NewFunctionHook'

export default function Index(params) {
    const showRecord = useAppSelector(state => state.newFunctionSlice.showRecord)
    const dispatch = useDispatch()
    useEffect(() => {
        pageEvent({ page: 'waters' })
        timeEvent('waters page time')
        return () => {
            trackEvent('waters page time')
        }
    }, [])

    function isMobile() {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
        )
        return flag
    }

    const isShowWelcomePage = useAppSelector(state => state.app?.isShowWelcomePage)
    const [isPhone, setIsPhone] = useState(false)
    useEffect(() => {
        setIsPhone(isMobile())
    }, [])
    useEffect(() => {
        if (isPhone) {
            if (window.sessionStorage.getItem('isWelcome') == '1') {
                dispatch(setIsShowWelcomePage(false))
            } else {
                dispatch(setIsShowWelcomePage(true))
            }
        }
    }, [isPhone])

    return (
        <div className="grow">
            <Head title={'Waters - MinisterAI'} />

            {isPhone && isShowWelcomePage ? (
                <WelcomePage setIsShowWelcomePage={setIsShowWelcomePage} />
            ) : (
                <>{showRecord ? <WatersRecord /> : <WaterWorkbench />}</>
            )}
        </div>
    )
}
