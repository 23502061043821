import { EntityId, EntityState, Update, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { TabHeaderItem } from '@/pages/AIImage/TabHeader'
import { fetchMj_historicalStateRecord } from './GeneratedImageThunk'
import { DropdownListData } from '@/pages/sdModelList/DropdownList'
import { ISdModelListItem } from './sdModelListSlice'
import { ChooseModelType } from '@/pages/AIImage'
import { STCreateTaskParams } from '@/pages/AIImage/StHooks'

export interface IRecoverTask {
    createTime: string
    drawMethod: number
    mjDrawTaskMode: GeneratedMode
    pathUrl: string
    prompt: string
    taskErrorMessage: string
    taskId: string
    taskImageId: number
    taskStatus: number
    taskType: number
    rootTaskId: string
    rootPicUrl: string

    mjDrawSelectIndex: number // U V 的索引 123
    picUrl: string // upscale 基于的图
}

export interface ISizeItem {
    name: string
    value: string
    width: number
    height: number
}

export interface IModelItem extends ISdModelListItem {
    fileFormat: string
    fileSize: number //文件bytes大小
    modelDrawIcon: string
    isRecommend?: number

    isPublic?: number

    //自己用
}

// export type ISelectedModelItem = Pick<IModelItem, 'modelId' | 'modelName' | 'modelIcon' | 'modelDrawIcon'> &
//     Partial<Omit<IModelItem, 'modelId' | 'modelName' | 'modelIcon' | 'modelDrawIcon'>>
export interface ISelectedModelItem {
    modelIcon: string //封面icon
    modelId: string //模型ID
    modelName: string //模型名
    modelDrawIcon?: string
    isXl: number

    //自己用
    lastTaskId?: string //最近使用的taskId
    subUsedModels?: ISelectedModelItem[]
    weight?: string //二级模型有权重
}
export interface ITaskRecord {
    imageList: string[]
    prompt: string
    taskId: string
}

export interface ISdModalState {
    categorys: DropdownListData[]
    types: DropdownListData[]
    baseModels: DropdownListData[]
    samplers: DropdownListData[]
    hiresUpscaler: DropdownListData[]
    sorts: DropdownListData[]
    curCategory?: DropdownListData
    curType?: DropdownListData
    curSort?: DropdownListData
    curSearch?: string

    publicModelList: EntityState<IModelItem>
    privateModelList: EntityState<IModelItem>

    chooseType?: ChooseModelType
}

export interface ISdModelConfig {
    //config
    cfgScale: number
    clipSkip: number
    denoisingStrength: number
    ensd: number
    samplingId: number
    samplingSteps: number
    seed: number
}
export interface ISdControlNetConfig {
    singleImgUrl: string
    pixelPerfect: boolean
    preprocessorId: number
    weight: number //0~2 默认1
    startingControlStep: number //0-1 默认0
    endingControlStep: number //0~1 默认1
    controlMode: number // 默认0
    resizeMode: number // 0-Just Resize 1-Crop and Resize 2-Resize and Fill 默认1
    maskImgUrl?: string
    cannyLowThreshold?: number //1-255 默认100
    cannyHighThreshold?: number //1-255 默认100
    mlsdValueThreshold?: number //0.01-20 默认1
    mlsdDistanceThreshold?: number //0.01-20 默认1
    downSamplingRate?: number //1-8 默认1
    styleFidelity?: number //0-1 默认0.5
}
export interface ISdHiresConfig {
    //config
    upscaler: number
    hiresSteps: number
    denoisingStrength: number
    upscaleBy: number
}

export interface ISdTextualItem {
    //config
    textualDesc: string
    textualModelId: string
    textualName: string
    modelSD: string
}

export interface IGenerateStateDiffusionState {
    requestImageActions: {
        createTask?: (params: STCreateTaskParams) => void
        upscale?: (taskId: string, width: number, height: number, toEnlargeUrl: string) => void
        variation?: (taskId: string) => void
    }

    imageItemDetailShow?: { show: boolean; taskId?: string }
    imageItems: IStImageListItem[]

    showSegmentedPrompt: boolean
    segmentedPrompts: {
        segmentSubject?: string
        segmentDescribeSubject?: string
        segmentScene?: string
        segmentStyle?: string
        segmentQuality?: string
        segmentArtist?: string
    }
    prompt: string
    negativePrompt: string
    promptGenerating: boolean

    showNegativePrompt: boolean
    negativePromptGenerating: boolean

    numberImage: number

    minSize: number
    maxSize: number
    xlMinSize: number
    xlMaxSize: number
    sizeItems: ISizeItem[]
    xlSizeItems: ISizeItem[]
    selectedSizeItem: ISizeItem | null
    inputSize: { width: number; height: number }

    uploadResponse: { url: string; base64StringOrUrl: string }
    uploadProgress: number
    uploadControlNetProgress: number
    taskRecords: ITaskRecord[]

    modalTab?: TabHeaderItem
    modalState: ISdModalState

    selectedModelItem?: ISelectedModelItem
    vaeModel?: ISelectedModelItem
    modelConfig: ISdModelConfig
    modelConfigCache: { [modelId: string]: ISdModelConfig }
    hiresConfig: ISdHiresConfig
    hiresCollapsed: boolean

    vaeModelList: IModelItem[]
    historyDetailParams?: any

    textualList?: ISdTextualItem[]
    isConcontrolNet: boolean
    controlNetSingleImgUrl: { url: string; base64StringOrUrl: string }
    controlNetMaskImgUrl: { url: string; base64StringOrUrl: string }
    controlNets: IControlNetItem
}

export interface IGenerateMidjourneyBoolParams {
    showName: string
    name: string
    value: boolean
    desc: string
    exclusive: string[]
}

export interface IGenerateMidjourneyModel {
    showName: string
    name: string
    desc: string
    pic: string
}

export interface IGenerateImageProgress {
    date: string
    progress: number
    step: string
}

export type GeneratedMode = 'preview' | 'variation' | 'upscale' | 'seed'

export interface IStImageListItem {
    taskId: string
    generating: boolean
    generatingPercent: number
    generatedImageList: string[]
    generatedImageProgress: IGenerateImageProgress | null
    generatedImageHeight: number
    generatedImageWidth: number
    taskType: number //4u 5v

    progressTimer?: NodeJS.Timer
}

export interface IMjImageListItem {
    taskId: string
    image: string

    // u1 u2 精修图状态
    detailState: {
        mode: GeneratedMode
        generating: boolean
        // generatingPercent: number
        generatedImageList: string[]
        generatedImageProgress: IGenerateImageProgress | null
    }
    detailImages: IMjImageListItem[]
    detailButtons: { [prop: string]: boolean }
}

export interface IGenerateMjState {
    generating: boolean
    // generatingPercent: number
    generatedImageList: IMjImageListItem[]
    generatedImageProgress: IGenerateImageProgress | null

    prompt: string
    negativePrompt: string
    promptGenerating: boolean

    // numberImage: number

    sizeItems: ISizeItem[]
    selectedSizeItem: ISizeItem
    inputSize: { width: number; height: number }

    modelItems: IGenerateMidjourneyModel[]
    selectedModelItem: IGenerateMidjourneyModel

    uploadResponse: { url: string; base64String: string }
    uploadProgress: number
    stylize: { showName: 'Stylize'; name: '--stylize'; value: number; desc: string }
    boolParams: IGenerateMidjourneyBoolParams[]
    seedValue: string | null
    seedLoading: boolean

    recoverTask?: IRecoverTask
}
export interface IControlNetItem {
    singleImgUrl: string
    pixelPerfect: boolean
    preprocessorId: number
    weight: number //0~2 默认1
    startingControlStep: number //0-1 默认0
    endingControlStep: number //0~1 默认1
    controlMode: number // 默认0
    resizeMode: number // 0-Just Resize 1-Crop and Resize 2-Resize and Fill 默认1

    maskImgUrl?: string
    cannyLowThreshold?: number //1-255 默认100
    cannyHighThreshold?: number //1-255 默认100
    mlsdValueThreshold?: number //0.01-20 默认1
    mlsdDistanceThreshold?: number //0.01-20 默认1
    downSamplingRate?: number //1-8 默认1
    styleFidelity?: number //0-1 默认0.5
}
export interface IGenerateImageSliceState {
    stableDiffusion: IGenerateStateDiffusionState
    midjourney: IGenerateMjState
}

export const publicModelsAdapter = createEntityAdapter<IModelItem>({
    selectId: item => item.modelId,
})
export const privateModelsAdapter = createEntityAdapter<IModelItem>({
    selectId: item => item.modelId,
})

const midjourneyModels: IGenerateMidjourneyModel[] = JSON.parse(
    JSON.stringify([
        { showName: 'v4', name: '--v 4', desc: 'generatedImage.midjourneyV4Desc', pic: 'https://img.mst.xyz/img/v5a_1.png' },
        { showName: 'v5', name: '--v 5', desc: 'generatedImage.midjourneyV5Desc', pic: 'https://img.mst.xyz/img/v5_1.png' },
        // { showName: 'v5a', name: '--v 5a', desc: 'generatedImage.midjourneyV5aDesc', pic: 'https://werewolf-resource.53site.com/s/temp/v5a_1.png' },
    ]),
)
const initialState: IGenerateImageSliceState = {
    stableDiffusion: {
        requestImageActions: {},
        imageItems: [],

        showSegmentedPrompt: false,
        segmentedPrompts: {},
        prompt: '',
        showNegativePrompt: false,
        negativePrompt: '',
        promptGenerating: false,
        negativePromptGenerating: false,

        numberImage: 1,

        minSize: 512,
        xlMinSize: 768,

        // maxSize: 4096,
        maxSize: 1024,
        xlMaxSize: 2048,

        sizeItems: [
            { name: '1:1', width: 512, height: 512, value: '1/1' },
            { name: '4:3', width: 800, height: 600, value: '4/3' },
            { name: '3:4', width: 600, height: 800, value: '3/4' },
            { name: '16:9', width: 960, height: 540, value: '16/9' },
            { name: '9:16', width: 540, height: 960, value: '9/16' },
        ],
        xlSizeItems: [
            { name: '1:1', width: 768, height: 768, value: '1/1' },
            { name: '4:3', width: 1024, height: 768, value: '4/3' },
            { name: '3:4', width: 768, height: 1024, value: '3/4' },
            { name: '16:9', width: 1440, height: 810, value: '16/9' },
            { name: '9:16', width: 810, height: 1440, value: '9/16' },
        ],
        selectedSizeItem: { name: '1:1', width: 512, height: 512, value: '1/1' },
        inputSize: { width: 512, height: 512 },

        uploadResponse: { url: '', base64StringOrUrl: '' },
        uploadProgress: 0,
        uploadControlNetProgress: 0,

        taskRecords: [],

        vaeModelList: [],
        modalState: {
            categorys: [],
            types: [],
            baseModels: [],
            samplers: [],
            hiresUpscaler: [],
            sorts: [
                { name: 'modelShop.newest', data: 1 },
                { name: 'modelShop.mostGeneration', data: 2 },
                { name: 'modelShop.mostDownload', data: 3 },
            ],
            curCategory: undefined,
            curType: undefined,
            curSort: { name: 'modelShop.mostGeneration', data: 2 },
            curSearch: undefined,

            publicModelList: publicModelsAdapter.getInitialState(),
            privateModelList: privateModelsAdapter.getInitialState(),
        },
        modelConfig: {
            cfgScale: 7,
            clipSkip: 1,
            denoisingStrength: 0.75,
            ensd: 0,
            samplingId: 1,
            samplingSteps: 20,
            seed: -1,
        },
        modelConfigCache: {},
        hiresConfig: {
            upscaler: 0,
            hiresSteps: 0,
            denoisingStrength: 0.55,
            upscaleBy: 1.5,
        },
        hiresCollapsed: true,
        textualList: [],
        controlNetSingleImgUrl: { url: '', base64StringOrUrl: '' },
        controlNetMaskImgUrl: { url: '', base64StringOrUrl: '' },
        isConcontrolNet: false,
        controlNets: {
            singleImgUrl: '',
            pixelPerfect: true,
            preprocessorId: 3,
            weight: 1, //0~2 默认1
            startingControlStep: 0, //0-1 默认0
            endingControlStep: 1, //0~1 默认1
            controlMode: 0, // 默认0
            resizeMode: 1, // 0-Just Resize 1-Crop and Resize 2-Resize and Fill 默认1

            maskImgUrl: '',
            cannyLowThreshold: 100, //1-255 默认100
            cannyHighThreshold: 200, //1-255 默认100
            mlsdValueThreshold: 0.1, //0.01-20 默认1
            mlsdDistanceThreshold: 0.1, //0.01-20 默认1
            downSamplingRate: 1, //1-8 默认1
            styleFidelity: 0.5, //0-1 默认0.5
        },
    },
    midjourney: {
        generating: false,
        // generatingPercent: 0,
        generatedImageList: [],
        generatedImageProgress: null,

        prompt: '',
        negativePrompt: '',
        promptGenerating: false,

        // numberImage: 4,

        sizeItems: [
            { name: '1:1', width: 512, height: 512, value: '1/1' },
            { name: '4:3', width: 800, height: 600, value: '4/3' },
            { name: '3:4', width: 600, height: 800, value: '3/4' },
            { name: '16:9', width: 800, height: 450, value: '16/9' },
            { name: '9:16', width: 450, height: 800, value: '9/16' },
        ],
        selectedSizeItem: { name: '1:1', width: 512, height: 512, value: '1/1' },
        inputSize: { width: 2048, height: 2048 },

        modelItems: midjourneyModels,
        selectedModelItem: midjourneyModels[0],

        uploadResponse: { url: '', base64String: '' },
        uploadProgress: 0,

        stylize: { showName: 'Stylize', name: '--stylize', value: 100, desc: 'generatedImage.midjourneyStylize' },
        boolParams: [
            {
                showName: 'Uplight',
                name: '--uplight',
                value: false,
                desc: 'generatedImage.midjourneyUplight',
                exclusive: ['--upbeta'],
            },
            {
                showName: 'Upbeta',
                name: '--upbeta',
                value: false,
                desc: 'generatedImage.midjourneyUpbeta',
                exclusive: ['--uplight'],
            },
            {
                showName: 'Seed',
                name: '--seed',
                value: true,
                desc: 'generatedImage.mjSeedDesc',
                exclusive: [],
            },
        ],
        seedValue: null,
        seedLoading: false,
    },
}

const generateImageSlice = createSlice({
    name: 'generateImageSlice',
    initialState: initialState,
    reducers: {
        resetImageState(state, action) {
            return initialState
        },
        //st
        updateSd_segmentedPrompt(state, action: { type: string; payload: IGenerateStateDiffusionState['segmentedPrompts'] }) {
            for (const key of Object.keys(action.payload)) {
                state.stableDiffusion.segmentedPrompts[key] = action.payload[key]
            }
        },
        setSd_imageItemDetailShow(state, action: { type: string; payload: IGenerateStateDiffusionState['imageItemDetailShow'] }) {
            state.stableDiffusion.imageItemDetailShow = action.payload
        },
        upinsertSt_imageItem(
            state,
            action: {
                payload: { taskId?: string; imageItem: Partial<IStImageListItem> }
                type: string
            },
        ) {
            const rootTaskId = action.payload.taskId
            let rootItem: IStImageListItem | undefined = undefined
            let i = 0

            for (const iterator of state.stableDiffusion.imageItems) {
                if (rootTaskId == iterator.taskId) {
                    rootItem = iterator
                    break
                }
                i++
            }

            if (rootItem) {
                state.stableDiffusion.imageItems[i] = { ...rootItem, ...action.payload.imageItem }
            } else {
                state.stableDiffusion.imageItems.unshift({ ...action.payload.imageItem, taskId: rootTaskId } as any)
            }
        },
        deleteSt_imageItem(
            state,
            action: {
                payload: { taskId: string }
                type: string
            },
        ) {
            const rootTaskId = action.payload.taskId
            let rootItem: IStImageListItem | undefined = undefined

            let i = 0
            for (const iterator of state.stableDiffusion.imageItems) {
                if (rootTaskId == iterator.taskId) {
                    rootItem = iterator

                    state.stableDiffusion.imageItems.splice(i, 1)
                    break
                }
                i++
            }
        },
        setStableDiffusionState(state, action) {
            for (const key of Object.keys(action.payload)) {
                state.stableDiffusion[key] = action.payload[key]
            }
            // state.stableDiffusion = { ...state.stableDiffusion, ...action.payload }
        },
        setPrompt(state, action) {
            state.stableDiffusion.prompt = action.payload
        },
        updateSd_modalState(state, action: { type: string; payload: Partial<Omit<ISdModalState, 'publicModelList' | 'privateModelList'>> }) {
            for (const key of Object.keys(action.payload)) {
                state.stableDiffusion.modalState[key] = action.payload[key]
            }
        },

        setSd_publicModels(state, action) {
            publicModelsAdapter.setAll(state.stableDiffusion.modalState.publicModelList, action.payload)
        },
        addSd_publicModels(state, action) {
            publicModelsAdapter.addMany(state.stableDiffusion.modalState.publicModelList, action.payload)
        },
        updateSd_publicModelOne(state, action: { type: string; payload: Update<IModelItem> }) {
            publicModelsAdapter.updateOne(state.stableDiffusion.modalState.publicModelList, action.payload)
        },
        setSd_privateModels(state, action) {
            privateModelsAdapter.setAll(state.stableDiffusion.modalState.privateModelList, action.payload)
        },
        addSd_privateModels(state, action) {
            privateModelsAdapter.addMany(state.stableDiffusion.modalState.privateModelList, action.payload)
        },
        updateSd_privateModelOne(state, action: { type: string; payload: Update<IModelItem> }) {
            privateModelsAdapter.updateOne(state.stableDiffusion.modalState.privateModelList, action.payload)
        },
        removeSd_privateModelOne(state, action: { type: string; payload: EntityId }) {
            privateModelsAdapter.removeOne(state.stableDiffusion.modalState.privateModelList, action.payload)
        },

        removeSd_selectedSubModel(state, action: { type: string; payload: number }) {
            state.stableDiffusion.selectedModelItem?.subUsedModels?.splice(action.payload, 1)
        },
        replaceSd_selectedSubModel(state, action: { type: string; payload: { index: number; model: ISelectedModelItem } }) {
            if (state.stableDiffusion.selectedModelItem?.subUsedModels) {
                const loraIndex = state.stableDiffusion.selectedModelItem.subUsedModels.findIndex((v, i) => v.modelId == action.payload.model.modelId)
                if (loraIndex >= 0) {
                    return
                }
                state.stableDiffusion.selectedModelItem.subUsedModels[action.payload.index] = action.payload.model
            }
        },
        upsertSd_selectedSubModel(state, action: { type: string; payload: ISelectedModelItem }) {
            if (state.stableDiffusion.selectedModelItem && !state.stableDiffusion.selectedModelItem.subUsedModels) {
                state.stableDiffusion.selectedModelItem.subUsedModels = []
            }

            const subModels = state.stableDiffusion.selectedModelItem?.subUsedModels
            if (subModels) {
                const loraIndex = subModels.findIndex((v, i) => v.modelId == action.payload.modelId)
                if (loraIndex >= 0) {
                    subModels[loraIndex] = action.payload
                } else {
                    subModels.push(action.payload)
                }
            }
        },
        setSd_selectedModel(state, action: { type: string; payload: ISelectedModelItem }) {
            // console.log('🚀 ~ file: GenerateImageSlice.ts:442 ~ setSd_selectedModel ~    action.payload:', action.payload)
            state.stableDiffusion.selectedModelItem = action.payload
        },
        setSd_historyDetailParams(state, action) {
            state.stableDiffusion.historyDetailParams = action.payload
        },
        setSd_vaeModel(state, action: { type: string; payload: ISelectedModelItem }) {
            state.stableDiffusion.vaeModel = action.payload
        },
        updateSd_modelConfig(state, action: { type: string; payload: Partial<ISdModelConfig> }) {
            if (!state.stableDiffusion.modelConfig) {
                state.stableDiffusion.modelConfig = {} as any
            }

            for (const key of Object.keys(action.payload)) {
                if (state.stableDiffusion.modelConfig) {
                    state.stableDiffusion.modelConfig[key] = action.payload[key]
                }
            }

            if (state.stableDiffusion.selectedModelItem?.modelId) {
                state.stableDiffusion.modelConfigCache[state.stableDiffusion.selectedModelItem?.modelId] = state.stableDiffusion.modelConfig
            }
        },
        updateSd_controlNets(state, action: { type: string; payload: Partial<ISdControlNetConfig> }) {
            state.stableDiffusion.controlNets = { ...state.stableDiffusion.controlNets, ...action.payload }
        },
        resetSd_controlNets(state, action: { type: string; payload: Partial<ISdControlNetConfig> }) {
            state.stableDiffusion.controlNets = action.payload
        },
        updateSd_hiresConfig(state, action: { type: string; payload: Partial<ISdHiresConfig> }) {
            state.stableDiffusion.hiresConfig = { ...state.stableDiffusion.hiresConfig, ...action.payload }
        },
        setSd_textualList(state, action) {
            state.stableDiffusion.textualList = action.payload
        },

        //mj
        setMidjourneyState(state, action) {
            state.midjourney = { ...state.midjourney, ...action.payload }
        },
        insertMj_image(state, action) {
            state.midjourney.generatedImageList = [action.payload, ...state.midjourney.generatedImageList]
        },
        updateMj_imageDetailState(state, action) {
            const rootTaskId = action.payload.rootTaskId
            let rootItem: IMjImageListItem | undefined = undefined
            for (const iterator of state.midjourney.generatedImageList) {
                if (rootTaskId == iterator.taskId) {
                    rootItem = iterator
                    break
                }
            }

            if (rootItem) {
                rootItem.detailState = { ...rootItem.detailState, ...action.payload.detailState }
            }
        },
        insertMj_imageDetailImages(state, action) {
            const rootTaskId = action.payload.rootTaskId
            let rootItem: IMjImageListItem | undefined = undefined
            for (const iterator of state.midjourney.generatedImageList) {
                if (rootTaskId == iterator.taskId) {
                    rootItem = iterator
                    break
                }
            }

            if (rootItem) {
                rootItem.detailImages = [...action.payload.detailImages, ...rootItem.detailImages]
            }
        },
        insertMj_imageDetailButtons(state, action) {
            const rootTaskId = action.payload.rootTaskId
            let rootItem: IMjImageListItem | undefined = undefined
            for (const iterator of state.midjourney.generatedImageList) {
                if (rootTaskId == iterator.taskId) {
                    rootItem = iterator
                    break
                }
            }

            if (rootItem) {
                rootItem.detailButtons = { ...rootItem.detailButtons, ...action.payload.detailButtons }
            }
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchMj_historicalStateRecord.fulfilled, (state, action) => {
            const tasks = action.payload.tasks
            if (tasks && tasks[0]) {
                const task = tasks[0] as IRecoverTask

                if (task.drawMethod == 2) {
                    //mj
                    state.midjourney.recoverTask = task
                    const mode = task.mjDrawTaskMode as GeneratedMode
                    switch (mode) {
                        case 'preview':
                            {
                                state.midjourney.generating = true
                            }
                            break
                        case 'variation':
                            {
                                const imageState = {
                                    taskId: task.rootTaskId,
                                    image: task.rootPicUrl,

                                    // u1 u2 精修图状态
                                    detailState: {
                                        mode: mode,
                                        generating: true,
                                        // generatingPercent: 0,
                                        generatedImageList: [],
                                        generatedImageProgress: null,
                                    },
                                    detailImages: [],
                                    detailButtons: {},
                                }

                                state.midjourney.generatedImageList = [imageState]
                            }
                            break
                        case 'upscale':
                            {
                                const key = `U${task.mjDrawSelectIndex}`
                                const o = {}
                                if (task.mjDrawSelectIndex) {
                                    o[key] = true
                                }

                                const imageState = {
                                    taskId: task.rootTaskId,
                                    image: task.rootPicUrl,

                                    // u1 u2 精修图状态
                                    detailState: {
                                        mode: mode,
                                        generating: true,
                                        // generatingPercent: 0,
                                        generatedImageList: [],
                                        generatedImageProgress: null,
                                    },
                                    detailImages: [],
                                    detailButtons: o,
                                }

                                state.midjourney.generatedImageList = [imageState]
                            }
                            break
                        case 'seed':
                            {
                                state.midjourney.seedLoading = true
                            }
                            break
                        default:
                            break
                    }
                }
            }
        })
    },
})

export const {
    resetImageState,
    //st
    upinsertSt_imageItem,
    deleteSt_imageItem,
    setStableDiffusionState,
    updateSd_modalState,
    setSd_selectedModel,
    setSd_vaeModel,
    updateSd_modelConfig,
    updateSd_controlNets,
    resetSd_controlNets,
    setSd_publicModels,
    setSd_privateModels,
    updateSd_publicModelOne,
    updateSd_privateModelOne,
    removeSd_privateModelOne,
    removeSd_selectedSubModel,
    upsertSd_selectedSubModel,
    replaceSd_selectedSubModel,
    setSd_historyDetailParams,
    setSd_imageItemDetailShow,
    updateSd_segmentedPrompt,
    updateSd_hiresConfig,
    setSd_textualList,
    setPrompt,
    addSd_publicModels,
    addSd_privateModels,

    //mj
    setMidjourneyState,
    insertMj_image,
    updateMj_imageDetailState,

    insertMj_imageDetailImages,
    insertMj_imageDetailButtons,
} = generateImageSlice.actions
export default generateImageSlice.reducer
