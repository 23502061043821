import { AppDispatch } from '@/store'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Macy from 'macy'
import { ISdModelListItem } from '@/slices/sdModelListSlice'
import { useAppSelector } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { debounce } from '@mui/material'
import VAEModalItem from './VAEModalItem'

export interface IVaeModalContentRef {}
export interface IVAEModalContentProps {
    className?: string
    modelList: ISdModelListItem[]
}
const VAEModalContent = React.forwardRef<IVaeModalContentRef, IVAEModalContentProps>((props, ref) => {
    const macyContainerRef = useRef(null)
    const { modelList } = props
    const [macy, setMacy] = useState<any>()

    useEffect(() => {
        if (modelList.length > 0 && macy) {
            macy.runOnImageLoad(function () {
                debounce(() => {
                    // console.log('Every time an image loads I get fired')
                    macy.recalculate(true)
                }, 500)
            }, true)
        }
    }, [modelList, macy])

    useEffect(() => {
        const macy = Macy({
            container: macyContainerRef.current,
            trueOrder: false,
            waitForImages: false,
            mobileFirst: true,
            breakAt: {
                1300: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 4,
                },
                900: {
                    margin: {
                        x: 12,
                        y: 12,
                    },
                    columns: 3,
                },
                0: {
                    margin: {
                        x: 8,
                        y: 8,
                    },
                    columns: 2,
                },
            },
        })

        macy.on(macy.constants.EVENT_IMAGE_COMPLETE, function (ctx) {})
        setMacy(macy)
        return () => {}
    }, [])

    return (
        <div className={props.className}>
            <div ref={macyContainerRef} className="">
                {modelList.map((model, index) => (
                    <VAEModalItem key={model.modelId} model={model} index={index} />
                ))}
            </div>
        </div>
    )
})

export default VAEModalContent
