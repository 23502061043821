import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CreateResourcesModal(props) {
    const { open, onCancel, title, footer, resourceName, onChange, onOk, setDescription, description } = props
    const { t } = useTranslation()

    return (
        <>
            <div
                className={`fixed ${
                    open ? '' : 'hidden'
                } text-white left-0 top-0 h-full w-full overflow-y-auto overflow-x-hidden outline-none flex justify-center items-center`}
            >
                <div
                    className={`pointer-events-none relative w-10/12 lg:w-1/2 translate-y-[-50px] transition-all duration-300 ease-in-out mx-auto mt-16 lg:mt-7 max-w-[900px] ${
                        open ? 'transform-none opacity-100' : 'opacity-0'
                    } `}
                >
                    <div className="shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-menuBgColor bg-clip-padding text-current shadow-lg outline-none">
                        <div className="flex flex-shrink-0 items-center justify-between rounded-t-md p-4">
                            <h5 className="text-xl leading-normal font-bold">{title}</h5>
                            <button
                                onClick={onCancel}
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="relative flex-auto p-4">
                            <p>{t('Training&Datasets.NAMEANDDESCRIPTION')}</p>
                            <input
                                className="block w-full mt-5 min-w-0 flex-1 p-2 rounded-md bg-iptBgColor outline-none"
                                // placeholder={t('generatedImage.negativePromptPlaceHolder')!}
                                value={resourceName}
                                onChange={e => {
                                    onChange(e.target.value)
                                }}
                                placeholder={t('Training&Datasets.PLEASEENTERNAME') as any}
                                // value={state.negativePrompt}
                                // onChange={e => updateState({ negativePrompt: e.target.value })}
                            />
                            <input
                                value={description}
                                onChange={e => {
                                    setDescription(e.target.value)
                                }}
                                className="block w-full mt-8 min-w-0 flex-1 p-2 rounded-md bg-iptBgColor outline-none"
                                // placeholder={t('generatedImage.negativePromptPlaceHolder')!}

                                placeholder={t('Training&Datasets.PLEASEENTERDESCRIPTION') as any}

                                // value={state.negativePrompt}
                                // onChange={e => updateState({ negativePrompt: e.target.value })}
                            />
                        </div>
                        <div className="flex items-center justify-center rounded-b-md p-4">
                            {/* <Button className="w-full mt-3" >
                                Create Dataset
                            </Button> */}
                            <button
                                onClick={onOk}
                                className="py-3 px-28 rounded-full bg-gradient-to-l from-btnBgColorFrom  to-btnBgColorTo hover:bg-black"
                            >
                                {t('Training&Datasets.CREATE_RESOURCES')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
