import { getModelTaskDetail, getCurrentTaskDetail } from '@/api/stableDiffusionRequest'
import loading_pnd from '../../../assets/loading.png'
import loading_static from '../../../assets/loading_static.png'
import { useAppSelector } from '@/hooks'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Progress } from 'antd'
export default function TrainOutput(params) {
    const { t } = useTranslation()

    const timeRef = useRef()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const modelTaskID = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.modelTaskID
    })

    const [curTaskID, setCurTaskID] = useState(null)

    const [taskModel, setTaskModel] = useState(null)
    async function getModelsDetail(taskID) {
        const res = await getModelTaskDetail({ authToken: authToken, taskId: taskID })
        if (res.code == 1) {
            setTaskModel(res.data)
            if (res.data.taskStatus != 1) {
                clearInterval(timeRef.current)
            }
        }
    }
    async function getHistoryModelsDetail() {
        const res = await getCurrentTaskDetail({ authToken: authToken })
        if (res.code == 1) {
            setCurTaskID(res.data.taskId)
        }
    }
    useEffect(() => {
        if (modelTaskID) {
            setCurTaskID(modelTaskID)
        }
    }, [modelTaskID])

    useEffect(() => {
        if (curTaskID) {
            getModelsDetail(curTaskID)
            timeRef.current = setInterval(() => {
                getModelsDetail(curTaskID)
            }, 10000)
            return () => {
                clearInterval(timeRef.current)
            }
        }
    }, [curTaskID])

    useEffect(() => {
        if (authToken) {
            getHistoryModelsDetail()
        }
    }, [authToken])

    return (
        <div className="flex flex-col p-2 mt-2 rounded w-full lg:w-1/3">
            <div>{t('training.output.title')}</div>
            <div className="mt-2 border-2 border-trainBorderColor ">
                <div className="h-[40vh] flex flex-col justify-center items-center text-gray-500">
                    <img className="w-32" src={taskModel && taskModel.taskStatus == 1 ? loading_pnd : loading_static} />
                    {taskModel && taskModel.taskStatus == 1 && (
                        <div className="flex flex-col items-center">
                            <span className="mt-4 px-4">{taskModel.progress.name}</span>
                            {taskModel.progress.process && taskModel.progress.process.substring(0, taskModel.progress.process.length - 1) > 0 && (
                                <div className="w-1/2 min-w-[200px] flex py-2 text-gray-500">
                                    <Progress
                                        percent={Math.floor(
                                            parseFloat(taskModel.progress.process.substring(0, taskModel.progress.process.length - 1)),
                                        )}
                                        strokeColor={{ '0%': '#786CFD', '100%': '#F56DBC' }}
                                        showInfo={false}
                                    />
                                    <span>{taskModel.progress.process}</span>
                                </div>
                            )}
                            <span className=" px-4">{`${t('training.output.remainingTime')} : ${taskModel.progress.remaining}`}</span>
                        </div>
                    )}
                    {taskModel && taskModel.taskStatus == 2 && <span className="mt-4 px-4">{'Successful training!!!'}</span>}
                    {taskModel && taskModel.taskStatus == -1 && <span className="mt-4 px-4">{'Training failure:' + taskModel.errorMessage}</span>}
                </div>
            </div>
        </div>
    )
}
