import { AppDispatch } from '@/store'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Macy from 'macy'
import { ISdModelListItem } from '@/slices/sdModelListSlice'
import { useAppSelector } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { debounce } from '@mui/material'
import SdModelListModalItem from './SdModelListModalItem'
import { IModelItem } from '@/slices/GenerateImageSlice'

export interface ISdModelListModalContentRef {}
export interface ISdModelListModalContentProps {
    className?: string
    modelList: IModelItem[]
}
const SdModelListModalContent = React.forwardRef<ISdModelListModalContentRef, ISdModelListModalContentProps>((props, ref) => {
    const dispatch = useDispatch<AppDispatch>()
    const { t, i18n } = useTranslation()
    const state = useAppSelector(state => state.generateImageSlice.stableDiffusion.modalState)
    const macyContainerRef = useRef(null)
    const { modelList } = props
    const [macy, setMacy] = useState<any>()

    useEffect(() => {
        console.log('🚀 ~ file: SdModelListModalContent.tsx:24 ~ SdModelListModalContent ~ macy:', macy)

        if (macy) {
            // macy.runOnImageLoad(function () {
            //     // debounce(() => {
            //     // console.log('Every time an image loads I get fired')
            //     macy.recalculate(true)
            //     // }, 300)
            // }, true)

            macy.runOnImageLoad(function () {
                console.log('I only get called when all images are loaded')
                macy.recalculate(true, true)
            })
        }
    }, [modelList, macy])

    // useEffect(() => {
    //     if (state.errorToast) {
    //         message.error(state.errorToast)
    //     }
    // }, [state.errorToast])

    useEffect(() => {
        const macy = Macy({
            container: macyContainerRef.current,
            trueOrder: false,
            waitForImages: false,
            mobileFirst: true,
            breakAt: {
                1300: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 4,
                },
                900: {
                    margin: {
                        x: 12,
                        y: 12,
                    },
                    columns: 3,
                },
                0: {
                    margin: {
                        x: 8,
                        y: 8,
                    },
                    columns: 2,
                },
            },
        })

        macy.on(macy.constants.EVENT_IMAGE_COMPLETE, function (ctx) {})
        setMacy(macy)
        return () => {}
    }, [])

    return (
        <div className={props.className}>
            <div key="macy-container-model" id="macy-container-model" ref={macyContainerRef} className="min-h-0">
                {modelList.map((model, index) => (
                    <SdModelListModalItem key={model.modelId} model={model} index={index} />
                ))}
            </div>
        </div>
    )
})

export default SdModelListModalContent
