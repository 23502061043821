/*
 * @Author: jianwen.Wang
 * @Date: 2022-05-31 14:16:42
 * @LastEditTime: 2022-05-31 16:13:27
 * @LastEditors: jiawen.wang
 */
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

let instance: any = null

const Position = (props: any) => {
    const { targetRef, children, getContainer, onNotVisibleArea } = props
    const container = getContainer && getContainer()

    if (!instance) {
        instance = document.createElement('div')
        instance.className = 'll-position'
        document.body.appendChild(instance)
    }

    useEffect(() => {
        function setInstanceStyle() {
            const { top, left, height, width } = targetRef.current.getBoundingClientRect()
            const style = {
                width: width + 'px',
                top: document.documentElement.scrollTop + top + height + 4 + 'px',
                left: left + 'px',
            }
            console.log('🚀 ~ file: Position.tsx:17 ~ Position ~ instance:', instance)

            instance.style.top = style.top
            instance.style.left = style.left
            instance.style.width = style.width
            instance.style.width = '300px'

            instance.style.maxHeight = '209px'
            instance.style.overflowY = 'scroll'
            instance.style.zIndex = '999'

            return { top, left, height }
        }

        setInstanceStyle()

        function handleScroll() {
            const { top, height } = setInstanceStyle()

            if (container.offsetTop > top) {
                onNotVisibleArea()
            }
            if (top - container.offsetTop + height > container.offsetHeight) {
                onNotVisibleArea()
            }
        }

        if (container) {
            container.addEventListener('scroll', handleScroll, false)
        }

        return () => {
            if (container) {
                console.log('🚀 ~ file: Position.tsx:17 ~ Position ~ instance:', instance)

                container.removeEventListener('scroll', handleScroll, false)
                // document.body.removeChild(instance)
                // instance = null
            }
        }
    }, [targetRef])

    return instance && ReactDOM.createPortal(children, instance)
}
export default Position
