import React, { useEffect, useRef, useState } from 'react'

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import imgArrow from '../../assets/model/img_arrow.png'
import loading_pnd from '../../assets/common/watersLoading.png'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import './WatersSwiperStyle.css'

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper'
import { useTranslation } from 'react-i18next'
import { Image, Progress } from 'antd'
import { useAppSelector } from '@/hooks'

function WatersSwiper(params) {
    const { images, setOpen, setImagePreviewUrl } = params
    const { t } = useTranslation()
    const [thumbsSwiper, setThumbsSwiper] = useState(null)
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [showImagePreviewIndex, setShowImagePreviewIndex] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [initIndex, setInitIndex] = useState(0)

    const swiperRef = useRef(null)
    const thumbsRef = useRef(null)
    function gcd(n, m) {
        if (m == 0) return n
        return gcd(m, n % m)
    }

    function scale(n, m) {
        let imgStr = n / gcd(n, m) + '_' + m / gcd(n, m)
        if (imgStr !== '1_1' && imgStr !== '3_4' && imgStr !== '4_3' && imgStr !== '16_9' && imgStr !== '9_16') {
            imgStr = 'default'
        }
        return imgStr
    }

    const handleSlideChange = swiper => {
        setSelectedIndex(swiper.realIndex)
    }

    const host = useAppSelector(state => {
        return state.app && state.app.host
    })

    return (
        images.length > 0 && (
            <div className="group w-full h-full max-w-full max-h-full">
                <Swiper
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                    }}
                    loop={true}
                    spaceBetween={10}
                    navigation={{
                        enabled: true,
                        // nextEl: '.swiper-button-next',
                        // prevEl: '.swiper-button-prev',
                    }}
                    // thumbs={{ swiper: thumbsSwiper }}
                    thumbs={{ swiper: thumbsRef.current }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiperMain"
                    initialSlide={initIndex}
                    onSlideChange={handleSlideChange}
                    observer={true}
                    observeParents={true}
                    observeSlideChildren={true}
                    onSwiper={swiperA => {
                        swiperRef.current = swiperA
                    }}
                    ref={swiperRef}
                >
                    {images.map((imageItem, index) => {
                        return (
                            <SwiperSlide key={index}>
                                {imageItem.taskStatus == 2 ? (
                                    <div className=" relative w-full h-full max-w-full max-h-full flex flex-col justify-center items-center bg-gray-bg text-white">
                                        <img className="w-28 -mt-4 scale-50" src={loading_pnd} />
                                        {imageItem.progress && imageItem.progress.progress > 0 && (
                                            <div className=" max-w-[90%] w-24 lg:w-48 text-white">
                                                <Progress
                                                    percent={Math.floor(imageItem.progress.progress)}
                                                    strokeColor={{ '0%': '#9042EE', '100%': '#9042EE' }}
                                                />
                                            </div>
                                        )}
                                        <div className="flex items-center text-white/90 mb-5">
                                            <span className="px-2 lg:px-8 whitespace-normal text-center">
                                                {imageItem.progress && imageItem.progress
                                                    ? imageItem.progress.step
                                                    : t('generatedImage.generateProgressStatus0')}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <img
                                            src={
                                                imageItem.taskStatus == -1
                                                    ? imageItem.isRecommend == 1
                                                        ? imageItem.imageUrl + (host == 'xyz' ? '?x-oss-process=style/waters3' : '')
                                                        : `${
                                                              'https://resource.mst.xyz/image/default/' +
                                                              scale(imageItem.targetWidth, imageItem.targetHeight) +
                                                              t('newFunction.ImageLanguage')
                                                          }`
                                                    : imageItem.imageUrl + (host == 'xyz' ? '?x-oss-process=style/waters3' : '')
                                            }
                                            onClick={() => {
                                                if (imageItem.taskStatus !== 3) {
                                                    return
                                                }
                                                setOpen(true)
                                                setImagePreviewUrl(imageItem.imageUrl + (host == 'xyz' ? '?x-oss-process=style/waters3' : ''))
                                            }}
                                            className="w-full h-full max-w-full max-h-full"
                                        />
                                    </div>
                                )}

                                {imageItem.taskStatus == 3 && (
                                    <Image
                                        style={{ display: 'none' }}
                                        src={imageItem.imageUrl + (host == 'xyz' ? '?x-oss-process=style/waters3' : '')}
                                        preview={{
                                            visible: showImagePreviewIndex == index && showImagePreview,
                                            onVisibleChange: value => {
                                                setShowImagePreview(value)
                                            },
                                        }}
                                        rootClassName="block static"
                                    />
                                )}
                            </SwiperSlide>
                        )
                    })}
                    {images.length > 1 && (
                        <>
                            <div
                                className="swiper-button-next rounded-full lg:w-[68px] lg:h-[68px] w-[40px] h-[40px] bg-[rgba(255,255,255,)] hidden group-hover:flex"
                                style={{ top: 'calc(50% - 34px)' }}
                                onClick={() => {
                                    swiperRef.current.slideNext()
                                }}
                            >
                                <img className="w-full" src={imgArrow} alt="" />
                            </div>
                            <div
                                className="swiper-button-prev rounded-full lg:w-[68px] lg:h-[68px] w-[40px] h-[40px] bg-[rgba(255,255,255,)] hidden group-hover:flex"
                                style={{ top: 'calc(50% - 34px)' }}
                                onClick={() => {
                                    swiperRef.current.slidePrev()
                                }}
                            >
                                <img className="w-full rotate-180" src={imgArrow} alt="" />
                            </div>
                        </>
                    )}
                </Swiper>
                <Swiper
                    // onSwiper={setThumbsSwiper}
                    onSwiper={swiper => {
                        thumbsRef.current = swiper
                    }}
                    loop={true}
                    spaceBetween={5}
                    slidesPerView={images.length}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiperBar hidden group-hover:flex"
                    observer={true}
                    observeParents={true}
                    observeSlideChildren={true}
                >
                    {images.map((imageItem, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div
                                    className={`${
                                        selectedIndex === index ? 'border border-white' : ''
                                    } w-full max-w-full min-w-full h-full min-h-full max-h-full`}
                                >
                                    <img
                                        src={
                                            imageItem.taskStatus == 2
                                                ? loading_pnd
                                                : imageItem.taskStatus == -1
                                                ? imageItem.isRecommend == 1
                                                    ? imageItem.imageUrl
                                                    : `${
                                                          'https://resource.mst.xyz/image/default/' +
                                                          scale(imageItem.targetWidth, imageItem.targetHeight) +
                                                          t('newFunction.ImageLanguage')
                                                      }`
                                                : imageItem.imageUrl
                                        }
                                    />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        )
    )
}
export default WatersSwiper
