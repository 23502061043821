import ConfigProvider from 'antd/es/config-provider'
import { Spin } from 'antd'
import theme from 'antd/es/theme'
import React, { PropsWithChildren } from 'react'
import { useAppSelector } from '@/hooks'

const AntProvider: React.FC<PropsWithChildren> = props => {
    const loading = useAppSelector(state => {
        return state.app && state.app.loading
    })
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F56DBC',
                },
                algorithm: theme.darkAlgorithm,
                components: {
                    Pagination: {
                        itemActiveBg: '#C835FF',
                        itemActiveColorDisabled: '#ffffff',
                    },
                },
            }}
        >
            <Spin spinning={loading} style={{ minHeight: '100vh' }}>
                {props.children}
            </Spin>
        </ConfigProvider>
    )
}
export default AntProvider
