import React from 'react'
import loading_pnd from '../../assets/loading.png'
import loading_static from '../../assets/loading_static.png'

import { useAppSelector } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { Progress } from 'antd'
import SdImageCell from './components/SdImageCell'
import { shallowEqual } from 'react-redux'
// import { useStLoading } from './StHooks'

export interface IAIImageImageEditorProps {}
const AIImageImageEditor: React.FC<IAIImageImageEditorProps> = props => {
    const { t } = useTranslation()
    // const dispatch = useDispatch<AppDispatch>()
    // const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))

    // const { loadingItem } = useStLoading()
    const state = useAppSelector(state => {
        return state.generateImageSlice.stableDiffusion
    }, shallowEqual)

    function imagesRender() {
        // if (loadingItem && loadingItem.generating && state.imageItems.length < 2) {
        //     //一个结果都没有生图的loading
        //     return (
        //         <div className="h-[40vh] flex flex-col justify-center items-center text-center text-white/90">
        //             <img className="w-32 mb-4" src={loading_pnd} />
        //             {loadingItem.generatingPercent > 0 && (
        //                 <div className="w-48">
        //                     <Progress percent={Math.floor(loadingItem.generatingPercent)} strokeColor={{ '0%': '#786CFD', '100%': '#F56DBC' }} />
        //                 </div>
        //             )}
        //             {/* <div className="flex items-center mt-5">
        //                 <span className="ml-2">{state.generatedImageProgress?.step || t('generatedImage.generateProgressStatus0')}</span>
        //             </div> */}
        //             <div className="mt-5">{loadingItem.generatedImageProgress?.step || t('generatedImage.generateProgressStatus0')}</div>
        //             {/* <div>Please note that the data on this page will be lost after refreshing. Please wait.</div> */}
        //         </div>
        //     )
        // } else
        if (state.imageItems && state.imageItems.length > 0) {
            // 已经有生成图时的loading
            const jsx = state.imageItems
                .map(item => {
                    if (item.generating) {
                        // 正在生图
                        return (
                            <div
                                key={item.taskId}
                                className="relative w-full flex flex-col justify-center items-center overflow-hidden rounded-lg bg-gray-bg"
                            >
                                <img className="w-28 -mt-4 scale-50" src={loading_pnd} />
                                {item.generatingPercent > 0 && (
                                    <div className="w-48">
                                        <Progress percent={Math.floor(item.generatingPercent)} strokeColor={{ '0%': '#786CFD', '100%': '#F56DBC' }} />
                                    </div>
                                )}
                                <div className="flex items-center text-white/90 mb-5">
                                    <span className="px-8 whitespace-normal text-center">
                                        {item.generatedImageProgress?.step || t('generatedImage.generateProgressStatus0')}
                                    </span>
                                </div>
                            </div>
                        )
                    } else {
                        // 生图结果
                        return <SdImageCell key={item.taskId} model={item} />
                        //     return item.generatedImageList?.map(url => (

                        //         <div
                        //             key={url}
                        //             className="relative block w-full overflow-hidden rounded-lg bg-gray-bg"
                        //             style={{ aspectRatio: `${item.generatedImageWidth}/${item.generatedImageHeight}` }}
                        //         >
                        //             <div className="absolute mt-2 ml-2 text-white/90 flex space-x-1">
                        //                 <a
                        //                     target="_blank"
                        //                     className="w-8 h-8 flex justify-center items-center mr-1 bg-black/50 !hover:bg-black/80 transition rounded-sm"
                        //                     // onClick={e => download(val)}
                        //                     href={url}
                        //                 >
                        //                     <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        //                         <path
                        //                             fillRule="evenodd"
                        //                             clipRule="evenodd"
                        //                             d="M3.97589 7.18878C4.2102 6.95446 4.5901 6.95446 4.82442 7.18877L8.00015 10.3645L11.1758 7.18878C11.4102 6.95446 11.7901 6.95446 12.0244 7.18877C12.2587 7.42309 12.2587 7.80299 12.0244 8.0373L8.42442 11.6373C8.19011 11.8716 7.81021 11.8716 7.57589 11.6373L3.97589 8.03731C3.74158 7.80299 3.74158 7.42309 3.97589 7.18878Z"
                        //                         ></path>
                        //                         <path
                        //                             fillRule="evenodd"
                        //                             clipRule="evenodd"
                        //                             d="M7.99999 1.01305C8.33136 1.01305 8.59999 1.28168 8.59999 1.61305V11.213C8.59999 11.5444 8.33136 11.813 7.99999 11.813C7.66862 11.813 7.39999 11.5444 7.39999 11.213V1.61305C7.39999 1.28168 7.66862 1.01305 7.99999 1.01305Z"
                        //                         ></path>
                        //                         <path
                        //                             fillRule="evenodd"
                        //                             clipRule="evenodd"
                        //                             d="M0.230989 13.4228C0.33602 13.1085 0.675943 12.9389 0.990228 13.0439C5.5348 14.5627 10.4653 14.5627 15.0098 13.0439C15.3241 12.9389 15.664 13.1085 15.7691 13.4228C15.8741 13.7371 15.7045 14.077 15.3902 14.1821C10.5988 15.7833 5.40131 15.7833 0.609878 14.1821C0.295592 14.077 0.125958 13.7371 0.230989 13.4228Z"
                        //                         ></path>
                        //                     </svg>
                        //                 </a>
                        //                 {item.generatedImageWidth <= 2048 && item.generatedImageHeight <= 2048 && (
                        //                     <div>
                        //                         <button
                        //                             disabled={loadingItem?.generating}
                        //                             className="w-8 h-8 flex justify-center items-center mr-1 rounded-sm disabled:opacity-30"
                        //                             onClick={e => {
                        //                                 if (props.onEnlarge) {
                        //                                     props.onEnlarge(item, url)
                        //                                 }
                        //                             }}
                        //                         >
                        //                             <img src={enlarge_icon} alt="enlarge" />
                        //                         </button>
                        //                     </div>
                        //                 )}
                        //             </div>
                        //             <div className="w-full h-full flex justify-center items-center">
                        //                 <Image
                        //                     src={url}
                        //                     alt=""
                        //                     rootClassName="block static"
                        //                     className="pointer-events-none object-cover group-!hover:opacity-75 rounded-lg"
                        //                     preview={{ maskClassName: 'invisible' }}
                        //                 />
                        //             </div>
                        //         </div>
                        //     ))
                    } //else end
                })
                .flat()

            return (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">{jsx}</div>
                </>
            )
        } else {
            return (
                <div className="h-[40vh] flex flex-col justify-center items-center text-gray-500">
                    <img className="w-32" src={loading_static} />
                    <span className="mt-4">{t('generatedImage.generateProgressStatus')}</span>
                </div>
            )
        }
    }

    return (
        <>
            {/* <div className="mt-1 mb-8 text-xs font-bold text-gray-grayText">{t('generatedImage.generatedImagesDesc')}</div> */}
            {imagesRender()}
        </>
    )
}

export default AIImageImageEditor
