import { ConfigProvider, Dropdown, Input, message, theme, Spin } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
const { TextArea } = Input

import resolution_1_1_image from '../../assets/v1.6/resolution_1_1.png'
import resolution_4_3_image from '../../assets/v1.6/resolution_4_3.png'
import resolution_3_4_image from '../../assets/v1.6/resolution_3_4.png'
import resolution_16_9_image from '../../assets/v1.6/resolution_16_9.png'
import resolution_9_16_image from '../../assets/v1.6/resolution_9_16.png'
import dropDownArrow from '../../assets/v1.6/dropDownArrow.png'
import logo from '../../assets/home/waterAreaLogo.png'
import titleWaters from '../../assets/home/titleWaters.png'
import bg from '../../assets/home/homeWater-bg.png'

import { historicalRecord, taskResult, createTask } from '../../api/newFunctionRequest'
import { useAppSelector } from '@/hooks'
import { setCanAnimate, setImageRecordList, addImageRecord, setShowHistoricalRecordItem } from '../../slices/NewFunctionSlice'
import { useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next'

import { pageEvent, timeEvent, trackEvent, trackIdentify, googleTrackEvent } from '@/lib/analyse'
import GraphResult from '../Waters/GraphResult'
import useNewFunction from '../Waters/NewFunctionHook'
import { setShowLogin } from '@/slices/AppSlice'
import PromptHelperPopover from '@/components/Popover/PromptHelperPopover'

function WatersArea(params: any) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    useNewFunction({ generationType: 'vistor' })
    const resolutionGroup = [
        { name: '1:1', image: resolution_1_1_image, index: 0, width: 768, height: 768, shap: 'square' },
        { name: '4:3', image: resolution_4_3_image, index: 1, width: 1024, height: 768, shap: 'landscape' },
        { name: '3:4', image: resolution_3_4_image, index: 2, width: 768, height: 1024, shap: 'vertical' },
        { name: '16:9', image: resolution_16_9_image, index: 3, width: 1440, height: 810, shap: 'landscape' },
        { name: '9:16', image: resolution_9_16_image, index: 4, width: 810, height: 1440, shap: 'vertical' },
    ]
    const vistorAuthToken = useAppSelector(state => {
        return state.app && state.app.vistorAuthToken
    })
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const imageRecordList = useAppSelector(state => {
        return state.newFunctionSlice && state.newFunctionSlice.imageRecordList
    })
    const [showTitleArea, setShowTitleArea] = useState(true)
    const [selectResolution, setSelectResolution] = useState(0)
    const [searchStr, setSearchStr] = useState('')
    const [canGenerate, setCanGenerate] = useState(true)
    const [hasImageRecords, setHasImageRecords] = useState(false)

    const titleAreaRef = useRef<HTMLDivElement>(null)
    const generateClick = async () => {
        if (!searchStr) {
            message.error(t('newFunction.InputPlaceholder'))
            return
        }
        if (authToken) {
            dispatch(setShowLogin(true))
            return
        }
        trackEvent('Home generate')
        googleTrackEvent('Home generate', 'Home generate')
        setPromptHelperOpen(false)
        setCanGenerate(false)
        try {
            const res = await createTask({
                authToken: vistorAuthToken,
                drawType: 1,
                height: resolutionGroup[selectResolution].height,
                width: resolutionGroup[selectResolution].width,
                originPrompt: searchStr,
                shap: resolutionGroup[selectResolution].shap,
            })
            if (res.code == 1) {
                setShowTitleArea(false)
                await historicalRecordRequest()
                dispatch(setShowHistoricalRecordItem(0))
                setSearchStr('')
                setCanGenerate(true)
            } else if (res.code == 3501) {
                dispatch(setShowLogin(true))
                setCanGenerate(true)
            } else {
                message.error(res.message)
                setCanGenerate(true)
            }
        } catch (error) {
            setCanGenerate(true)
        }
    }

    const historicalRecordRequest = useCallback(async () => {
        const res = await historicalRecord({ authToken: vistorAuthToken, itemCount: 10, groupTableId: 0 })
        if (res.code == 1) {
            if (res.data.groupResults) {
                dispatch(setImageRecordList(res.data.groupResults))
            }
        }
    }, [vistorAuthToken])

    useEffect(() => {
        if (vistorAuthToken) {
            historicalRecordRequest()
        }
    }, [vistorAuthToken])

    useEffect(() => {
        if (imageRecordList && imageRecordList.length > 0) {
            setShowTitleArea(false)
        } else {
            setShowTitleArea(true)
        }
    }, [imageRecordList])

    useEffect(() => {
        const titleAreaAlement = titleAreaRef.current

        const handleTransitionEnd = e => {
            if (e.target.clientHeight == 500) {
                setHasImageRecords(false)
            } else {
                setHasImageRecords(true)
            }
        }

        titleAreaAlement?.addEventListener('transitionend', handleTransitionEnd)

        return () => {
            titleAreaAlement?.removeEventListener('transitionend', handleTransitionEnd)
        }
    }, [])
    //

    const [fix, setFix] = useState(true)
    const container = useRef<HTMLDivElement>(null)

    useEffect(() => {
        let backgroundWidth = 0,
            backgroundHeight = 960
        const resizeObserver = new ResizeObserver(entries => {
            let a = 0
            for (let entry of entries) {
                a = entry.contentRect.height
            }
            window.addEventListener('scroll', handleScroll)
            function handleScroll() {
                setPromptHelperOpen(false)
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                if (scrollTop >= a - backgroundHeight) {
                    setFix(false)
                } else {
                    setFix(true)
                }
            }
        })
        if (container.current) {
            resizeObserver.observe(container.current)
            // const computedStyle = getComputedStyle(container.current)
            // const backgroundImage = computedStyle.backgroundImage
            // if (backgroundImage !== 'none') {
            //     const image = new Image()
            //     image.src = backgroundImage.slice(4, -1).replace(/"/g, '')
            //     image.onload = () => {
            //         backgroundWidth = parseInt(computedStyle.backgroundSize.split(' ')[0], 10)
            //         backgroundHeight = parseInt(computedStyle.backgroundSize.split(' ')[1], 10)
            //     }
            // }
        }

        return () => {
            resizeObserver.disconnect()
        }
    }, [])

    const [promptHelperOpen, setPromptHelperOpen] = useState(false)
    const inputAreaRef = useRef<HTMLDivElement>(null)
    const handlePromptHelperValue = value => {
        setSearchStr((searchStr + value.promptHelperStr).replace(/^[\u002c\uFF0C]+/, ''))
    }

    const showLoginPop = useAppSelector(state => {
        return state.app && state.app.showLogin
    })
    useEffect(() => {
        if (showLoginPop) {
            setPromptHelperOpen(false)
        }
    }, [showLoginPop])

    return (
        <div ref={container} className={`${fix ? 'bg-fixed bg-top' : ' bg-scroll bg-bottom'} home-waters bg-no-repeat relativec min-h-[960px]`}>
            <div
                className={`px-4 w-full flex flex-col items-center justify-end overflow-hidden ${
                    showTitleArea ? 'h-[500px]' : 'h-[101px] lg:h-[64px]'
                }`}
                style={{ transitionProperty: 'height', transitionDuration: '0.5s', transitionTimingFunction: 'ease-out' }}
                ref={titleAreaRef}
            >
                <img src={logo} alt="" />
                <div className=" font-[Novecento] text-3xl font-bold mt-[60px]">{t('home.title')}</div>
                <div className=" font-[Novecento] text-base mt-[34px] mb-[40px]">{t('home.subTitle')}</div>
            </div>
            <div
                className={`fixed left-0 top-0 w-full z-[11] ${showTitleArea ? 'h-[101px] lg:h-[64px]' : 'h-[167px] lg:h-[130px]'} ${
                    hasImageRecords ? '' : 'bg-pageBgColor '
                }`}
                style={{ transitionProperty: 'height', transitionDuration: '0.5s', transitionTimingFunction: 'ease-in' }}
            ></div>
            <div
                id="watersIpt"
                className={`${
                    hasImageRecords ? 'sticky top-[101px] lg:top-[64px] bg-pageBgColor ' : ' relative'
                } w-full  mx-auto   z-20 px-4 py-[14px]`}
            >
                <div className={` w-full max-w-[1383px] mx-auto relative `}>
                    {hasImageRecords && <img className="w-[150px] hidden lg:block absolute left-0 top-1" src={titleWaters} alt="" />}

                    <div
                        className={`flex flex-col items-center lg:items-start  space-y-3 lg:space-y-0 lg:flex-row lg:space-x-2 w-full lg:max-w-[70%] mx-auto`}
                    >
                        <div>
                            <Dropdown
                                placement="bottom"
                                trigger={['click']}
                                menu={{
                                    style: { overflowY: 'auto', backgroundColor: '#1e1e1e' },
                                    items: resolutionGroup.map(resolutionItem => {
                                        return {
                                            key: resolutionItem.index,
                                            label: (
                                                <div className="flex items-center  text-xs cursor-pointer">
                                                    <div className="flex items-center h-[45px]" key={resolutionItem.name}>
                                                        <div className="w-[50px] ">
                                                            <img className="mx-auto" src={resolutionItem.image} alt="" />
                                                        </div>
                                                        <div className="text-white text-xs pl-[10px] w-[40px]">{resolutionItem.name}</div>
                                                    </div>
                                                    <div className="w-[30px]"></div>
                                                </div>
                                            ),
                                        }
                                    }),
                                    onClick: ({ key, keyPath, domEvent }) => {
                                        setSelectResolution(parseInt(key))
                                    },
                                }}
                            >
                                <div className="flex items-center border-gradual h-[40px] w-[80px] rounded-[20px] px-[12px] text-xs cursor-pointer">
                                    <div className="flex items-center h-[40px]">
                                        <div className="w-[25px] ">
                                            <img className="mx-auto" src={resolutionGroup[selectResolution].image} alt="" />
                                        </div>
                                        {/* <div className="text-white text-xs pl-[10px] w-[40px]">{resolutionGroup[selectResolution].name}</div> */}
                                    </div>
                                    <img src={dropDownArrow} className="ml-[10px] mr-[8px]" alt="" />
                                </div>
                            </Dropdown>
                        </div>
                        <PromptHelperPopover
                            inputAreaRef={inputAreaRef}
                            open={promptHelperOpen}
                            handlePromptHelperValue={handlePromptHelperValue}
                            showNegativePrompt={false}
                        >
                            <div
                                ref={inputAreaRef}
                                className={` flex-grow flex flex-col items-center lg:items-start  space-y-3 lg:space-y-0 lg:flex-row lg:space-x-2 w-full `}
                            >
                                <button
                                    className={`${
                                        !promptHelperOpen ? 'border-gradual' : 'bg-[#6401B8]'
                                    } block  h-[40px] w-[128px] rounded-[20px] text-base font-medium disabled:from-gray-500 disabled:to-gray-500 text-white/90`}
                                    onClick={() => {
                                        setPromptHelperOpen(!promptHelperOpen)
                                    }}
                                >
                                    {t('newFunction.PromptHelper')}
                                </button>

                                <div
                                    className={` flex-1 min-h-[40px]  text-white  rounded-[20px] px-3 w-full bg-gray-bg`}
                                    style={{
                                        transitionProperty: 'backgroundColor',
                                        transitionDuration: '0.5s',
                                        transitionTimingFunction: 'ease-out',
                                    }}
                                >
                                    <TextArea
                                        className="text-sm border-none py-[8px] px-[4px] bg-gray-bg focus:border-none focus:shadow-none  text-white placeholder:text-[rgb(157,162,173)] dark-thin-scrollbar"
                                        placeholder={t('newFunction.InputPlaceholder')}
                                        autoSize={{ minRows: 1, maxRows: 6 }}
                                        value={searchStr}
                                        onChange={e => {
                                            setSearchStr(e.target.value)
                                        }}
                                        onKeyDown={e => {
                                            if (e.keyCode == 13) {
                                                e.preventDefault()
                                                generateClick()
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <Spin spinning={!canGenerate}>
                                        <button
                                            className="block m-bg-gradient h-[40px] w-[128px] rounded-[20px] text-base font-medium disabled:from-gray-500 disabled:to-gray-500 text-white/90"
                                            onClick={() => {
                                                generateClick()
                                            }}
                                            disabled={!canGenerate}
                                        >
                                            {t('newFunction.Generate')}
                                        </button>
                                    </Spin>
                                </div>
                            </div>
                        </PromptHelperPopover>
                    </div>
                </div>
            </div>

            <div className={`max-w-[1383px] mx-auto min-h-[280px] z-10 px-4 mb-[28px]`}>
                {imageRecordList && imageRecordList.length > 0 && (
                    <div>
                        {imageRecordList.map((imageRecordObj, index) => {
                            return (
                                <div key={index}>
                                    <GraphResult
                                        imageRecordObj={imageRecordObj}
                                        historicalRecordRequest={historicalRecordRequest}
                                        type={'vistor'}
                                        setCanGenerate={setCanGenerate}
                                    ></GraphResult>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default WatersArea
