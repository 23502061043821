/*
 * @Author: jianwen.Wang
 * @Date: 2022-05-30 16:44:33
 * @LastEditTime: 2022-07-05 15:31:29
 * @LastEditors: jiawen.wang
 */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

export default function RpcSelectItem(props: any) {
    const [selected, setSelected] = useState(false)
    const { label, value, className = '', handleSelect, defaultValue, speed = '', searchStr = '' } = props

    return (
        <>
            {label.toLowerCase().includes(searchStr.toLowerCase()) && (
                <div onClick={() => handleSelect({ value, label })} className={`${className} ${selected ? 'll-selected' : ''}`}>
                    <span>{label}</span>
                    <span>{value}</span>
                </div>
            )}
        </>
    )
}
