import axios from './index' //进行了二次封装了

// 发送聊天会话
export const sendMessaaage = params => {
    return axios.post('/openai/api/completions', params)
}
export const messaaageStream = params => {
    return axios.post('/openai/api/completions/stream', params)
}

// 添加会话session接口
export const getSession = params => {
    return axios.post('/openai/api/getSessionToken', params)
}

// 删除某个会话
export const deleteSession = params => {
    return axios.post('/openai/api/deleteRecord', params)
}

// 删除所有会话
export const deleteAllSession = params => {
    return axios.post('/openai/api/deleteAllRecord', params)
}

// 历史会话接口
export const historySession = params => {
    return axios.post('/openai/api/history', params)
}
//获取ChatGPT模型
export const models = params => {
    return axios.post('/openai/api/models', params)
}

//发送验证码 没用
export const fetchResister = params => {
    return axios.post('/chat/api/account/resister', params)
}

// 发送图片请求 没用
export const sendImageGenerater = params => {
    return axios.post('/openai/api/imageGenerations', params)
}
//获取OSS config
export const getOssAssumeRoleToken = params => {
    return axios.post('/stableDiffusion/api/web/oss/getOssAssumeRoleToken', params)
}
//未知 没用
export const getResolutionList = params => {
    return axios.post('stableDiffusion/api/web/draw/getResolutionList', params)
}
//训练模型-
export const getModelList = params => {
    return axios.post('/stableDiffusion/api/web/draw/getModelList', params)
}
//未知 没用
export const sizeList = params => {
    return axios.post('/stableDiffusion/api/web/draw/sizeList', params)
}
//不需要的元素列表
export const textualList = params => {
    return axios.post('/stableDiffusion/api/web/draw/textual', params)
}
//生图-创建生图任务-sd
export const stCreateTask = (params, signal) => {
    return axios.post('/stableDiffusion/api/web/draw/createTask', params, { signal: signal })
}
//官网生成图片 生图结果返回接口及当前进度-sd
export const stDrawTaskResult = (params, signal) => {
    return axios.post('/stableDiffusion/api/web/draw/drawTaskResult', params, { signal: signal })
}
//生图-联想关键词
export const stPromptAssociate = params => {
    return axios.post('/openai/api/stableDiffusion/web/draw/promptAssociate', params)
}
//官网生成图片 历史记录列表
export const historicalRecord = params => {
    return axios.post('/stableDiffusion/api/web/draw/historicalRecord', params)
}
//官网生成图片 历史记录列表-详情
export const historicalRecordDetail = params => {
    return axios.post('/stableDiffusion/api/web/draw/historicaldetail', params)
}
//历史记录删除-通用
export const historicalRecordDelete = params => {
    return axios.post('/stableDiffusion/api/web/draw/del/historicalRecord', params)
}

export const midJourneyCreateTask = params => {
    return axios.post('/midJourney/api/web/draw/createTask', params)
}

export const midJourneyDrawTaskResult = (params, signal) => {
    return axios.post('/midJourney/api/web/draw/drawTaskResult', params, { signal: signal })
}

export const midJourneyPromptAssociate = params => {
    return axios.post('/openai/api/midJourney/web/draw/promptAssociate', params)
}

export const midJourneyHistoricalStateRecord = params => {
    return axios.post('/stableDiffusion/api/web/draw/historicalStateRecord', params)
}

export const stHistoricalStateRecord = params => {
    return axios.post('/stableDiffusion/api/web/draw/drawTaskCurrent', params)
}

export const stTranslate = params => {
    return axios.post('/openai/api/translate', params)
}
export const stMulTranslate = params => {
    return axios.post('/openai/api/multi/translate', params)
}

export const sdModelListPublic = params => {
    return axios.post('/stableDiffusion/api/web/model/list/public', params)
}

export const sdModelListPrivate = params => {
    return axios.post('/stableDiffusion/api/web/model/list/private', params)
}

export const sdModelListType = params => {
    return axios.post('/stableDiffusion/api/web/model/system', params)
}

export const sdModelListModalPublic = params => {
    return axios.post('/stableDiffusion/api/web/draw/model/list/public', params)
}
export const sdVaeModelList = params => {
    return axios.post('/stableDiffusion/api/web/model/list/vae', params)
}

export const sdModelListModalPrivate = params => {
    return axios.post('/stableDiffusion/api/web/draw/model/list/private', params)
}

export const sdModelDefaultConfig = params => {
    return axios.post('/stableDiffusion/api/web/draw/getModelDefaultConfig', params)
}

export const noticeList = params => {
    return axios.post(`/chat/api/account/noticeList`, params)
}

export const sdLoraParentList = params => {
    return axios.post('/stableDiffusion/api/web/draw/model/lora/relevance', params)
}

export const sdHomeModelList = params => {
    return axios.post('/stableDiffusion/api/web/model/list/homepage', params)
}
