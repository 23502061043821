import TrainingModel from './TrainingModel'
import TrainInput from './TrainInput'
import TrainOutput from './TrainOutput'
export default function Index(params) {
    return (
        <div className="w-full flex flex-col items-center relative gap-4">
            {/* <div className="flex flex-row w-full gap-4">
                <button className="w-1/4 m-btn-base">Load Settings</button>
                <button className="w-1/4 m-btn-base">Save Settings</button>
                <button className="w-1/4 m-btn-base">Train</button>
                <button className="w-1/4 m-btn-base">Generate Ckpt</button>
            </div>
            <div>Select or create a model to begin.</div> */}
            <div className="w-full gap-4 flex flex-col lg:flex  lg:flex-row ">
                <TrainingModel></TrainingModel>
                <TrainInput></TrainInput>
                <TrainOutput></TrainOutput>
            </div>
        </div>
    )
}
