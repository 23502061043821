import { createTask, getWaterHistoryImageList, getWaterSimilarImageList, historicalRecord } from '@/api/newFunctionRequest'
import { RootState } from '@/store'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import { setShowLogin } from './AppSlice'
import { TabHeaderItem } from '@/pages/AIImage/TabHeader'
interface NFTaskProgressObj {
    date: string
    progress: number
    step: string
}
interface NFTaskObj {
    taskId: string
    taskStatus: number // 1-提问中，2-画图中，3-画图成功，0-任务中断，-1-任务异常
    height?: number
    width?: number
    imageList?: Array<string>
    progress?: NFTaskProgressObj
    taskErrorMessage?: string
    taskGroupId: string
    targetWidth?: Number
    targetHeight?: Number
    imageUrl?: string
}

interface NFImageItemObj {
    canU: number
    canV: number
    imagePath?: string
    imageUrl?: string
    taskId: string
    taskStatus: Number
    taskObj?: NFTaskObj
    width?: Number
    height?: Number
    targetWidth?: Number
    targetHeight?: Number
    taskErrorMessage?: string
    progress?: NFTaskProgressObj
}
interface NFImageRecordObj {
    createTime: string
    groupId: string
    groupTableId: number
    taskGroupId: string
    taskGroupStatus: number // 1-文生图/V画图中，2-U画图中，3-画图完成，-1-异常
    prompt: string
    taskResults: Array<NFImageItemObj>
    upscaleTaskProgress?: NFTaskProgressObj
    upscaleTaskResults?: Array<NFImageItemObj>
    targetWidth?: Number
    targetHeight?: Number
}

interface NFGroupTaskObj {
    taskGroupId: string
    taskGroupStatus: number //  1-文生图/V画图中，2-U画图中，3-画图完成，-1-异常
    taskResults: Array<NFImageItemObj>
    upscaleTaskProgress?: NFTaskProgressObj
    upscaleTaskResults?: Array<NFImageItemObj>
}

interface IWatersSimilarImage {
    canV: number
    taskId: string
    url: string
}

interface INewFunctionData {
    taskList: Array<NFImageItemObj>
    watersWorkbenchTask: NFImageRecordObj | undefined
    imageRecordList: Array<NFImageRecordObj>
    groupTaskList: Array<string>
    canGenerate: boolean // 是否让点生图
    canAnimate: boolean
    showRecord: boolean
    similarImages: IWatersSimilarImage[]
    communitySimilarImages: IWatersSimilarImage[]

    similarState: {
        page: number
        taskId?: string
    }
    communitySimilarState: {
        page: number
    }
    isLoadingImage: boolean
    showHistoricalRecordItem: number
    tabIndex: number

    similarGenerateCallback?: Function
}

const initialState: INewFunctionData = {
    taskList: [],
    watersWorkbenchTask: undefined,
    imageRecordList: [],
    groupTaskList: [],
    canGenerate: true,
    canAnimate: true,
    showRecord: false,
    similarImages: [],
    communitySimilarImages: [],
    similarState: {
        page: 1,
        taskId: undefined,
    },
    communitySimilarState: {
        page: 1,
    },
    tabIndex: 0,
    isLoadingImage: false,
    showHistoricalRecordItem: 0,
}
const newFunctionSlice = createSlice({
    name: 'newFunctionSlice',
    initialState,
    reducers: {
        resetWatersState(state, action) {
            return initialState
        },
        setSimilarGenerateCallback(state, action) {
            state.similarGenerateCallback = action.payload
        },
        setTabIndex(state, action) {
            state.tabIndex = action.payload
        },
        setIsLoadingImage(state, action) {
            state.isLoadingImage = action.payload
        },
        updateSimilarImageByIndex(state, action: { type: string; payload: { index: number; item: Partial<IWatersSimilarImage> } }) {
            for (const key of Object.keys(action.payload.item)) {
                state.similarImages[action.payload.index][key] = action.payload.item[key]
            }
        },
        setSimilarImages(state, action) {
            state.similarImages = action.payload
        },
        setSimilarState(state, action: { type: string; payload: Partial<INewFunctionData['similarState']> }) {
            for (const key of Object.keys(action.payload)) {
                state.similarState[key] = action.payload[key]
            }
        },
        updateCommunitySimilarImageByIndex(state, action: { type: string; payload: { index: number; item: Partial<IWatersSimilarImage> } }) {
            for (const key of Object.keys(action.payload.item)) {
                state.communitySimilarImages[action.payload.index][key] = action.payload.item[key]
            }
        },
        setCommunitySimilarImages(state, action) {
            state.communitySimilarImages = action.payload
        },
        setCommunitySimilarState(state, action: { type: string; payload: Partial<INewFunctionData['communitySimilarState']> }) {
            for (const key of Object.keys(action.payload)) {
                state.communitySimilarState[key] = action.payload[key]
            }
        },
        setShowRecord(state, action) {
            state.showRecord = action.payload
        },
        setTaskList(state, action) {
            state.taskList = action.payload
        },

        setCanGenerate(state, action) {
            state.canGenerate = action.payload
        },

        setCanAnimate(state, action) {
            state.canAnimate = action.payload
        },

        setGroupTaskList(state, action) {
            state.groupTaskList = action.payload
        },

        setImageRecordList(state, action) {
            state.groupTaskList = []
            state.imageRecordList = action.payload
            state.imageRecordList.map((imageRecordItem, imageRecordItemIndex) => {
                if (imageRecordItem.taskGroupStatus == 1 || imageRecordItem.taskGroupStatus == 2) {
                    state.groupTaskList.push(imageRecordItem.taskGroupId)
                }
                if (imageRecordItemIndex == 0) {
                    if (imageRecordItem.taskGroupStatus == 1 || imageRecordItem.taskGroupStatus == 2) {
                        state.watersWorkbenchTask = imageRecordItem
                    }
                }
            })
        },

        addImageRecord(state, action) {
            action.payload.map((imageRecordItem: NFImageRecordObj, historyItemIndex: number) => {
                if (imageRecordItem.taskGroupStatus == 1 || imageRecordItem.taskGroupStatus == 2) {
                    state.groupTaskList.push(imageRecordItem.taskGroupId)
                }
            })
            state.imageRecordList = [...state.imageRecordList, ...action.payload]
        },

        updateImageRecord(state, action) {
            action.payload.map((taskItem: NFGroupTaskObj) => {
                state.imageRecordList.map((recordItem: NFImageRecordObj, index) => {
                    if (taskItem.taskGroupId == recordItem.taskGroupId) {
                        state.imageRecordList[index].taskGroupStatus = taskItem.taskGroupStatus
                        state.imageRecordList[index].taskResults = taskItem.taskResults
                        state.imageRecordList[index].upscaleTaskResults = taskItem.upscaleTaskResults
                    }
                })
                if (taskItem.taskGroupStatus == 3 || taskItem.taskGroupStatus == -1 || taskItem.taskGroupStatus == 0) {
                    state.groupTaskList = state.groupTaskList.filter(taskingItem => {
                        return taskingItem !== taskItem.taskGroupId
                    })
                    if (state.watersWorkbenchTask?.taskGroupId == taskItem.taskGroupId) {
                        state.watersWorkbenchTask = undefined
                    }
                }
            })

            // setImageRecordList([...state.imageRecordList])
        },

        deleteImageRecord(state, action) {
            state.groupTaskList = state.groupTaskList.filter(item => {
                return action.payload.taskGroupId !== item
            })
            state.imageRecordList = state.imageRecordList.filter(item => {
                return action.payload.taskGroupId !== item.taskGroupId
            })
            if (state.watersWorkbenchTask?.taskGroupId == action.payload.taskGroupId) {
                state.watersWorkbenchTask = undefined
            }
        },

        setImageCanV(state, action) {
            // 设置是否可以V
            state.imageRecordList.map((recordItem: NFImageRecordObj, index) => {
                if (recordItem.taskGroupId == action.payload.taskGroupId) {
                    recordItem.taskResults[action.payload.imageItemIndex].canV = 0
                }
            })
        },

        deleteAllWatersImageRecord(state, action) {
            state.groupTaskList = []
            state.imageRecordList = []
            state.watersWorkbenchTask = undefined
        },
        setShowHistoricalRecordItem(state, action) {
            state.showHistoricalRecordItem = action.payload
        },
    },
    extraReducers: builder => {},
})

export const call_similarGenerateCallback = createAsyncThunk('newFunctionSlice/call_similarGenerateCallback', async (a: undefined, thunkApi) => {
    const similarGenerateCallback = (thunkApi.getState() as RootState).newFunctionSlice.similarGenerateCallback
    console.log(
        '🚀 ~ file: NewFunctionSlice.ts:256 ~ constcall_similarGenerateCallback=createAsyncThunk ~ similarGenerateCallback:',
        similarGenerateCallback,
    )
    similarGenerateCallback?.()
})

export const fetchWaters_similarImages = createAsyncThunk(
    'newFunctionSlice/fetchWaters_similarImages',
    async ({ taskId, page }: { taskId?: string; page: number }, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken

        if (page == 1) {
            thunkApi.dispatch(setSimilarImages([]))
        }

        const resp = await getWaterSimilarImageList({ authToken, page, taskId })
        if (resp.code == 1) {
            const images = resp.data.images

            thunkApi.dispatch(setSimilarState({ page, taskId }))
            if (page == 1) {
                thunkApi.dispatch(setSimilarImages(images))
            } else {
                const similarImages = (thunkApi.getState() as RootState).newFunctionSlice.similarImages
                thunkApi.dispatch(setSimilarImages([...similarImages, ...images]))
            }
        }
        return resp
    },
)

export const fetchWaters_similarAllImages = createAsyncThunk(
    'newFunctionSlice/fetchWaters_similarAllImages',
    async ({ page }: { taskId?: string; page: number }, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken

        const resp = await getWaterHistoryImageList({ authToken, page })
        if (resp.code == 1) {
            const images = resp.data.images

            thunkApi.dispatch(setCommunitySimilarState({ page }))
            if (page == 1) {
                thunkApi.dispatch(setCommunitySimilarImages(images))
            } else {
                const similarImages = (thunkApi.getState() as RootState).newFunctionSlice.communitySimilarImages
                thunkApi.dispatch(setCommunitySimilarImages([...similarImages, ...images]))
            }
        }
        return resp
    },
)

export const Waters_UVSButtonClick = createAsyncThunk(
    'newFunctionSlice/Waters_UVSButtonClick',
    async ({ type, authToken, taskId }: { type: number; authToken: string; taskId: string }, thunkApi) => {
        //type 2-V重绘 3-U放大 4-S推荐相似
        // thunkApi.dispatch(setImageCanV({ groupId: imageRecordObj.taskGroupId, imageItemIndex: index }))
        if (type == 2 || type == 3) {
            const res = await createTask({
                authToken,
                drawType: type,
                taskId: taskId,
            })
            if (res.code == 1) {
                const recordRes = await historicalRecord({ authToken, itemCount: 10, groupTableId: 0 })
                if (recordRes.code == 1) {
                    if (recordRes.data.groupResults) {
                        thunkApi.dispatch(setImageRecordList(recordRes.data.groupResults))
                        thunkApi.dispatch(setShowHistoricalRecordItem(0))
                    }
                    thunkApi.dispatch(setShowRecord(false))
                }
            } else if (res.code == 3501) {
                thunkApi.dispatch(setShowLogin(true))
            } else {
                message.error(res.message)
            }
        } else {
            thunkApi.dispatch(setShowHistoricalRecordItem(1))
            thunkApi.dispatch(setShowRecord(false))
            thunkApi.dispatch(fetchWaters_similarImages({ taskId: taskId, page: 1 }))
            thunkApi.dispatch(setTabIndex(1))
        }
    },
)

export default newFunctionSlice.reducer

export const {
    setTaskList,
    setImageRecordList,
    deleteImageRecord,
    updateImageRecord,
    addImageRecord,
    setImageCanV,
    setCanGenerate,
    setCanAnimate,
    deleteAllWatersImageRecord,
    setShowRecord,
    setSimilarImages,
    setSimilarState,
    updateSimilarImageByIndex,
    setIsLoadingImage,
    setShowHistoricalRecordItem,
    setCommunitySimilarImages,
    setCommunitySimilarState,
    updateCommunitySimilarImageByIndex,
    setTabIndex,
    resetWatersState,
    setSimilarGenerateCallback,
} = newFunctionSlice.actions
