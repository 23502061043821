import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, Redirect, useLocation, useParams } from 'react-router-dom'
import { fetchCheckAuthTokenValid, loadAppDetails } from './slices/AppSlice'
import { AppDispatch } from './store'
import { routes } from './router'
import Cookies from 'js-cookie'
import './App.css'
import { Head } from './components/Head'
import { getOverseaUrl, getSiteDomain } from './helpers'
import { trackEvent, trackInit, googleTrackEvent } from './lib/analyse'
import GlobalStorage from './lib/globalStorage'
import { AppProvider } from './providers/app'
trackInit()
function App() {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (GlobalStorage.get('auth_token')) {
            dispatch(fetchCheckAuthTokenValid({ authToken: GlobalStorage.get('auth_token') }))
        }
        dispatch(loadAppDetails({}))
        trackEvent('enterApp')
        googleTrackEvent('enterApp', 'enterApp')
    }, [])

    return (
        <div id="app">
            <Head title={getSiteDomain()} />
            <Switch>
                {routes.map(item => {
                    return <Route key={item.path} path={item.path} component={item.component} name={item.name} />
                })}
                <Redirect from="/" to="/tools" exact />
                <Redirect to="/404" />
            </Switch>
        </div>
    )
}

export default App
