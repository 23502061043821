import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios from '../api/index' //进行了二次封装了
import type { AxiosRequestConfig, AxiosError } from 'axios'

type ApiBaseError = { isCodeError: boolean; message: string; data: any }
const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' },
    ): BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
        },
        unknown,
        ApiBaseError
    > =>
    async ({ url, method, data, params }) => {
        try {
            const resp = await axios({ url: baseUrl + url, method, data, params })
            if (resp.code == 1) {
                return { data: resp.data }
            } else {
                return {
                    error: {
                        isCodeError: true,
                        message: resp.message,
                        data: resp,
                    },
                }
            }
        } catch (axiosError) {
            let err = axiosError as AxiosError
            return {
                error: {
                    isCodeError: false,
                    message: err.response?.message ?? err.message,
                    data: err.response?.data || err.message,
                },
                // error: {
                //     status: err.response?.status,
                //     data: err.response?.data || err.message,
                // },
            }
        }
    }

export const sdApi = createApi({
    baseQuery: axiosBaseQuery(),
    endpoints: builder => ({
        getSdRecordDetail: builder.query<any, { taskId: string; authToken?: string }>({
            query(arg) {
                return { url: '/stableDiffusion/api/web/draw/historicaldetail', method: 'POST', data: arg }
            },
            keepUnusedDataFor: 0,
        }),
        getSdRecordListByModelId: builder.query<any, { modelId: string; keyword: string; authToken?: string }>({
            query(arg) {
                return { url: '/stableDiffusion/api/web/model/draw/historicalRecord', method: 'POST', data: arg }
            },
            keepUnusedDataFor: 0,
        }),
    }),
})

export const { useLazyGetSdRecordDetailQuery, useLazyGetSdRecordListByModelIdQuery } = sdApi
