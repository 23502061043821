import OSS from 'ali-oss'
// const OSS = require('ali-oss')

export function uploadFiles2OSS(data) {
    const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        // region: 'oss-cn-hangzhou',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        // if(){
        //
        // }
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: data.securityToken,
        endpoint: window.sessionStorage.getItem('header-serve') == 'cloudflare' && data.bucket == 'model-mstai' ? 'oss-accelerate.aliyuncs.com' : 'oss-cn-hangzhou.aliyuncs.com',
        // cname: true,
        // refreshSTSToken: async () => {
        //     // 向您搭建的STS服务获取临时访问凭证。
        //     const refreshRes = await dispatch(getAcsForUploadFile())
        //     console.log('refreshSTSToken info=', refreshRes)
        //     if (refreshRes.payload.status === 0) {
        //         const refreshResData = refreshRes.payload.data
        //         return {
        //             accessKeyId: refreshResData.credentials.accessKeyId,
        //             accessKeySecret: refreshResData.credentials.accessKeySecret,
        //             stsToken: refreshResData.credentials.securityToken,
        //         }
        //     }
        // },
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 300000,
        // 填写Bucket名称。
        bucket: data.bucket,
    })
    return client
    const headers = {
        // 指定上传文件的类型。
        // 'Content-Type': 'text/html',
        // 指定该Object被下载时网页的缓存行为。
        // 'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        // 'Content-Disposition': 'oss_download.txt',
        // 指定该Object被下载时的内容编码格式。
        // 'Content-Encoding': 'UTF-8',
        // 指定过期时间。
        // 'Expires': 'Wed, 08 Jul 2022 16:57:01 GMT',
        // 指定Object的存储类型。
        // 'x-oss-storage-class': 'Standard',
        // 指定Object的访问权限。
        // 'x-oss-object-acl': 'private',
        // 设置Object的标签，可同时设置多个标签。
        // 'x-oss-tagging': 'Tag1=1&Tag2=2',
        // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
        // 'x-oss-forbid-overwrite': 'true',
    }
    // console.log('🚀 ~ file: uploadFiles.js:5 ~ uploadFiles2OSS ~ file:', file)
    // try {
    //     // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
    //     // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
    //     // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
    //     const result = await client.put(
    //         data.dataDir + file.name,
    //         file,
    //         //{headers}
    //     )
    //     console.log(result)
    // } catch (e) {
    //     console.log(e)
    // }
}

export function blobToBase64(blob, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
        callback(reader.result)
    })
    reader.readAsDataURL(blob)
}
