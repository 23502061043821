import { translateWords } from '@/api/apiCommon'
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const MyTranslationContext = createContext<Object | undefined>(undefined)

export function useTranslationField(field: string) {
    const { i18n } = useTranslation()
    return useMemo(() => {
        return i18n.language == 'zh' ? field : field + 'En'
    }, [i18n.language])
}
export const useMyTranslation: typeof useTranslation = (ns, options) => {
    const { t, i18n, ready } = useTranslation(ns, options)
    const translationObject = useContext(MyTranslationContext)

    const mT: typeof t = useCallback(
        (key: any) => {
            // console.log('🚀 ~ file: TranslationConfig.tsx:14 ~ useMyTranslation ~ translationObject:', translationObject)
            if (typeof key == 'string' && translationObject?.[key]) {
                const v = translationObject[key][i18n.language]
                if (v != undefined && v != null) {
                    return v
                }
            }

            return t(key)
        },
        [t, i18n, translationObject],
    )

    return { t: mT, i18n, ready } as any
}

let jsonData: Object | undefined = undefined
const fetchConfig = new Promise<Object>((resolve, reject) => {
    translateWords()
        .then(res => {
            if (res.code == 1) {
                resolve(res.data.wordTranslations)
            } else {
                reject(new Error(res.message))
            }
        })
        .catch(e => {
            reject(e)
        })
})

const promiseWarpper = new Promise<Object>((resolve, reject) => {
    fetchConfig
        .then(res => {
            jsonData = res
            // console.log('🚀 ~ file: TranslationConfig.tsx:54 ~ promiseWarpper ~ res:', res, jsonData)
            resolve(res)
        })
        .catch(e => {
            resolve({})
        })
})
const TranslationConfig: React.FC<PropsWithChildren> = props => {
    const [json, setJson] = useState<object | undefined>(undefined)

    useEffect(() => {
        fetchConfig.then(res => {
            setJson(res)
        })
    }, [])

    /*
    useEffect(() => {
        setJson(jsonData)
        console.log('🚀 ~ file: TranslationConfig.tsx:60 ~ useEffect ~ jsonData:', jsonData)
    }, [jsonData])

    if (!jsonData) {
        throw promiseWarpper
    }
    */

    return <MyTranslationContext.Provider value={json}>{props.children}</MyTranslationContext.Provider>
}

export default TranslationConfig
