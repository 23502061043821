import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../store'
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

function debounce(fn: Function, ms: number) {
    let timer: any
    return function (...args: any) {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
            timer = null
        }, ms)
    }
}
// export function useDebounce(fn: Function, time: number) {
//     return debounce(fn, time)
// }

export function useDebounce(fn: Function, delay: number, dep = []) {
    const { current } = useRef<{ fn: Function; timer: any }>({ fn, timer: null })
    useEffect(
        function () {
            current.fn = fn
        },
        [fn],
    )

    return useCallback(function f(...args) {
        if (current.timer) {
            clearTimeout(current.timer)
        }
        current.timer = setTimeout(() => {
            current.fn(...args)
        }, delay)
    }, dep)
}

export function useThrottle(fn: Function, delay: number, dep = []) {
    const { current } = useRef<{ fn: Function; timer: any }>({ fn, timer: null })
    useEffect(
        function () {
            current.fn = fn
        },
        [fn],
    )

    return useCallback(function f(...args) {
        if (!current.timer) {
            current.timer = setTimeout(() => {
                delete current.timer
            }, delay)
            current.fn(...args)
        }
    }, dep)
}

export function usePageState<T>(curPage: number, size: number, dataList: T[]) {
    return useMemo<[number, T[]]>(() => {
        const pageCount = Math.ceil(dataList.length / size)
        const start = (curPage - 1) * size
        const pageList = dataList.slice(start, start + size)
        return [pageCount, pageList ?? []]
    }, [curPage, size, dataList])
}

export function useScrollGesture(elementRef: RefObject<HTMLElement>, ignoreListeners: string[] = []) {
    useEffect(() => {
        const on = function (element: HTMLElement | Document, event: string, handler: any, options?: boolean | EventListenerOptions | undefined) {
            if (element && event && handler) {
                element.addEventListener(event, handler, options)
            }
        }

        const off = function (element: HTMLElement | Document, event: string, handler: any, options?: boolean | EventListenerOptions | undefined) {
            if (element && event) {
                element.removeEventListener(event, handler, options)
            }
        }

        let targetDrag = {
            isDown: false,
            coord: {
                x: 0,
                y: 0,
                startX: 0,
                startY: 0,
            },
            ignoreing: false,
        }

        let el = elementRef.current as HTMLElement

        const scrollMousedown = (event: MouseEvent) => {
            targetDrag.isDown = true
            targetDrag.coord.x = event.pageX
            targetDrag.coord.y = event.pageY
            targetDrag.coord.startX = event.pageX
            targetDrag.coord.startY = event.pageY
        }
        const scrollMouseup = (event: MouseEvent) => {
            if (targetDrag.isDown) {
                if (Math.abs(targetDrag.coord.x - targetDrag.coord.startX) > 5) {
                    targetDrag.ignoreing = true
                    setTimeout(() => {
                        targetDrag.ignoreing = false
                    }, 100)
                }
            }

            targetDrag.isDown = false
            targetDrag.coord.x = 0
            targetDrag.coord.y = 0
            targetDrag.coord.startX = 0
            targetDrag.coord.startY = 0
        }

        const scrollMousemove = (event: MouseEvent) => {
            let movX = targetDrag.coord.x - event.pageX
            targetDrag.coord.x = event.pageX
            if (targetDrag.isDown) {
                el.scrollLeft = el.scrollLeft + movX
            }
        }

        const ignoreAction = (event: MouseEvent) => {
            if (targetDrag.ignoreing) {
                event.stopPropagation()
            }
        }

        if (el) {
            on(el, 'mousedown', scrollMousedown, false)
            on(document, 'mouseup', scrollMouseup, false)
            on(el, 'mousemove', scrollMousemove, false)
            for (const listener of ignoreListeners) {
                on(el, listener, ignoreAction, false)
            }
        }
        return () => {
            if (el) {
                off(el, 'mousedown', scrollMousedown, false)
                off(document, 'mouseup', scrollMouseup, false)
                off(el, 'mousemove', scrollMousemove, false)
                for (const listener of ignoreListeners) {
                    off(el, listener, ignoreAction, false)
                }
            }
        }
    }, [elementRef.current])
}
