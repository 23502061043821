import React, { useCallback, useContext, useMemo, useState } from 'react'
import collectOnIcon from '../../../assets/collect_on.png'
import collectOffIcon from '../../../assets/collect_off.png'
import train_icon from '../../../assets/model_train_icon.png'
import upload_icon from '../../../assets/model_upload_icon.png'
import setTopOnIcon from '../../../assets/model/set_top_icon_on.png'
import setTopOffIcon from '../../../assets/model/set_top_icon_off.png'

import { useDispatch } from 'react-redux'
import { useAppSelector } from '@/hooks'
import { AppDispatch } from '@/store'
import { useMyTranslation } from '@/components/TranslationConfig/TranslationConfig'
import { ModelListModalContext, ModelListTab } from './ModelListModal'
import ParentDropdown from '../components/ParentDropdown'
import {
    IGenerateStateDiffusionState,
    IModelItem,
    ISelectedModelItem,
    replaceSd_selectedSubModel,
    setStableDiffusionState,
    upsertSd_selectedSubModel,
} from '@/slices/GenerateImageSlice'
import { useHistory } from 'react-router-dom'
import { fetchSd_setSelectedModel } from '@/slices/GeneratedImageThunk'

export interface ISdModelListModalItemProps {
    model: IModelItem
    index: number
}

const SdModelListModalItem: React.FC<ISdModelListModalItemProps> = props => {
    const { model, index } = props
    const dispatch = useDispatch<AppDispatch>()
    const state = useAppSelector(state => state.generateImageSlice.stableDiffusion)
    const { t } = useMyTranslation()
    const history = useHistory()

    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))

    const [parentOpen, setParentOpen] = useState(false)
    const { setOpen, modelId, extra } = useContext(ModelListModalContext)

    const textRender = useCallback(() => {
        const onlyLora = modelId ? true : false
        const showParent = model.modelType == 3 || model.modelType == 6

        function buttonRender() {
            if (onlyLora) {
                return (
                    <button
                        onClick={e => {
                            if (extra?.changeLoraIndex != undefined) {
                                dispatch(replaceSd_selectedSubModel({ index: extra.changeLoraIndex, model: { ...model, weight: '0.7' } }))
                            } else {
                                dispatch(upsertSd_selectedSubModel({ ...model, weight: '0.7' }))
                            }

                            if (setOpen) {
                                setOpen(false)
                            }
                        }}
                        className={`bg-btnBgColorTo flex-1 h-9 rounded-md mr-2`}
                    >
                        {t('generatedImage.Select')}
                    </button>
                )
            } else {
                if (showParent) {
                    return (
                        <ParentDropdown
                            modelId={model.modelId}
                            onSelectParent={parent => {
                                // lora
                                if (parent) {
                                    const selectedItem: ISelectedModelItem = {
                                        ...parent,
                                        subUsedModels: [{ ...model, weight: '0.7' }],
                                    }
                                    dispatch(fetchSd_setSelectedModel(selectedItem))
                                    // updateState({ selectedModelItem: selectedItem })
                                    if (setOpen) {
                                        setOpen(false)
                                    }
                                }
                            }}
                            onOpenChange={open => {
                                console.log('🚀 ~ file: SdModelListModalItem.tsx:39 ~ open:', open)
                                setParentOpen(open)
                            }}
                        >
                            <button className={`bg-btnBgColorTo flex-1 h-9 rounded-md mr-2`}>{t('generatedImage.Select')}</button>
                        </ParentDropdown>
                    )
                } else {
                    return (
                        <button
                            onClick={e => {
                                const selectedItem: ISelectedModelItem = {
                                    ...model,
                                    subUsedModels: state.selectedModelItem?.subUsedModels,
                                }

                                dispatch(fetchSd_setSelectedModel(selectedItem))
                                // updateState({ selectedModelItem: selectedItem })
                                if (setOpen) {
                                    setOpen(false)
                                }
                            }}
                            className={`bg-btnBgColorTo flex-1 h-9 rounded-md mr-2`}
                        >
                            {t('generatedImage.Select')}
                        </button>
                    )
                }
            }
        }

        return (
            <div className="block lg:absolute bottom-0 p-1 pb-0.5 w-full bg-gradient-to-t from-black/60 to-black/10">
                <div
                    className={`-ml-1 -mt-14 absolute p-2 w-full flex justify-around items-center  transition-opacity opacity-0 group-hover:opacity-100 ${
                        parentOpen ? 'opacity-100' : ''
                    } `}
                >
                    {buttonRender()}
                    <button
                        onClick={() => {
                            // console.log({ chooseModelType: state.modalState.chooseType })
                            // history.push('/tools/ModelList/ModelDetail/' + model.modelId, {
                            //     chooseModelType: state.modalState.chooseType,
                            // })
                            window.open('/tools/ModelList/ModelDetail/' + model.modelId, '_blank')
                        }}
                        className="bg-btnBgColorFrom flex-1 h-9 rounded-md"
                    >
                        {t('generatedImage.Detail')}
                    </button>
                </div>
                <div className="text-white text-sm text-ellipsis line-clamp-2 mb-0.5">{model.modelName}</div>
                <div className="flex flex-wrap text-xs">
                    <div className="m-bg-gradient rounded py-0.5 px-2 m-0.5">{model.modelTypeName}</div>
                </div>
                <div className="flex flex-wrap text-xs">
                    {model.categoryItemList.map((c, i) => (
                        <div key={i} className="bg-white/30 rounded py-0.5 px-2 m-0.5">
                            {t(c.modelCateName)}
                        </div>
                    ))}
                </div>
            </div>
        )
    }, [model, parentOpen, state.modalState.chooseType])

    const markElement = useMemo(() => {
        function collectRender(isCollect) {
            // console.log('🚀 ~ file: SdModelListContentItem.tsx:44 ~ collectRender ~ isCollect:', isCollect)
            return (
                <div className="flex">
                    {isCollect && (
                        <button
                            className="bg-black/50 rounded h-10 w-10 flex justify-center items-center"
                            onClick={e => {
                                e.stopPropagation()
                                // e.nativeEvent.stopImmediatePropagation()
                                // if (isCollect) {
                                //     dispatch(fetchSd_modelCollect({ modelId: model.modelId, type: 2 }))
                                // } else {
                                //     dispatch(fetchSd_modelCollect({ modelId: model.modelId, type: 1 }))
                                // }
                            }}
                        >
                            <img className="w-7" src={isCollect ? collectOnIcon : collectOffIcon} alt="collect" />
                        </button>
                    )}
                </div>
            )
        }

        function markRender(img: string) {
            return (
                <div className="flex -mt-1.5">
                    <img className="w-8" src={img} alt="train" />
                </div>
            )
        }

        function rightOne() {
            if (model.accountModelType == '1') {
                // 自己
                if (model.channel == 2) {
                    //上传
                    return markRender(upload_icon)
                } else if (model.channel == 3) {
                    //训练
                    return markRender(train_icon)
                } else {
                    return collectRender(true)
                }
            } else if (model.accountModelType == '2') {
                // 收藏
                return collectRender(true)
            } else {
                return collectRender(false)
            }
        }

        return (
            <div className="absolute top-1.5 right-1.5 flex items-start space-x-1">
                {model.isRecommend == 1 && <span className="m-bg-gradient px-2 rounded-full text-sm">{t('generatedImage.Recommended')}</span>}
                {rightOne()}
            </div>
        )
    }, [model, index])

    function setTopRender(isTop) {
        // console.log('🚀 ~ file: SdModelListContentItem.tsx:44 ~ collectRender ~ isCollect:', isCollect)
        return (
            <div className="absolute top-1.5 left-1.5 flex space-x-1">
                {isTop && (
                    <button
                        className="rounded h-10 w-10 flex justify-center items-center"
                        // onClick={e => {
                        //     e.stopPropagation()
                        //     // e.nativeEvent.stopImmediatePropagation()
                        //     if (isTop) {
                        //         dispatch(fetchSd_modelTop({ modelId: model.modelId, type: 2, index: index }))
                        //     } else {
                        //         dispatch(fetchSd_modelTop({ modelId: model.modelId, type: 1, index: index }))
                        //     }
                        // }}
                    >
                        <img className="w-full" src={isTop ? setTopOnIcon : setTopOffIcon} alt="collect" />
                    </button>
                )}
            </div>
        )
    }

    return (
        <div className="w-0">
            <div className="group relative rounded-lg bg-gray-bg overflow-hidden">
                <div className={`bg-black opacity-0 group-hover:opacity-60 absolute h-full w-full ${parentOpen ? 'opacity-60' : ''} `}></div>
                <div className="hidden lg:block">{textRender()}</div>
                {/* 标签 */}
                {markElement}
                {state.modalTab?.tab == ModelListTab.Private && setTopRender(model.isTop == 1)}
                <img
                    src={model.modelIcon + '?x-oss-process=image/resize,w_700/quality,Q_80'}
                    className="rounded-lg lg:rounded-none block cursor-pointer w-full"
                    alt=""
                />
                <div className="block lg:hidden">{textRender()}</div>
            </div>
        </div>
    )
}

export default SdModelListModalItem
