import logo from '../../assets/icon.png'
import { getOverseaUrl } from '@/helpers'

function Index(params) {
    return (
        <main>
            <div className="w-full relative overflow-auto mx-auto bg-black text-white">
                <nav className=" fixed w-full left-0 top-0 z-50 flex items-center justify-center border-b border-b-[#2a2929] bg-black px-5">
                    <div className="w-full max-w-7xl flex ">
                        <a href="/" className="flex items-center flex-row py-[20px]">
                            <img src={logo} className="w-[40px] " alt="" />
                            <div className="px-3 text-2xl font-extrabold">MINISTERAI</div>
                        </a>
                    </div>
                </nav>
                <div className=" mt-[40px] flex flex-col items-center p-5">
                    <h1 className=" mx-auto mt-[50px]">Terms of Service</h1>
                    <div className="mx-auto py-10">
                        <p className="w-full max-w-7xl py-5 font-light">Last updated: April 24, 2023</p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            These Terms of Use (the “Terms”) govern your use of the website and application located at{' '}
                            {getOverseaUrl() ? <a href="https://mst.ai/">https://mst.ai/</a> : <a href="https://mst.xyz/">https://mst.xyz/</a>} and
                            other sites and subdomains (collectively, “MINISTERAI” or the “Website”) owned and operated by MINISTERAI .
                        </p>

                        <p className="w-full max-w-7xl py-5 font-light">
                            By accessing the Website, you agree to abide by these Terms and to comply with all applicable laws and regulations. We
                            reserve the right to review and amend any of these Terms at our sole discretion and update this page from time to time.
                            Any change to these Terms will take effect immediately from the date of publication. If you do not agree with these Terms,
                            you are prohibited from using or accessing our Website or using any other services provided by us.
                        </p>

                        <p className="w-full max-w-7xl py-5 font-light">
                            PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE WEBSITE EACH TIME YOU USE THE WEBSITE. THESE TERMS GOVERN YOUR USE OF
                            THE WEBSITE UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO MAKE
                            THE WEBSITE AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. BY USING THE WEBSITE, YOU ARE CONFIRMING YOU UNDERSTAND AND
                            AGREE TO BE BOUND BY ALL OF THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY,
                            YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU” WILL
                            MEAN THAT ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE UNWILLING TO
                            MAKE THE WEBSITE AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE WEBSITE. NOTE THAT
                            THESE TERMS MAY BE UPDATED AT ANY TIME, WITHOUT NOTIFYING YOU AND BY AGREEING TO THESE TERMS NOW, YOU AGREE THAT YOUR
                            CONTINUED USAGE OF THIS SERVICE CONFIRMS YOUR ONGOING AGREEMENT TO ANY UPDATED TERMS. PLEASE ENSURE THAT YOU READ THE
                            TERMS OF SERVICE EACH TIME YOU USE THE SERVICE AND DISCONTINUE USAGE IF YOU NO LONGER AGREE TO ANY UPDATED TERMS.
                        </p>

                        <h2 className="py-5">1. ACCOUNT & REGISTRATION</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            You must be 18 years or older and able to form a binding contract with us to use the products and services offered on the
                            Website, including our application programming interface, software, tools, developer services, data, and documentation
                            (collectively, the “Services”). If you use the Services on behalf of another person or entity, you must have the authority
                            to accept the Terms on their behalf. You must provide accurate and complete information to register for an account. You
                            may not make your access credentials or account available to others outside your organization, and you are responsible for
                            all activities that occur using your credentials.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">You may use our Services for free (a “Free Tier Account”)</p>

                        <h2 className="py-5">2. SERVICES</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            MINISTERAI provides you with tools and services to create game assets, such as items, environments, helmets, and
                            buildings, with AI and craft worlds in minutes. You may use a general or fine-tuned existing model to general all sorts of
                            production-ready art assets. You can also train your own AI model and generate thousands of variations and deviations from
                            your training data, or from models on the platform that others have trained.
                        </p>

                        <h2 className="py-5">3. CONTENT</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            a) Your Content. You may provide input to the Services (“Input”), and receive output generated and returned by the
                            Services based on the Input (“Output”). Input and Output are collectively “Content.” As between the parties and to the
                            extent permitted by applicable law, you own all Input, and subject to your compliance with these Terms, MINISTERAI hereby
                            assigns to you all its right, title and interest in and to Output.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            b) For free tier users on the platform (whose images are public by default), MINISTERAI and its successors own the rights
                            to use, copy, reproduce, process, adapt, modify, publish, transmit, create derivative works of, publicly display, publicly
                            perform, sublicense, and distribute text prompts and images you input into the services, or any assets produced by the
                            service at your direction. MINISTERAI has the rights to make the assets available to the public, use them commercially,
                            including for the purposes of providing, maintaining, promoting, and improving the services. Free tier users are granted a
                            worldwide, non-exclusive, royalty-free license by MINISTERAI to access the assets they create using the service for their
                            own commercial use.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            c) For all plan tiers and generation types, MINISTERAI may use Content as necessary to provide and maintain the Services,
                            comply with applicable law, and enforce our policies. You are responsible for Content, including for ensuring that it does
                            not violate any applicable law or these Terms. This license granted to MINISTERAI remains in effect even if this agreement
                            is terminated by any party for any reason.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            d) Similarity of Content. Due to the nature of machine learning, Output may not be unique across users and the Services
                            may generate the same or similar output for MINISTERAI or a third party.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            e) Use of Content to Improve Services. One of the main benefits of machine learning models is that they can be improved
                            over time. To help MINISTERAI provide and maintain the Services, you understand and agree that we may use your Content or
                            details around your platform usage to develop and improve the Services. If the privacy toggle is engaged on the
                            Generations page, any ‘Input’ or ‘Output’ provided by a user is NOT subject to granting MINISTERAI and its successors the
                            right to the Use of Content to Improve Services.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            f) Use of Content for Advertising Purposes. For any images or models not set to private, you agree to grant us a
                            non-exclusive, non-transferable, non-sublicensable license to use your Content for advertising purposes, including, but
                            not limited to, posting your Content on our Website.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            g) Any Output that is not set to private may be visible and usable by MINISTERAI or other users on the platform in
                            derivative or transformative ways.
                        </p>
                        <h2 className="py-5">4. DMCA TAKEDOWN NOTICE</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            MINISTERAI will take down works in response to Digital Millennium Copyright Act (“DMCA”) takedown notices and/or other
                            intellectual property infringement claims and will terminate a user’s access to the Website if the user is determined to
                            be a repeat infringer. If you believe that your content has been copied in a way that constitutes copyright or trademark
                            infringement, or violates your publicity or other intellectual property rights, you may submit a takedown notice to us at
                            contact@mst.xyz. Should we update, amend or make any changes to this document, those changes will be prominently posted
                            here.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            For us to process your infringement claim regarding content on the Website, you must be the rightsholder or someone
                            authorized to act on behalf of the rightsholder. Your notice must include:
                        </p>

                        <ul className="w-full max-w-7xl py-5 font-light list-disc list-inside">
                            <li>
                                Identification of the copyrighted work(s), trademark, publicity rights, or other intellectual property rights that you
                                claim are being infringed;
                            </li>

                            <li>
                                Identification of the allegedly infringing material that is requested to be removed, including a description of the
                                specific location (i.e., URLs) on the Website of the material claimed to be infringing, so that we may locate the
                                material;
                            </li>
                            <li>
                                Your contact information – at a minimum, your full legal name (not a pseudonym) and email address;
                                <ul className="list-[circle] list-inside pl-6">
                                    <li>
                                        A statement that you have a good faith belief that the use of the material in the manner complained of is not
                                        authorized by the intellectual property rights owner, its agent, or the law;
                                    </li>
                                    <li>A statement that the information in the notice is accurate; and</li>
                                    <li>
                                        A statement under penalty of perjury that you are authorized to act on behalf of the intellectual property
                                        owner of the intellectual property that is allegedly being infringed.
                                    </li>
                                </ul>
                            </li>

                            <li>Your physical or electronic signature (of your full legal name).</li>
                        </ul>
                        <p className="w-full max-w-7xl py-5 font-light">
                            Please note that we will forward your notice of intellectual property infringement, including your contact information, to
                            the party who will have their content removed so they understand why it is no longer available on our Website and can also
                            contact you to resolve any dispute.
                        </p>
                        <h2 className="py-5">5. INTELLECTUAL PROPERTY RIGHTS</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            We grant you a non-exclusive, non-transferable, non-sublicensable, revocable license to use the Website for both personal
                            and commercial uses, as provided in these Terms. The Website and any related contents, features, and functionality,
                            including but not limited to, all information, software, products, materials, APIs, text, displays, images, video, and
                            audio, and the design, selection, and arrangement thereof, are owned by the Company, its licensors, or other providers of
                            such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or
                            proprietary rights laws. For the avoidance of doubt, rights, titles and interests in and to users’ Content are subject to
                            and governed by Section 3 above.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            The following section refers to proprietary designs, UX, UI, and methodologies of the MINISTERAI platform itself – not the
                            generative content you produce using the platform. As per Section 3, we assign to you all its right, title and interest in
                            content that you as a user produce on the MINISTERAI platform.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            You must not reproduce, modify, create derivative works of, republish, download, store, or transmit any of the proprietary
                            material on our Website (this refers to our UX, UI, process methodologies and other aspects of the platform) as follows:
                        </p>
                        <ul className="w-full max-w-7xl py-5 font-light list-disc list-inside">
                            <li>You may share content created by other users on social media.</li>
                            <li>
                                Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those
                                materials.
                            </li>
                            <li>You may store files that are automatically cached by your web browser for display enhancement purposes.</li>

                            <li>
                                You may print or download one copy of a reasonable number of pages of the Website for your own personal,
                                non-commercial use and not for further reproduction, publication, or distribution.
                            </li>

                            <li>
                                If we provide desktop, mobile, or other applications for download, you agree to be bound by our end user license
                                agreement for such applications..
                            </li>
                        </ul>
                        <p className="w-full max-w-7xl py-5 font-light">
                            If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website
                            in breach of the Terms, your right to use the Website will stop immediately and you must, at our option, return or destroy
                            any copies of the materials you have made. No right, title, or interest in or to the Website or any content on the Website
                            is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not
                            expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other laws.
                        </p>
                        <h2 className="py-5">6. TRADEMARKS</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            The Company name, the Company logo, and all related names, logos, product and service names, designs, and slogans are
                            trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission
                            of the Company. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks
                            of their respective owners.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            All other third-party trademarks, registered trademarks, and product names mentioned on the Website are the property of
                            their respective owners and may not be copied, imitated or used, in whole or in part, without the permission of the
                            applicable intellectual property rights holder. Reference to any products, services, processes or other information by
                            name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship, or
                            recommendation by MINISTERAI or the Company.
                        </p>
                        <h2 className="py-5">7. LIMITATIONS OF USE</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            By using the Website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:
                        </p>
                        <ul className="w-full max-w-7xl py-5 font-light list-disc list-inside">
                            <li>
                                modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on the
                                Website;
                            </li>

                            <li>
                                modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on the
                                Website;
                            </li>
                            <li>remove any copyright or other proprietary notations from any materials and software on the Website;</li>
                            <li>transfer the materials to another person or “mirror” the materials on any other server;</li>
                            <li>
                                knowingly or negligently use this website or any of its associated services in a way that abuses, disrupts, or
                                interferes with our networks or any other service the Company provides;
                            </li>
                            <li>
                                use the Website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent,
                                unlawful, or harmful material;
                            </li>
                            <li>
                                use the Website or its associated services in violation of any applicable local, state, national, or international
                                laws or regulations;
                            </li>
                            <li>use the Website in conjunction with sending unauthorised advertising, spam, or solicitation;</li>
                            <li>harvest, collect, or gather user data without the user’s express consent;</li>
                            <li>
                                use the Website or its associated services in such a way that may infringe the privacy, intellectual property rights,
                                or other rights of third parties;
                            </li>
                            <li>
                                generate content that includes impersonations of any real person or falsely portrays an individual in a misleading or
                                defamatory way;
                            </li>
                            <li>
                                generate content that includes depictions of sexual abuse, sexual violence, explicit pornography, or any form of
                                non-consensual acts;
                            </li>
                            <li>generate content that includes depictions of child nudity, child pornography, or any form of child exploitation;</li>
                            <li>generate content that includes depictions of acts that involve non-consent, sexual or otherwise;</li>
                            <li>
                                generate content that includes depictions of animal cruelty or harm to animals, including acts of violence or torture;
                            </li>
                            <li>generate content that includes explicit depictions of gore or extreme violence;</li>
                            <li>
                                generate content that promotes or incites hatred, violence, discrimination, or harm based on race, ethnicity,
                                religion, gender, sexual orientation, or any other protected characteristic;
                            </li>
                            <li>
                                generate content that intentionally endangers or threatens the safety, security, or well-being of any individual or
                                group, or;
                            </li>
                            <li>
                                generate content that, in the sole discretion of the MINISTERAI team, is deemed unsuitable, obscene, offensive, or
                                contrary to community standards and user expectations.
                            </li>
                        </ul>
                        <p className="w-full max-w-7xl py-5 font-light">
                            Any violation of these terms may result in immediate termination of your access to the Website, and the Company may take
                            further action, including reporting violations to law enforcement authorities, as deemed appropriate. The Company reserves
                            the right to block, restrict, or delete content that violates these terms, in its sole discretion, without prior notice or
                            liability.
                        </p>
                        <h2 className="py-5">8. TERM AND TERMINATION</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            These Terms shall remain in full force and effect so long as you access and use our Website. You have the right to
                            terminate your use of our Website at any time and for any or no reason. We may terminate your access to or use of the
                            Website at any time without prior notice if you breach any of these Terms or take any suspected fraudulent, abusive, or
                            illegal activity, and termination of your access will be in addition to any other remedies we may have in law or equity.
                        </p>
                        <h2 className="py-5">9. DISCLAIMERS</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            You expressly understand and agree that your access to and use of the Website is at your sole risk, and that the Website
                            is provided “as is” and “as available” without warranties of any kind, whether express or implied. To the fullest extent
                            permissible pursuant to applicable law, we, our subsidiaries, affiliates, and licensors make no express warranties and
                            hereby disclaim all implied warranties regarding the Website and any part of it (including, without limitation, the site,
                            any smart contract, or any external websites), including the implied warranties of merchantability, fitness for a
                            particular purpose, non-infringement, correctness, accuracy, or reliability. Without limiting the generality of the
                            foregoing, we, our subsidiaries, affiliates, and licensors do not represent or warrant to you that: (i) your access to or
                            use of the Website will meet your requirements, (ii) your access to or use of the Website will be uninterrupted, timely,
                            secure or free from error, (iii) usage data provided through the Website will be accurate, (iii) the Website or any
                            content, services, or features made available on or through the app are free of viruses or other harmful components, or
                            (iv) that any data that you disclose when you use the Website will be secure. Some jurisdictions do not allow the
                            exclusion of implied warranties in contracts with consumers, so some or all of the above exclusions may not apply to you.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            You accept the inherent security risks of providing information and dealing online over the internet and agree that we
                            have no liability or responsibility for any breach of security unless it is due to our gross negligence.
                        </p>
                        <h2 className="py-5">10. LIMITATION OF LIABILITY</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE
                            PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING
                            OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE
                            WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING
                            BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF OPPORTUNITY, LOSS OF
                            REPUTATION, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
                            WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE WEBSITE AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE
                            WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK
                            BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. WE WOULD NOT BE ABLE TO PROVIDE THE WEBSITE TO
                            YOU WITHOUT THESE LIMITATIONS.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                        </p>
                        <h2 className="py-5">11. INDEMNIFICATION</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless the Company and its past,
                            present and future employees, partners, contractors, consultants, suppliers, vendors, service providers, agents,
                            representatives, predecessors, successors and assigns (the “Indemnified Parties”) from and against all claims, demands,
                            actions, damages, losses, costs and expenses (including attorneys’ fees) that arise from or relate to: (i) your use of the
                            Website or any services, (ii) your responsibilities or obligations under these Terms, (iii) your violation of these Terms,
                            (iv) your violation of any applicable laws or regulations, or (v) your violation of any rights of any other person or
                            entity. You expressly agree that this extends to any Inputs you upload to the platform, and that you have the rights to
                            utilize these Inputs with the Services we provide.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            The Company reserves the right to exercise sole control over the defense, at your expense, of any claim subject to
                            indemnification. This indemnity is in addition to, and not in lieu of, any other indemnities set forth in a written
                            agreement between you and the Company.
                        </p>
                        <h2 className="py-5">12. RELIANCE ON INFORMATION POSTED</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            The information presented on or through the Website is made available solely for general information purposes. We do not
                            warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is
                            strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials
                            by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.
                        </p>
                        <p className="w-full max-w-7xl py-5 font-light">
                            This Website includes content provided by third parties, including materials provided by other users, bloggers, and
                            third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in
                            these materials, and all articles and responses to questions and other content, other than the content provided by the
                            Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials
                            do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for
                            the content or accuracy of any materials provided by any third parties.
                        </p>
                        <h2 className="py-5">13. LINKS FROM THIS WEBSITE</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            If the Website contains links to other sites and resources provided by third parties, these links are provided for your
                            convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We
                            have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or
                            damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this
                            Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.
                        </p>
                        <h2 className="py-5">14. SEVERANCE</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            Any term of these Terms which is wholly or partially void or unenforceable is severed to the extent that it is void or
                            unenforceable. The validity of the remainder of these Terms is not affected.
                        </p>
                        <h2 className="py-5">15. DISPUTE RESOLUTION; ARBITRATION</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            PLEASE READ THIS SECTION CAREFULLY. IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT
                            IN COURT. ALL DISPUTES ARISING OUT OF OR RELATED TO THESE TERMS OF USE OR ANY ASPECT OF THE RELATIONSHIP BETWEEN YOU AND
                            US, WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION, OR ANY OTHER LEGAL THEORY, WILL BE RESOLVED
                            THROUGH FINAL AND BINDING ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE OR JURY, AND YOU AGREE
                            THAT THE COMPANY AND YOU ARE EACH WAIVING THE RIGHT TO SUE IN COURT AND TO HAVE A TRIAL BY A JURY. YOU AGREE THAT ANY
                            ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE
                            AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION.
                        </p>
                        <h2 className="py-5">16. NO CLASS ACTION</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            YOU AND THE COMPANY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS
                            A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
                        </p>
                        <h2 className="py-5">17. PRIVACY POLICY</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            Our
                            <a href="/privacyPolicy" target="_blank">
                                Privacy Policy
                            </a>
                            explains how we collect and use personal information.
                        </p>
                        <h2 className="py-5">18. CONTACT US</h2>
                        <p className="w-full max-w-7xl py-5 font-light">
                            All feedback, comments and questions on the Terms should be sent to &nbsp;
                            <a href="mailto:contact@mst.xyz" target="_blank">
                                contact@mst.xyz
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Index
