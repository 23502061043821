/*
 * @Author: yunpeng.li yunpeng.li@sdbean.com
 * @Date: 2023-04-17 13:51:35
 * @LastEditors: yunpeng.li yunpeng.li@sdbean.com
 * @LastEditTime: 2023-04-24 09:32:31
 * @FilePath: /chatgpt-web/src/pages/AIImage/IntegerSlider.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react'
import { Col, InputNumber, Row, Slider } from 'antd'

export interface IIntegerSliderProps {
    className: string
    min: number
    max: number
    title: 'W' | 'H'
    value: number
    onChange: (newValue: number) => void
}
const IntegerSlider: React.FC<IIntegerSliderProps> = props => {
    return (
        <Row className={props.className}>
            <Col span={16}>
                <div className="mr-1">
                    <Slider
                        min={props.min}
                        max={props.max}
                        step={1}
                        onChange={props.onChange}
                        value={typeof props.value === 'number' ? props.value : 0}
                        trackStyle={{ background: 'linear-gradient(to right, #786CFD,#F56DBC)' }}
                        railStyle={{ backgroundColor: 'black' }}
                    />
                </div>
            </Col>
            <Col span={8}>
                <div className="p-1 rounded-md bg-black text-white/90 flex justify-center items-center">
                    <span className="text-xs">{props.title}</span>
                    <InputNumber
                        className="-mx-1 m-ant-input-center sm:text-xs"
                        bordered={false}
                        controls={false}
                        value={props.value}
                        onChange={e => props.onChange(Number(e))}
                        min={props.min}
                        max={props.max}
                        maxLength={props.max.toString().length}
                    />
                    <span className="text-xs text-gray-400">px</span>
                </div>
            </Col>
        </Row>
    )
}

export default IntegerSlider
