import React, { useCallback, useMemo } from 'react'
import collectOnIcon from '../../assets/collect_on.png'
import collectOffIcon from '../../assets/collect_off.png'
import setTopOnIcon from '../../assets/model/set_top_icon_on.png'
import setTopOffIcon from '../../assets/model/set_top_icon_off.png'

import train_icon from '../../assets/model_train_icon.png'
import upload_icon from '../../assets/model_upload_icon.png'

import { useDispatch } from 'react-redux'
import {
    DetailType,
    ISdModelListItem,
    fetchModelCollect,
    fetchModelDetail,
    fetchModelSetTop,
    setDetailModelId,
    setDetailType,
} from '@/slices/sdModelListSlice'
import { useAppSelector } from '@/hooks'
import { ModelListTab } from '../AIImage/modal/ModelListModal'
import { AppDispatch } from '@/store'
import { useMyTranslation } from '@/components/TranslationConfig/TranslationConfig'
import { modelDetailApi } from './api/apiModel'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'

export interface ISdModelListContentItemProps {
    model: ISdModelListItem
    index: number
}

const SdModelListContentItem: React.FC<ISdModelListContentItemProps> = props => {
    const { model, index } = props
    const dispatch = useDispatch<AppDispatch>()
    const state = useAppSelector(state => state.sdModelListSlice)
    const { t } = useMyTranslation()
    const authToken = useAppSelector(state => state.app && state.app.authToken)
    const history = useHistory()

    const textRender = useCallback(
        () => (
            <div className="block lg:absolute bottom-0 p-1 pb-0.5 w-full bg-gradient-to-t from-black/60 to-black/10">
                <div className="text-white text-sm text-ellipsis line-clamp-2 mb-0.5">{model.modelName}</div>
                <div className="flex flex-wrap text-xs">
                    <div className="m-bg-gradient rounded py-0.5 px-2 m-0.5">{model.modelTypeName}</div>
                </div>
                <div className="flex flex-wrap text-xs">
                    {model.categoryItemList.map((c, i) => (
                        <div key={i} className="bg-white/30 rounded py-0.5 px-2 m-0.5">
                            {t(c.modelCateName)}
                        </div>
                    ))}
                </div>
            </div>
        ),
        [model],
    )

    const markElement = useMemo(() => {
        function collectRender(isCollect) {
            // console.log('🚀 ~ file: SdModelListContentItem.tsx:44 ~ collectRender ~ isCollect:', isCollect)
            return (
                <div className="absolute top-1.5 right-1.5 flex space-x-1">
                    <button
                        className={`${isCollect ? 'flex' : 'hidden group-hover:flex'}  bg-black/50 rounded h-10 w-10 justify-center items-center`}
                        onClick={e => {
                            e.stopPropagation()
                            // e.nativeEvent.stopImmediatePropagation()
                            if (isCollect) {
                                dispatch(fetchModelCollect({ modelId: model.modelId, type: 2, index: index }))
                            } else {
                                dispatch(fetchModelCollect({ modelId: model.modelId, type: 1, index: index }))
                            }
                        }}
                    >
                        <img className="w-7" src={isCollect ? collectOnIcon : collectOffIcon} alt="collect" />
                    </button>
                </div>
            )
        }

        function markRender(img: string) {
            return (
                <div className="absolute top-0 right-3 flex space-x-1">
                    <img className="w-8" src={img} alt="train" />
                </div>
            )
        }

        if (model.accountModelType == '1') {
            // 自己
            if (model.channel == 2) {
                //上传
                return markRender(upload_icon)
            } else if (model.channel == 3) {
                //训练
                return markRender(train_icon)
            } else {
                return collectRender(true)
            }
        } else if (model.accountModelType == '2') {
            // 收藏
            return collectRender(true)
        } else {
            return collectRender(false)
        }
    }, [model, index])

    function setTopRender(isTop) {
        // console.log('🚀 ~ file: SdModelListContentItem.tsx:44 ~ collectRender ~ isCollect:', isCollect)
        return (
            <div className="absolute top-1.5 left-1.5 flex space-x-1">
                <button
                    className="rounded h-10 w-10 flex justify-center items-center"
                    onClick={e => {
                        e.stopPropagation()
                        // e.nativeEvent.stopImmediatePropagation()
                        if (isTop) {
                            dispatch(fetchModelSetTop({ modelId: model.modelId, type: 2, index: index }))
                        } else {
                            dispatch(fetchModelSetTop({ modelId: model.modelId, type: 1, index: index }))
                        }
                    }}
                >
                    <img className="w-full" src={isTop ? setTopOnIcon : setTopOffIcon} alt="collect" />
                </button>
            </div>
        )
    }

    return (
        <div
            className="w-0"
            onClick={() => {
                // dispatch(fetchModelDetail({ modelId: model.modelId }))
                // history.push('/tools/ModelList/ModelDetail/' + model.modelId)
                window.open('/tools/ModelList/ModelDetail/' + model.modelId, '_blank')
            }}
        >
            <div
                className="group relative rounded-lg bg-gray-bg overflow-hidden"
                // key={model.pathUrl}
                // onClick={() => {
                //     //  showPreview(true, index)
                // }}
            >
                <div className="hidden lg:block">{textRender()}</div>
                {/* 标签 */}
                {markElement}
                {state.curTab?.tab == ModelListTab.Private && setTopRender(model.isTop == 1)}
                <img
                    src={model.modelIcon + '?x-oss-process=image/resize,w_700/quality,Q_80'}
                    className="rounded-lg lg:rounded-none block cursor-pointer w-full"
                    alt=""
                />
                <div className="block lg:hidden">{textRender()}</div>
            </div>
        </div>
    )
}

export default SdModelListContentItem
