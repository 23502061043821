import React from 'react'
import { Dropdown, Space } from 'antd'
import type { MenuProps } from 'antd'
import arrow_down_icon from '../../assets/arrow_down_icon.png'
import { useMyTranslation } from '@/components/TranslationConfig/TranslationConfig'

export type DropdownListData = { name: string; data?: any }
export interface IDropdownListProps {
    value?: DropdownListData
    onClickHandle: (item: DropdownListData) => void
    cellData: DropdownListData[]
    className?: string
}
const DropdownList: React.FC<IDropdownListProps> = ({ cellData, className, onClickHandle, value }) => {
    const { t } = useMyTranslation()
    const items: MenuProps['items'] = cellData.map((data, i) => {
        return {
            key: String(i),
            label: <button className={'h-6 px-1.5'}>{t(data.name)}</button>,
        }
    })

    return (
        <Dropdown
            className={className}
            trigger={['click']}
            menu={{
                items,
                className: '!bg-gray-bg',
                // triggerSubMenuAction: 'click',
                onClick: ({ key, keyPath, domEvent }) => {
                    onClickHandle(cellData[parseInt(key)])
                },
            }}
        >
            <button className={'text-xs lg:text-sm bg-gray-bg h-10 px-6 text-left rounded-full flex justify-between items-center '}>
                <span className="">{t(value?.name ?? (cellData.length > 0 ? cellData[0].name : ''))}</span>{' '}
                <img className="ml-3 w-4" src={arrow_down_icon} />
            </button>
        </Dropdown>
    )
}
export default DropdownList
