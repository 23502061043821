import axios from './index' //进行了二次封装了

export const getOssAssumeRoleToken = params => {
    return axios.post('/stableDiffusion/api/web/oss/getOssAssumeRoleToken', params)
}
export const createResource = params => {
    return axios.post('/stableDiffusion/api/web/train/createResource', params)
}
export const checkResourceComplete = params => {
    return axios.post('/stableDiffusion/api/web/train/checkResourceComplete', params)
}
export const deleteResource = params => {
    return axios.post('/stableDiffusion/api/web/train/deleteResource', params)
}
export const stableDiffusionCreateModel = params => {
    return axios.post('/stableDiffusion/api/web/train/createModel', params)
}

export const stableDiffusionModelList = params => {
    return axios.post('/stableDiffusion/api/web/train/getModelList', params)
}

export const stableDiffusionModelDelete = params => {
    return axios.post('/stableDiffusion/api/web/train/deleteModel', params)
}

export const createModelTask = params => {
    return axios.post('/stableDiffusion/api/web/train/createModelTask', params)
}

export const getResourcesList = params => {
    return axios.post('/stableDiffusion/api/web/train/getResourceList', params)
}

export const getModelList = params => {
    return axios.post('/stableDiffusion/api/web/train/getModelList', params)
}

export const getModelTaskDetail = params => {
    return axios.post('/stableDiffusion/api/web/train/getTaskDetail', params)
}
// 判断当前是否有正在进行的任务
export const getCurrentTaskDetail = params => {
    return axios.post('/stableDiffusion/api/web/train/getCurrentTaskDetail', params)
}
export const getControlnetConfig = params => {
    return axios.post('/stableDiffusion/api/web/controlnet/config', params)
}
