import { trackEvent, googleTrackEvent } from '@/lib/analyse'
import { IGenerateStateDiffusionState, setPrompt, setStableDiffusionState, updateSd_segmentedPrompt } from '@/slices/GenerateImageSlice'
import { Spin, Popover, message } from 'antd'
import { t } from 'i18next'
import React, { startTransition, useMemo, useRef, useState } from 'react'
import CustomSwitch from './CustomSwitch'
import SegmentedPromptTextArea from './SegmentedPromptTextArea'
import { useAppSelector } from '@/hooks'
import { stPromptAssociate, stMulTranslate, stTranslate } from '@/api/request'
import { AppDispatch } from '@/store'
import { shallowEqual, useDispatch } from 'react-redux'
import PromptHelperPopover from '@/components/Popover/PromptHelperPopover'

interface IDescAreaProps {
    promptHelperOpen: boolean
    setPromptHelperOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const DescArea: React.FC<IDescAreaProps> = ({ promptHelperOpen, setPromptHelperOpen }) => {
    const SegmentedPromptHolders = [
        { holder: 'promptPlaceHolder0', valueKey: 'segmentSubject' },
        { holder: 'promptPlaceHolder1', valueKey: 'segmentDescribeSubject' },
        { holder: 'promptPlaceHolder2', valueKey: 'segmentScene' },
        { holder: 'promptPlaceHolder3', valueKey: 'segmentStyle' },
        { holder: 'promptPlaceHolder4', valueKey: 'segmentQuality' },
        { holder: 'promptPlaceHolder5', valueKey: 'segmentArtist' },
    ]

    // const state = useAppSelector(state => state.generateImageSlice.stableDiffusion)
    const prompt = useAppSelector(state => state.generateImageSlice.stableDiffusion.prompt)
    const showSegmentedPrompt = useAppSelector(state => state.generateImageSlice.stableDiffusion.showSegmentedPrompt)
    const segmentedPrompts = useAppSelector(state => state.generateImageSlice.stableDiffusion.segmentedPrompts)
    const negativePrompt = useAppSelector(state => state.generateImageSlice.stableDiffusion.negativePrompt)
    const promptGenerating = useAppSelector(state => state.generateImageSlice.stableDiffusion.promptGenerating)
    const showNegativePrompt = useAppSelector(state => state.generateImageSlice.stableDiffusion.showNegativePrompt)
    const negativePromptGenerating = useAppSelector(state => state.generateImageSlice.stableDiffusion.negativePromptGenerating)

    const authToken = useAppSelector(state => {
        return state.app?.authToken
    })

    const dispatch = useDispatch<AppDispatch>()
    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))

    const requestPrompt = async () => {
        if (!prompt || prompt.length < 1) {
            message.error(t('generatedImage.pleaseEnterPrompt'))
            return
        }

        try {
            updateState({ promptGenerating: true })
            const res = (await stPromptAssociate({
                authToken: authToken,
                prompt: prompt,
            })) as any

            if (res.code == 1) {
                updateState({ prompt: res.data.promptAssociate })
            } else {
                message.error(t(res.message))
            }
            updateState({ promptGenerating: false })
        } catch (error) {
            updateState({ promptGenerating: false })
            message.error(t('generatedImage.errorServiceBusy'))
        }
    }

    const requestTranslate = async () => {
        if (showSegmentedPrompt) {
            const exits = Object.values(segmentedPrompts).filter(p => p.length > 0)
            if (exits.length < 1) {
                message.error(t('generatedImage.pleaseEnterPrompt'))
                return
            }

            const needT = SegmentedPromptHolders.filter(({ holder, valueKey }) => {
                const p = segmentedPrompts[valueKey]
                return p && p.length > 0
            })
            console.log('🚀 ~ file: index.tsx:243 ~ needT ~ needT:', needT)

            const toTranslates = needT.map(({ holder, valueKey }) => {
                return segmentedPrompts[valueKey]
            })

            try {
                updateState({ promptGenerating: true })
                const res = await stMulTranslate({ authToken, messages: toTranslates })
                if (res.code == 1) {
                    const results = res.data as string[]
                    needT.forEach(({ holder, valueKey }, index) => {
                        dispatch(updateSd_segmentedPrompt({ [valueKey]: results[index] }))
                    })
                }
            } catch (error) {
                message.error(t('generatedImage.errorServiceBusy'))
            } finally {
                updateState({ promptGenerating: false })
            }
        } else {
            if (!prompt || prompt.length < 1) {
                message.error(t('generatedImage.pleaseEnterPrompt'))
                return
            }

            try {
                updateState({ promptGenerating: true })

                const r = await stTranslate({ authToken, message: `${prompt}` })
                if (r.code == 1) {
                    updateState({ prompt: r.data })
                } else {
                    message.error(r.message)
                }
                updateState({ promptGenerating: false })
            } catch (error) {
                updateState({ promptGenerating: false })
                message.error(t('generatedImage.errorServiceBusy'))
            }
        }
    }

    const requestNegativeTranslate = async () => {
        if (!negativePrompt || negativePrompt.length < 1) {
            return
        }

        try {
            updateState({ negativePromptGenerating: true })

            const r = await stTranslate({ authToken, message: `${negativePrompt}` })
            if (r.code == 1) {
                updateState({ negativePrompt: r.data })
            } else {
                message.error(r.message)
            }

            updateState({ negativePromptGenerating: false })
        } catch (error) {
            updateState({ negativePromptGenerating: false })
            message.error(t('generatedImage.errorServiceBusy'))
        }
    }

    const handleTitleChange = e => {
        updateState({ prompt: e.target.value })
    }

    return (
        <div className="grow text-white/90">
            <div className="flex flex-col">
                <div className="mt-4 sm:mt-0 text-sm ">
                    <div className="relative flex flex-col lg:flex-row justify-between font-medium">
                        <span className="flex items-center space-x-2">
                            <label htmlFor="image-add-desc" className="block">
                                {t('generatedImage.prompt')}
                            </label>
                            <div className="w-24 transition-all absolute"></div>
                            <CustomSwitch
                                checked={showSegmentedPrompt}
                                checkedText={t('generatedImage.segmented')}
                                uncheckedText={t('generatedImage.basic')}
                                trackClassName={isChecked => {
                                    return 'relative w-[6rem] rounded-full p-[2px] border-[1px] border-[#494949]'
                                }}
                                checkedClassName={isChecked => {
                                    const base = 'transition-all w-[4.5rem] rounded-full text-white text-xs px-1 py-[1px]'
                                    if (isChecked) {
                                        return base + ' ml-[calc(1.5rem-6px)] bg-gradient-to-r from-[#FA8A98] to-[#F04095]'
                                    } else {
                                        return base + ' ml-0 bg-gradient-to-r from-[#61ACFF] to-[#8A06FF]'
                                    }
                                }}
                                onChange={e => {
                                    updateState({ showSegmentedPrompt: !showSegmentedPrompt })
                                }}
                            />
                            <div className={`${promptHelperOpen ? '' : 'm-bg-gradient'}  rounded-full p-[1px] overflow-hidden`}>
                                <button
                                    className={`px-9 py-1 text-xs lg:text-base rounded-full ${promptHelperOpen ? 'bg-[#6401B8]' : 'bg-black'}`}
                                    onClick={e => setPromptHelperOpen(pre => !pre)}
                                >
                                    {t('newFunction.PromptHelper')}
                                </button>
                            </div>
                        </span>
                        <span className=" mt-2 lg:mt-0 lg:absolute right-0 -bottom-0.5 flex">
                            <div className="rounded-full m-bg-gradient p-[1px]">
                                <button
                                    onClick={() => {
                                        trackEvent('SD button event', { button_type: 'prompt helper' })
                                        googleTrackEvent('SD button event', 'prompt helper')
                                        requestPrompt()
                                    }}
                                    disabled={!!(promptGenerating || showSegmentedPrompt)}
                                    className={`flex justify-center items-center rounded-full px-4 lg:px-9 py-1 font-medium bg-black disabled:bg-gray-500 text-white/90 text-xs lg:text-base disabled:cursor-not-allowed`}
                                >
                                    <span>{t('generatedImage.associate')!}</span>
                                </button>
                            </div>
                            <div className="rounded-full m-bg-gradient p-[1px] ml-2">
                                <button
                                    onClick={() => {
                                        trackEvent('SD button event', { button_type: 'prompt translate' })
                                        googleTrackEvent('SD button event', 'prompt translate')
                                        requestTranslate()
                                    }}
                                    disabled={!!promptGenerating}
                                    className={
                                        'flex justify-center items-center rounded-full px-4 lg:px-9 py-1 font-medium bg-black disabled:bg-gray-500 text-white/90 text-xs lg:text-base disabled:cursor-not-allowed'
                                    }
                                >
                                    <span>{t('generatedImage.translate')!}</span>
                                </button>
                            </div>
                        </span>
                    </div>
                    <Spin wrapperClassName="w-full" spinning={promptGenerating}>
                        {showSegmentedPrompt ? (
                            <div
                                id="image-add-desc"
                                // className={`overflow-y-auto mt-2 rounded-md p-[3px] w-full flex flex-wrap`}
                                className={`overflow-hidden mt-2 rounded-md p-[3px] grid grid-flow-dense grid-cols-1 lg:grid-cols-3 gap-2`}
                            >
                                {SegmentedPromptHolders.map(({ holder, valueKey }, index) => (
                                    <SegmentedPromptTextArea
                                        className="bg-gray-bg p-2 text-sm min-h-[3.5rem] max-h-[7rem] break-all"
                                        key={valueKey}
                                        placeholder={t(`generatedImage.${holder}`)!}
                                        value={segmentedPrompts[valueKey]}
                                        onChange={e => {
                                            dispatch(updateSd_segmentedPrompt({ [valueKey]: e.target.value }))
                                        }}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div id="image-add-desc" className="overflow-hidden mt-2 grid rounded-md p-[3px]">
                                <SegmentedPromptTextArea
                                    className="bg-gray-bg p-2 text-sm min-h-[7.5rem] max-h-[15rem] break-all"
                                    placeholder={t('generatedImage.promptPlaceHolder')!}
                                    value={prompt}
                                    onChange={e => handleTitleChange(e)}
                                ></SegmentedPromptTextArea>
                            </div>
                        )}
                    </Spin>
                </div>
                <div className="relative mt-4 text-sm">
                    <div className="relative flex justify-between font-medium">
                        <span className="flex items-center space-x-2">
                            <label htmlFor="image-remove-desc" className="inline-block font-medium">
                                {t('generatedImage.negativePrompt')}
                            </label>
                            <CustomSwitch
                                checked={showNegativePrompt}
                                checkedText=" "
                                uncheckedText=" "
                                trackClassName={isChecked => {
                                    return 'relative w-[2.5rem] rounded-full p-0.5 border-[1px] border-[#494949]'
                                }}
                                checkedClassName={isChecked => {
                                    const base = 'whitespace-pre transition-all w-[1.1rem] rounded-full text-white text-xs px-1'
                                    if (isChecked) {
                                        return base + ' ml-[1rem] bg-gradient-to-r from-[#FA8A98] to-[#F04095]'
                                    } else {
                                        return base + ' ml-0 bg-gradient-to-r from-[#61ACFF] to-[#8A06FF]'
                                    }
                                }}
                                onChange={e => {
                                    updateState({ showNegativePrompt: !showNegativePrompt })
                                }}
                            />
                        </span>
                        <span className="absolute right-0 -bottom-0.5 flex">
                            <div
                                className={`${
                                    showNegativePrompt ? 'opacity-100' : 'opacity-0'
                                } transition-opacity rounded-full m-bg-gradient p-[1px]`}
                            >
                                <button
                                    onClick={() => {
                                        trackEvent('SD button event', { button_type: 'negative prompt translate' })
                                        googleTrackEvent('SD button event', 'negative prompt translate')
                                        requestNegativeTranslate()
                                    }}
                                    disabled={negativePromptGenerating ? true : false}
                                    className={`flex justify-center items-center rounded-full px-9 py-1 font-medium bg-black disabled:bg-gray-500 text-white/90 text-xs lg:text-base disabled:cursor-not-allowed`}
                                >
                                    <span>{t('generatedImage.translate')!}</span>
                                </button>
                            </div>
                        </span>
                    </div>
                    <Spin wrapperClassName="w-full" spinning={negativePromptGenerating}>
                        <div
                            id="image-remove-desc"
                            className={`${
                                showNegativePrompt ? 'max-h-[10rem] p-[3px]' : 'max-h-0 p-0'
                            } transition-all overflow-hidden mt-2 flex rounded-md `}
                        >
                            <SegmentedPromptTextArea
                                className="bg-gray-bg p-2 text-sm min-h-[4rem] max-h-[8rem] break-all"
                                placeholder={t('generatedImage.negativePromptPlaceHolder')!}
                                value={negativePrompt}
                                onChange={e => updateState({ negativePrompt: e.target.value })}
                            ></SegmentedPromptTextArea>
                            {/* <textarea
                                className={`h-16  block w-full resize-none min-w-0 flex-1 p-2 rounded-md bg-gray-bg outline-none text-sm`}
                                placeholder={t('generatedImage.negativePromptPlaceHolder')!}
                                value={state.negativePrompt}
                                onChange={e => updateState({ negativePrompt: e.target.value })}
                            /> */}
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    )
}
export default DescArea
