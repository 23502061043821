import deleteImage from '../../assets/v1.6/delete.png'
import vActive from '../../assets/v1.6/V-active.png'
import vGray from '../../assets/v1.6/V-gray.png'
import uActive from '../../assets/v1.6/U-active.png'
import uGray from '../../assets/v1.6/U-gray.png'
import waters_s from '../../assets/waters_s.png'
import loading_pnd from '../../assets/common/watersLoading.png'

import DeleteModel from '../../components/DeleteModal'
import { useState, memo, useEffect, useDeferredValue } from 'react'
import { t } from 'i18next'
import { Image, Progress, message, Popover } from 'antd'

import { useAppSelector } from '@/hooks'
import { setTaskList, setImageRecordList, deleteImageRecord, setImageCanV, setShowRecord, Waters_UVSButtonClick } from '@/slices/NewFunctionSlice'
import { useDispatch } from 'react-redux'
import { deleteRecord, createTask } from '../../api/newFunctionRequest'
import NewFunctionImageDetail from './NewFunctionImageDetail'
import { setLoading, setShowLogin } from '@/slices/AppSlice'
import { useTranslation } from 'react-i18next'
import { trackEvent, googleTrackEvent } from '@/lib/analyse'
import OneImagePreview from '../../components/PhotoPreview/OneImagePreview'

import WatersSwiper from './WatersSwiper'
import moment from 'moment'

function GraphResult(params) {
    // UIPromptType  1:只显示prompt  2:同时显示prompt和日期及删除按钮
    // UIBtnType 1:显示 U V 按钮  2:显示 U V S 按钮
    // PageType 1:watersbench 2:watersRecord 3:home
    const { imageRecordObj, historicalRecordRequest, containerRef, type, setCanGenerate, UIPromptType, UIBtnType } = params
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const vistorAuthToken = useAppSelector(state => {
        return state.app && state.app.vistorAuthToken
    })
    const [deleteConfirmShow, setDeleteConfirmShow] = useState(false)
    const [showImagePreview, setShowImagePreview] = useState(false)
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null)
    const handleModelDelete = async () => {
        const res = await deleteRecord({ authToken: type == 'vistor' ? vistorAuthToken : authToken, groupId: imageRecordObj.taskGroupId })
        if (res.code == 1) {
            dispatch(deleteImageRecord(imageRecordObj))
        } else {
            message.error(res.message)
        }
        setDeleteConfirmShow(false)
    }

    const UVSBtnClick = async (drawType, item, index) => {
        trackEvent('Waters generate')
        googleTrackEvent('Waters generate', 'Waters generate')
        dispatch(setImageCanV({ groupId: imageRecordObj.taskGroupId, imageItemIndex: index }))
        dispatch(Waters_UVSButtonClick({ type: drawType, authToken: type == 'vistor' ? vistorAuthToken : authToken, taskId: item.taskId }))
    }

    const regenerateClick = async () => {
        setCanGenerate(false)
        const res = await createTask({
            authToken: type == 'vistor' ? vistorAuthToken : authToken,
            drawType: 1,
            height: imageRecordObj.targetHeight,
            width: imageRecordObj.targetWidth,
            originPrompt: imageRecordObj.prompt,
            shap:
                imageRecordObj.targetWidth == 512
                    ? 'square'
                    : imageRecordObj.targetWidth == 800
                    ? 'landscape'
                    : imageRecordObj.targetWidth == 600
                    ? 'vertical'
                    : imageRecordObj.targetWidth == 960
                    ? 'landscape'
                    : imageRecordObj.targetWidth == 540
                    ? 'vertical'
                    : '',
        })
        if (res.code == 1) {
            await historicalRecordRequest()
            setCanGenerate(true)
        } else {
            message.error(res.message)
            setCanGenerate(true)
        }
    }

    const [smallScreen, setSmallScreen] = useState(false)
    function whatScreen() {
        var width = document.querySelector('html')?.offsetWidth
        if (width <= 1024) {
            setSmallScreen(true)
        }
    }

    function gcd(n, m) {
        if (m == 0) return n
        return gcd(m, n % m)
    }

    function scale(n, m) {
        let imgStr = n / gcd(n, m) + '_' + m / gcd(n, m)
        if (imgStr !== '1_1' && imgStr !== '3_4' && imgStr !== '4_3' && imgStr !== '16_9' && imgStr !== '9_16') {
            imgStr = 'default'
        }
        return imgStr
    }

    useEffect(() => {
        whatScreen()
    }, [])

    const host = useAppSelector(state => {
        return state.app && state.app.host
    })

    return (
        <div className="w-full flex flex-col mt-4">
            {showImagePreview && <OneImagePreview setOpen={setShowImagePreview} imageUrl={imagePreviewUrl}></OneImagePreview>}

            {type == 'vistor' ? (
                <div className="w-full rounded-lg p-2 flex flex-col bg-[rgba(0,0,0,0.6)] group relative">
                    <div className="text-xs w-full">{imageRecordObj.prompt}</div>
                    {/* <button
                        onClick={e => {
                            regenerateClick()
                        }}
                        className={` absolute left-0 top-0 hidden group-hover:block overflow-clip m-bg-gradient rounded-full h-8 px-8 space-x-3  items-center`}
                    >
                        <span className="text-base font-medium">{t('newFunction.regenerate')}</span>
                    </button> */}
                </div>
            ) : (
                <>
                    {UIPromptType == 2 ? (
                        <div className="w-full border border-[#505050] rounded-lg p-2 lg:pr-5 flex items-start group relative">
                            <div className="text-xs grow">{imageRecordObj.prompt}</div>
                            <div className="hidden lg:block w-auto text-xs px-[85px] whitespace-nowrap">
                                {moment.utc(imageRecordObj.createTime).local().format('YYYY-MM-DD HH:mm')}
                            </div>
                            <img
                                className="my-[-4px] w-[20px] cursor-pointer"
                                src={deleteImage}
                                alt=""
                                onClick={() => {
                                    setDeleteConfirmShow(true)
                                }}
                            />
                        </div>
                    ) : (
                        <div className="w-full border border-[#505050] rounded-lg p-2 lg:pr-5 flex items-start group relative">
                            <div className="text-xs grow">{imageRecordObj.prompt}</div>
                        </div>
                    )}
                </>

                //
            )}
            <div
                className="flex flex-col lg:flex-row pt-[17px]"
                style={{ justifyContent: imageRecordObj.targetWidth >= imageRecordObj.targetHeight ? 'space-between' : 'flex-start' }}
            >
                <div
                    className=" flex flex-wrap group"
                    style={{ width: smallScreen ? '100%' : imageRecordObj.targetWidth >= imageRecordObj.targetHeight ? '48%' : '27%' }}
                >
                    {imageRecordObj.taskResults.map((item, index) => {
                        return (
                            <div
                                className=" relative  cursor-pointer w-1/2"
                                key={index}
                                onClick={() => {
                                    if (item.taskStatus == 3) {
                                        setShowImagePreview(true)
                                        setImagePreviewUrl(item.imageUrl + (host == 'xyz' ? '?x-oss-process=style/waters3' : ''))
                                    }
                                    if (item.taskStatus == -1) {
                                        if (item.isRecommend == 1) {
                                            setShowImagePreview(true)
                                            setImagePreviewUrl(item.imageUrl + (host == 'xyz' ? '?x-oss-process=style/waters3' : ''))
                                        }
                                    }
                                }}
                            >
                                {item.taskStatus == 3 && (
                                    <div className=" absolute left-[11px] right-[11px] bottom-[2px]  lg:hidden lg:group-hover:flex z-10 flex flex-row justify-between">
                                        <div className="space-x-2">
                                            <Popover
                                                content={<div className="flex items-center justify-center">{t('preview.btnVariationsTip')}</div>}
                                            >
                                                <button
                                                    disabled={item.canV !== 1}
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        UVSBtnClick(2, item, index)
                                                    }}
                                                >
                                                    <img className="w-[30px] lg:w-[38px]" src={item.canV === 1 ? vActive : vGray} alt="" />
                                                </button>
                                            </Popover>
                                            <Popover content={<div className="flex items-center justify-center">{t('preview.btnUpscaleTip')}</div>}>
                                                <button
                                                    disabled={item.canU !== 1}
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        UVSBtnClick(3, item, index)
                                                    }}
                                                >
                                                    <img className="w-[30px] lg:w-[38px]" src={item.canU === 1 ? uActive : uGray} alt="" />
                                                </button>
                                            </Popover>
                                        </div>
                                        <div>
                                            {UIBtnType == 2 && (
                                                <Popover
                                                    content={<div className=" flex items-center justify-center">{t('preview.btnSimliaTip')}</div>}
                                                >
                                                    <button
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            UVSBtnClick(4, item, index)
                                                        }}
                                                    >
                                                        <img className="w-[30px] lg:w-[38px]" src={waters_s} alt="" />
                                                    </button>
                                                </Popover>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {item.taskStatus == 3 || item.taskStatus == -1 || item.taskStatus == 0 ? (
                                    <div className=" relative">
                                        <img
                                            src={
                                                item.taskStatus == -1
                                                    ? item.isRecommend == 1
                                                        ? item.imageUrl + (host == 'xyz' ? '?x-oss-process=style/waters2' : '')
                                                        : `${
                                                              'https://resource.mst.xyz/image/default/' +
                                                              scale(item.targetWidth, item.targetHeight) +
                                                              t('newFunction.ImageLanguage')
                                                          }`
                                                    : item.imageUrl + (host == 'xyz' ? '?x-oss-process=style/waters2' : '')
                                            }
                                            alt=""
                                            style={{ width: '100%' }}
                                        />
                                        {item.taskStatus == -1 && item.isRecommend == 1 && (
                                            <div className=" absolute bottom-[2px] left-[3px] right-[3px] rounded p-[15px] bg-[rgb(0,0,0,0.7)]">
                                                {t('preview.goudaiTip')}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        className="relative bg-gray-bg"
                                        style={{
                                            width: '100%',
                                            paddingBottom: `${(item.targetHeight / item.targetWidth) * 100}%`,
                                            height: 0,
                                        }}
                                    >
                                        <div
                                            key={item.taskId}
                                            className={`absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-white`}
                                        >
                                            <img className="w-28 -mt-4 scale-50" src={loading_pnd} />
                                            {item.progress && item.progress.progress && item.progress.progress > 0 && (
                                                <div className="w-24 lg:w-48 max-w-[90%] text-white">
                                                    <Progress
                                                        percent={Math.floor(item.progress.progress)}
                                                        strokeColor={{ '0%': '#9042EE', '100%': '#9042EE' }}
                                                    />
                                                </div>
                                            )}
                                            <div className="flex items-center text-white/90 mb-5">
                                                <span className="px-2 lg:px-8 whitespace-normal text-center">
                                                    {item.progress && item.progress
                                                        ? item.progress.step
                                                        : t('generatedImage.generateProgressStatus0')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
                {(imageRecordObj.taskGroupStatus == 3 || imageRecordObj.taskGroupStatus == 2 || imageRecordObj.taskGroupStatus == -1) && (
                    <div
                        className="relative"
                        style={{
                            width: smallScreen ? '100%' : imageRecordObj.targetWidth >= imageRecordObj.targetHeight ? '48%' : '27%',
                            marginLeft: smallScreen ? '0' : imageRecordObj.targetWidth >= imageRecordObj.targetHeight ? '0' : '4%',
                            marginTop: smallScreen ? '30px' : '0',
                        }}
                    >
                        <WatersSwiper
                            images={imageRecordObj.upscaleTaskResults}
                            // images={imageRecordObj.taskResults}
                            setOpen={setShowImagePreview}
                            setImagePreviewUrl={setImagePreviewUrl}
                        ></WatersSwiper>
                    </div>
                )}
            </div>

            <DeleteModel
                open={deleteConfirmShow}
                title={t('newFunction.deleteTitle')}
                subtitle={t('newFunction.deleteSubTitle')}
                cancelM={e => {
                    setDeleteConfirmShow(false)
                }}
                sureM={e => {
                    handleModelDelete()
                }}
            ></DeleteModel>
        </div>
    )
}

export default memo(GraphResult)
