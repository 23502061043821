import React, { useCallback, useEffect, useRef, useState } from 'react'
import Macy from 'macy'
import { ISdModelListItem } from '@/slices/sdModelListSlice'
import { useTranslation } from 'react-i18next'
import { sdHomeModelList } from '@/api/request'

export interface IHomeModelListProps {
    // modelList: ISdModelListItem[]
}
const HomeModelList: React.FC<IHomeModelListProps> = props => {
    const macyContainerRef = useRef(null)
    // const { modelList } = props
    const { t } = useTranslation()
    const [macy, setMacy] = useState<any>()
    const [modelList, setModelList] = useState<ISdModelListItem[]>([])

    useEffect(() => {
        sdHomeModelList().then(res => {
            if (res.code == 1) {
                setModelList(res.data.homePageModelList)
            }
        })
    }, [])

    useEffect(() => {
        if (modelList.length > 0 && macy) {
            // state.macy.runOnImageLoad(function () {
            //     // console.log('I only get called when all images are loaded', state.modelList)
            //     state.macy.recalculate(true, true)
            // })

            macy.runOnImageLoad(function () {
                macy.recalculate(true)
            }, true)
        }
    }, [modelList, macy])

    useEffect(() => {
        const macy = Macy({
            container: macyContainerRef.current,
            trueOrder: false,
            waitForImages: false,
            mobileFirst: true,
            breakAt: {
                // 2100: {
                //     margin: {
                //         x: 16,
                //         y: 16,
                //     },
                //     columns: 6,
                // },
                1700: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 5,
                },
                1300: {
                    margin: {
                        x: 16,
                        y: 16,
                    },
                    columns: 4,
                },
                900: {
                    margin: {
                        x: 12,
                        y: 12,
                    },
                    columns: 3,
                },
                0: {
                    margin: {
                        x: 8,
                        y: 8,
                    },
                    columns: 2,
                },
            },
        })

        macy.on(macy.constants.EVENT_IMAGE_COMPLETE, function (ctx) {})

        setMacy(macy)
        return () => {}
    }, [])

    const modelTextRender = useCallback(
        model => (
            <div className="block lg:absolute bottom-0 p-1 pb-0.5 w-full bg-gradient-to-t from-black/60 to-black/10">
                <div className="text-white text-sm text-ellipsis line-clamp-2 mb-0.5">{model.modelName}</div>
                <div className="flex flex-wrap text-xs">
                    <div className="m-bg-gradient rounded py-0.5 px-2 m-0.5">{model.modelTypeName}</div>
                </div>
                <div className="flex flex-wrap text-xs">
                    {model.categoryItemList?.map((c, i) => (
                        <div key={i} className="bg-white/30 rounded py-0.5 px-2 m-0.5">
                            {t(c.modelCateName)}
                        </div>
                    ))}
                </div>
            </div>
        ),
        [],
    )

    return (
        <div>
            <div ref={macyContainerRef} className="">
                {modelList.map((model, index) => (
                    <div key={model.modelId} className="w-0">
                        <div
                            className="group relative rounded-lg bg-gray-bg overflow-hidden"
                            // key={model.pathUrl}
                            // onClick={() => {
                            //     //  showPreview(true, index)
                            // }}
                        >
                            <div className="hidden lg:block">{modelTextRender(model)}</div>
                            <img
                                src={model.modelIcon + '?x-oss-process=image/resize,w_700/quality,Q_80'}
                                className="rounded-lg lg:rounded-none block w-full"
                                alt=""
                            />
                            <div className="block lg:hidden">{modelTextRender(model)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HomeModelList
