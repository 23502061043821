/*
 * @Author: jianwen.Wang
 * @Date: 2022-04-21 21:20:34
 * @LastEditTime: 2023-06-25 11:39:05
 * @LastEditors: yunpeng.li yunpeng.li@sdbean.com
 */
// import moment from 'moment'
// import { PlatformContainer } from '../api/Dto/orderDto'
// import BigNumber from 'bignumber.js'

export function setAll(state: any, properties: any) {
    if (properties) {
        const props = Object.keys(properties)
        props.forEach(key => {
            state[key] = properties[key]
        })
    }
}

export function inputVerify(str: string) {
    var reg = /^\d*\.{0,1}\d*$/
    return reg.test(str)
}

export function getUuid() {
    if (typeof crypto === 'object') {
        if (typeof crypto.randomUUID === 'function') {
            return crypto.randomUUID()
        }
        if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
            const callback = (c: any) => {
                const num = Number(c)
                return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16)
            }
            // @ts-ignore
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, callback)
        }
    }
    let timestamp = new Date().getTime()
    let perforNow = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let random = Math.random() * 16
        if (timestamp > 0) {
            random = (timestamp + random) % 16 | 0
            timestamp = Math.floor(timestamp / 16)
        } else {
            random = (perforNow + random) % 16 | 0
            perforNow = Math.floor(perforNow / 16)
        }
        return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16)
    })
}

/**
 * @description:
 * @param {function} draw
 * @param {number} duration
 * @param {*} timing
 * @return {*}
 */
export function animate(
    {
        draw,
        duration,
        onFinished,
    }: {
        draw: (progress: number) => void
        duration: number
        onFinished?: Function
    },
    timing = (timeFraction: number) => timeFraction,
): void {
    let start = performance.now()

    requestAnimationFrame(function animate(time) {
        // timeFraction 从 0 增加到 1
        let timeFraction = (time - start) / duration
        if (timeFraction > 1) timeFraction = 1

        // 计算当前动画状态
        let progress = timing(timeFraction)

        draw(progress) // 绘制

        if (timeFraction < 1) {
            requestAnimationFrame(animate)
        } else {
            if (onFinished) {
                onFinished()
            }
        }
    })
}

export function getImageUrl(imagePath, imageDir = '') {
    return new URL('../assets/' + imageDir + '/' + imagePath, import.meta.url).href
}
export function scrollToTop() {
    const c = document.documentElement.scrollTop || document.body.scrollTop
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop)
        window.scrollTo(0, c - c / 8)
    }
}

export async function copyToClipboard(text: string) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text)
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement('textarea')
        textArea.value = text

        // Move textarea out of the viewport so it's not visible
        textArea.style.position = 'absolute'
        textArea.style.left = '-999999px'

        document.body.prepend(textArea)
        textArea.select()

        try {
            document.execCommand('copy')
        } catch (error) {
            console.error(error)
        } finally {
            textArea.remove()
        }
    }
}
export const renderSize = fileSize => {
    if (fileSize < 1024) {
        return fileSize + 'B'
    } else if (fileSize < 1024 * 1024) {
        var temp: any = fileSize / 1024
        temp = temp.toFixed(1)
        return temp + 'KB'
    } else if (fileSize < 1024 * 1024 * 1024) {
        var temp: any = fileSize / (1024 * 1024)
        temp = temp.toFixed(1)
        return temp + 'MB'
    } else {
        var temp: any = fileSize / (1024 * 1024 * 1024)
        temp = temp.toFixed(1)
        return temp + 'GB'
    }
}
export const getOverseaUrl = () => {
    // console.log('getOverseaUrl ', window.sessionStorage.getItem('header-serve') == 'cloudflare')
    // console.log('getBrowserLang ', getUserBrowserLang())
    if (
        window.location.href.includes('mnst.ai') ||
        window.location.href.includes('chat-outside.53site.com') ||
        window.location.href.includes('localhost')
    ) {
        return true
    } else {
        if (window.sessionStorage.getItem('header-serve') == 'cloudflare') {
            return true
        } else if (getUserBrowserLang() !== 'zh') {
            return true
        } else {
            return false
        }
    }
    // if (
    //     window.sessionStorage.getItem('header-serve') == 'cloudflare' && (window.location.href.includes('mst.ai') ||
    //         window.location.href.includes('ministers.ai') ||
    //         window.location.href.includes('mnst.ai') ||
    //         window.location.href.includes('localhost'))
    //
    // ) {
    //     return true
    // } else {
    //     return false
    // }
}
export const getUserBrowserLang = () => {
    var type = navigator.appName
    if (type == 'Netscape') {
        var lang = navigator.language //获取浏览器配置语言，支持非IE浏览器
    } else {
        var lang = navigator.userLanguage //获取浏览器配置语言，支持IE5+ == navigator.systemLanguage
    }
    var lang = lang.substr(0, 2) //获取浏览器配置语言前两位
    return lang
}

export const getChatTabState = () => {
    return false
}

export const getSiteDomain = () => {
    if (window.location.href.includes('mnst.ai')) {
        return 'MinisterAI - Free online AI Art Generator | Stable Diffusion'
    } else if (window.location.href.includes('ministers.ai')) {
        return 'MinisterAI - Free online AI Art Generator | Stable Diffusion'
    } else if (window.location.href.includes('mst.ai')) {
        return 'MinisterAI - Free online AI Art Generator | Stable Diffusion'
    } else if (window.location.href.includes('localhost')) {
        return 'localhost'
    } else {
        return 'MinisterAI - Free online AI Art Generator | Stable Diffusion'
    }
}

export const reorderArray = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}
export function uploadBase64ToOSS(base64String) {
    console.log('🚀 ~ file: index.tsx:211 ~ uploadBase64ToOSS ~ base64String:', base64String)
    if (base64String) {
        var base64Data = base64String.split(',')[1]
        // 解码Base64字符串为二进制数据
        var binaryData = window.atob(base64Data)

        // 转换二进制数据为Uint8Array
        var uint8Array = new Uint8Array(binaryData.length)
        for (var i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i)
        }
        return new Blob([uint8Array])
    }
}
export function uploadBase64ColorReversToOSS(base64String) {
    if (base64String) {
        var base64Data = base64String.split(',')[1]
        // 解码Base64字符串为二进制数据
        var binaryData = window.atob(base64Data)
        var length = binaryData.length
        var uint8Array = new Uint8Array(length)
        for (var i = 0; i < length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i)
        }
        for (var i = 0; i < length; i++) {
            uint8Array[i] = uint8Array[i] ^ 255
        }
        var reversedBinaryData = String.fromCharCode.apply(null, uint8Array)
        var reversedBase64Image = btoa(reversedBinaryData)
        console.log('🚀 ~ file: index.tsx:244 ~ uploadBase64ColorReversToOSS ~ reversedBase64Image:', reversedBase64Image)
        return new Blob([uint8Array])
    }
}
export function reverseColorsBase64Image(base64Image) {
    // 创建一个 Image 对象
    var image = new Image()
    image.src = base64Image // 这里假设是 JPEG 格式的 base64 图片

    // 创建一个 Canvas 元素
    var canvas = document.createElement('canvas')
    var ctx = canvas.getContext('2d')

    // 图片加载完成后进行操作
    return new Promise(function (resolve, reject) {
        image.onload = function () {
            // 设置 Canvas 尺寸与图像一致
            canvas.width = image.width
            canvas.height = image.height

            // 在 Canvas 上绘制图像
            ctx.drawImage(image, 0, 0)

            // 获取图像数据
            var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
            var data = imageData.data

            // 颜色反转
            for (var i = 0; i < data.length; i += 4) {
                var red = data[i]
                var green = data[i + 1]
                var blue = data[i + 2]

                data[i] = 255 - red
                data[i + 1] = 255 - green
                data[i + 2] = 255 - blue
            }

            // 将修改后的图像数据绘制回 Canvas
            ctx.putImageData(imageData, 0, 0)

            // 将 Canvas 中的图像转为 base64 字符串
            var reversedBase64Image = canvas.toDataURL('image/jpeg')
            console.log('🚀 ~ file: index.tsx:287 ~ reversedBase64Image:', reversedBase64Image)

            // 返回颜色反转后的 base64 图片字符串
            resolve(reversedBase64Image)
        }

        // 图片加载出错时的处理
        image.onerror = function (error) {
            reject(error)
        }
    })
}
