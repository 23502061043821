import React, { useState } from 'react'
import { Select, Form, Input, ConfigProvider } from 'antd'
import phone_icon from '../../assets/phone_icon.png'
import MSTSelect from '../public/MStSelect'
import MSTSelectItem from '../public/MStSelect/MSTSelectItem'
import { useAppSelector } from '@/hooks'
const { Option } = Select
export default function PhoneNumInput(props) {
    const countryArea = useAppSelector(state => {
        return state.app && state.app.countryArea
    })
    const [phoneAreaList, setPhoneAreaList] = useState([
        {
            country: 'United States',
            phoneArea: '+1',
        },
        {
            country: 'China',
            phoneArea: '+86',
        },
        {
            country: 'Canada',
            phoneArea: '+1',
        },
        {
            country: 'Japan',
            phoneArea: '+81',
        },
        {
            country: 'Germany',
            phoneArea: '+49',
        },
        {
            country: 'India',
            phoneArea: '+91',
        },
        {
            country: 'United Kingdom',
            phoneArea: '+44',
        },
        {
            country: 'France',
            phoneArea: '+33',
        },
        {
            country: 'Russia',
            phoneArea: '+7',
        },
        {
            country: 'Brazil',
            phoneArea: '+7',
        },
        {
            country: 'Iran',
            phoneArea: '+98',
        },
        {
            country: 'Italy',
            phoneArea: '+39',
        },
        {
            country: 'South Korea',
            phoneArea: '+82',
        },
        {
            country: 'Australia',
            phoneArea: '+61',
        },
        {
            country: 'Mexico',
            phoneArea: '+52',
        },
        {
            country: 'Spain',
            phoneArea: '+34',
        },
        {
            country: 'Indonesia',
            phoneArea: '+62',
        },
        {
            country: 'Netherlands',
            phoneArea: '+31',
        },
        {
            country: 'Saudi Arabia',
            phoneArea: '+966',
        },
        {
            country: 'Turkey',
            phoneArea: '+90',
        },
        {
            country: 'Taiwan',
            phoneArea: '+886',
        },
        {
            country: 'Switzerland',
            phoneArea: '+41',
        },
        {
            country: 'Poland',
            phoneArea: '+48',
        },
        {
            country: 'Sweden',
            phoneArea: '+46',
        },
        {
            country: 'Argentina',
            phoneArea: '+54',
        },
        {
            country: 'Belgium',
            phoneArea: '+32',
        },
        {
            country: 'Thailand',
            phoneArea: '+66',
        },
        {
            country: 'Nigeria',
            phoneArea: '+234',
        },
        {
            country: 'Israel',
            phoneArea: '+972',
        },
        {
            country: 'Ireland',
            phoneArea: '+353',
        },
        {
            country: 'United Arab Emirates',
            phoneArea: '+971',
        },
        {
            country: 'Norway',
            phoneArea: '+47',
        },
        {
            country: 'Austria',
            phoneArea: '+43',
        },
        {
            country: ' Egypt',
            phoneArea: '+20',
        },
        {
            country: 'Malaysia',
            phoneArea: ' +60',
        },
        {
            country: 'Singapore',
            phoneArea: ' +65',
        },

        {
            country: 'Philippines',
            phoneArea: '+63',
        },
        {
            country: 'Denmark',
            phoneArea: '+45',
        },
        {
            country: 'Hong Kong',
            phoneArea: '+852',
        },

        {
            country: 'Luxembourg',
            phoneArea: '+352',
        },
        {
            country: 'Iceland',
            phoneArea: '+354',
        },
        {
            country: 'Qatar',
            phoneArea: '+974',
        },
        {
            country: 'Finland',
            phoneArea: '+358',
        },
        {
            country: 'New Zealand',
            phoneArea: '+64',
        },
        {
            country: 'Kuwait',
            phoneArea: '+965',
        },
        {
            country: 'Czech Republic',
            phoneArea: '+420',
        },
        {
            country: 'Portugal',
            phoneArea: '+351',
        },
        {
            country: 'Lithuania',
            phoneArea: '+370',
        },
        {
            country: 'Greece',
            phoneArea: '+30',
        },
        {
            country: 'Hungary',
            phoneArea: '+36',
        },
        {
            country: 'Croatia',
            phoneArea: '+385',
        },
        {
            country: 'Uruguay',
            phoneArea: '+598',
        },
        {
            country: 'Chile',
            phoneArea: '+56',
        },
        {
            country: 'Turkmenistan',
            phoneArea: '+993',
        },
    ])

    const { setPhoneArea, phoneArea } = props

    const handleChange = (value: { label: string; value: number }) => {
        setPhoneArea(value.value)
    }
    const [searchStr, setSearchStr] = useState('')
    const handleSearch = e => {
        setSearchStr(e.target.value)
    }
    return (
        <>
            <MSTSelect
                getContainer={() => document.getElementById('area')}
                defaultValue={phoneArea}
                onChange={handleChange}
                onSearch={handleSearch}
                setSearchStr={str => setSearchStr(str)}
            >
                {countryArea.map((item, index) => {
                    return (
                        <MSTSelectItem
                            key={item.country}
                            className="mb_select-item"
                            label={item.country}
                            value={item.area}
                            searchStr={searchStr}
                        ></MSTSelectItem>
                    )
                })}
            </MSTSelect>
        </>
    )
}
