import {
    IGenerateStateDiffusionState,
    ISdModalState,
    privateModelsAdapter,
    publicModelsAdapter,
    setSd_privateModels,
    setSd_publicModels,
    setStableDiffusionState,
    updateSd_modalState,
} from '@/slices/GenerateImageSlice'
import { Modal, Spin } from 'antd'
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/hooks'
import { shallowEqual, useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import SdModelListModalSearch from './SdModelListModalSearch'
import DivBoundaryLoader from '@/pages/sdModelList/DivBoundaryLoader'
import SdModelListModalContent from './SdModelListModalContent'
import TabHeader, { TabHeaderItem } from '../TabHeader'
import { fetchSt_modalPrivateList, fetchSt_modalPublicList } from '@/slices/GeneratedImageThunk'
import { useCommonSlice } from '@/slices/commonSlice'
import { ChooseModelType } from '..'
import closeImage from '../../../assets/photoPreview/close.png'
import loading_animation from '../../../assets/loading.png'

export interface IModelListModalContext {
    open: boolean
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
    modelId?: string
    extra?: any
}
export const ModelListModalContext = createContext<IModelListModalContext>({ open: false })

interface IModelListModalProps {
    defaultOpen?: boolean
    modelId?: string // 如果 modelId 存在，表示需要获取 lora
    el: (
        open: boolean,
        setOpen: React.Dispatch<React.SetStateAction<boolean>>,
        extra: any,
        setExtra: React.Dispatch<React.SetStateAction<any>>,
    ) => JSX.Element
}
export enum ModelListTab {
    Private,
    Public,
}

const ModelListModal: React.FC<IModelListModalProps> = ({ modelId, el, defaultOpen }) => {
    const { t, i18n } = useTranslation()
    const Tabs = [
        { name: t('generatedImage.public'), tab: ModelListTab.Public },
        { name: t('generatedImage.private'), tab: ModelListTab.Private },
    ]

    const dispatch = useDispatch<AppDispatch>()
    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))

    const [listLoading, setListLoading] = useState(false)

    const state = useAppSelector(state => {
        return state.generateImageSlice.stableDiffusion
    }, shallowEqual)

    const authToken = useAppSelector(state => {
        return state.app?.authToken
    })

    const [open, setOpen] = useState(defaultOpen ?? false)
    const [extra, setExtra] = useState<any>(undefined)

    const { isPendingThunk } = useCommonSlice()

    const modalState = useAppSelector(state => state.generateImageSlice.stableDiffusion.modalState, shallowEqual)
    const publicModelSelectors = publicModelsAdapter.getSelectors<ISdModalState>(state => state.publicModelList)
    const privateModelSelectors = privateModelsAdapter.getSelectors<ISdModalState>(state => state.privateModelList)

    useEffect(() => {
        updateState({ modalTab: Tabs[0] })
    }, [])

    const curModelList = useMemo(() => {
        if (state.modalTab?.tab == ModelListTab.Public) {
            return publicModelSelectors.selectAll(modalState)
        } else if (state.modalTab?.tab == ModelListTab.Private) {
            return privateModelSelectors.selectAll(modalState)
        } else {
            return publicModelSelectors.selectAll(modalState)
        }
    }, [state.modalTab, modalState.publicModelList, modalState.privateModelList])

    const [showMore, setShowMore] = useState({ public: true, private: true })

    const [curFetchAction, haveCurMore] = useMemo(() => {
        if (state.modalTab?.tab == ModelListTab.Public) {
            return [fetchSt_modalPublicList, showMore.public]
        } else if (state.modalTab?.tab == ModelListTab.Private) {
            return [fetchSt_modalPrivateList, showMore.private]
        } else {
            return [fetchSt_modalPublicList, showMore.public]
        }
    }, [state.modalTab, showMore])

    function handleLoadingMore(res: any) {
        if (res.publicModelList) {
            setShowMore(more => {
                return { ...more, public: res.publicModelList.length >= 20 }
            })
        } else if (res.privateModelList) {
            setShowMore(more => {
                return { ...more, private: res.privateModelList.length >= 20 }
            })
        }
    }

    const loadImages = useCallback(
        (lastModelId?: string) => {
            if (isPendingThunk(curFetchAction)) {
                return
            }

            if (!lastModelId) {
                dispatch(setSd_publicModels([]))
                dispatch(setSd_privateModels([]))
            }

            setListLoading(true)
            dispatch(curFetchAction({ modelId, lastModelId }))
                .unwrap()
                .then(handleLoadingMore)
                .finally(() => {
                    setListLoading(false)
                })
        },
        [curFetchAction, modelId, isPendingThunk],
    )

    useEffect(() => {
        if (authToken && open) {
            loadImages()
        }
    }, [state.modalTab, authToken, open])

    useEffect(() => {
        return () => {
            dispatch(setSd_publicModels([]))
            dispatch(setSd_privateModels([]))
        }
    }, [])

    useEffect(() => {
        if (!open) {
            dispatch(
                updateSd_modalState({
                    chooseType: undefined,
                    curSort: modalState.sorts[1],
                    curCategory: modalState.categorys[0],
                    curType: modalState.types[0],
                    curSearch: '',
                }),
            )
            dispatch(setSd_publicModels([]))
            dispatch(setSd_privateModels([]))
        } else if (open) {
            dispatch(updateSd_modalState({ chooseType: modelId ? ChooseModelType.Lora : ChooseModelType.All }))
        }
    }, [open])

    const ctxValue = useMemo(() => {
        return { open, setOpen, modelId, extra }
    }, [open, setOpen, modelId, extra])

    return (
        <ModelListModalContext.Provider value={ctxValue}>
            {el(open, setOpen, extra, setExtra)}
            <Modal
                zIndex={19}
                title=""
                maskClosable={false}
                open={open}
                width="82rem"
                onOk={() => setOpen(false)}
                footer={<></>}
                onCancel={e => setOpen(false)}
                closeIcon={<img src={closeImage} />}
                className="modal-zero-padding"
            >
                <Spin spinning={curModelList.length < 1 && listLoading}>
                    <div className="flex flex-col h-[80vh] overflow-clip">
                        <DivBoundaryLoader
                            className="overflow-y-auto grow dark-thin-scrollbar px-5 pb-5"
                            onReachBottom={() => {
                                console.log('🚀 ~ file: ModelListModal.tsx:210 ~ setEndIndex:', 'setEndIndex')

                                if (isPendingThunk(curFetchAction)) {
                                    return
                                }

                                const lastObj = curModelList[curModelList.length - 1]
                                if (lastObj && haveCurMore) {
                                    loadImages(lastObj.modelId)
                                }
                            }}
                        >
                            <div className="sticky py-5 top-0 z-10 bg-menuBgColor">
                                <TabHeader
                                    tabValue={state.modalTab}
                                    className="lg:ml-4 space-x-5 lg:space-x-10 text-lg font-normal"
                                    buttonClassName={isActive => ''}
                                    tabs={Tabs}
                                    onChange={function (tab: TabHeaderItem): void {
                                        updateState({ modalTab: tab })
                                    }}
                                />
                                <SdModelListModalSearch
                                    fetchFunction={() => {
                                        dispatch(curFetchAction({ modelId: modelId }))
                                            .unwrap()
                                            .then(handleLoadingMore)
                                    }}
                                />
                            </div>
                            <div>
                                <SdModelListModalContent modelList={curModelList} />
                                {curModelList.length < 1 && !isPendingThunk(curFetchAction) && (
                                    <div className="h-[400px] flex justify-center items-center">
                                        <div className="text-white/60 text-center">{t('generatedImage.emptyText')}</div>
                                    </div>
                                )}
                                {haveCurMore && curModelList.length > 0 && (
                                    <div key="macy-container-model-loading" className="flex justify-center items-center mb-5">
                                        {/*isPendingThunk(curFetchAction) &&*/ <img src={loading_animation} className="h-20" />}
                                    </div>
                                )}
                            </div>
                        </DivBoundaryLoader>
                    </div>
                </Spin>
            </Modal>
        </ModelListModalContext.Provider>
    )
}
export default ModelListModal
