import { AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from '../../../api/index' //进行了二次封装了

import {
    ModelDetailDTO,
    ModelDetailResponse,
    ModelPublicListDTO,
    ModelPublicListResponse,
    ModelUpLoadHistoryDTO,
    ResponseData,
    ModelUpLoadHistoryResponse,
    ModelCollectDTO,
    ModelCreateUploadTaskDTO,
    ModelCreateUploadTaskResponse,
    ModelMainModelListDTO,
    ModelMainModelListResponse,
    ModelGetUploadingTaskInfoResponse,
    ModelFileDownloadDto,
    ModelFileUploadSuccessNoticeResponse,
    ModelFileUploadSuccessNoticeDTO,
    ModelDeleteDto,
    ModelFileDeployDto,
    ModelFileStateDto,
    ModelEditDTO,
    NoticeListDTO,
    NoticeListResponse,
    modelTopDTO,
    ModelRichTextDTO,
} from '../types'

const API_URL = `/stableDiffusion/api/web`

export const modelPublicList = (params: ModelPublicListDTO): Promise<ModelPublicListResponse> => {
    return axios.post(`${API_URL}/model/list/public`, params)
}

export const modelDetailApi = (params: ModelDetailDTO): Promise<ResponseData<ModelDetailResponse>> => {
    return axios.post(`${API_URL}/model/detail`, params)
}

export const modelUpLoadHistory = (params: ModelUpLoadHistoryDTO): Promise<ResponseData<ModelUpLoadHistoryResponse>> => {
    return axios.post(`${API_URL}/upload/model/history`, params)
}
export const modelCollect = (params: ModelCollectDTO): Promise<ResponseData> => {
    return axios.post(`${API_URL}/collect/model`, params)
}
export const modelCreateUploadTask = (params: ModelCreateUploadTaskDTO): Promise<ResponseData<ModelCreateUploadTaskResponse>> => {
    return axios.post(`${API_URL}/modelUpload/createUploadTask`, params)
}

export const modelRichTextEdit = (params: ModelRichTextDTO): Promise<ResponseData<any>> => {
    return axios.post(`${API_URL}/model/richtext/edit`, params)
}

export const modelMainModelList = (params: ModelMainModelListDTO): Promise<ResponseData<ModelMainModelListResponse>> => {
    return axios.post(`${API_URL}/main/model/list`, params)
}

export const modelFileUploadSuccessNotice = (
    params: ModelFileUploadSuccessNoticeDTO,
): Promise<ResponseData<ModelFileUploadSuccessNoticeResponse>> => {
    return axios.post(`${API_URL}/modelUpload/fileUploadSuccessNotice`, params)
}
//模型上传-查询进行中的上传任务
export const modelGetUploadingTaskInfo = (params: ModelMainModelListDTO): Promise<ResponseData<ModelGetUploadingTaskInfoResponse>> => {
    return axios.post(`${API_URL}/modelUpload/getUploadingTaskInfo`, params)
}
export const modelFileDownload = (params: ModelFileDownloadDto): Promise<ResponseData<any>> => {
    return axios.post(`${API_URL}/modelUpload/modelFileDownload`, params)
}

export const modelFileState = (params: ModelFileStateDto, config?: AxiosRequestConfig): Promise<ResponseData<any>> => {
    return axios.post(`${API_URL}/model/state`, params, config)
}

export const modelFileDeploy = (params: ModelFileDeployDto): Promise<ResponseData<any>> => {
    return axios.post(`${API_URL}/modelUpload/modelFileDeploy`, params)
}

export const modelDelete = (params: ModelDeleteDto): Promise<ResponseData<any>> => {
    return axios.post(`${API_URL}/delete/model`, params)
}
export const modelEdit = (params: ModelEditDTO): Promise<ResponseData<any>> => {
    return axios.post(`${API_URL}/model/edit`, params)
}

export const noticeList = (params: NoticeListDTO): Promise<ResponseData<NoticeListResponse>> => {
    return axios.post(`${API_URL}/chat/api/account/noticeList`, params)
}
export const modelTop = (params: modelTopDTO): Promise<ResponseData<any>> => {
    return axios.post(`${API_URL}/account/top/model`, params)
}
