import React, { useEffect, useState } from 'react'
import { ConfigProvider, Image, Upload, Modal, theme, Form, message, Button } from 'antd'
import type { RcFile, UploadProps } from 'antd/es/upload'
import type { UploadFile } from 'antd/es/upload/interface'
import { PlusOutlined } from '@ant-design/icons'
import { useAppSelector } from '@/hooks'
import { useTranslation } from 'react-i18next'

interface OSSDataType {
    dir: string
    expire: string
    host: string
    accessId: string
    policy: string
    signature: string
}
const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = error => reject(error)
    })
interface AliyunOSSUploadProps {
    value?: UploadFile[]
    onChange?: (fileList: UploadFile[]) => void
}
export default function UploadPicCom(props) {
    const { fileList, setFileList, delOSSImg } = props
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [isPhone, setIsPhone] = useState(false)
    const resourcesListItem = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.resourcesListItem
    })
    const { t } = useTranslation()

    function whatScreen() {
        var width: any = document.querySelector('html')?.offsetWidth
        var title = document.querySelector('title')
        if (width <= 768) {
            // title.innerHTML = '极小屏幕-' + width
            setIsPhone(true)
        } else {
            if (width > 768 && width <= 992) {
                // title.innerHTML = '小屏幕-' + width
            } else {
                if (width > 992 && width <= 1200) {
                    // title.innerHTML = '普通屏幕-' + width
                } else {
                    if (width > 1200) {
                        // title.innerHTML = '大屏幕-' + width
                    }
                }
            }
        }
    }
    useEffect(() => {
        whatScreen()
    }, [])

    const uploadButton = (
        <>
            {isPhone ? (
                <Button>{t('Training&Datasets.UPLOAD')}</Button>
            ) : (
                <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>{t('Training&Datasets.UPLOAD')}</div>
                </div>
            )}
        </>
    )
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile)
        }

        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    }
    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList)

    const handleCancel = () => setPreviewOpen(false)

    const uploadProps: UploadProps = {
        name: 'file',
        accept: 'image/png',
        maxCount: 1000,
        multiple: true,
        listType: isPhone ? 'picture' : 'picture-card',
        // listType: 'picture',
        onPreview: handlePreview,
        onRemove: file => {
            if (file.originFileObj) {
                const index = fileList.indexOf(file)
                const newFileList = fileList.slice()
                newFileList.splice(index, 1)
                setFileList(newFileList)
            } else {
                delOSSImg(resourcesListItem, file.name)
            }
        },
        onChange: handleChange,
        beforeUpload: file => {
            setFileList(prevState => {
                return [...prevState, file]
            })

            return false
        },
        fileList: fileList,
    }
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F56DBC',
                },

                algorithm: theme.darkAlgorithm,
            }}
        >
            <Upload {...uploadProps}>{fileList.length >= 1000 ? null : uploadButton}</Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </ConfigProvider>
    )
}
