import { ConfigProvider, Dropdown, Input, message, theme, Spin } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
const { TextArea } = Input

import resolution_1_1_image from '../../assets/v1.6/resolution_1_1.png'
import resolution_4_3_image from '../../assets/v1.6/resolution_4_3.png'
import resolution_3_4_image from '../../assets/v1.6/resolution_3_4.png'
import resolution_16_9_image from '../../assets/v1.6/resolution_16_9.png'
import resolution_9_16_image from '../../assets/v1.6/resolution_9_16.png'
import waters_back from '../../assets/waters_back.png'
import loading_pnd from '../../assets/common/watersLoading.png'

import GraphResult from './GraphResult'

import { historicalRecord, taskResult, createTask } from '../../api/newFunctionRequest'
import { useAppSelector } from '@/hooks'
import { setCanAnimate, setImageRecordList, addImageRecord, setShowRecord, setShowHistoricalRecordItem } from '../../slices/NewFunctionSlice'
import { useDispatch } from 'react-redux'
import useNewFunction from './NewFunctionHook'
import { useTranslation } from 'react-i18next'
import { setIsShowWelcomePage } from '@/slices/AppSlice'
import WelcomePage from '../Chat/WelcomePage'
import { pageEvent, timeEvent, trackEvent, trackIdentify, googleTrackEvent } from '@/lib/analyse'
import { Head } from '@/components/Head'

export default function WatersRecord(params) {
    const dispatch = useDispatch()
    useNewFunction({ generationType: 'vip' })
    const { t } = useTranslation()
    const resolutionGroup = [
        { name: '1:1', image: resolution_1_1_image, index: 0, width: 768, height: 768, shap: 'square' },
        { name: '4:3', image: resolution_4_3_image, index: 1, width: 1024, height: 768, shap: 'landscape' },
        { name: '3:4', image: resolution_3_4_image, index: 2, width: 768, height: 1024, shap: 'vertical' },
        { name: '16:9', image: resolution_16_9_image, index: 3, width: 1440, height: 810, shap: 'landscape' },
        { name: '9:16', image: resolution_9_16_image, index: 4, width: 810, height: 1440, shap: 'vertical' },
    ]
    const [showAnimation, setShowAnimation] = useState(false)
    const [selectResolution, setSelectResolution] = useState(0)
    const [searchStr, setSearchStr] = useState(null)
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const imageRecordList = useAppSelector(state => {
        return state.newFunctionSlice && state.newFunctionSlice.imageRecordList
    })
    const canAnimate = useAppSelector(state => {
        return state.newFunctionSlice && state.newFunctionSlice.canAnimate
    })
    const containerRef = useRef(null)
    const [isAtBottom, setIsAtBottom] = useState(false)
    const [canLoadMore, setCanLoadMore] = useState(false)
    const [loadMoreGroupId, setLoadMoreGroupId] = useState(null)

    const [canGenerate, setCanGenerate] = useState(true)

    useEffect(() => {
        if (authToken) {
            historicalRecordRequest()
        }
    }, [authToken])

    useEffect(() => {
        if (imageRecordList.length > 0) {
            if (canAnimate) {
                setShowAnimation(true)
                dispatch(setCanAnimate(false))
            }
        }
    }, [imageRecordList])
    useEffect(() => {
        pageEvent({ page: 'waters' })
        timeEvent('waters page time')
        return () => {
            trackEvent('waters page time')
        }
    }, [])
    const historicalRecordRequest = useCallback(async () => {
        const res = await historicalRecord({ authToken, itemCount: 10, groupTableId: 0 })
        if (res.code == 1) {
            if (res.data.groupResults) {
                dispatch(setImageRecordList(res.data.groupResults))
                if (res.data.groupResults.length == 0) {
                    setCanLoadMore(false)
                } else if (res.data.groupResults.length < 10) {
                    setCanLoadMore(false)
                } else {
                    setCanLoadMore(true)
                    setLoadMoreGroupId(res.data.groupResults[res.data.groupResults.length - 1].groupTableId)
                }
            } else {
                setCanLoadMore(false)
            }
        }
    }, [authToken])

    const generateClick = async () => {
        if (!searchStr) {
            message.error(t('newFunction.InputPlaceholder'))
            return
        }
        setCanGenerate(false)
        trackEvent('Waters generate')
        googleTrackEvent('Waters generate', 'Waters generate')
        try {
            const res = await createTask({
                authToken,
                drawType: 1,
                height: resolutionGroup[selectResolution].height,
                width: resolutionGroup[selectResolution].width,
                originPrompt: searchStr,
                shap: resolutionGroup[selectResolution].shap,
            })
            if (res.code == 1) {
                await historicalRecordRequest()
                dispatch(setShowHistoricalRecordItem(0))
                setSearchStr('')
                setCanGenerate(true)
            } else {
                message.error(res.message)
                setCanGenerate(true)
            }
        } catch (error) {
            setCanGenerate(true)
        }
    }

    const requestMoreData = async groupTableId => {
        const res = await historicalRecord({ authToken, itemCount: 20, groupTableId })
        if (res.code == 1) {
            if (res.data.groupResults) {
                dispatch(addImageRecord(res.data.groupResults))
                if (res.data.groupResults.length == 0) {
                    setCanLoadMore(false)
                } else if (res.data.groupResults.length < 20) {
                    setCanLoadMore(false)
                } else {
                    setCanLoadMore(true)
                    setLoadMoreGroupId(res.data.groupResults[res.data.groupResults.length - 1].groupTableId)
                }
            } else {
                setCanLoadMore(false)
            }
        }
    }

    useEffect(() => {
        if (isAtBottom && canLoadMore) {
            requestMoreData(loadMoreGroupId)
        }
    }, [isAtBottom, canLoadMore])

    function isMobile() {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
        )
        return flag
    }
    useEffect(() => {
        const handleScrollPC = e => {
            const container = e.target
            const isBottom = container.scrollTop + container.clientHeight + 1 > container.scrollHeight

            setIsAtBottom(isBottom)
        }

        const handleScrollMobile = () => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            setIsAtBottom(scrollTop + windowHeight === scrollHeight)
        }

        if (isMobile()) {
            window.addEventListener('scroll', handleScrollMobile, true)
        } else {
            if (containerRef.current) {
                containerRef.current.addEventListener('scroll', handleScrollPC)
            }
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('scroll', handleScrollPC)
            }
            window.removeEventListener('scroll', handleScrollMobile, true)
        }
    }, [containerRef.current])

    const isShowWelcomePage = useAppSelector(state => {
        return state.app && state.app.isShowWelcomePage
    })
    const [isPhone, setIsPhone] = useState(false)
    useEffect(() => {
        setIsPhone(isMobile())
    }, [])
    useEffect(() => {
        if (isPhone) {
            if (window.sessionStorage.getItem('isWelcome') == '1') {
                dispatch(setIsShowWelcomePage(false))
            } else {
                dispatch(setIsShowWelcomePage(true))
            }
        }
    }, [isPhone])

    const titleAreaRef = useRef(null)
    const [transitionend, setTransitionend] = useState(false)
    useEffect(() => {
        const titleAreaAlement = titleAreaRef.current

        const handleTransitionEnd = e => {
            console.log('🚀 ~ file: index.jsx:210 ~ handleTransitionEnd ~ e:', e)
            setTransitionend(true)
        }

        titleAreaAlement?.addEventListener('transitionend', handleTransitionEnd)

        return () => {
            titleAreaAlement?.removeEventListener('transitionend', handleTransitionEnd)
        }
    }, [])
    return (
        <>
            <div className="mx-auto text-[#fff] w-full min-h-full max-h-full overflow-y-scroll " ref={containerRef}>
                <div className="mx-auto w-full max-w-[1385px] px-5 pb-5 min-h-full relative">
                    <div className="flex justify-between items-center sticky top-0 pt-4 pb-1 bg-black z-10">
                        <h2 className="text-2xl font-bold">{t('newFunction.GenerationRecord')}</h2>
                        <button
                            onClick={e => dispatch(setShowRecord(false))}
                            className="flex items-center justify-center rounded-full m-bg-gradient h-[40px] w-[200px]"
                        >
                            <img className="h-4 mr-4" src={waters_back} />
                            <span className="text-base"> {t('newFunction.Back')}</span>
                        </button>
                    </div>
                    {imageRecordList && imageRecordList.length > 0 && (
                        <div>
                            {imageRecordList.map((imageRecordObj, index) => {
                                return (
                                    <div key={index}>
                                        <GraphResult
                                            imageRecordObj={imageRecordObj}
                                            historicalRecordRequest={historicalRecordRequest}
                                            containerRef={containerRef}
                                            setCanGenerate={setCanGenerate}
                                            UIPromptType={2}
                                            UIBtnType={2}
                                        ></GraphResult>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    {canLoadMore && (
                        <div className="w-full flex items-center justify-center mt-4">
                            <img src={loading_pnd} alt="" />
                        </div>
                    )}
                </div>
            </div>
            )
        </>
    )
}
