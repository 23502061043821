/*
 * @Author: yunpeng.li yunpeng.li@sdbean.com
 * @Date: 2023-04-14 17:48:26
 * @LastEditors: yunpeng.li yunpeng.li@sdbean.com
 * @LastEditTime: 2023-08-28 13:30:23
 * @FilePath: /chatgpt-web/src/pages/AIImage/TabHeader.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useCallback, useMemo } from 'react'

export type TabHeaderItem = { name: string; tab: any }
interface ITabHeaderProps {
    className?: string
    buttonClassName?: string | ((isActice: boolean) => string)
    lineClassName?: string | ((isActice: boolean) => string)
    tabValue?: TabHeaderItem | null
    tabs: TabHeaderItem[]
    onChange: (tab: TabHeaderItem) => void
}
const TabHeader: React.FC<ITabHeaderProps> = ({ tabs, onChange, tabValue, className, buttonClassName, lineClassName }) => {
    const curTab = useMemo(() => {
        return tabValue ?? tabs[0]
    }, [tabValue, tabs])

    const realButtonClassName = useCallback(
        (isActive: boolean) => {
            if (typeof buttonClassName == 'string') {
                return buttonClassName
            } else if (typeof buttonClassName == 'function') {
                return `${isActive ? '' : 'text-gray-400'} hover:text-white/90  ${buttonClassName(isActive)}`
            }
            return ''
        },
        [buttonClassName],
    )

    const realLineClassName = useCallback(
        (isActive: boolean) => {
            if (typeof lineClassName == 'string') {
                return lineClassName
            } else if (typeof lineClassName == 'function') {
                return lineClassName(isActive)
            }
            return ''
        },
        [lineClassName],
    )

    return (
        <div className={'text-lg font-bold ' + className}>
            {tabs.map(tab => {
                return (
                    <button
                        key={tab.name}
                        id={tab.name}
                        className={'outline-none ' + realButtonClassName(curTab?.tab == tab.tab)}
                        onClick={e => {
                            onChange(tab)
                        }}
                    >
                        <span>
                            <span>{tab.name}</span>
                            <div
                                className={
                                    `mt-1.5 ${curTab?.tab == tab.tab ? 'm-bg-gradient' : ''} h-0.5 scale-x-125 ` +
                                    realLineClassName(curTab?.tab == tab.tab)
                                }
                            />
                        </span>
                    </button>
                )
            })}
        </div>
    )
}

export default TabHeader
