import { t } from 'i18next'
import React, { useCallback, useContext } from 'react'
import { VaeModalContext } from './VAEModal'
import { IGenerateStateDiffusionState, IModelItem, setStableDiffusionState } from '@/slices/GenerateImageSlice'
import { AppDispatch } from '@/store'
import { useDispatch } from 'react-redux'

interface IVAEModalItemProps {
    model: IModelItem
    index: number
}
const VAEModalItem: React.FC<IVAEModalItemProps> = props => {
    const dispatch = useDispatch<AppDispatch>()
    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))

    const { model, index } = props
    const { setOpen } = useContext(VaeModalContext)
    const textRender = useCallback(
        () => (
            <div className="block lg:absolute bottom-0 p-1 pb-0.5 w-full bg-gradient-to-t from-black/60 to-black/10">
                <div className="text-white text-sm text-ellipsis line-clamp-2 mb-0.5">{model.modelName}</div>
                {/* <div className="flex flex-wrap text-xs">
                    <div className="m-bg-gradient rounded py-0.5 px-2 m-0.5">{model.modelTypeName}</div>
                </div>
                <div className="flex flex-wrap text-xs">
                    {model.categoryItemList.map((c, i) => (
                        <div key={i} className="bg-white/30 rounded py-0.5 px-2 m-0.5">
                            {t(c.modelCateName)}
                        </div>
                    ))}
                </div> */}
            </div>
        ),
        [model],
    )
    return (
        <div
            className="w-0"
            onClick={e => {
                updateState({ vaeModel: { ...model } })
                if (setOpen) {
                    setOpen(false)
                }
            }}
        >
            <div className="group relative rounded-lg bg-gray-bg overflow-hidden">
                <div className="">{textRender()}</div>
                {model.isRecommend == 1 && (
                    <span className="absolute top-1.5 right-1.5 m-bg-gradient px-2 rounded-full text-sm">{t('generatedImage.Recommended')}</span>
                )}
                <img
                    src={model.modelIcon + '?x-oss-process=image/resize,w_700/quality,Q_80'}
                    className="rounded-lg lg:rounded-none block cursor-pointer w-full"
                    alt=""
                />
                {/* <div className="block lg:hidden">{textRender()}</div> */}
            </div>
        </div>
    )
}
export default VAEModalItem
