import { useAppSelector } from '@/hooks'
import { Dropdown, Spin, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import resolution_1_1_image from '../../assets/v1.6/resolution_1_1.png'
import resolution_4_3_image from '../../assets/v1.6/resolution_4_3.png'
import resolution_3_4_image from '../../assets/v1.6/resolution_3_4.png'
import resolution_16_9_image from '../../assets/v1.6/resolution_16_9.png'
import resolution_9_16_image from '../../assets/v1.6/resolution_9_16.png'
import watersLoading from '../../assets/common/watersLoading.png'

import dropDownArrow from '../../assets/v1.6/dropDownArrow.png'
import waters_similar_more from '../../assets/waters_similar_more.png'

import loading_pnd from '../../assets/loading.png'
import { createTask, historicalRecord } from '@/api/newFunctionRequest'
import { trackEvent, googleTrackEvent } from '@/lib/analyse'
import {
    fetchWaters_similarAllImages,
    fetchWaters_similarImages,
    setCanAnimate,
    setImageRecordList,
    setShowHistoricalRecordItem,
    setShowRecord,
    setSimilarGenerateCallback,
    setTabIndex,
} from '@/slices/NewFunctionSlice'
import WatersSimilarWaterfall, { IWatersSimilarWaterFallRef } from './components/WatersSimilarWaterfall'
import { useDispatch } from 'react-redux'
import GraphResult from './GraphResult'
import _ from 'lodash'

// const resolutionGroup = [
//     { name: '1:1', image: resolution_1_1_image, index: 0, width: 512, height: 512, shap: 'square' },
//     { name: '4:3', image: resolution_4_3_image, index: 1, width: 800, height: 600, shap: 'landscape' },
//     { name: '3:4', image: resolution_3_4_image, index: 2, width: 600, height: 800, shap: 'vertical' },
//     { name: '16:9', image: resolution_16_9_image, index: 3, width: 960, height: 540, shap: 'landscape' },
//     { name: '9:16', image: resolution_9_16_image, index: 4, width: 540, height: 960, shap: 'vertical' },
// ]

const resolutionGroup = [
    { name: '1:1', image: resolution_1_1_image, index: 0, width: 768, height: 768, shap: 'square' },
    { name: '4:3', image: resolution_4_3_image, index: 1, width: 1024, height: 768, shap: 'landscape' },
    { name: '3:4', image: resolution_3_4_image, index: 2, width: 768, height: 1024, shap: 'vertical' },
    { name: '16:9', image: resolution_16_9_image, index: 3, width: 960, height: 540, shap: 'landscape' },
    { name: '9:16', image: resolution_9_16_image, index: 4, width: 540, height: 960, shap: 'vertical' },
]
import styles from './styles/index.module.css'
import DivBoundaryLoader, { IDivBoundaryLoaderRef } from '../sdModelList/DivBoundaryLoader'
import useWatersWorkbenchHook from './WatersWorkbenchHook'
import { useTranslation } from 'react-i18next'
import { useCommonSlice } from '@/slices/commonSlice'
import { AppDispatch } from '@/store'
import TabHeader, { TabHeaderItem } from '../AIImage/TabHeader'
import WatersCommunitySimilarWaterfall, {
    IWatersCommunitySimilarWaterFallProps,
    IWatersCommunitySimilarWaterFallRef,
} from './components/WatersCommunitySimilarWaterfall'
import { animate } from '@/helpers'
import PromptHelperPopover from '@/components/Popover/PromptHelperPopover'

interface IWaterWorkbenchProps {}
const WaterWorkbench: React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()
    const { t } = useTranslation()
    useWatersWorkbenchHook()

    const Tabs = [
        { name: t('newFunction.Community'), tab: 0 },
        { name: t('newFunction.Similar'), tab: 1 },
    ]
    const tabIndex = useAppSelector(state => {
        return state.newFunctionSlice.tabIndex
    })
    const communitySimilarImages = useAppSelector(state => {
        return state.newFunctionSlice.communitySimilarImages
    })
    const imageRecordList = useAppSelector(state => {
        return state.newFunctionSlice && state.newFunctionSlice.imageRecordList
    })
    const showHistoricalRecordItem = useAppSelector(state => {
        return state.newFunctionSlice && state.newFunctionSlice.showHistoricalRecordItem
    })
    const isLoadingImage = useAppSelector(state => state.newFunctionSlice.isLoadingImage)
    const [selectResolution, setSelectResolution] = useState(0)

    const [searchStr, setSearchStr] = useState<string>('')

    const authToken = useAppSelector(state => state.app?.authToken)
    const canAnimate = useAppSelector(state => state.newFunctionSlice?.canAnimate)

    const scrollRef = useRef<IDivBoundaryLoaderRef>(null)

    const [transitionend, setTransitionend] = useState(false)
    const [canGenerate, setCanGenerate] = useState(true)

    const waterFallRef = useRef<IWatersSimilarWaterFallRef>(null)
    const communityWaterFallRef = useRef<IWatersCommunitySimilarWaterFallRef>(null)

    const slideTopContect = useRef<HTMLDivElement>(null)
    const [showAnimation, setShowAnimation] = useState(false)

    const historicalRecordRequest = useCallback(
        async params => {
            const res = await historicalRecord({ authToken, itemCount: 10, groupTableId: 0 })
            if (res.code == 1) {
                if (res.data.groupResults) {
                    if (params.isFirst) {
                        if (res.data.groupResults.length == 1) {
                            setShowAnimation(true)
                        }
                    }

                    dispatch(setImageRecordList(res.data.groupResults))
                    if (res.data.groupResults.length > 0) {
                        dispatch(setTabIndex(1))
                        console.log('🚀 ~ file: WatersWorkbench.tsx:109 ~ setTab:')
                    }
                }
            }
        },
        [authToken],
    )
    useEffect(() => {
        if (imageRecordList.length > 0) {
            dispatch(fetchWaters_similarImages({ taskId: imageRecordList[0].taskResults[0].taskId, page: 1 }))
            setTransitionend(true)
            // if (canAnimate) {
            //     setShowAnimation(true)
            // }
        }
    }, [imageRecordList])
    const showRecord = imageRecordList && imageRecordList.length > 0 && showHistoricalRecordItem == 0

    // useEffect(() => {
    //     if (!showRecord) {
    //         // 历史记录 S 切换 similar
    //         if (tab) dispatch(setTab(Tabs[1]))
    //         console.log('🚀 ~ file: WatersWorkbench.tsx:131 ~ useEffect ~ setTab:')
    //     }
    // }, [showRecord])

    useEffect(() => {
        const handleScrollPC = e => {
            setShowAnimation(true)
        }

        scrollRef.current?.divElement.addEventListener('scroll', _.throttle(handleScrollPC, 1000))
        slideTopContect.current?.addEventListener('webkitAnimationEnd', function () {
            // dispatch(setCanAnimate(false))
            setTransitionend(true)
        })
        return () => {
            scrollRef.current?.divElement.removeEventListener('scroll', _.throttle(handleScrollPC, 1000))
        }
    }, [])

    useEffect(() => {
        if (authToken) {
            historicalRecordRequest({ isFirst: false })
        }
    }, [authToken])
    const generateClick = async () => {
        if (!searchStr) {
            message.error(t('newFunction.InputPlaceholder'))
            return
        }
        setCanGenerate(false)
        trackEvent('Waters generate')
        googleTrackEvent('Waters generate', 'Waters generate')
        setPromptHelperOpen(false)
        try {
            const res = await createTask({
                authToken,
                drawType: 1,
                height: resolutionGroup[selectResolution].height,
                width: resolutionGroup[selectResolution].width,
                originPrompt: searchStr,
                shap: resolutionGroup[selectResolution].shap,
            })
            if (res.code == 1) {
                await historicalRecordRequest({ isFirst: true })
                dispatch(setShowHistoricalRecordItem(0))
                setSearchStr('')
                setCanGenerate(true)
            } else {
                message.error(res.message)
                setCanGenerate(true)
            }
        } catch (error) {
            setCanGenerate(true)
        }
    }
    const items = resolutionGroup?.map((data, i) => {
        return {
            key: String(i),
            label: (
                <div className="flex items-center text-xs cursor-pointer">
                    <div className="flex items-center h-[45px]" key={data.name}>
                        <div className="w-[50px] ">
                            <img className="mx-auto" src={data.image} alt="" />
                        </div>
                        <div className="text-white text-xs pl-[10px] w-[40px]">{data.name}</div>
                    </div>
                    <div className="w-[30px]"></div>
                </div>
            ),
        }
    })
    const titleAreaRef = useRef<HTMLDivElement>(null)

    const { isPendingThunk } = useCommonSlice()

    const titleHeight = useMemo(() => {
        return titleAreaRef.current?.clientHeight ?? 0
    }, [titleAreaRef.current?.clientHeight])

    const similarLoading = isPendingThunk(fetchWaters_similarImages) || isLoadingImage
    const communitySimilarLoading = isPendingThunk(fetchWaters_similarAllImages)

    function scrollTo(top: number, animated = false) {
        if (scrollRef.current?.divElement) {
            if (animated) {
                const cur = scrollRef.current.divElement.scrollTop
                animate({
                    draw: progress => {
                        if (scrollRef.current) {
                            const sub = (top - cur) * progress
                            scrollRef.current.divElement.scrollTop = cur + sub
                        }
                    },
                    duration: 250,
                })
            } else {
                scrollRef.current.divElement.scrollTop = top
            }
        }
    }

    const scrollToTab = useCallback(() => {
        const el = document.getElementById('similarDivId')
        if (scrollRef.current?.divElement && el) {
            scrollTo(el.offsetTop - titleHeight, true)
        }
    }, [scrollRef.current?.divElement, titleHeight])

    function uvGenerateCallback() {
        if (tabIndex != 1) dispatch(setTabIndex(1))
        if (scrollRef.current?.divElement) {
            setTimeout(() => {
                scrollTo(0)
            }, 0)
        }
    }
    const uvGenerateCallbackRef = useRef(uvGenerateCallback)

    useEffect(() => {
        dispatch(setSimilarGenerateCallback(uvGenerateCallbackRef.current))
    }, [uvGenerateCallbackRef.current])

    const scrollTopsRef = useRef([0, 0])
    const handleScroll = () => {
        if (tabIndex == 0) {
            scrollTopsRef.current[0] = scrollRef.current?.divElement.scrollTop ?? 0
        } else {
            scrollTopsRef.current[1] = scrollRef.current?.divElement.scrollTop ?? 0
        }
        setPromptHelperOpen(false)
    }

    useEffect(() => {
        // 添加滚动事件监听

        const action = _.throttle(handleScroll, 1000)
        scrollRef.current?.divElement.addEventListener('scroll', action)

        // 在组件卸载时移除滚动事件监听
        return () => {
            scrollRef.current?.divElement.removeEventListener('scroll', action)
        }
    }, [tabIndex])

    useEffect(() => {
        if (tabIndex == 0) {
            if (scrollRef.current?.divElement) {
                scrollTo(scrollTopsRef.current[0], true)
            }
        } else {
            if (scrollRef.current?.divElement) {
                scrollTo(scrollTopsRef.current[1], true)
            }
        }
    }, [tabIndex])

    const showCommunityMore = useMemo(() => {
        return communitySimilarImages.length >= 100
    }, [communitySimilarImages])

    const [promptHelperOpen, setPromptHelperOpen] = useState(false)
    const inputAreaRef = useRef<HTMLDivElement>(null)
    const handlePromptHelperValue = value => {
        setSearchStr((searchStr + value.promptHelperStr).replace(/^[\u002c\uFF0C]+/, ''))
    }

    const communitySimilarListEle = useMemo(() => {
        console.log('asdasdasda: WatersCommunitySimilarWaterfall')
        return (
            <WatersCommunitySimilarWaterfall
                ref={communityWaterFallRef}
                onS={async () => {
                    dispatch(setTabIndex(1))
                    setTimeout(() => {
                        // 在下一帧执行的操作
                        scrollToTab()
                    }, 0)
                }}
                onV={() => {
                    dispatch(setTabIndex(1))
                    setTimeout(() => {
                        // 在下一帧执行的操作
                        scrollTo(0)
                    }, 0)
                }}
                onOneMore100={() => {
                    setTimeout(() => {
                        // 在下一帧执行的操作
                        scrollToTab()
                    }, 0)
                }}
            />
        )
    }, [scrollToTab, communityWaterFallRef])

    const similarListEle = useMemo(() => {
        console.log('asdasdasda: WatersSimilarWaterfall')
        return (
            <WatersSimilarWaterfall
                ref={waterFallRef}
                onS={() => {
                    scrollToTab()
                }}
            />
        )
    }, [scrollToTab, waterFallRef])

    return (
        <DivBoundaryLoader
            ref={scrollRef}
            className="w-full h-screen overflow-y-auto"
            onReachBottom={async e => {
                console.log('onReachBottom')
                if (tabIndex == 0) {
                    if (showCommunityMore) return

                    if (!communitySimilarLoading) {
                        communityWaterFallRef.current?.loadMore()
                    }
                } else {
                    if (!similarLoading) {
                        waterFallRef.current?.loadMore()
                    }
                }
            }}
        >
            <div className="relative max-w-[1383px] mx-auto px-5 pb-5  min-h-[101vh]">
                <div ref={titleAreaRef} className="lg:sticky top-0 left-0 right-0 z-20 bg-pageBgColor pb-2">
                    <div className={`${showAnimation ? 'block' : transitionend ? 'block' : 'hidden'} text-2xl font-bold h-[60px] py-[14px]`}>
                        <span>Waters</span>
                    </div>
                    <div
                        ref={slideTopContect}
                        className={`flex flex-col lg:flex-row justify-between space-y-2 lg:space-y-0 mt-4 lg:mt-0  h-[250px]  lg:h-[80vh] ${
                            transitionend ? 'items-center lg:items-start' : ' items-center'
                        }
                        } ${showAnimation ? styles['slide-top-contect'] : transitionend ? styles['slide-top-contect-end'] : ''}`}
                    >
                        <div
                            className={`flex lg:flex-row flex-col items-center lg:items-start space-y-2 lg:space-y-0  lg:space-x-2 w-full max-w-[1383px] lg:max-w-[70%] mx-auto min-h-[40px] relative  ${
                                showAnimation ? styles['slide-top'] : transitionend ? styles['slide-top-end'] : ''
                            }`}
                        >
                            <Dropdown
                                className="bg-[#1e1e1e]"
                                placement="bottom"
                                trigger={['click']}
                                menu={{
                                    items: items,
                                    triggerSubMenuAction: 'click',
                                    onClick: ({ key, keyPath, domEvent }) => {
                                        setSelectResolution(Number(key))
                                    },
                                }}
                            >
                                <div className="flex items-center w-[80px]  h-[40px] rounded-[20px] px-[12px] text-xs cursor-pointer border-gradual">
                                    <div className="flex items-center h-[40px]">
                                        <div className="w-[25px]">
                                            <img className="mx-auto max-h-[30px]" src={resolutionGroup[selectResolution].image} alt="" />
                                        </div>
                                        {/* <div className="text-white text-xs pl-[10px] w-[40px]">{resolutionGroup[selectResolution].name}</div> */}
                                    </div>
                                    <img src={dropDownArrow} className="ml-[10px] mr-[8px]" alt="" />
                                </div>
                            </Dropdown>
                            <PromptHelperPopover
                                inputAreaRef={inputAreaRef}
                                open={promptHelperOpen}
                                handlePromptHelperValue={handlePromptHelperValue}
                                showNegativePrompt={false}
                            >
                                <div
                                    ref={inputAreaRef}
                                    className={` flex-grow flex flex-col items-center lg:items-start  space-y-3 lg:space-y-0 lg:flex-row lg:space-x-2 w-full `}
                                >
                                    <button
                                        className={`${
                                            !promptHelperOpen ? 'border-gradual' : 'bg-[#6401B8]'
                                        } block  h-[40px] w-[128px] rounded-[20px] text-base font-medium disabled:from-gray-500 disabled:to-gray-500 text-white/90`}
                                        onClick={() => {
                                            setPromptHelperOpen(!promptHelperOpen)
                                        }}
                                    >
                                        {t('newFunction.PromptHelper')}
                                    </button>
                                    <div className="w-full flex-1 min-h-[40px] mx-3 text-white bg-gray-bg rounded-[20px] px-3 flex items-center">
                                        <TextArea
                                            className="text-sm border-none py-[8px] px-[4px] focus:border-none focus:shadow-none bg-gray-bg text-white placeholder:text-[rgb(157,162,173)] dark-thin-scrollbar"
                                            placeholder={t('newFunction.InputPlaceholder').toString()}
                                            autoSize={{ minRows: 1, maxRows: 6 }}
                                            value={searchStr}
                                            onChange={e => {
                                                setSearchStr(e.target.value)
                                            }}
                                            onKeyDown={e => {
                                                if (e.keyCode == 13) {
                                                    e.preventDefault()
                                                    generateClick()
                                                }
                                            }}
                                        />
                                    </div>

                                    <Spin spinning={!canGenerate}>
                                        <button
                                            className="block m-bg-gradient h-[40px] w-[128px] rounded-[20px] text-base font-medium disabled:from-gray-500 disabled:to-gray-500 text-white/90"
                                            onClick={() => {
                                                generateClick()
                                            }}
                                            disabled={!canGenerate}
                                        >
                                            {t('newFunction.Generate')}
                                        </button>
                                    </Spin>
                                </div>
                            </PromptHelperPopover>
                        </div>
                        <button
                            disabled={imageRecordList.length < 1}
                            className={`${
                                transitionend ? 'block' : 'hidden'
                            } m-bg-gradient h-[40px] rounded-[20px] px-6 text-base font-medium disabled:from-gray-500 disabled:to-gray-500 text-white/90`}
                            onClick={() => {
                                dispatch(setShowRecord(true))
                            }}
                        >
                            {t('newFunction.GenerationRecord')}
                        </button>
                    </div>
                </div>
                <div className={`text-sm text-center text-[#D1D1D1] ${showAnimation ? 'hidden' : transitionend ? 'hidden' : 'block'}`}>
                    <p>{t('newFunction.viewMoreGeneration')}</p>
                    <img className="w-[23px] h-[21px] mx-auto mt-3" src={waters_similar_more} alt="" />
                </div>

                {showRecord && (
                    <>
                        <GraphResult
                            imageRecordObj={imageRecordList[0]}
                            historicalRecordRequest={historicalRecordRequest}
                            // containerRef={containerRef}
                            setCanGenerate={setCanGenerate}
                        />
                    </>
                )}
                <div className="min-h-[100vh]">
                    <div id="similarDivId" className="mt-0">
                        <div className={`${transitionend ? 'lg:sticky top-[108px]' : ''} pt-3 pb-2 z-10 bg-black`}>
                            <TabHeader
                                tabValue={Tabs[tabIndex]}
                                className={`lg:ml-4 space-x-0 lg:space-x-10 text-lg font-normal`}
                                buttonClassName={isActice => {
                                    if (isActice) {
                                        return 'px-6 lg:px-0 p-2 lg:p-0 bg-menuBgColor lg:bg-black rounded-t-lg'
                                    } else {
                                        return 'px-6 lg:px-0 p-2 lg:p-0 rounded-t-lg'
                                    }
                                }}
                                lineClassName="hidden lg:block"
                                tabs={Tabs}
                                onChange={function (tab: TabHeaderItem): void {
                                    dispatch(setTabIndex(tab.tab))
                                }}
                            />
                        </div>
                        <div>
                            <div className={`${tabIndex == 0 ? 'h-auto' : 'h-0'} overflow-hidden`}>{communitySimilarListEle}</div>
                            <div className={`${tabIndex == 0 ? 'h-64' : 'h-0'} overflow-hidden flex items-center justify-center`}>
                                {communitySimilarLoading && <img className="h-14" src={watersLoading} />}
                            </div>
                        </div>
                        <div>
                            <div className={`${tabIndex == 1 && !isLoadingImage ? 'h-auto' : 'h-0'} overflow-hidden`}>{similarListEle}</div>
                            <div className={`${tabIndex == 1 ? 'h-64' : 'h-0'} overflow-hidden flex items-center justify-center`}>
                                {similarLoading && <img className="h-14" src={watersLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DivBoundaryLoader>
    )
}

export default WaterWorkbench
