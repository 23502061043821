// import { axios } from '@/lib/axios'
import axios from './index' //进行了二次封装了
const promotHelpsJson = import.meta.env.VITE_PROMPT_HELPEERS_JSON
//获取国家区号
export const countryarea = params => {
    return axios.get('/account/api/common/countryarea', params)
}
//geeTest Captcha
export const fetchGeeTestCaptcha = params => {
    return axios.post('/account/api/common/geetest/getCaptcha', { clientType: 'web' })
}
//geeTest validate
export const fetchGeeTestValidateCaptcha = params => {
    return axios.post('/account/api/common/geetest/validateCaptcha', params)
} //校验turnstile验证码
export const validateTrunstileCode = params => {
    return axios.post('/account/api/common/cloudflare/validateTurnstile', params)
}
//通过turnstile获取手机验证码
export const phoneVerificationCodeCloudflare = params => {
    return axios.post('/account/api/common/getPhoneVerificationCodeCloudflare', params)
}
//发送验证码
export const fetchPhoneVerificationCode = params => {
    return axios.post('/account/api/common/getPhoneVerificationCode', params)
}

//中英文翻译

export const translateWords = params => {
    return axios.post('/account/api/word/translate', params)
}

// prompthelperjson
export const promptHelperJson = params => {
    return axios.get(promotHelpsJson) 
}