import axios from './index' //进行了二次封装了

// 创建画图任务
export const createTask = params => {
    return axios.post('/mst/api/web/draw/createTask', params)
}

// 画图进度查询
export const taskResult = params => {
    return axios.post('/mst/api/web/draw/taskResult', params)
}

// 画图历史
export const historicalRecord = params => {
    return axios.post('/mst/api/web/draw/historicalRecord', params)
}

// 删除画图历史
export const deleteRecord = params => {
    return axios.post('/mst/api/web/draw/deleteRecord', params)
}

// 图片详情
export const waterHistoricalRecordDetail = params => {
    return axios.post('/mst/api/web/draw/historicaldetail', params)
}
// 图片详情
export const getWaterHistoryImageList = params => {
    return axios.post('/mst/api/web/homepage/image', params)
}

export const getWaterSimilarImageList = params => {
    return axios.post('/mst/api/web/recommend/image', params)
}
