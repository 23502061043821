import { createSelector, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface StableDiffusionModel {
    name: string
    modelId: string
}

export interface ResourceModel {
    name: string
    resourceId: string
    isCompleted: number
    ossFileDir: string
    description: string
}

export interface Model {
    icon: string
    isPublic: number
    modelId: string
    name: string
}

interface StableDiffusionState {
    modelLists: Array<StableDiffusionModel>[]
    resourcesList: Array<ResourceModel>[]
    resourcesListItem: ResourceModel | null
    listLength: string | number

    // training用的字段
    selectedModel: Model | null
    modelName: string
    modelImage: string
    modelTaskID: string
}

const initialState: StableDiffusionState = {
    modelLists: [],
    resourcesList: [],
    resourcesListItem: null,
    listLength: 'new',

    selectedModel: null,
    modelName: '',
    modelImage: '',
    modelTaskID: '',
}

const stableDiffusionState = createSlice({
    name: 'StableDiffusionModel',
    initialState: initialState,
    reducers: {
        setSelectedModel(state, action) {
            state.selectedModel = action.payload
        },
        setModelName(state, action) {
            state.modelName = action.payload
        },
        setModelImage(state, action) {
            state.modelImage = action.payload
        },
        setModelTaskID(state, action) {
            state.modelTaskID = action.payload
        },
        setModelLists(state, action) {
            state.modelLists = action.payload
        },
        setResourcesList(state, action) {
            state.resourcesList = action.payload
        },
        setResourcesListItem(state, action) {
            state.resourcesListItem = action.payload
        },
        setListLength(state, action) {
            console.log('🚀 ~ file: StableDiffusionSlice.ts:48 ~ setListLength ~ action:', action.payload)
            state.listLength = action.payload
        },
    },
    extraReducers(builder) {},
})

export const { setSelectedModel, setModelName, setModelLists, setResourcesList, setResourcesListItem, setListLength, setModelImage, setModelTaskID } =
    stableDiffusionState.actions
export default stableDiffusionState.reducer
