import { getModelList } from '@/api/stableDiffusionRequest'
import { useAppSelector } from '@/hooks'
import { Space, Table, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import loading_static from '../../../assets/loading_static.png'
export default function Index(params) {
    const { t } = useTranslation()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })

    const [modelList, setModelList] = useState([])
    useEffect(() => {
        async function getData() {
            const res = await getModelList({ authToken, includePrivate: 1, includePublic: 0, modelType: [1, 2] })
            console.log('🚀 ~ file: InputSettings.jsx:50 ~ getData ~ res1:', res)
            if (res.code == 1) {
                setModelList(res.data.models)
            }
        }
        if (authToken) {
            getData()
        }
    }, [authToken])

    return (
        <div>
            <table className="w-full">
                <tbody>
                    <tr className=" text-[#CB63DA]">
                        <th className="p-2 hidden sm:flex items-center justify-center">{t('jobStatus.table.icon')}</th>
                        <th className="p-2">{t('jobStatus.table.name')}</th>
                        <th className="p-2">{t('jobStatus.table.instanceToken')}</th>
                        <th className="p-2">{t('jobStatus.table.baseModel')}</th>
                        <th className="p-2">{t('jobStatus.table.modelType')}</th>
                        <th className="p-2">{t('jobStatus.table.status')}</th>
                    </tr>
                    {modelList.map((model, index) => {
                        return (
                            <tr className={`${index / 2 == 0 ? 'bg-[#111111]' : ''} text-center`} key={index}>
                                <td className="p-2 hidden sm:flex items-center justify-center">
                                    <img src={model.icon} alt="" className=" max-w-[50px] max-h-[50px]" />
                                </td>
                                <td className="p-2 w-[14vw] max-w-[14vw] sm:w-auto sm:max-w-none text-center whitespace-nowrap overflow-hidden text-ellipsis">
                                    {model.name}
                                </td>
                                <td className="p-2 w-[14vw] max-w-[14vw] sm:w-auto sm:max-w-none text-center whitespace-nowrap overflow-hidden text-ellipsis">
                                    {model.resources[0].prompt}
                                </td>
                                <td className="p-2 w-[14vw] max-w-[14vw] sm:w-[150px] sm:max-w-[150px] text-center whitespace-nowrap overflow-hidden text-ellipsis">
                                    {model.srcModelName}
                                </td>
                                <td className="p-2 w-[14vw] max-w-[14vw] sm:w-[150px] sm:max-w-[150px] text-center whitespace-nowrap overflow-hidden text-ellipsis">
                                    {model.modelType == 2 ? 'lora' : 'model'}
                                </td>
                                <td className="p-2 w-[14vw] max-w-[14vw] sm:w-[150px] sm:max-w-[150px] text-center whitespace-nowrap overflow-hidden text-ellipsis">
                                    {model.modelStatus == 2 ? t('jobStatus.table.dataStatus.success') : t('jobStatus.table.dataStatus.training')}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {modelList.length <= 0 && (
                <div className="h-[40vh] flex flex-col justify-center items-center text-gray-500">
                    <img className="w-32" src={loading_static} />
                    <span className="mt-4">{t('Training&Datasets.noModelTip')}</span>
                </div>
            )}
        </div>
    )
}
