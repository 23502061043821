import { useEffect, useRef, useState } from 'react'
import { historicalRecord, taskResult, createTask } from '../../api/newFunctionRequest'
import { useAppSelector } from '@/hooks'
import {
    setTaskList,
    setImageRecordList,
    updateImageRecord,
    fetchWaters_similarImages,
    setIsLoadingImage,
    call_similarGenerateCallback,
} from '../../slices/NewFunctionSlice'
import { useDispatch } from 'react-redux'

function useWatersWorkbenchHook(params) {
    const dispatch = useDispatch()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const watersWorkbenchTask = useAppSelector(state => {
        return state.newFunctionSlice && state.newFunctionSlice.watersWorkbenchTask
    })
    const similarGenerateCallback = useAppSelector(state => {
        return state.app && state.newFunctionSlice.similarGenerateCallback
    })

    const timerRef = useRef(null)

    useEffect(() => {
        if (watersWorkbenchTask !== undefined) {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
            dispatch(setIsLoadingImage(true))
            dispatch(call_similarGenerateCallback())
            taskResult({ authToken: authToken, taskGroupIds: [watersWorkbenchTask.taskGroupId] }).then(res => {
                if (res.code == 1) {
                    dispatch(updateImageRecord(res.data.groupResults))
                    if (res.data.groupResults[0].taskGroupStatus == 3) {
                        dispatch(setIsLoadingImage(false))
                        dispatch(fetchWaters_similarImages({ taskId: res.data.groupResults[0].taskResults[0].taskId, page: 1 }))
                    }
                } else {
                    dispatch(setIsLoadingImage(false))
                    clearInterval(timerRef.current)
                }
            })
            timerRef.current = setInterval(async function () {
                taskResult({ authToken: authToken, taskGroupIds: [watersWorkbenchTask.taskGroupId] }).then(res => {
                    if (res.code == 1) {
                        dispatch(updateImageRecord(res.data.groupResults))
                        if (res.data.groupResults[0].taskGroupStatus == 3) {
                            dispatch(setIsLoadingImage(false))
                            dispatch(fetchWaters_similarImages({ taskId: res.data.groupResults[0].taskResults[0].taskId, page: 1 }))
                            console.log('fetchWaters_similarImages')
                            dispatch(call_similarGenerateCallback())
                        }
                    } else {
                        dispatch(setIsLoadingImage(false))
                        clearInterval(timerRef.current)
                    }
                })
            }, 4000)
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }
    }, [watersWorkbenchTask])
}

export default useWatersWorkbenchHook
