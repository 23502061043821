/*
 * @Author: yunpeng.li yunpeng.li@sdbean.com
 * @Date: 2023-04-10 18:42:39
 * @LastEditors: yunpeng.li yunpeng.li@sdbean.com
 * @LastEditTime: 2023-05-26 13:39:45
 * @FilePath: /chatgpt-web/src/pages/AIImage/AIImageUpload.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react'
import type { UploadProps } from 'antd'
import { message, Upload } from 'antd'
import { RcFile } from 'antd/es/upload'
import { useTranslation } from 'react-i18next'
import upload_icon from '../../assets/upload_icon.png'

const { Dragger } = Upload

export interface IAIImageUploadProps {
    handleFile: (file: string | Blob | RcFile) => void
}
const AIImageUpload: React.FC<IAIImageUploadProps> = props => {
    const { t } = useTranslation()
    const draggerProps: UploadProps = {
        showUploadList: false,
        accept: '.png, .jpg, .jpeg',
        multiple: false,
        onChange(info) {
            const { status } = info.file
            if (status !== 'uploading') {
                console.log(info.file, info.fileList)
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`)
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`)
            }
        },
        onDrop(e) {},
        customRequest({ action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials }) {
            // EXAMPLE: post form-data with 'axios'
            // eslint-disable-next-line no-undef

            if ((file as File).size > 10000000) {
                message.error(t('generatedImage.errorFileSize10M'))
            } else {
                props.handleFile(file)
            }

            return {
                abort() {
                    console.log('upload progress is aborted.')
                },
            }
        },
    }

    return (
        <Dragger {...draggerProps}>
            <img className="mx-auto py-2 w-11" src={upload_icon} />
            <p className="ant-upload-text !text-sm leading-6">{t('generatedImage.uploadingInputImagesDesc')}</p>
            <p className="ant-upload-hint !text-sm leading-6">{t('generatedImage.uploadingInputImagesFromat')}</p>
        </Dragger>
    )
}

export default AIImageUpload
