import React, { useEffect, useRef, useState } from 'react'
import { Popover } from 'antd'
import './PromptHelperPopoverStyle.css'
import promprHelperArrow from '../../assets/common/promprHelperArrow.png'
import { promptHelperJson } from '../../api/apiCommon'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { setPromptHelpersLanguage } from '@/slices/AppSlice'

function content(props) {
    const { inputAreaRef, handlePromptHelperValue, showNegativePrompt } = props

    const dispatch = useAppDispatch()

    const [selectedTab1, setSelectedTab1] = useState(0)
    const [selectedTab2, setSelectedTab2] = useState(0)

    const tab1ContainerRef = useRef(null)
    const tab2ContainerRef = useRef(null)
    const [tab1ContainerCanScroll, setTab1ContainerCanScroll] = useState(false)
    const [tab2ContainerCanScroll, setTab2ContainerCanScroll] = useState(false)

    const promptHelpers = useAppSelector(state => {
        return (
            state.app &&
            state.app.promptHelpers.filter(item => {
                return showNegativePrompt ? true : item.oneTabPromptHelper.enText != 'Negative prompt'
            })
        )
    })
    const promptHelpersLanguage = useAppSelector(state => {
        return state.app && state.app.promptHelpersLanguage
    })

    useEffect(() => {
        const handleTab1Resize = () => {
            if (tab1ContainerRef.current) {
                const { scrollWidth, clientWidth } = tab1ContainerRef.current
                setTab1ContainerCanScroll(scrollWidth > clientWidth)
            }
        }
        const tab1Element = tab1ContainerRef.current
        if (tab1Element) {
            tab1Element.addEventListener('resize', handleTab1Resize)
        }
        const handleTab2Resize = () => {
            if (tab2ContainerRef.current) {
                const { scrollWidth, clientWidth } = tab2ContainerRef.current
                setTab2ContainerCanScroll(scrollWidth > clientWidth)
            }
        }
        const tab2Element = tab2ContainerRef.current
        if (tab2Element) {
            tab2Element.addEventListener('resize', handleTab2Resize)
        }
        handleTab1Resize()
        handleTab2Resize()
        return () => {
            if (tab1Element) {
                tab1Element.removeEventListener('resize', handleTab1Resize)
            }
            if (tab2Element) {
                tab2Element.removeEventListener('resize', handleTab2Resize)
            }
        }
    }, [selectedTab1, selectedTab2])

    return (
        <div
            className="h-[310px] bg-[#171717] rounded-2xl border border-[#9113FC] p-[12px]"
            style={{ width: inputAreaRef.current ? inputAreaRef.current.offsetWidth : '0' }}
        >
            <div className="w-full flex items-center justify-between">
                <div className=" relative flex items-center" style={{ width: 'calc(100% - 85px)' }}>
                    {tab1ContainerCanScroll && (
                        <img
                            src={promprHelperArrow}
                            className=" absolute w-[11px] h-[20px] left-0 top-[11px]  cursor-pointer rotate-180"
                            onClick={() => {
                                tab1ContainerRef.current.scrollTo({
                                    top: 0,
                                    left: tab1ContainerRef.current.scrollLeft - 150,
                                    behavior: 'smooth',
                                })
                            }}
                        ></img>
                    )}
                    <div className={`${tab1ContainerCanScroll ? 'mx-5' : ''} flex overflow-x-auto scrollbar-none`} ref={tab1ContainerRef}>
                        {promptHelpers.map((item, index) => {
                            return (
                                <div
                                    className={`${
                                        selectedTab1 == index ? 'border-b-[2px] border-[#9113FC] text-[#fff]' : 'text-[#505050]'
                                    }  px-[12px] py-[10px] text-[14px] font-normal  whitespace-nowrap w-max cursor-pointer`}
                                    key={index}
                                    onClick={() => {
                                        setSelectedTab1(index)
                                        setSelectedTab2(0)
                                    }}
                                >
                                    {promptHelpersLanguage == 'EN' ? item.oneTabPromptHelper.enText : item.oneTabPromptHelper.zhText}
                                </div>
                            )
                        })}
                    </div>
                    {tab1ContainerCanScroll && (
                        <img
                            src={promprHelperArrow}
                            className=" absolute w-[11px] h-[20px] right-0 top-[11px]  cursor-pointer"
                            onClick={() => {
                                tab1ContainerRef.current.scrollTo({
                                    top: 0,
                                    left: tab1ContainerRef.current.scrollLeft + 150,
                                    behavior: 'smooth',
                                })
                            }}
                        ></img>
                    )}
                </div>
                <button
                    className="relative w-[75px] h-[30px] rounded-full p-[2px] border-[1px] border-[#494949]"
                    onClick={() => {
                        dispatch(setPromptHelpersLanguage(promptHelpersLanguage == 'EN' ? 'CN' : 'EN'))
                    }}
                >
                    <div
                        className={`w-[75%] h-[24px] leading-[24px] rounded-full ${
                            promptHelpersLanguage == 'EN'
                                ? 'ml-[25%] bg-gradient-to-r from-[#BF02FC] to-[#FD5B82] '
                                : 'ml-0 bg-gradient-to-r from-[#61ACFF] to-[#8A06FF] '
                        } text-[19px] font-bold`}
                    >
                        {promptHelpersLanguage}
                    </div>
                </button>
            </div>
            <div className=" relative flex items-center" style={{ width: '100%' }}>
                {tab2ContainerCanScroll && (
                    <img
                        src={promprHelperArrow}
                        className=" absolute w-[11px] h-[20px] left-0 top-[11px] cursor-pointer rotate-180"
                        onClick={() => {
                            tab2ContainerRef.current.scrollTo({
                                top: 0,
                                left: tab2ContainerRef.current.scrollLeft - 150,
                                behavior: 'smooth',
                            })
                        }}
                    ></img>
                )}
                <div className={`${tab2ContainerCanScroll ? 'mx-5' : ''} flex overflow-x-auto scrollbar-none`} ref={tab2ContainerRef}>
                    {promptHelpers.length > 0 &&
                        promptHelpers[selectedTab1].twoPromptHelperTabs.map((item, index) => {
                            return (
                                <div
                                    className={`${
                                        selectedTab2 == index ? ' text-[#FFAC5B]' : 'text-[#505050]'
                                    }  px-[12px] py-[10px] text-[14px] font-normal  whitespace-nowrap w-max cursor-pointer`}
                                    key={index}
                                    onClick={() => {
                                        setSelectedTab2(index)
                                    }}
                                >
                                    {promptHelpersLanguage == 'EN' ? item.threeTabPromptHelper.enText : item.threeTabPromptHelper.zhText}
                                </div>
                            )
                        })}
                </div>
                {tab2ContainerCanScroll && (
                    <img
                        src={promprHelperArrow}
                        className=" absolute w-[11px] h-[20px] right-0 top-[11px] cursor-pointer"
                        onClick={() => {
                            tab2ContainerRef.current.scrollTo({
                                top: 0,
                                left: tab2ContainerRef.current.scrollLeft + 150,
                                behavior: 'smooth',
                            })
                        }}
                    ></img>
                )}
            </div>
            <div className="  overflow-y-auto h-[200px] thin-scrollbar">
                {promptHelpers.length > 0 &&
                    promptHelpers[selectedTab1].twoPromptHelperTabs[selectedTab2].threePromptHelperTabs.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className=" float-left m-[2px] cursor-pointer "
                                onClick={() => {
                                    handlePromptHelperValue({
                                        promptHelperType: promptHelpers[selectedTab1].oneTabPromptHelper.enText,
                                        promptHelperStr: promptHelpersLanguage == 'EN' ? ',' + item.enText : '，' + item.zhText,
                                    })
                                }}
                            >
                                <span
                                    className={`${
                                        promptHelpersLanguage == 'EN' ? 'bg-[#343434] active:bg-[#4C4C4C]' : 'bg-[#6401B8] active:bg-[#B069EC]'
                                    } text-[#fff] text-[14px] font-normal py-[6px] px-[18px] leading-[29px]  rounded-l-[8px]`}
                                >
                                    {item.zhText}
                                </span>
                                <span
                                    className={`${
                                        promptHelpersLanguage == 'EN' ? 'bg-[#6401B8] active:bg-[#B069EC]' : 'bg-[#343434] active:bg-[#4C4C4C]'
                                    } text-[#fff] text-[14px] font-normal py-[6px] px-[18px] leading-[29px]  rounded-r-[8px]`}
                                >
                                    {item.enText}
                                </span>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

function PromptHelperPopover(props) {
    const handleOpenChange = newOpen => {
        // console.log('🚀 ~ file: PromptHelperPopover.jsx:16 ~ handleOpenChange ~ newOpen:', newOpen)
        // setOpen(newOpen)
    }

    return (
        <Popover
            content={content(props)}
            // title="Title"
            trigger="click"
            open={props.open}
            arrow={false}
            onOpenChange={handleOpenChange}
            placement="bottomLeft"
            autoAdjustOverflow={false}
            rootClassName="prompthelper"
            // style={{ width: '300px' }}
        >
            {props.children}
        </Popover>
    )
}

export default PromptHelperPopover
