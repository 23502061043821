import { useEffect, useState, useRef } from 'react'

import { getModelList } from '@/api/stableDiffusionRequest'
import { useAppSelector } from '@/hooks'
import { setSelectedModel, setModelName, setModelImage } from '@/slices/StableDiffusionSlice'
import { useDispatch } from 'react-redux'
import { blobToBase64, uploadFiles2OSS } from '@/api/uploadFiles'
import uploadImage from '../../../assets/train_upload.png'
import './indexStyle.css'
import { message, Upload } from 'antd'
import { getOssAssumeRoleToken } from '@/api/request'
import { getUuid } from '@/helpers'
import { useTranslation } from 'react-i18next'
const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
}

export default function ModelCreate(params) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [modelType, setModelType] = useState('512model')
    const [modelList, setModelList] = useState([])
    const [modelImageUrl, setModelImageUrl] = useState('')
    const ossRef = useRef()
    const ossData = useRef()
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })

    const modelName = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.modelName
    })

    const selectedModel = useAppSelector(state => {
        return state.stableDiffusionSlice && state.stableDiffusionSlice.selectedModel
    })

    const [currentModelID, setCurrentModelID] = useState(undefined)

    useEffect(() => {
        async function requestModelList() {
            const res = await getModelList({ authToken, includePrivate: 1, includePublic: 1, modelType: [1], onlySuccess: 1 })
            if (res.code == 1) {
                if (res.data.models.length > 0) {
                    setModelList(res.data.models)
                    if (!selectedModel) {
                        dispatch(setSelectedModel(res.data.models[0]))
                        setCurrentModelID(res.data.models[0].modelId)
                    }
                }
            }
        }
        if (authToken) {
            requestModelList()
            requestOssClient()
        }
    }, [authToken])

    useEffect(() => {
        if (selectedModel) {
            setCurrentModelID(selectedModel.modelId)
        }
    }, [selectedModel])

    async function requestOssClient() {
        if (ossRef.current) return

        const res = await getOssAssumeRoleToken({ authToken, dataId: '1', dataType: 4 })
        if (res.code == 1) {
            ossData.current = res.data
            const client = uploadFiles2OSS(res.data)
            ossRef.current = client
        }
    }

    async function handleChange(e) {
        const file = e.file.originFileObj
        if (file) {
            await requestOssClient()
            const client = ossRef.current

            var fileExtension = file.name.split('.').pop().toLowerCase()
            const newFileName = getUuid() + '.' + fileExtension
            const filePath = ossData.current.dataDir + newFileName

            try {
                const result = await client?.put(filePath, file, {
                    progress: async percent => {
                        // onProgress({ percent: percent * 100 })
                        if (percent > 0.9999) {
                        }
                    },
                })
                console.log('🚀 ~ file: ModelCreate.jsx:81 ~ handleChange ~ result:', result)
                dispatch(setModelImage(result.url))
                blobToBase64(file, base64String => {
                    setModelImageUrl(base64String)
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleModelSelectedChange = e => {
        modelList.map(modelItem => {
            if (modelItem.modelId == e.target.value) {
                dispatch(setSelectedModel(modelItem))
                setCurrentModelID(modelItem.modelId)
            }
        })
    }

    const handleModelNameChange = e => {
        dispatch(setModelName(e.target.value))
    }

    return (
        <div className="flex flex-col p-2">
            <div className="flex flex-col  rounded mt-2">
                <span className="m-select-title">{t('training.model.modelName')}</span>
                <input
                    className="w-full m-input-base "
                    type="text"
                    onChange={e => {
                        handleModelNameChange(e)
                    }}
                    value={modelName}
                />
            </div>
            <div className="flex flex-col p-2 rounded mt-2 border border-trainBorderColor">
                <div className="flex flex-row space-x-4">
                    <div className="flex space-x-2 mr-4">
                        <div className="custom-checkbox">
                            <input type="checkbox" id="512model" value="512model" checked={true} disabled />
                            <label htmlFor="512model">512*Model</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col  rounded mt-2">
                <span className="m-select-title">{t('training.model.modelImage')}</span>
                <div className="mt-2">
                    <Upload name="avatar" listType="picture" showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}>
                        {modelImageUrl ? (
                            <img src={modelImageUrl} className="w-[80px] h-[80px]" />
                        ) : (
                            <div className="w-[80px] h-[80px]">
                                <img src={uploadImage} alt="" />
                            </div>
                        )}
                    </Upload>
                </div>
            </div>
            <div className="flex flex-col rounded mt-2">
                <span className="m-select-title">{t('training.model.title')}</span>
                <div className="flex w-full">
                    <select
                        className="!w-full custom-select"
                        title=""
                        onChange={e => {
                            handleModelSelectedChange(e)
                        }}
                        value={currentModelID}
                    >
                        {modelList.map((modelItem, modelIndex) => {
                            return (
                                <option value={modelItem.modelId} key={modelIndex}>
                                    {modelItem.name}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}
