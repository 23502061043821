import Cookies from 'js-cookie'

class GlobalStorage {
    // constructor() {}

    static set(key: string, value: string) {
        window.localStorage.setItem(key, value)
        Cookies.set(key, value)
    }

    static get(key: string) {
        let value: string | undefined | null = window.localStorage.getItem(key)
        if (value == null) {
            value = Cookies.get(key) ?? undefined
        }
        return value
    }

    static remove(key: string) {
        window.localStorage.removeItem(key)
        Cookies.remove(key)
    }
}

export default GlobalStorage
