import { useAppSelector } from '@/hooks'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { stMulTranslate, stPromptAssociate, stTranslate } from '../../api/request'
import { message, ConfigProvider, theme, Spin, Dropdown, Tooltip, Popover } from 'antd'

import { shallowEqual, useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import {
    IGenerateStateDiffusionState,
    setSd_historyDetailParams,
    setStableDiffusionState,
    updateSd_segmentedPrompt,
    upinsertSt_imageItem,
} from '@/slices/GenerateImageSlice'
import { useTranslation } from 'react-i18next'
import AIImageImageEditor from './AIImageImageEditor'
import ImageWaterFlow from './ImageWaterFlow'
import { STCreateTaskParams, STCreateTaskRes, useStLoading, useStRequestDraw } from './StHooks'
import { fetchSt_historicalStateRecord, fetchSd_setSelectedModel, fetchSd_defaultConfig, fetchSd_textualList } from '@/slices/GeneratedImageThunk'
import { useLocation } from 'react-router-dom'
import { useDelpoyAction } from '../sdModelList/hooks/sdModelListHook'
import SdConfigBar from './SdConfigBar'
import PhotoPreview from '@/components/PhotoPreview'
import { useHistory } from 'react-router-dom'
import SegmentedPromptTextArea from '@/pages/AIImage/components/SegmentedPromptTextArea'
import CustomSwitch from './components/CustomSwitch'
import model_modal_title_arrow from '../../assets/modelModal/model_modal_title_arrow.png'
import { resolve } from 'path'
import { useMyTranslation } from '@/components/TranslationConfig/TranslationConfig'
import { pageEvent, trackEvent, timeEvent, googleTrackEvent } from '@/lib/analyse'
import { getUuid, uploadBase64ToOSS } from '@/helpers'
import { useAliyunOSSContext } from '@/lib/AliyunOSS'
import StickyHeader from './components/StickyHeader'
import DescArea from './components/DescArea'
import { Head } from '@/components/Head'
import PromptHelperPopover from '@/components/Popover/PromptHelperPopover'

export enum ChooseModelType {
    All = 1,
    Lora,
}

interface IAIImageProps {
    name: string
}
export const STLoadingPrefix = 'STLoadingPrefix'
export function getSTLoadingId(index: number) {
    return `${STLoadingPrefix}${index}`
}
export function isSTLoadingId(id: string) {
    return id.substring(0, STLoadingPrefix.length) == STLoadingPrefix
}

const Index: React.FC<IAIImageProps> = props => {
    // return (
    //     <div className="flex">
    //         <SdConfigBar />
    //         <DescArea />
    //     </div>
    // )

    const { t } = useMyTranslation()
    const { requestOssClient, multipartUpload, defaultUpload, setOSSProgress } = useAliyunOSSContext()

    const dispatch = useDispatch<AppDispatch>()
    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))

    const history = useHistory()

    // const state = useAppSelector(state => state.generateImageSlice.stableDiffusion)
    const selectedModelItem = useAppSelector(state => state.generateImageSlice.stableDiffusion.selectedModelItem, shallowEqual)
    const uploadResponse = useAppSelector(state => state.generateImageSlice.stableDiffusion.uploadResponse, shallowEqual)
    const prompt = useAppSelector(state => state.generateImageSlice.stableDiffusion.prompt)
    const negativePrompt = useAppSelector(state => state.generateImageSlice.stableDiffusion.negativePrompt)
    const numberImage = useAppSelector(state => state.generateImageSlice.stableDiffusion.numberImage)
    const inputSize = useAppSelector(state => state.generateImageSlice.stableDiffusion.inputSize, shallowEqual)
    const modelConfig = useAppSelector(state => state.generateImageSlice.stableDiffusion.modelConfig, shallowEqual)
    const vaeModel = useAppSelector(state => state.generateImageSlice.stableDiffusion.vaeModel, shallowEqual)
    const showSegmentedPrompt = useAppSelector(state => state.generateImageSlice.stableDiffusion.showSegmentedPrompt)
    const segmentedPrompts = useAppSelector(state => state.generateImageSlice.stableDiffusion.segmentedPrompts, shallowEqual)
    const hiresCollapsed = useAppSelector(state => state.generateImageSlice.stableDiffusion.hiresCollapsed)
    const hiresConfig = useAppSelector(state => state.generateImageSlice.stableDiffusion.hiresConfig)
    const isConcontrolNet = useAppSelector(state => state.generateImageSlice.stableDiffusion.isConcontrolNet)
    const controlNetMaskImgUrl = useAppSelector(state => state.generateImageSlice.stableDiffusion.controlNetMaskImgUrl)
    const controlNets = useAppSelector(state => state.generateImageSlice.stableDiffusion.controlNets, shallowEqual)
    const controlNetSingleImgUrl = useAppSelector(state => state.generateImageSlice.stableDiffusion.controlNetSingleImgUrl)
    const textualList = useAppSelector(state => state.generateImageSlice.stableDiffusion.textualList, shallowEqual)
    const imageItemDetailShow = useAppSelector(state => state.generateImageSlice.stableDiffusion.imageItemDetailShow)
    const showNegativePrompt = useAppSelector(state => state.generateImageSlice.stableDiffusion.showNegativePrompt)

    // const shopState = useAppSelector(state => state.sdModelListSlice)

    const authToken = useAppSelector(state => {
        return state.app?.authToken
    })

    const location = useLocation()

    useEffect(() => {
        pageEvent({ page: 'stable diffusion' })
        timeEvent('stable diffusion page time')

        return () => {
            trackEvent('stable diffusion page time')
        }
    }, [])
    useEffect(() => {
        if (authToken) {
            dispatch(fetchSd_textualList(authToken))
            //全参数
            if (location?.state?.outModel) {
                dispatch(fetchSd_setSelectedModel(location?.state.outModel))
            } else if (location?.state?.historyDetailParams) {
                dispatch(setSd_historyDetailParams(location?.state?.historyDetailParams))
            } else {
                dispatch(fetchSd_defaultConfig({ modelId: selectedModelItem?.modelId ?? '0', useCache: true }))
            }

            //更新局部参数
            if (location?.state?.upscale) {
                requestEnlarge.apply(null, location?.state?.upscale)
                history.replace(location.pathname, {})
            } else if (location?.state?.variation) {
                requestVariation.apply(null, location?.state?.variation)
                history.replace(location.pathname, {})
            } else if (location?.state?.inputImage) {
                setInputUrl(location?.state?.inputImage, location?.state?.inputImage)
            }
        }
    }, [location, authToken])

    const historyRecorderC = useRef(null)

    const { createTask, requestDetailDrawResult } = useStRequestDraw({
        beforeCreate: (params: STCreateTaskParams) => {
            updateState({ imageItems: [] })
        },
        afterCreate: (params: STCreateTaskParams, taskIds: string[]) => {
            historyRecorderC.current && (historyRecorderC.current as any).childMethod() // 刷新历史图片记录
        },
        start: (result: STCreateTaskRes) => {
            // const successData = result.successData
            const resTaskId = result.curTaskId

            dispatch(
                upinsertSt_imageItem({
                    taskId: resTaskId,
                    imageItem: {
                        generating: true,
                    },
                }),
            )
        },
        polling: (result: STCreateTaskRes) => {
            const successData = result.successData
            const curTaskId = result.curTaskId
            dispatch(
                upinsertSt_imageItem({
                    taskId: curTaskId,
                    imageItem: {
                        generating: true,
                        generatedImageProgress: successData.progress,
                    },
                }),
            )
        },
        end: (result: STCreateTaskRes) => {
            const successData = result.successData
            const errorMessage = result.errorMessage
            const curTaskId = result.curTaskId

            console.log('🚀 ~ file: index.tsx:137 ~ successData:', successData, 'errorMessage:', errorMessage)
            if (errorMessage || errorMessage == '') {
                // stopTimer(true, curTaskId ? curTaskId : STLoadingPrefix)
                if (errorMessage.length > 0) {
                    message.error(errorMessage)
                }
            } else {
                //成功
                const imageList = successData.imageList
                dispatch(
                    upinsertSt_imageItem({
                        taskId: curTaskId,
                        imageItem: {
                            taskId: curTaskId,
                            generatedImageList: imageList,
                            generatedImageHeight: successData.height,
                            generatedImageWidth: successData.width,
                            taskType: successData.taskType,
                        },
                    }),
                )
                // stopTimer(false, curTaskId)
            }
        },
    })

    const requestVariation = async (rootTaskId: string) => {
        // let a: STCreateTaskParams

        createTask({
            taskId: rootTaskId,
            taskType: 5,
        })
    }

    const requestEnlarge = useCallback(
        async (rootTaskId: string, width: number, height: number, toEnlargeUrl: string) => {
            // let a: STCreateTaskParams

            createTask({
                imageUrl: toEnlargeUrl,
                targetWidth: width * 2,
                targetHeight: height * 2,
                taskId: rootTaskId,
                // apiVersion: 2,
            })
        },
        [createTask],
    )

    const { fetchDeployStatePoll, fetchDeployState, fetchToDeploy, deployStatus } = useDelpoyAction(authToken)

    const [deployLoading, setDeployLoading] = useState(false)
    const requestImage = async () => {
        // if (state.showSegmentedPrompt) {
        //     const exits = Object.values(state.segmentedPrompts).filter(p => p.length > 0)
        //     if (exits.length < 1) {
        //         message.error(t('generatedImage.pleaseEnterPrompt'))
        //         return
        //     }
        // } else {
        //     if (!state.prompt || state.prompt.length < 1) {
        //         message.error(t('generatedImage.pleaseEnterPrompt'))
        //         return
        //     }
        // }

        // message.info(t('generatedImage.generatedImageToast'))

        const loraModels = selectedModelItem?.subUsedModels?.map(item => ({
            lora_model_id: item.modelId,
            // lora_model_is_public: item.isPublic,
            lora_weight: item.weight!,
        }))

        try {
            setDeployLoading(true)

            const loraModelIds = loraModels?.map(item => item.lora_model_id)
            let modelIds = [selectedModelItem!.modelId]
            if (loraModelIds) {
                modelIds = [...loraModelIds, ...modelIds]
            }
            const { resultStatus, needToDeployIds, deployingIds } = await fetchDeployState(modelIds)

            console.log('🚀 ~ file: index.tsx:289 ~ requestImage ~ state.selectedModelItem:', selectedModelItem)

            if (resultStatus == 2) {
                trackEvent('createTask', { imageNum: numberImage })
                googleTrackEvent('createTask', 'createTask')

                let params: STCreateTaskParams = {
                    imageUrl: uploadResponse.url || undefined,
                    modelId: selectedModelItem!.modelId,
                    apiVersion: 2,
                    negativePrompt: negativePrompt,
                    prompt: prompt,
                    width: inputSize.width,
                    height: inputSize.height,
                    imageNum: numberImage,
                    taskId: selectedModelItem?.lastTaskId,
                    loraModels: loraModels && loraModels.length > 0 ? loraModels : undefined,

                    cfgScale: modelConfig?.cfgScale, //1-30
                    clipSkip: modelConfig?.clipSkip, //1-12
                    denoisingStrength: modelConfig?.denoisingStrength,
                    ensd: modelConfig?.ensd,
                    samplingId: modelConfig?.samplingId,
                    samplingStep: modelConfig?.samplingSteps,
                    seed: modelConfig?.seed,
                    vaeModelId: vaeModel?.modelId,
                }

                if (showSegmentedPrompt) {
                    const subParams = {}
                    for (const key of Object.keys(segmentedPrompts)) {
                        const v = segmentedPrompts[key]
                        if (v && v.length > 0) {
                            subParams[key] = v
                        }
                    }

                    params = {
                        ...params,
                        ...subParams,
                    }
                } else {
                    params = {
                        ...params,
                        prompt: prompt,
                    }
                }

                // if (!hiresCollapsed && !uploadResponse.url) {
                if (!hiresCollapsed) {
                    //hire
                    params = {
                        ...params,
                        //hire
                        useHires: !hiresCollapsed ? 1 : 0,
                        hiresUpscaleBy: hiresConfig.upscaleBy,
                        hiresUpscaleDenoisingStrength: hiresConfig.denoisingStrength,
                        hiresUpscaleSteps: hiresConfig.hiresSteps,
                        hiresUpscaler: hiresConfig.upscaler,
                    }
                }
                if (isConcontrolNet && !isXl) {
                    if (!controlNetSingleImgUrl.url) {
                        message.error(t('generatedImage.ControlNet.MustUploadImage'))
                        return
                    }

                    if (controlNetMaskImgUrl.base64StringOrUrl) {
                        let ossInfo = await requestOssClient(2)
                        const newFileName = getUuid() + '.' + 'png'
                        const filePath = ossInfo.dataDir
                        let result = await defaultUpload({
                            client: ossInfo.client,
                            file: uploadBase64ToOSS(controlNetMaskImgUrl.base64StringOrUrl),
                            filePath: filePath,
                            fileName: newFileName,
                        })
                        params = {
                            ...params,
                            controlNets: [
                                {
                                    ...controlNets,
                                    singleImgUrl: controlNetSingleImgUrl.url + '?x-oss-process=image/resize,w_240',
                                    maskImgUrl: result.name,
                                },
                            ],
                        }
                    } else {
                        params = {
                            ...params,
                            controlNets: [
                                {
                                    ...controlNets,
                                    singleImgUrl: controlNetSingleImgUrl.url,
                                },
                            ],
                        }
                        console.log(controlNetSingleImgUrl.url)
                    }
                }

                createTask(params)
            } else {
                if (needToDeployIds.length > 0) {
                    fetchToDeploy(needToDeployIds)
                }

                const ids = deployingIds.concat(needToDeployIds)
                history.push('/tools/ModelList/ModelDetail/' + ids[0])
            }
        } catch (e: any) {
            console.log(e)
            message.error(e.message || t('generatedImage.errorServiceBusy'))
        } finally {
            setDeployLoading(false)
        }
    }

    const { loadingItem, spinning } = useStLoading()

    //恢复生图状态
    useEffect(() => {
        if (authToken && !spinning) {
            dispatch(fetchSt_historicalStateRecord(authToken))
                .unwrap()
                .then(res => {
                    const taskIds = res.taskIds as string[]
                    if (taskIds && taskIds.length > 0 && !spinning) {
                        requestDetailDrawResult({} as any, taskIds.slice(0, 4))
                    }
                })
        }
        return () => {}
    }, [authToken, spinning])

    useEffect(() => {
        updateState({
            requestImageActions: {
                createTask: createTask,
                upscale: requestEnlarge,
                variation: requestVariation,
            },
        })
    }, [])

    const [textualPopoverOpen, setTextualPopoverOpen] = useState(false)

    const headRender = useCallback(
        function () {
            return (
                <div className="flex items-center mb-5 justify-between">
                    <h3 className="text-lg sm:text-2xl font-bold leading-6 text-white/90">{props['name']}</h3>
                </div>
            )
        },
        [props['name']],
    )

    const setInputUrl = useCallback((ossName: string, showUrl: string) => {
        updateState({ uploadResponse: { url: ossName, base64StringOrUrl: showUrl } })
    }, [])

    const TipInfo: React.FC<{ title: string; className?: string }> = ({ title, className }) => {
        return (
            <Tooltip title={title}>
                <div data-tool-target="tooltip-default" className={className}>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10 16.8334C13.4978 16.8334 16.3334 13.9978 16.3334 10.5C16.3334 7.00222 13.4978 4.16669 10 4.16669C6.50222 4.16669 3.66669 7.00222 3.66669 10.5C3.66669 13.9978 6.50222 16.8334 10 16.8334Z"
                            stroke="white"
                            strokeOpacity="0.5"
                            strokeWidth="1.2"
                            strokeLinecap="square"
                        ></path>
                        <path
                            d="M9.25 7.75C9.25 7.94891 9.32902 8.13968 9.46967 8.28033C9.61032 8.42098 9.80109 8.5 10 8.5C10.1989 8.5 10.3897 8.42098 10.5303 8.28033C10.671 8.13968 10.75 7.94891 10.75 7.75C10.75 7.55109 10.671 7.36032 10.5303 7.21967C10.3897 7.07902 10.1989 7 10 7C9.80109 7 9.61032 7.07902 9.46967 7.21967C9.32902 7.36032 9.25 7.55109 9.25 7.75Z"
                            fill="white"
                            fillOpacity="0.5"
                        ></path>
                        <path
                            d="M10.375 9.5H9.625C9.55625 9.5 9.5 9.55625 9.5 9.625V13.875C9.5 13.9437 9.55625 14 9.625 14H10.375C10.4438 14 10.5 13.9437 10.5 13.875V9.625C10.5 9.55625 10.4438 9.5 10.375 9.5Z"
                            fill="white"
                            fillOpacity="0.5"
                        ></path>
                    </svg>
                </div>
            </Tooltip>
        )
    }

    function textualInversionContentRander() {
        return (
            <>
                {textualList && (
                    <div className="flex flex-col items-start space-y-2">
                        {textualList.map((textualItem, index) => {
                            return (
                                <span
                                    className="flex items-center whitespace-nowrap min-w-[200px]"
                                    onClick={e => updateState({ negativePrompt: negativePrompt + ',' + textualItem.modelSD })}
                                    key={index}
                                >
                                    <span>{textualItem.textualName}</span>
                                    <TipInfo title={t(textualItem.textualDesc)} className="inline-block" />
                                </span>
                            )
                        })}
                    </div>
                )}
            </>
        )
    }

    const [isColumn, setIsColumn] = useState(false)

    useEffect(() => {
        const el = document.getElementById('structure-id')
        function resizeHandle() {
            const styles = window.getComputedStyle(el!)
            const direction = styles.getPropertyValue('flex-direction')
            console.log('🚀 ~ file: index.tsx:765 ~ useEffect ~ direction:', direction)
            setIsColumn(direction == 'column')
        }
        resizeHandle()
        window.addEventListener('resize', resizeHandle)
        return () => {
            window.removeEventListener('resize', resizeHandle)
        }
    }, [])

    const outContainerRef = useRef<HTMLDivElement>(null)

    const promptAddRef = useRef<HTMLDivElement>(null)
    const [promptHelperOpen, setPromptHelperOpen] = useState(false)

    function handlePromptHelperValue({ promptHelperType, promptHelperStr }: { promptHelperType: string; promptHelperStr: string }) {
        console.log('🚀 ~ file: DescArea.tsx:151 ~ handlePromptHelperValue ~ promptHelperType:', promptHelperType)
        const mapKey = {
            Subject: 'segmentSubject',
            Characteristics: 'segmentDescribeSubject',
            Environment: 'segmentScene',
            'Visual style': 'segmentStyle',
            Quality: 'segmentQuality',
            'Artistic style': 'segmentArtist',
        }
        if (promptHelperType == 'Negative prompt') {
            if (!negativePrompt) {
                promptHelperStr = promptHelperStr.replace(/^[\u002c\uFF0C]+/, '')
            }
            console.log('🚀 ~ file: DescArea.tsx:162 ~ handlePromptHelperValue ~ promptHelperStr:', negativePrompt, promptHelperStr)

            updateState({ negativePrompt: (negativePrompt ?? '') + promptHelperStr })
            if (!showNegativePrompt) {
                updateState({ showNegativePrompt: true })
            }
        } else if (showSegmentedPrompt) {
            const key = mapKey[promptHelperType]
            const pre = segmentedPrompts[key]
            if (!pre) {
                promptHelperStr = promptHelperStr.replace(/^[\u002c\uFF0C]+/, '')
            }
            dispatch(updateSd_segmentedPrompt({ [key]: (pre ?? '') + promptHelperStr }))
        } else {
            if (!prompt) {
                promptHelperStr = promptHelperStr.replace(/^[\u002c\uFF0C]+/, '')
            }
            updateState({ prompt: (prompt ?? '') + promptHelperStr })
        }
    }

    const isXl = useMemo(() => {
        return (selectedModelItem?.isXl || selectedModelItem?.subUsedModels?.[0]?.isXl) ?? 0
    }, [selectedModelItem])

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F56DBC',
                },
                algorithm: theme.darkAlgorithm,
            }}
        >
            <Head title={'Stable Diffusion - MinisterAI'} />

            <div className="grow flex h-screen flex-col bg-black lg:pt-0">
                <div
                    id="structure-id"
                    ref={isColumn ? outContainerRef : undefined}
                    className={`${isColumn ? 'overflow-y-auto' : 'overflow-clip'} flex grow flex-col sm:flex-row h-full`}
                >
                    <SdConfigBar />
                    <div
                        ref={isColumn ? undefined : outContainerRef}
                        className={`w-full focus:outline-none ${isColumn ? '' : 'h-full overflow-y-auto'} `}
                    >
                        <div className="xl:max-w-[1280px] xl:mx-auto bg-black relative mx-auto xl:px-0 ">
                            <div className="pb-10">
                                <div className="min-h-[90vh] px-4 py-0 sm:rounded-md">
                                    <PromptHelperPopover
                                        handlePromptHelperValue={handlePromptHelperValue}
                                        inputAreaRef={promptAddRef}
                                        open={promptHelperOpen}
                                        showNegativePrompt={true}
                                    >
                                        <div className="">
                                            <StickyHeader>
                                                <div className="hidden sm:block">{headRender()}</div>
                                            </StickyHeader>

                                            <DescArea setPromptHelperOpen={setPromptHelperOpen} promptHelperOpen={promptHelperOpen} />

                                            <div
                                                ref={promptAddRef}
                                                className="w-full flex items-center justify-center flex-col-reverse md:flex-row-reverse md:justify-between py-2"
                                            >
                                                <button
                                                    onClick={() => {
                                                        trackEvent('SD button event', {
                                                            button_type: isConcontrolNet ? 'generate controlNet' : 'generate',
                                                        })
                                                        googleTrackEvent('SD button event', isConcontrolNet ? 'generate controlNet' : 'generate')
                                                        requestImage()
                                                        setPromptHelperOpen(false)
                                                    }}
                                                    disabled={loadingItem?.generating || deployLoading ? true : false}
                                                    className={`flex justify-center items-center rounded-full w-3/4 md:w-auto px-9 py-1 font-medium m-bg-gradient disabled:from-gray-500 disabled:to-gray-500 text-white/90 text-base disabled:cursor-not-allowed`}
                                                >
                                                    <span>{t('generatedImage.generate')!}</span>
                                                </button>
                                                <div className={`${showNegativePrompt ? 'flex' : 'hidden'}`}>
                                                    <div className="flex-1 w-full">
                                                        <Popover
                                                            placement="bottomLeft"
                                                            trigger="click"
                                                            content={textualInversionContentRander()}
                                                            onOpenChange={open => {
                                                                setTextualPopoverOpen(open)
                                                            }}
                                                        >
                                                            <div className="w-[200px] border-gradual-reverse flex justify-between items-center bg-black rounded-md cursor-pointer">
                                                                <div className="text-xs h-[30px] leading-[30px] px-[11px]">Textual Inversion</div>
                                                                <div>
                                                                    <img
                                                                        className={`scale-75 mr-1 ${textualPopoverOpen ? 'rotate-180' : 'rotate-0'}`}
                                                                        src={model_modal_title_arrow}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </PromptHelperPopover>

                                    <StickyHeader>
                                        <h2 className="mb-8 text-2xl font-bold text-white/90">{t('generatedImage.generatedImages')}</h2>
                                    </StickyHeader>
                                    <AIImageImageEditor />
                                    <ImageWaterFlow
                                        ref={historyRecorderC}
                                        pageType={'sd'}
                                        requestEnlarge={requestEnlarge}
                                        outContainerRef={outContainerRef}
                                        setInputUrl={setInputUrl}
                                    />
                                    {imageItemDetailShow?.show && imageItemDetailShow?.taskId && (
                                        <PhotoPreview
                                            imageObj={{ taskId: imageItemDetailShow.taskId }}
                                            type={'sd'}
                                            // deleteImageM={() => {
                                            //     dispatch(fetchSd_deleteImageRecord(state.imageItemDetailShow!.taskId!))
                                            // }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    )
}

export default Index
