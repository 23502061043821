import React, { useState } from 'react'
import { Col, InputNumber, Row, Slider } from 'antd'

export interface IInputSliderProps {
    min: number
    max: number
    value: number
    step: number
    onChange: (newValue: number) => void
}
const InputSlider: React.FC<IInputSliderProps> = props => {
    return (
        <div className="flex-1 flex justify-between items-center w-full">
            <div className="mr-1 flex-1">
                <Slider
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    onChange={props.onChange}
                    value={typeof props.value === 'number' ? props.value : 0}
                    trackStyle={{ background: 'linear-gradient(to right, #786CFD,#F56DBC)' }}
                    railStyle={{ backgroundColor: 'black' }}
                />
            </div>
            <div className="shrink-0 grow-0 w-11 h-6 p-0.5 rounded-md bg-black text-white/90 flex justify-center items-center">
                <InputNumber
                    className="-mx-1 m-ant-input-center sm:text-xs"
                    bordered={false}
                    controls={false}
                    value={props.value}
                    onChange={e => props.onChange(Number(e))}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    // maxLength={props.max.toString().length}
                />
            </div>
        </div>
    )
}

export default InputSlider
