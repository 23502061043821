import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import en from './en.json'
import zh from './zh.json'

const resources = {
    en: {
        translation: en,
    },
    zh: {
        translation: zh,
    },
}

i18n.use(detector).use(initReactI18next).init({
    fallbackLng: 'en',
    resources,
})

export default i18n
