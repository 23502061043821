import {
    IModelItem,
    ISelectedModelItem,
    addSd_privateModels,
    addSd_publicModels,
    privateModelsAdapter,
    setStableDiffusionState,
    updateSd_hiresConfig,
} from '@/slices/GenerateImageSlice'
import { t } from 'i18next'
import { MJCreateTaskParams, MJCreateTaskRes } from '@/pages/Midjourney/MjHooks'
import { STCreateTaskParams, STCreateTaskRes } from '@/pages/AIImage/StHooks'
import { getSTLoadingId } from '@/pages/AIImage'
import {
    midJourneyDrawTaskResult,
    midJourneyHistoricalStateRecord,
    stDrawTaskResult,
    stCreateTask,
    stHistoricalStateRecord,
    sdModelListType,
    sdModelListModalPublic,
    sdModelListModalPrivate,
    sdModelDefaultConfig,
    sdLoraParentList,
    sdVaeModelList,
    historicalRecordDelete,
    textualList,
} from '@/api/request'
import { createAsyncThunk, isPending } from '@reduxjs/toolkit'
import {
    upinsertSt_imageItem,
    deleteSt_imageItem,
    IGenerateImageProgress,
    IStImageListItem,
    updateSd_modalState,
    setSd_vaeModel,
    updateSd_modelConfig,
    setSd_selectedModel,
    setSd_publicModels,
    setSd_privateModels,
    updateSd_publicModelOne,
    updateSd_privateModelOne,
    removeSd_privateModelOne,
    setSd_textualList,
} from './GenerateImageSlice'
import { RootState } from '@/store'
import { ISdModelListConfigItem } from './sdModelListSlice'
import { ModelListTab } from '@/pages/AIImage/modal/ModelListModal'
import { modelCollect, modelTop } from '@/pages/sdModelList/api/apiModel'
import { AnyCnameRecord } from 'dns'

export const fetchSd_deleteImageRecord = createAsyncThunk(
    'sdModelListSlice/fetchSd_modelTop',
    async (taskId: string, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken
        try {
            const res = await historicalRecordDelete({
                taskId: taskId,
                // taskImgId: selectDeleteImageObj.taskImageId,
                authToken: authToken,
            })
            if (res.code == 1) {
                thunkApi.dispatch(deleteSt_imageItem({ taskId: taskId }))
                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error(e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
    {
        condition: (taskId: string, thunkApi) => {
            const isPending = (thunkApi.getState() as RootState).commonSlice.requests[fetchSd_deleteImageRecord.typePrefix] == 'pending'
            return !isPending
        },
    },
)

export const fetchSd_modelTop = createAsyncThunk(
    'sdModelListSlice/fetchSd_modelTop',
    async (params: { modelId: string; type: number; index: number }, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken
        const sdState = (thunkApi.getState() as RootState).generateImageSlice.stableDiffusion
        try {
            const isTop = params.type
            if (sdState.modalTab?.tab == ModelListTab.Public) {
                console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', 'public')
                thunkApi.dispatch(
                    updateSd_publicModelOne({
                        id: params.modelId,
                        changes: { isTop: isTop },
                    }),
                )
            } else {
                thunkApi.dispatch(
                    updateSd_privateModelOne({
                        id: params.modelId,
                        changes: { isTop: isTop },
                    }),
                )
            }
            const res = await modelTop({ authToken, modelId: params.modelId.toString(), type: params.type })
            console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', res)
            if (res.code == 1) {
                thunkApi.dispatch(fetchSt_modalPrivateList({}))
                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error(e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
    {
        condition: (params: { modelId: string; type: number; index: number }, thunkApi) => {
            const isPending = (thunkApi.getState() as RootState).commonSlice.requests[fetchSd_modelTop.typePrefix] == 'pending'
            return !isPending
        },
    },
)

export const fetchSd_modelCollect = createAsyncThunk(
    'generateImageSlice/fetchSd_modelCollect',
    async (params: { modelId: string; type: number }, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken
        const sdState = (thunkApi.getState() as RootState).generateImageSlice.stableDiffusion
        try {
            const accountModelType = params.type == 1 ? '2' : '0'
            if (sdState.modalTab?.tab == ModelListTab.Public) {
                console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', 'public')
                thunkApi.dispatch(
                    updateSd_publicModelOne({
                        id: params.modelId,
                        changes: { accountModelType: accountModelType },
                    }),
                )
            } else {
                thunkApi.dispatch(
                    updateSd_privateModelOne({
                        id: params.modelId,
                        changes: { accountModelType: accountModelType },
                    }),
                )
            }

            const res = await modelCollect({ authToken, modelId: params.modelId.toString(), type: params.type })
            console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', res)
            if (res.code == 1) {
                const accountModelType = params.type == 1 ? '2' : '0'
                if (sdState.modalTab?.tab == ModelListTab.Public) {
                    console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', 'public')
                    thunkApi.dispatch(
                        updateSd_publicModelOne({
                            id: params.modelId,
                            changes: { accountModelType: accountModelType },
                        }),
                    )
                } else {
                    thunkApi.dispatch(
                        updateSd_privateModelOne({
                            id: params.modelId,
                            changes: { accountModelType: accountModelType },
                        }),
                    )
                    if (params.type == 2) {
                        thunkApi.dispatch(removeSd_privateModelOne(params.modelId))
                    }
                }
                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error(e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
    {
        condition: (params: { modelId: string; type: number }, thunkApi) => {
            const isPending = (thunkApi.getState() as RootState).commonSlice.requests[fetchSd_modelCollect.typePrefix] == 'pending'
            return !isPending
        },
    },
)

export const fetchSd_setSelectedModel = createAsyncThunk(
    'generateImageSlice/fetchSd_setSelectedModel',
    async (model: ISelectedModelItem, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken
        const sdState = () => (thunkApi.getState() as RootState).generateImageSlice.stableDiffusion

        const preId = sdState().selectedModelItem?.modelId

        thunkApi.dispatch(setSd_selectedModel(model))

        if (preId != model.modelId) {
            await thunkApi.dispatch(fetchSd_defaultConfig({ modelId: model.modelId, useCache: true }))
        }
    },
)

export const fetchSd_defaultConfig = createAsyncThunk(
    'generateImageSlice/fetchSd_defaultConfig',
    async ({ modelId, useCache }: { modelId: string; useCache?: boolean }, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken
        const sdState = (thunkApi.getState() as RootState).generateImageSlice.stableDiffusion
        try {
            const res = await sdModelDefaultConfig({ authToken, modelId })
            if (res.code == 1) {
                const data = { ...res.data }
                if (modelId == '0') {
                    thunkApi.dispatch(setSd_selectedModel(data.checkpoint))
                }
                thunkApi.dispatch(setSd_vaeModel(data.vae))

                delete data.checkpoint
                delete data.vae

                const cacheObj = (thunkApi.getState() as RootState).generateImageSlice.stableDiffusion.modelConfigCache[modelId]
                if (useCache && cacheObj) {
                    console.log('🚀 ~ file: GeneratedImageThunk.ts:215 ~ cacheObj:', cacheObj)

                    thunkApi.dispatch(updateSd_modelConfig(cacheObj))
                } else {
                    thunkApi.dispatch(updateSd_modelConfig(data))
                }

                thunkApi.dispatch(
                    updateSd_hiresConfig({
                        upscaler: data.hiresUpscaler,
                        hiresSteps: data.hiresUpscaleSteps,
                        denoisingStrength: data.hiresUpscaleDenoisingStrength,
                        upscaleBy: data.hiresUpscaleBy,
                    }),
                )

                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error('🚀 ~ file: sdModelListSlice.ts:92 ~ fetchPublicModelList ~ e:', e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
)

export const fetchSd_textualList = createAsyncThunk('generateImageSlice/fetchSd_textualList', async (params: any, thunkApi) => {
    const authToken = (thunkApi.getState() as any).app.authToken
    try {
        const res = await textualList({ authToken })
        console.log('🚀 ~ file: GeneratedImageThunk.ts:234 ~ constfetchSd_textualList=createAsyncThunk ~ res:', res)
        if (res.code == 1) {
            thunkApi.dispatch(setSd_textualList(res.data.textualItemList))
        } else {
            return thunkApi.rejectWithValue(new Error(res.message))
        }
    } catch (e) {
        console.error('🚀 ~ file: sdModelListSlice.ts:92 ~ fetchPublicModelList ~ e:', e)
        return thunkApi.rejectWithValue(new Error(''))
    }
})

export const fetchSd_loraParentList = createAsyncThunk('generateImageSlice/fetchSd_loraParentList', async (params: { modelId: string }, thunkApi) => {
    const authToken = (thunkApi.getState() as any).app.authToken
    const sdState = (thunkApi.getState() as RootState).generateImageSlice.stableDiffusion

    try {
        const res = await sdLoraParentList({ authToken, modelId: params.modelId })
        if (res.code == 1) {
            // thunkApi.dispatch(updateSd_modalState({ parentModelList: res.data.relevanceList }))
            return res.data.relevanceList
        } else {
            return thunkApi.rejectWithValue(new Error(res.message))
        }
    } catch (e) {
        console.error('🚀 ~ file: sdModelListSlice.ts:92 ~ fetchPublicModelList ~ e:', e)
        return thunkApi.rejectWithValue(new Error(''))
    }
})

export const fetchSt_modalVaeList = createAsyncThunk('generateImageSlice/fetchSt_modalVaeList', async (params: { modelId: string }, thunkApi) => {
    const authToken = (thunkApi.getState() as any).app.authToken
    const p = {
        modelId: params.modelId,
    }
    try {
        const res = await sdVaeModelList({ authToken, ...p })
        if (res.code == 1) {
            thunkApi.dispatch(setStableDiffusionState({ vaeModelList: res.data.vaeModelList }))
            return res.data
        } else {
            return thunkApi.rejectWithValue(new Error(res.message))
        }
    } catch (e) {
        console.error('🚀 ~ file: sdModelListSlice.ts:92 ~ fetchPublicModelList ~ e:', e)
        return thunkApi.rejectWithValue(new Error(''))
    }
})

export const fetchSt_modalPublicList = createAsyncThunk(
    'generateImageSlice/fetchSt_modalPublicList',
    async (params: { modelId?: string; lastModelId?: string }, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken
        const sdState = (thunkApi.getState() as RootState).generateImageSlice.stableDiffusion

        let selectModelId: string | undefined = undefined
        if (params.modelId) {
            //选 lora
            selectModelId = sdState.selectedModelItem?.modelId
        } else {
            //选主
            const firstLora = sdState.selectedModelItem?.subUsedModels?.[0]
            if (firstLora) {
                selectModelId = firstLora.modelId
            }
        }

        const p = {
            selectModelId,
            isMainModel: params.modelId ? 0 : 1,
            lastModelId: params.lastModelId,
            category: (sdState.modalState.curCategory?.data as ISdModelListConfigItem)?.systemDicId ?? 0,
            search: sdState.modalState.curSearch,
            sort: sdState.modalState.curSort?.data ?? 0,
            type: (sdState.modalState.curType?.data as ISdModelListConfigItem)?.systemDicId ?? 0,
        }
        try {
            const res = await sdModelListModalPublic({ authToken, ...p })
            if (res.code == 1) {
                if (params.lastModelId) {
                    thunkApi.dispatch(addSd_publicModels(res.data.publicModelList))
                } else {
                    thunkApi.dispatch(setSd_publicModels(res.data.publicModelList))
                }
                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error('🚀 ~ file: sdModelListSlice.ts:92 ~ fetchPublicModelList ~ e:', e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
)

export const fetchSt_modalPrivateList = createAsyncThunk(
    'generateImageSlice/fetchSt_modalPrivateList',
    async (params: { modelId?: string; lastModelId?: string }, thunkApi) => {
        const authToken = (thunkApi.getState() as RootState).app.authToken

        const sdState = (thunkApi.getState() as RootState).generateImageSlice.stableDiffusion

        let selectModelId: string | undefined = undefined
        if (params.modelId) {
            //选 lora
            selectModelId = sdState.selectedModelItem?.modelId
        } else {
            //选主
            const firstLora = sdState.selectedModelItem?.subUsedModels?.[0]
            if (firstLora) {
                selectModelId = firstLora.modelId
            }
        }

        const p = {
            selectModelId,
            isMainModel: params.modelId ? 0 : 1,
            lastModelId: params.lastModelId,
            category: (sdState.modalState.curCategory?.data as ISdModelListConfigItem)?.systemDicId ?? 0,
            search: sdState.modalState.curSearch,
            sort: sdState.modalState.curSort?.data ?? 0,
            type: (sdState.modalState.curType?.data as ISdModelListConfigItem)?.systemDicId ?? 0,
        }
        try {
            const res = await sdModelListModalPrivate({ authToken, ...p })
            if (res.code == 1) {
                if (params.lastModelId) {
                    thunkApi.dispatch(addSd_privateModels(res.data.privateModelList))
                } else {
                    thunkApi.dispatch(setSd_privateModels(res.data.privateModelList))
                }
                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error('🚀 ~ file: sdModelListSlice.ts:115 ~ fetchPrivateModelList ~ e:', e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
)

//类型 1: type, 2: cate, 3: baseModel, 4: 采样器, 5: hires upscaler, 6: controlnet preprocesser, 7: controlnet control mode, 8: controlnet resize mode'
export const fetchSt_modalConfig = createAsyncThunk('generateImageSlice/fetchSt_modalConfig', async (type: number, thunkApi) => {
    const authToken = (thunkApi.getState() as any).app.authToken
    try {
        const res = await sdModelListType({ authToken, type: type })
        if (res.code == 1) {
            const arr: { name: string; data: any }[] = res.data.systemList.map(s => ({ name: s.dicName, data: s }))

            if (type == 1) {
                arr.unshift({
                    name: 'modelShop.all',
                    data: {
                        dicName: 'modelShop.all',
                        systemDicId: 0,
                    },
                })
                thunkApi.dispatch(updateSd_modalState({ types: arr }))
            } else if (type == 2) {
                arr.unshift({
                    name: 'modelShop.all',
                    data: {
                        dicName: 'modelShop.all',
                        systemDicId: 0,
                    },
                })
                thunkApi.dispatch(updateSd_modalState({ categorys: arr }))
            } else if (type == 3) {
                thunkApi.dispatch(updateSd_modalState({ baseModels: arr }))
            } else if (type == 4) {
                thunkApi.dispatch(updateSd_modalState({ samplers: arr }))
            } else if (type == 5) {
                thunkApi.dispatch(updateSd_modalState({ hiresUpscaler: arr }))
            }
            return res.data.systemList
        } else {
            return thunkApi.rejectWithValue(new Error(res.message))
        }
    } catch (e) {
        console.error(e)
        return thunkApi.rejectWithValue(new Error(''))
    }
})

// export const fetchStModels = createAsyncThunk(
//     'generateImageSlice/fetchStModels',
//     async (
//         params: {
//             authToken: string
//             listType: number // 0-preview 1-public model 2-private model 3-public lora 4-private lora
//             keyword?: string
//             srcModelId?: string
//             srcModelIsPublic?: number

//             //自己用
//             curModel?: IModelItem
//             curModelIndex?: number
//         },
//         thunkApi,
//     ) => {
//         const res = await getModelList({ ...params, curModel: undefined, curModelIndex: undefined })
//         if (res.code == 1) {
//             return { params: params, data: res.data }
//         } else {
//             return thunkApi.rejectWithValue(res.message)
//         }
//     },
// )

export const fetchMj_historicalStateRecord = createAsyncThunk(
    'generateImageSlice/fetchMjHistoricalStateRecord',
    async (authToken: string, thunkApi) => {
        const res = await midJourneyHistoricalStateRecord({ authToken, drawType: 2 })
        if (res.code == 1) {
            return res.data
        } else {
            return thunkApi.rejectWithValue(res.message)
        }
    },
)

export const fetchSt_historicalStateRecord = createAsyncThunk(
    'generateImageSlice/fetchSt_historicalStateRecord',
    async (authToken: string, thunkApi) => {
        const res = await stHistoricalStateRecord({ authToken })
        if (res.code == 1) {
            return res.data
        } else {
            return thunkApi.rejectWithValue(res.message)
        }
    },
)

type MJTaskResultHandle = {
    beforeStart?: (params: MJCreateTaskParams) => void
    start?: (result: Exclude<MJCreateTaskRes, 'errorMessage'>) => void
    polling?: (result: Exclude<MJCreateTaskRes, 'errorMessage'>) => void
    end?: (result: MJCreateTaskRes) => void
}

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const fetchMj_taskResult = createAsyncThunk(
    'generateImageSlice/fetchMj_taskResult',
    async ({ params, curTaskId, resultHandle }: { params: MJCreateTaskParams; curTaskId: string; resultHandle: MJTaskResultHandle }, thunkApi) => {
        const { beforeStart, start, polling, end } = resultHandle

        try {
            let done = false
            while (!done) {
                const authToken = (thunkApi.getState() as any).app.authToken

                if (!authToken) {
                    const errResult = { params, errorMessage: '', curTaskId: curTaskId }
                    end && end(errResult)
                    return
                }

                const res = (await midJourneyDrawTaskResult({ authToken, taskId: curTaskId }, thunkApi.signal)) as any

                if (res.code == 1) {
                    const taskStatus = res.data.taskStatus
                    if (taskStatus == 1) {
                        //入队

                        const result = { params, successData: res.data, curTaskId }
                        polling && polling(result)
                    } else if (taskStatus == 2) {
                        done = true
                        //成功
                        const result = { params, successData: res.data, curTaskId }
                        end && end(result)
                    } else {
                        done = true
                        const errResult = { params, errorMessage: res.data.taskErrorMessage, curTaskId }
                        end && end(errResult)
                    }
                } else {
                    done = true
                    const errResult = { params, errorMessage: res.message, curTaskId }
                    end && end(errResult)
                }

                await delay(2000)
            }
        } catch (error) {
            console.log('🚀 ~ file: GeneratedImageThunk.ts:120 ~ error:', error)
            if ((error as any).msg?.name != 'CanceledError') {
                const errResult = { params, errorMessage: t('generatedImage.errorServiceBusy'), curTaskId: curTaskId }
                end && end(errResult)
            }
            // else{
            //     const errResult = {params,errorMessage:t('generatedImage.errorServiceBusy')}
            //     end && end(errResult)
            // }
        }

        return {}
    },
)

type STTaskResultHandle = {
    beforeCreate?: (params: STCreateTaskParams) => void
    afterCreate?: (params: STCreateTaskParams, taskIds: string[]) => void
    start?: (result: Exclude<STCreateTaskRes, 'errorMessage'>) => void
    polling?: (result: Exclude<STCreateTaskRes, 'errorMessage'>) => void
    end?: (result: STCreateTaskRes) => void
}

export const fetchSt_createTask = createAsyncThunk(
    'generateImageSlice/fetchSt_taskResult',
    async ({ params, resultHandle }: { params: STCreateTaskParams; resultHandle: STTaskResultHandle }, thunkApi) => {
        const { beforeCreate, afterCreate, start, polling, end } = resultHandle

        const authToken = (thunkApi.getState() as any).app.authToken

        if (!authToken) {
            const errResult = { params, errorMessage: t('generatedImage.errorServiceBusy'), curTaskId: '' }
            end?.(errResult)
            return
        }

        beforeCreate?.(params)

        const holderIds: string[] = []
        for (let index = 0; index < (params?.imageNum ?? 1); index++) {
            const holderId = getSTLoadingId(index)
            holderIds.push(holderId)
            thunkApi.dispatch(
                upinsertSt_imageItem({
                    taskId: holderId,
                    imageItem: {
                        generating: true,
                    },
                }),
            )
        }

        function removeHolderIds() {
            for (const holderId of holderIds) {
                thunkApi.dispatch(
                    deleteSt_imageItem({
                        taskId: holderId,
                    }),
                )
            }
        }

        try {
            const res = (await stCreateTask({
                authToken: authToken,
                ...params,
            })) as any

            if (res.code == 1) {
                const curTaskIds = res.data.taskIds

                removeHolderIds()

                afterCreate?.(params, curTaskIds)

                for (const loadingTaskId of curTaskIds) {
                    const result = { params, successData: res.data, curTaskId: loadingTaskId }
                    start?.(result)
                }

                thunkApi.dispatch(fetchSt_taskResult({ params, curTaskIds, resultHandle }))
            } else {
                removeHolderIds()

                const errResult = { params, errorMessage: res.message, curTaskId: '' }
                end?.(errResult)
            }
        } catch (e) {
            console.log('🚀 ~ file: GeneratedImageThunk.ts:209 ~ e:', e)
            removeHolderIds()

            const errResult = { params, errorMessage: t('generatedImage.errorServiceBusy'), curTaskId: '' }
            end?.(errResult)
        }

        return {}
    },
)

const beginTimer = (dispatch: any, data: IGenerateImageProgress, item?: IStImageListItem) => {
    console.log('🚀 ~ file: GeneratedImageThunk.ts:198 ~ beginTimer ~ item:', item)
    if (!item) return

    if (item.progressTimer) {
        clearInterval(item.progressTimer)
    }

    const taskId = item.taskId
    const d = 3000
    const t = 100
    let percent = item.generatingPercent ?? 0
    const nextPercent = (data.progress ?? 0) - percent
    const tick = (nextPercent / d) * t

    const timer = setInterval(() => {
        percent += tick
        // console.log('🚀 beginTimer ~ percent:', percent, 'tick:', tick)
        // updateState({ generatingPercent: percent })
        dispatch(
            upinsertSt_imageItem({
                taskId: taskId,
                imageItem: {
                    generatingPercent: percent,
                },
            }),
        )
    }, t)

    dispatch(
        upinsertSt_imageItem({
            taskId: taskId,
            imageItem: {
                progressTimer: timer,
            },
        }),
    )
}

function stopTimer(dispatch: any, success = true, item?: IStImageListItem) {
    console.log('🚀 ~ file: GeneratedImageThunk.ts:235 ~ stopTimer ~ item:', item)

    if (!item) return

    if (item.progressTimer) {
        clearInterval(item.progressTimer)
    }

    const d = !success ? 0 : 500
    const taskId = item.taskId

    setTimeout(() => {
        if (!success) {
            // updateState({ generatingPercent: 100 })
            dispatch(
                upinsertSt_imageItem({
                    taskId: taskId,
                    imageItem: {
                        generating: false,
                    },
                }),
            )
            dispatch(
                deleteSt_imageItem({
                    taskId: taskId,
                }),
            )
        } else {
            console.log('🚀 ~ file: GeneratedImageThunk.ts:235 ~ stopTimer success ~ taskId:', taskId)

            dispatch(
                upinsertSt_imageItem({
                    taskId: taskId,
                    imageItem: {
                        generatingPercent: 100,
                    },
                }),
            )

            setTimeout(() => {
                dispatch(
                    upinsertSt_imageItem({
                        taskId: taskId,
                        imageItem: {
                            generating: false,
                            generatedImageProgress: null,
                            generatingPercent: 0,
                        },
                    }),
                )
            }, d)
        }
    }, d)
}

export const fetchSt_taskResult = createAsyncThunk(
    'generateImageSlice/fetchSt_taskResult',
    async (
        { params, curTaskIds, resultHandle }: { params: STCreateTaskParams; curTaskIds: string[]; resultHandle: STTaskResultHandle },
        thunkApi,
    ) => {
        const { polling, end } = resultHandle

        const loadingTaskIds = curTaskIds

        function curImageItem(taskId: string) {
            let rootItem: IStImageListItem | undefined = undefined
            let i = 0

            const rootState = thunkApi.getState() as RootState
            for (const iterator of rootState.generateImageSlice.stableDiffusion.imageItems) {
                if (taskId == iterator.taskId) {
                    rootItem = iterator
                    break
                }
                i++
            }
            return rootItem
        }

        try {
            let done = false
            while (!done) {
                const rootState = thunkApi.getState() as RootState
                const authToken = (rootState.app as any).authToken

                if (!authToken) {
                    for (const loadingTaskId of loadingTaskIds) {
                        const errResult = { params, errorMessage: '', curTaskId: loadingTaskId }
                        end?.(errResult)
                    }
                    // done = true
                    return
                }

                const res = (await stDrawTaskResult({ authToken, taskIds: loadingTaskIds }, thunkApi.signal)) as any

                if (res.code == 1) {
                    const taskResults = res.data.taskResults

                    for (const taskResult of taskResults) {
                        const taskStatus = taskResult.taskStatus
                        const resultTaskId = taskResult.taskId

                        function removeFinishedId() {
                            const resultIndex = loadingTaskIds.indexOf(resultTaskId)
                            if (resultIndex != -1) {
                                //移出完成的
                                loadingTaskIds.splice(resultIndex, 1)
                            }
                        }

                        if (taskStatus == 1) {
                            //入队

                            const result = { params, successData: taskResult, curTaskId: resultTaskId }
                            polling?.(result)

                            if (taskResult.progress?.progress) {
                                beginTimer(thunkApi.dispatch, taskResult.progress, curImageItem(resultTaskId))
                            }
                        } else if (taskStatus == 2) {
                            //成功

                            removeFinishedId()

                            if (loadingTaskIds.length < 1) {
                                done = true
                            }

                            const result = { params, successData: taskResult, curTaskId: resultTaskId }
                            end?.(result)

                            stopTimer(thunkApi.dispatch, true, curImageItem(resultTaskId))
                        } else {
                            //失败

                            removeFinishedId()

                            if (loadingTaskIds.length < 1) {
                                done = true
                            }

                            const errResult = { params, errorMessage: taskResult.taskErrorMessage ?? '', curTaskId: resultTaskId }
                            end?.(errResult)

                            stopTimer(thunkApi.dispatch, false, curImageItem(resultTaskId))
                        }
                    }
                } else {
                    done = true
                    for (const loadingTaskId of loadingTaskIds) {
                        const errResult = { params, errorMessage: res.message, curTaskId: loadingTaskId }
                        end?.(errResult)
                        stopTimer(thunkApi.dispatch, false, curImageItem(loadingTaskId))
                    }
                }

                await delay(2000)
            }
        } catch (error) {
            console.log('🚀 ~ file: GeneratedImageThunk.ts:419 ~ error:', error)
            if ((error as any).msg?.name != 'CanceledError') {
                for (const loadingTaskId of loadingTaskIds) {
                    const errResult = { params, errorMessage: t('generatedImage.errorServiceBusy'), curTaskId: loadingTaskId }
                    end?.(errResult)
                    stopTimer(thunkApi.dispatch, false, curImageItem(loadingTaskId))
                }
            }
            // else{
            //     const errResult = {params,errorMessage:t('generatedImage.errorServiceBusy')}
            //     end && end(errResult)
            // }
        }

        return {}
    },
)
