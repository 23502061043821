import { sdModelListPrivate, sdModelListPublic, sdModelListType } from '@/api/request'
import { ModelListTab } from '@/pages/AIImage/modal/ModelListModal'
import { TabHeaderItem } from '@/pages/AIImage/TabHeader'
import index from '@/pages/Home'
import { MainModelList, ModelDetailDTO, ModelDetailResponse, ModelFileStateDto } from '@/pages/sdModelList'
import { DropdownListData } from '@/pages/sdModelList/DropdownList'
import { modelCollect, modelFileState, modelTop, modelDetailApi } from '@/pages/sdModelList/api/apiModel'
import { AppDispatch, RootState } from '@/store'
import { Action, AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { type } from 'os'
import { delay } from './GeneratedImageThunk'
import { message } from 'antd'

export interface ISdModelListConfigItem {
    dicDesc: string
    dicName: string
    id: number
    systemDicId: number
    type: number
}

export interface ISdModelListItem {
    accountModelType: string //收藏、拥有类别 1: 自己, 2:收藏
    categoryItemList: { modelCateId: number; modelCateName: string }[]
    deployStatus: number //部署状态 0-未部署 1-部署中 2-部署完成
    modelDesc: string //模型描述
    modelIcon: string //封面icon
    modelId: string //模型ID
    modelName: string //模型名
    modelType: number // 模型类型ID
    modelTypeName: string //模型类型名

    isTop?: number

    channel: number //privatemodel 渠道 1:官方, 2:私人上传, 3:训练 accountModelType为1时判断2、3来确定私人上传与训练
}

export type FetchStatus = 'idle' | 'loading' | 'succeeded' | 'failed'

interface RejectedAction extends Action {
    error: Error
    payload: Error
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
    const isRejected = action.type.startsWith('sdModelListSlice') && action.type.endsWith('rejected')
    if (isRejected) {
        console.log('🚀 ~ file: sdModelListSlice.ts:26 ~ isRejectedAction ~ action.type:', action.type)
    }
    return isRejected
}

export const fetchModelDeployState = createAsyncThunk('sdModelListSlice/fetchModelDeployState', async (params: ModelFileStateDto, thunkApi) => {
    const res = await modelFileState(params, { signal: thunkApi.signal })
    return res
})

export const fetchModelDeployStatePoll = createAsyncThunk('sdModelListSlice/fetchModelDeployState', async (params: ModelFileStateDto, thunkApi) => {
    let isDone = false

    let resultStatus = 2

    try {
        while (!isDone) {
            const delpoyStateRes = await modelFileState(params, { signal: thunkApi.signal })

            if (delpoyStateRes.code == 1) {
                const needToDeployIds: string[] = []
                const deployingIds: string[] = []

                for (const item of delpoyStateRes.data.modelStateList) {
                    if (item.deployStatus == 0) {
                        needToDeployIds.push(item.modelId)
                        resultStatus = Math.min(resultStatus, 0)
                    } else if (item.deployStatus == 1) {
                        deployingIds.push(item.modelId)
                        resultStatus = Math.min(resultStatus, 1)
                    }
                }

                if (needToDeployIds.length < 1 && deployingIds.length < 1) {
                    resultStatus = 2
                    return { resultStatus }
                }

                if (resultStatus == 2) {
                    return { resultStatus }
                }

                if (thunkApi.signal.aborted) {
                    isDone = true
                    console.log('🚀 ~ file: sdModelListSlice.ts:70 ~ fetchModelDeployStatePoll ~ isDone:', isDone)
                    return { resultStatus }
                }
            } else {
                return thunkApi.rejectWithValue(delpoyStateRes.message)
            }

            await delay(2000)
        }

        return { resultStatus }
    } catch (e) {
        console.error(e)
        return thunkApi.rejectWithValue(new Error(''))
    }
})

export const fetchModelCollect = createAsyncThunk(
    'sdModelListSlice/fetchModelListConfig',
    async (params: { modelId: string; type: number; index: number }, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken
        const sdState = (thunkApi.getState() as RootState).sdModelListSlice
        try {
            const accountModelType = params.type == 1 ? '2' : '0'
            if (sdState.curTab?.tab == ModelListTab.Public) {
                console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', 'public')
                thunkApi.dispatch(
                    setPublicModelItem({
                        index: params.index,
                        model: { ...sdState.publicModelList[params.index], accountModelType: accountModelType },
                    }),
                )
            } else {
                thunkApi.dispatch(
                    setPrivateModelItem({
                        index: params.index,
                        model: { ...sdState.privateModelList[params.index], accountModelType: accountModelType },
                    }),
                )
            }

            const res = await modelCollect({ authToken, modelId: params.modelId.toString(), type: params.type })
            console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', res)
            if (res.code == 1) {
                const accountModelType = params.type == 1 ? '2' : '0'
                if (sdState.curTab?.tab == ModelListTab.Public) {
                    console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', 'public')
                    thunkApi.dispatch(
                        setPublicModelItem({
                            index: params.index,
                            model: { ...sdState.publicModelList[params.index], accountModelType: accountModelType },
                        }),
                    )
                } else {
                    thunkApi.dispatch(
                        setPrivateModelItem({
                            index: params.index,
                            model: { ...sdState.privateModelList[params.index], accountModelType: accountModelType },
                        }),
                    )
                    if (params.type == 2) {
                        thunkApi.dispatch(
                            deletePrivateModelItem({
                                index: params.index,
                            }),
                        )
                    }
                }
                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error(e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
    {
        condition: (params: { modelId: string; type: number; index: number }, thunkApi) => {
            const sdState = (thunkApi.getState() as RootState).sdModelListSlice
            const fetchStatus = sdState.requestStatus[fetchModelCollect.typePrefix]
            if (fetchStatus === 'loading') {
                return false
            }
        },
    },
)
export const fetchModelSetTop = createAsyncThunk(
    'sdModelListSlice/fetchModelSetTop',
    async (params: { modelId: string; type: number; index: number }, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken
        const sdState = (thunkApi.getState() as RootState).sdModelListSlice
        try {
            if (sdState.curTab?.tab == ModelListTab.Public) {
                console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', 'public')
                thunkApi.dispatch(
                    setPublicModelItem({
                        index: params.index,
                        model: { ...sdState.publicModelList[params.index], isTop: params.type },
                    }),
                )
            } else {
                thunkApi.dispatch(
                    setPrivateModelItem({
                        index: params.index,
                        model: { ...sdState.privateModelList[params.index], isTop: params.type },
                    }),
                )
            }
            const res = await modelTop({ authToken, modelId: params.modelId.toString(), type: params.type })
            console.log('🚀 ~ file: sdModelListSlice.ts:61 ~ res:', res)
            if (res.code == 1) {
                thunkApi.dispatch(fetchPrivateModelList())
                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error(e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
    {
        condition: (params: { modelId: string; type: number; index: number }, thunkApi) => {
            const sdState = (thunkApi.getState() as RootState).sdModelListSlice
            const fetchStatus = sdState.requestStatus[fetchModelCollect.typePrefix]
            if (fetchStatus === 'loading') {
                return false
            }
        },
    },
)
//type	integer($int32) 1：类型 2：分类 3：baseModel'
export const fetchModelListConfig = createAsyncThunk('sdModelListSlice/fetchModelListConfig', async (type: 1 | 2 | 3, thunkApi) => {
    const authToken = (thunkApi.getState() as any).app.authToken
    try {
        const res = await sdModelListType({ authToken, type: type })
        if (res.code == 1) {
            const arr: { name: string; data: any }[] = res.data.systemList.map(s => ({ name: s.dicName, data: s }))
            if (type == 1) {
                thunkApi.dispatch(setTypes(arr))
            } else if (type == 2) {
                thunkApi.dispatch(setCategorys(arr))
            } else if (type == 3) {
                thunkApi.dispatch(setBaseModels(arr))
            }
            return res.data.systemList
        } else {
            return thunkApi.rejectWithValue(new Error(res.message))
        }
    } catch (e) {
        console.error(e)
        return thunkApi.rejectWithValue(new Error(''))
    }
})

export const fetchPublicModelList = createAsyncThunk(
    'sdModelListSlice/fetchPublicModelList',
    async (params: { lastModelId?: string } | undefined, thunkApi) => {
        const authToken = (thunkApi.getState() as any).app.authToken
        const sdState = (thunkApi.getState() as RootState).sdModelListSlice
        const p = {
            category: (sdState.curCategory?.data as ISdModelListConfigItem)?.systemDicId ?? 0,
            search: sdState.curSearch,
            sort: sdState.curSort?.data ?? 0,
            type: (sdState.curType?.data as ISdModelListConfigItem)?.systemDicId ?? 0,
            lastModelId: params?.lastModelId,
        }
        try {
            const res = await sdModelListPublic({ authToken, ...p })
            if (res.code == 1) {
                if (p.lastModelId) {
                    const sdState = (thunkApi.getState() as RootState).sdModelListSlice
                    thunkApi.dispatch(setPublicModelList([...sdState.publicModelList, ...res.data.publicModelList]))
                } else {
                    thunkApi.dispatch(setPublicModelList(res.data.publicModelList))
                }
                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error('🚀 ~ file: sdModelListSlice.ts:92 ~ fetchPublicModelList ~ e:', e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
)

export const fetchPrivateModelList = createAsyncThunk(
    'sdModelListSlice/fetchPrivateModelList',
    async (params: { lastModelId?: string } | undefined, thunkApi) => {
        const authToken = (thunkApi.getState() as RootState).app.authToken

        const sdState = (thunkApi.getState() as RootState).sdModelListSlice
        const p = {
            category: (sdState.curCategory?.data as ISdModelListConfigItem)?.systemDicId ?? 0,
            search: sdState.curSearch,
            sort: sdState.curSort?.data ?? 0,
            type: (sdState.curType?.data as ISdModelListConfigItem)?.systemDicId ?? 0,
            lastModelId: params?.lastModelId,
        }
        try {
            const res = await sdModelListPrivate({ authToken, ...p })
            if (res.code == 1) {
                if (p.lastModelId) {
                    const sdState = (thunkApi.getState() as RootState).sdModelListSlice
                    thunkApi.dispatch(setPrivateModelList([...sdState.privateModelList, ...res.data.privateModelList]))
                } else {
                    thunkApi.dispatch(setPrivateModelList(res.data.privateModelList))
                }
                return res.data
            } else {
                return thunkApi.rejectWithValue(new Error(res.message))
            }
        } catch (e) {
            console.error('🚀 ~ file: sdModelListSlice.ts:115 ~ fetchPrivateModelList ~ e:', e)
            return thunkApi.rejectWithValue(new Error(''))
        }
    },
)

export const fetchCurTabModelList = createAsyncThunk(
    'sdModelListSlice/fetchCurTabModelList',
    async (params: { lastModelId?: string } | undefined, thunkApi) => {
        const sdState = (thunkApi.getState() as RootState).sdModelListSlice

        if (sdState.curTab?.tab == ModelListTab.Public) {
            return await thunkApi.dispatch(fetchPublicModelList(params)).unwrap()
        } else if (sdState.curTab?.tab == ModelListTab.Private) {
            return await thunkApi.dispatch(fetchPrivateModelList(params)).unwrap()
        } else {
            return await thunkApi.dispatch(fetchPublicModelList(params)).unwrap()
        }
    },
)

export const fetchModelDetail = createAsyncThunk(
    'sdModelListSlice/fetchModelDetail',
    async (params: { modelId: string; replaceObj?: Object }, thunkApi) => {
        const authToken = (thunkApi.getState() as RootState).app.authToken
        const res = await modelDetailApi({ authToken, modelId: params.modelId })
        // const res = await modelDetailApi({ authToken, modelId: '5c68f1725721403c933b64e3065f1794' })
        if (res.code == 1) {
            const replace = params.replaceObj ? params.replaceObj : {}
            thunkApi.dispatch(setModelDetail({ ...res.data, ...replace }))
        } else {
            message.error(res.message)
        }
    },
)

export interface ISdModelListSliceState {
    errorToast: string
    macy: any
    macyImageFinished: boolean
    curTab?: TabHeaderItem
    publicModelList: ISdModelListItem[]
    privateModelList: ISdModelListItem[]
    requestStatus: { [p: string]: FetchStatus }
    detailModelId?: string

    categorys: DropdownListData[]
    types: DropdownListData[]
    baseModels: DropdownListData[]
    sorts: DropdownListData[]
    curCategory?: DropdownListData
    curType?: DropdownListData
    curSort?: DropdownListData
    curSearch?: string

    curModelTab?: TabHeaderItem
    publicMainModelList: MainModelList[]
    privateMainModelList: MainModelList[]

    checkMainModelList: any
    uploadingState: { uploading: boolean; taskType?: number }
    modelDetail: ModelDetailResponse
}
const initialState: ISdModelListSliceState = {
    errorToast: '',
    macy: null,
    macyImageFinished: false,
    publicModelList: [],
    privateModelList: [],
    requestStatus: {},

    categorys: [],
    types: [],
    baseModels: [],
    sorts: [
        { name: 'modelShop.newest', data: 1 },
        { name: 'modelShop.mostGeneration', data: 2 },
        { name: 'modelShop.mostDownload', data: 3 },
    ],
    curCategory: undefined,
    curType: undefined,
    curSort: { name: 'modelShop.mostGeneration', data: 2 },
    curSearch: undefined,
    publicMainModelList: [],
    privateMainModelList: [],
    checkMainModelList: [],
    uploadingState: { uploading: false },
    modelDetail: {},
}
const sdModelListSlice = createSlice({
    name: 'sdModelListSlice',
    initialState: initialState,
    reducers: {
        setUploadingState(state, action: { type: string; payload: { uploading: boolean; taskType?: number } }) {
            state.uploadingState = action.payload
        },
        setCurSearch(state, action) {
            state.curSearch = action.payload
        },
        setCurCategory(state, action) {
            state.curCategory = action.payload
        },
        setCurType(state, action) {
            state.curType = action.payload
        },
        setCurSort(state, action) {
            state.curSort = action.payload
        },
        setCategorys(state, action) {
            state.categorys = action.payload
        },
        setTypes(state, action) {
            state.types = action.payload
        },
        setBaseModels(state, action) {
            state.baseModels = action.payload
        },
        setMacy(state, action) {
            state.macy = action.payload
        },
        setMacyImageFinished(state, action) {
            state.macyImageFinished = action.payload
        },
        setCurTab(state, action) {
            state.curTab = action.payload
        },
        setExitModelList(state, action) {
            state.publicModelList = state.publicModelList.slice(0, 20)
            state.privateModelList = state.privateModelList.slice(0, 20)
        },
        setPublicModelList(state, action) {
            state.publicModelList = action.payload
        },
        setPublicModelItem(state, action: { type: string; payload: { index: number; model: ISdModelListItem } }) {
            state.publicModelList[action.payload.index] = action.payload.model
        },
        setPrivateModelList(state, action) {
            state.privateModelList = action.payload
        },
        setPrivateModelItem(state, action: { type: string; payload: { index: number; model: ISdModelListItem } }) {
            state.privateModelList[action.payload.index] = action.payload.model
        },
        deletePrivateModelItem(state, action: { type: string; payload: { index: number } }) {
            state.privateModelList.splice(action.payload.index, 1)
        },
        setDetailModelId(state, action) {
            state.detailModelId = action.payload
        },
        setModelDetail(state, action) {
            state.modelDetail = action.payload
        },
        setCurModelTab(state, action) {
            state.curModelTab = action.payload
        },

        setMainModelList(state, action) {
            state.publicMainModelList = action.payload.filter(item => {
                return item.isPublic == 1
            })
            state.privateMainModelList = action.payload.filter(item => {
                return item.isPublic == 0
            })
        },

        updatePublicMainModelListitem(
            state,
            action: {
                payload: { index: number; model: MainModelList }
                type: string
            },
        ) {
            state.publicMainModelList[action.payload.index] = action.payload.model
        },
        updatePrivateMainModelListitem(
            state,
            action: {
                payload: { index: number; model: MainModelList }
                type: string
            },
        ) {
            state.privateMainModelList[action.payload.index] = action.payload.model
        },
        setCheckMainModelList(state, action) {
            state.checkMainModelList = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(
                function isFetchAction(action: AnyAction) {
                    const isFetch = action.type.startsWith('sdModelListSlice/fetch')
                    return isFetch
                },
                // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
                (state, action) => {
                    const arr = (action.type as string).split('/')
                    arr.pop()
                    const typePrefix = arr.join('/')
                    if (action.type.endsWith('pending')) {
                        state.requestStatus[typePrefix] = 'loading'
                    } else if (action.type.endsWith('fulfilled')) {
                        state.requestStatus[typePrefix] = 'succeeded'
                    } else if (action.type.endsWith('rejected')) {
                        state.requestStatus[typePrefix] = 'failed'
                    }
                },
            )
            .addMatcher(
                isRejectedAction,
                // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
                (state, action) => {
                    console.error('🚀 ~ file: sdModelListSlice.ts:295 ~ extraReducers ~ action.error:', action)
                    state.errorToast = action.payload?.message
                },
            )
        // // and provide a default case if no other handlers matched
        // .addDefaultCase((state, action) => {})
    },
})

export const {
    setMacy,
    setMacyImageFinished,
    setCurTab,
    setPublicModelList,
    setPublicModelItem,
    setPrivateModelList,
    setPrivateModelItem,
    setCategorys,
    setTypes,
    setBaseModels,
    setCurCategory,
    setCurType,
    setCurSort,
    setCurSearch,
    setDetailModelId,
    setCurModelTab,
    setMainModelList,
    setCheckMainModelList,
    deletePrivateModelItem,
    setUploadingState,
    updatePublicMainModelListitem,
    updatePrivateMainModelListitem,
    setModelDetail,
    setExitModelList,
} = sdModelListSlice.actions
export default sdModelListSlice.reducer
