import React, { ReactElement, useContext, useMemo, useState } from 'react'
import OSS from 'ali-oss'
import { getOssAssumeRoleToken } from '@/api/request'
import { useAppSelector } from '@/hooks'
import { message } from 'antd'
import CryptoJS from 'crypto-js'
type AliyunOSSContextData = {
    AliyunOSSProvider: AliyunOSSProvider
} | null
type AliyunOSSProvider = {
    requestOssClient: Function
    multipartUpload: Function
    defaultUpload: Function
    OSSProgress: number
    setOSSProgress: Function
    cancel: Function
}
const AliyunOSSContext = React.createContext<AliyunOSSContextData>(null)

type ossAssumeRoleToken = {
    accessKeyId: string
    accessKeySecret: string
    bucket: string
    dataDir: string
    dataId: string
    securityToken: string
}
export interface ResponseData<T = any> {
    code: number
    data: T
    message: string
    reqTraceId: string
}
export const useAliyunOSSContext = () => {
    const aliyunOSSContext = useContext(AliyunOSSContext)
    if (!aliyunOSSContext) {
        throw new Error('useModelDetailsContext() can only be used inside of <ModelDetailsProvider />, ' + 'please declare it at a higher level.')
    }
    const { AliyunOSSProvider } = aliyunOSSContext
    return useMemo<AliyunOSSProvider>(() => {
        return { ...AliyunOSSProvider }
    }, [aliyunOSSContext])
}
export const AliyunOSSProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const [OSSProgress, setOSSProgress] = useState<number | null>(null)
    const [client, setClient] = useState<any>(null)
    async function requestOssClient(dataType, dataId = '1') {
        const res = await getOssAssumeRoleToken({ authToken, dataType, dataId })
        let client = new OSS({
            accessKeyId: res.data.accessKeyId,
            accessKeySecret: res.data.accessKeySecret,
            stsToken: res.data.securityToken,
            bucket: res.data.bucket,
            endpoint:
                window.sessionStorage.getItem('header-serve') == 'cloudflare' && res.data.bucket == 'model-mstai'
                    ? 'oss-accelerate.aliyuncs.com'
                    : 'oss-cn-hangzhou.aliyuncs.com',
            refreshSTSToken: async () => {
                // 向您搭建的STS服务获取临时访问凭证。
                const refreshRes = await getOssAssumeRoleToken({ authToken, dataType, dataId })
                if (refreshRes.code == 1) {
                    return {
                        accessKeyId: refreshRes.data.accessKeyId,
                        accessKeySecret: refreshRes.data.accessKeySecret,
                        stsToken: refreshRes.data.securityToken,
                    }
                }
            },
            refreshSTSTokenInterval: 300000,
        })

        setClient(client)

        return { client, bucket: res.data.bucket, dataDir: res.data.dataDir, dataId: res.data.dataId }
    }
    const defaultUpload = async ({ client, file, fileName, filePath }) => {
        const result = await client?.put(filePath + fileName, file)
        return result
    }

    const multipartUpload = async ({ client, file, fileName, filePath, multipartUploadFinish, progress, errorCallback }) => {
        const options = {
            partSize: 1 * 1024 * 1024, //分片的大小
            timeout: 60000,
            // 获取分片上传进度、断点和返回值。
            progress: (p, checkpoint, res) => {
                setOSSProgress(Math.ceil(p * 100))
                console.log('🚀 ~ file: AliyunOSS.tsx:85 ~ multipartUpload ~ Math.floor(p * 100):', Math.floor(p * 100))
                if (checkpoint) {
                    var checkOnlyKey = CryptoJS.MD5(checkpoint.file.name + checkpoint.fileSize)
                    localStorage.setItem(checkOnlyKey, JSON.stringify({ checkpoint, fileSize: checkpoint.fileSize, fileName: checkpoint.file.name }))
                }
            },
        }
        try {
            const result = await client.multipartUpload(filePath + fileName, file, { ...options })
            return multipartUploadFinish(result)
        } catch (e) {
            setOSSProgress(0)
            errorCallback(e)
        }
    }
    const cancel = () => {
        console.log('🚀 ~ file: AliyunOSS.tsx:105 ~ cancel ~ client:', client)

        if (client) {
            client.cancel()
            setOSSProgress(0)
        }
    }

    let uploadFileClient, currentCheckpoint //分别是实例化后的client和当前上传进度
    var upload_oss = {
        //用来存储后台生成的上传文件路径和文件的完整url
        fileKey: '',
        fileUrl: '',
    }
    const resumeUpload = async ({ client, file, fileName, filePath, multipartUploadFinish, progress }) => {
        let uploadFileClient = client

        //取出文件后缀
        var fileName = file.name
        var fileSize = file.size
        var pos = fileName.lastIndexOf('.')
        var suffix = pos >= 0 ? fileName.substring(pos) : ''
        let options = {
            progress, //这里的progress是上传进度的方法，用于更新进度条和获取当前的上传进度，下面会有介绍
            partSize: 500 * 1024, //分片的大小
            timeout: 60000,
        }
        upload_oss.fileKey += suffix
        upload_oss.fileUrl += suffix
        try {
            const unFinishUploadList = await uploadFileClient.listUploads({ prefix: upload_oss.fileKey })
            if (unFinishUploadList.nextUploadIdMarker) {
                let theCheckPoint = JSON.parse(localStorage.getItem(CryptoJS.MD5(fileName + fileSize)))

                currentCheckpoint = theCheckPoint
                currentCheckpoint.file = file
            }
            if (currentCheckpoint) {
                options.checkpoint = currentCheckpoint
            }
            const res = await uploadFileClient.multipartUpload(upload_oss.fileKey, file, options)

            if (currentCheckpoint) {
                localStorage.removeItem(CryptoJS.MD5(currentCheckpoint.file.name + currentCheckpoint.file.size))
            }
            currentCheckpoint = null
            uploadFileClient = null
        } catch (err: any) {
            if (uploadFileClient && uploadFileClient.isCancel()) {
                console.log('stop-upload!')
            } else {
                console.error(err)
                console.log(`err.name : ${err.name}`)
                console.log(`err.message : ${err.message}`)
                if (err.name.toLowerCase().indexOf('connectiontimeout') !== -1) {
                    // timeout retry
                    // if (retryCount < retryCountMax) {
                    //     retryCount++
                    //     console.error(`retryCount : ${retryCount}`)
                    //     uploadFile('')
                    // }
                }
            }
        }
    }

    const AliyunOSSProvider = useMemo<AliyunOSSProvider>(
        () => ({ requestOssClient, multipartUpload, defaultUpload, OSSProgress, setOSSProgress, cancel }),
        [requestOssClient, multipartUpload, defaultUpload, OSSProgress, setOSSProgress, cancel],
    )
    return <AliyunOSSContext.Provider value={{ AliyunOSSProvider }}>{children}</AliyunOSSContext.Provider>
}
