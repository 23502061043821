import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import Header from '../public/Header'
import NavBar from '../public/NavBar'
import { lazyImport } from '@/utils/lazyImport'
import { GPURentalRouters } from '@/pages/GPURental'
// import modelShopRouters from '@/pages/ModelDetail'
const { ModelShopRouters } = lazyImport(() => import('../../pages/ModelDetail'), 'ModelShopRouters')
export default function index(props) {
    let { path, component: Com, name } = props

    return (
        <div className="flex h-screen text-white">
            <Header name={name} />
            <NavBar />
            <Route
                path={path}
                render={props => {
                    return <Com {...props} name={name}></Com>
                }}
                exact
            />
            <Suspense>
                <ModelShopRouters />
            </Suspense>
            {/* <Suspense>
                    <GPURentalRouters />
                </Suspense> */}
        </div>
    )
}
