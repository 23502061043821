import React from 'react'
interface ICustomSwitchProps {
    checked: boolean
    trackClassName: (isChecked: boolean) => string
    checkedClassName: (isChecked: boolean) => string
    checkedText: string
    uncheckedText: string
    onChange?: (checked: boolean) => void
}
const CustomSwitch: React.FC<ICustomSwitchProps> = props => {
    const { trackClassName, checkedClassName, checked, checkedText, uncheckedText, onChange } = props
    return (
        <button
            className={trackClassName(checked)}
            onClick={() => {
                onChange?.(!checked)
            }}
        >
            <div className={checkedClassName(checked)}>{checked ? checkedText : uncheckedText}</div>
        </button>
    )
}
export default CustomSwitch
