function OneImagePreview(params) {
    const { imageUrl, setOpen } = params

    return (
        <div className="fixed w-[100vw] h-[100vh] top-0 left-0 z-[1000]">
            <div
                onClick={e => {
                    setOpen(false)
                }}
                className="w-full h-full flex justify-center items-center bg-gray-950 bg-opacity-70"
            >
                <img src={imageUrl} className="max-w-[90%] max-h-[90%]" alt="" />
            </div>
        </div>
    )
}

export default OneImagePreview
