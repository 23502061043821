import { useAppSelector } from '@/hooks'
import { IControlNetItem, IStImageListItem } from '@/slices/GenerateImageSlice'
import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import { fetchSt_createTask, fetchSt_taskResult } from '@/slices/GeneratedImageThunk'

export function useStLoading() {
    const state = useAppSelector(state => {
        return state.generateImageSlice.stableDiffusion
    })

    return useMemo(() => {
        let loadingItem: IStImageListItem | undefined
        let spinning = false
        for (const imageItem of state.imageItems) {
            if (imageItem.generating) {
                loadingItem = imageItem
                spinning = true
                break
            }
        }
        return { loadingItem, spinning }
    }, [state.imageItems])
}

export type STCreateTaskParams = {
    taskType?: number // v=5
    modelId?: string
    apiVersion?: number
    negativePrompt?: string
    prompt?: string

    segmentSubject?: string
    segmentDescribeSubject?: string
    segmentScene?: string
    segmentStyle?: string
    segmentQuality?: string
    segmentArtist?: string

    width?: number
    height?: number
    imageNum?: number
    imageUrl?: string
    taskId?: string
    loraModels?: { lora_model_id: string; lora_weight: string }[]

    cfgScale?: number //1-30
    clipSkip?: number //1-12
    denoisingStrength?: number
    ensd?: number
    samplingId?: number
    samplingStep?: number
    seed?: number
    vaeModelId?: string

    //hireupscale
    useHires?: number
    hiresUpscaleBy?: number
    hiresUpscaleDenoisingStrength?: number
    hiresUpscaleSteps?: number
    hiresUpscaler?: number

    //放大
    targetWidth?: number
    targetHeight?: number

    //local
    extra?: any
    controlNets?: IControlNetItem[]
}

export type STCreateTaskRes = {
    params: STCreateTaskParams
    curTaskId: string
    successData?: any
    errorMessage?: string
}

export function useStRequestDraw({
    beforeCreate,
    afterCreate,
    start,
    polling,
    end,
}: {
    beforeCreate?: (params: STCreateTaskParams) => void
    afterCreate?: (params: STCreateTaskParams, taskIds: string[]) => void
    start?: (result: Exclude<STCreateTaskRes, 'errorMessage'>) => void
    polling?: (result: Exclude<STCreateTaskRes, 'errorMessage'>) => void
    end?: (result: STCreateTaskRes) => void
}) {
    const authToken = useAppSelector(state => {
        return state.app?.authToken
    })

    const { t } = useTranslation()

    const dispatch = useDispatch<AppDispatch>()

    const abortControllerRef = useRef<{ abort: (reason?: string) => void }>()

    const createTask = async (params: STCreateTaskParams) => {
        const promise = dispatch(
            fetchSt_createTask({
                params,
                resultHandle: { beforeCreate, afterCreate, start, polling, end },
            }),
        )
    }

    async function requestDetailDrawResult(params: STCreateTaskParams, curTaskIds: string[]) {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort()
        }

        const promise = dispatch(
            fetchSt_taskResult({
                params,
                curTaskIds,
                resultHandle: { beforeCreate, afterCreate, start, polling, end },
            }),
        )
        abortControllerRef.current = promise
    }

    return { createTask, requestDetailDrawResult, abortControllerRef }
}
