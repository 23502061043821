// import { axios } from '@/lib/axios'
import axios from './index' //进行了二次封装了

// 用户登录接口
export const login = params => {
    return axios.post('/account/api/account/login', params)
}
// 用户google登录接口
export const loginWithGoogle = params => {
    return axios.post('/account/api/account/registerAndLoginByGoogle', params)
}
//用trunstile注册账号
export const registerWithCloudflareCode = params => {
    return axios.post('/account/api/account/resisterByPhoneCloudflare', params)
}
// 重置密码接口
export const resetPassword = params => {
    return axios.post('/account/api/account/reset/password', params)
}
// 验证autotoken是否有效
export const checkAuthTokenValid = params => {
    return axios.post('/account/api/account/checkAuthTokenValid', params)
}
//用户通告
export const noticeList = params => {
    return axios.post(`/account/api/account/noticeList`, params)
}
// 游客登录接口
export const vistorRegister = params => {
    return axios.post('/account/api/account/registerAndLoginByTemporarily', params)
}
