import { AccessRouteId } from './accessRouteConf'
import Home from '../pages/Home'
import Tools from '../pages/Tools'
// import Login from '../pages/Login'
import NotFound from '../pages/NotFound'

import Chat from '../pages/Chat'
import Transcription from '../pages/Transcription'
import AIImage from '../pages/AIImage'
import StableDiffusion from '../pages/StableDiffusion'
import TrainingAndDatasets from '../pages/TrainingAndDatasets'
import TermService from '../pages/TermService'
import NewFunction from '../pages/Waters'

import chatGPTIcon from '../assets/chatGPT1.png'
import AIImageIcon from '../assets/AIImage1.png'
import modelShop_phone_icon from '../assets/modelShop_phone_icon.png'
import Midjourney from '@/pages/Midjourney'
import midjourney_phone from '../assets/midjourney_phone.png'
import traningIcon from '../assets/traningPhone.png'
import watersIcon from '../assets/waters_phone.png'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import SdModelList from '@/pages/sdModelList'
import { lazyImport } from '@/utils/lazyImport'
import gpu_phone_icon from '@/assets/GpuRental_phone_icon.png'

const { GPURentalRouters } = lazyImport(() => import('../pages/GPURental'), 'GPURentalRouters')

export const routes = [
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    {
        path: '/tools',
        name: 'tools',
        component: Tools,
    },
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: Login,
    // },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
    },
    {
        path: '/serviceTerms',
        name: 'serviceTerms',
        component: TermService,
    },
    {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        component: PrivacyPolicy,
    },
]

export let subRoutes = [
    // {
    //     path: '/tools/transcription',
    //     icon: 'api',
    //     name: 'transcription',
    //     authority: AccessRouteId.transcription,
    //     component: Transcription,
    // },
    {
        path: '/tools/Waters',
        onIcon: 'watersIconOn',
        offIcon: 'watersIconOff',
        phoneIcon: watersIcon,
        name: 'Waters',
        authority: AccessRouteId.waters,
        component: NewFunction,
    },
    {
        path: '/tools/ModelList',
        onIcon: 'ModelShopIconOn',
        offIcon: 'ModelShopIconOff',
        phoneIcon: modelShop_phone_icon,
        name: 'Models',
        authority: AccessRouteId.modelShop,
        component: SdModelList,
    },
    // {
    //     path: '/tools/ModelList/ModelDetail',
    //     onIcon: 'ModelShopIconOn',
    //     offIcon: 'ModelShopIconOff',
    //     phoneIcon: modelShop_phone_icon,
    //     name: 'ModelDetail',
    //     authority: AccessRouteId.modelShop,
    //     component: SdModelList,
    // },
    {
        path: '/tools/AIImage',
        onIcon: 'AIImageIconOn',
        offIcon: 'AIImageIconOff',
        phoneIcon: AIImageIcon,
        name: 'Stable Diffusion',
        authority: AccessRouteId.aiImage,
        component: AIImage,
    },
    // {
    //     path: '/tools/midjourney',
    //     onIcon: 'MidJourneyOn',
    //     offIcon: 'MidJourneyOff',
    //     phoneIcon: midjourney_phone,
    //     name: 'Midjourney',
    //     authority: AccessRouteId.midjourney,
    //     component: Midjourney,
    // },
    // {
    //     path: '/tools/StableDiffusion',
    //     icon: 'api',
    //     name: 'StableDiffusion',
    //     authority: AccessRouteId.StableDiffusion,
    //     component: StableDiffusion,
    // },
    {
        path: '/tools/TrainingAndDatasets',
        icon: 'api',
        name: 'Training(Coming soon)',
        authority: AccessRouteId.trainingAndDatasets,
        onIcon: 'traningIconOn',
        offIcon: 'traningIconOff',
        phoneIcon: traningIcon,

        component: TrainingAndDatasets,
    },
    {
        path: '/tools/rental',
        icon: 'api',
        name: 'GPU Rental',
        authority: AccessRouteId.trainingAndDatasets,
        onIcon: 'GpuIconOn',
        offIcon: 'GpuIconOff',
        phoneIcon: gpu_phone_icon,
        component: GPURentalRouters,
    },
    // {
    //     path: '/tools/chat',
    //     onIcon: 'chatGPTIconOn',
    //     offIcon: 'chatGPTIconOff',
    //     phoneIcon: chatGPTIcon,
    //     name: 'ChatGPT',
    //     authority: AccessRouteId.chat,
    //     component: Chat,
    // },
    // {
    //     path: '/tools/chat',
    //     onIcon: 'chatGPTIconOn',
    //     offIcon: 'chatGPTIconOff',
    //     phoneIcon: chatGPTIcon,
    //     name: 'ChatGPT',
    //     authority: AccessRouteId.chat,
    //     component: Chat,
    // },
]
