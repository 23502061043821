export const AccessRouteId = {
    home: 1,
    chat: 2,
    transcription: 3,
    aiImage: 4,
    StableDiffusion: 5,
    trainingAndDatasets: 6,
    midjourney: 7,
    modelShop: 8,
    waters: 9,
    login: 99999,
}
