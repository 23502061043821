import React, { useEffect, useState } from 'react'
import { setCurrentSession, addSession, Session, Message, setSessionList, setMenuOpen, setAuthToken } from '@/slices/AppSlice'
import { getSession, deleteAllSession, historySession } from '../../api/request'
import { useAppSelector } from '@/hooks'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import Cookies from 'js-cookie'
import { Box, Button, Drawer, IconButton } from '@mui/material'
import { styled, alpha, useTheme } from '@mui/material/styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { HashRouter as Router, Route, useHistory, Switch, Redirect, useLocation, NavLink } from 'react-router-dom'
import icon_hd from '../../assets/logo_white.png'

import icon from '../../assets/icon.png'
import logoutIcon from '../../assets/logout.png'
import chatGPTIcon from '../../assets/chatGPT.png'
import AIImageIcon from '../../assets/AIImage.png'
import discordIcon from '../../assets/discord.png'
import wechatIcon from '../../assets/wechatIcon.png'
import wechatGroup from '../../assets/wechat-group.jpg'
import twitterIcon from '../../assets/twitter_icon.png'
import emailIcon from '../../assets/email_icon.png'

import languageIconEN from '../../assets/language-en.png'
import languageIconCN from '../../assets/language-cn.png'

import { gapi, loadAuth2 } from 'gapi-script'
import { subRoutes } from '@/router'
import { useTranslation } from 'react-i18next'
import { resetImageState } from '@/slices/GenerateImageSlice'
import { getChatTabState, getOverseaUrl } from '@/helpers'
import GlobalStorage from '@/lib/globalStorage'
import { trackEvent, googleTrackEvent } from '@/lib/analyse'
import { deleteAllWatersImageRecord, resetWatersState, setShowRecord } from '@/slices/NewFunctionSlice'
const drawerWidth = 200
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
    open?: boolean
}>(({ theme, open }) => ({
    flexGrow: 1,
    paddingTop: theme.spacing(0),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    // background: 'red',
    // ...(open && {
    //     transition: theme.transitions.create('margin', {
    //         easing: theme.transitions.easing.easeOut,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    //     marginLeft: 0,
    // }),
}))
export default function NavBar(props) {
    const { targetRef, children, getContainer, onNotVisibleArea } = props
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const history = useHistory()
    const location = useLocation()

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.substring(0, 2))
    // console.log('🚀 ~ file: NavBar.tsx:47 ~ NavBar ~ location:', location)
    const authToken = useAppSelector(state => {
        return state.app && state.app.authToken
    })
    const logout = () => {
        GlobalStorage.remove('user_remember')
        GlobalStorage.remove('auth_token')
        dispatch(resetImageState({}))
        dispatch(resetWatersState({}))
        dispatch(setAuthToken(''))
        dispatch(deleteAllWatersImageRecord({}))
        dispatch(setShowRecord(false))
        window.sessionStorage.removeItem('isWelcome')
        if (GlobalStorage.get('login_type') == 'google') {
            GlobalStorage.remove('login_type')
            var auth2 = gapi.auth2.getAuthInstance()
            auth2.signOut().then(() => {
                history.push('/')
            })
        } else {
            GlobalStorage.remove('login_type')
            history.push('/')
        }

        // window.location.reload()
    }
    const deleteSessions = async () => {
        const res: any = await deleteAllSession({ authToken: authToken })
        if (res.code == 1) {
            const res: any = await historySession({ authToken: authToken })
            if (res.code == 1) {
                dispatch(setSessionList(res.data.chatRecordList))
            }
        }
    }
    const menuOpen = useAppSelector(state => {
        return state.app && state.app.menuOpen
    })
    const handleDrawerClose = () => {
        dispatch(setMenuOpen(false))
        // setOpen(false)
    }

    useEffect(() => {
        setTimeout(() => {
            dispatch(setMenuOpen(false))
        }, 100)
    }, [location])

    const hrefPath = href => {
        history.push(href)
    }
    function getImageUrl(name) {
        return new URL(`../../assets/${name}.png`, import.meta.url).href
    }

    const changeLanguage = () => {
        if (currentLanguage == 'zh') {
            i18n.changeLanguage('en')
            setCurrentLanguage('en')
        } else if (i18n.language == 'en') {
            i18n.changeLanguage('zh')
            setCurrentLanguage('zh')
        }
    }
    return (
        <>
            <Drawer open={menuOpen} onClose={handleDrawerClose}>
                <DrawerHeader className="bg-menuBgColor">
                    <IconButton onClick={handleDrawerClose}>{<ChevronLeftIcon className="text-white" />}</IconButton>
                </DrawerHeader>

                <div className="flex min-h-0 flex-1 flex-col overflow-y-auto bg-menuBgColor text-white">
                    <div className="flex-1">
                        <nav className="flex flex-col space-y-1 py-6">
                            {subRoutes
                                .filter(item => {
                                    return getChatTabState() ? true : item.name !== 'ChatGPT'
                                })
                                .map(item => {
                                    return (
                                        <NavLink
                                            to={item.path}
                                            key={item.name}
                                            className="flex items-center p-3"
                                            // className={isActive => 'flex items-center h-14 p-3 ' + (!isActive ? item.offIcon : item.onIcon)}
                                            activeClassName="flex items-center w-full bg-menuBgColor"
                                            onClick={() => {
                                                trackEvent('nav button event', { button_type: item.name })
                                                googleTrackEvent('nav button event', item.name)
                                            }}
                                        >
                                            <div className="group flex items-center">
                                                <img src={item.phoneIcon} className="w-6" alt="" />
                                                <span className="ml-4">{item.name}</span>
                                            </div>
                                        </NavLink>
                                    )
                                })}
                        </nav>
                    </div>
                    <div className="flex flex-col space-y-1 py-6 pb-5">
                        {/* <div className="relative w-12 h-8 border-primary-600 bg-primary-50 text-primary-200 group flex items-center py-2 px-3 text-sm font-medium cursor-pointer">
                            <button className="absolute bottom-2 left-2 mb-8 hidden group-hover:flex z-[100]">
                                <img className="w-[150px] max-w-fit" src={wechatGroup} alt="" />
                            </button>
                            <img className="mr-4" src={wechatIcon} alt="" />
                            WeChat
                        </div> */}
                        <a
                            className="w-12 h-8 border-primary-600 bg-primary-50 text-primary-200 group flex items-center py-2 px-3 text-sm font-medium cursor-pointer"
                            href="mailto:contact@mst.xyz"
                        >
                            <img className="mr-4" src={emailIcon} alt="" />
                            Email
                        </a>
                        {getOverseaUrl() ? (
                            <>
                                <a
                                    className="w-12 h-8 border-primary-600 bg-primary-50 text-primary-200 group flex items-center py-2 px-3 text-sm font-medium cursor-pointer"
                                    href="https://twitter.com/mst__ai"
                                    target="_blank"
                                >
                                    <img className="mr-4" src={twitterIcon} alt="" />
                                    Twitter
                                </a>
                                <a
                                    className="w-12 h-8 border-primary-600 bg-primary-50 text-primary-200 group flex items-center py-2 px-3 text-sm font-medium cursor-pointer"
                                    href="https://discord.gg/ministers"
                                    target="_blank"
                                >
                                    <img className="mr-4" src={discordIcon} alt="" />
                                    Discord
                                </a>
                            </>
                        ) : (
                            ''
                        )}

                        <a
                            className="w-12 h-8 border-primary-600 bg-primary-50 text-primary-200 group flex items-center py-2 px-3 text-sm font-medium cursor-pointer"
                            onClick={changeLanguage}
                        >
                            {currentLanguage == 'zh' && <img className="mr-4" src={languageIconCN} alt="" />}
                            {currentLanguage == 'en' && <img className="mr-4" src={languageIconEN} alt="" />}
                            Language
                        </a>
                        {/* <a
                            href="/serviceTerms"
                            target="_blank"
                            className=" h-8 border-primary-600 bg-primary-50 text-primary-200 group flex items-center py-2 px-3 text-sm font-medium cursor-pointer"
                        >
                            <span>{t('navTools.teramsService')}</span>
                        </a> */}
                        <a
                            className="w-12 h-8 border-primary-600 bg-primary-50 text-primary-200 group flex items-center py-2 px-3 text-sm font-medium cursor-pointer"
                            onClick={logout}
                        >
                            <img className="mr-4" src={logoutIcon} alt="" />
                            Logout
                        </a>
                    </div>
                </div>
            </Drawer>

            <div className="hidden lg:flex lg:flex-shrink-0 bg-menuBgColor">
                <div className="flex w-20 flex-col">
                    <div className="flex min-h-0 flex-1 flex-col">
                        <div className="w-full pt-6">
                            <img className=" mx-auto w-[60px]" src={getImageUrl('icon')}></img>
                        </div>
                        <div className="flex-1">
                            <nav className="flex flex-col items-center space-y-0 py-6">
                                {subRoutes
                                    .filter(item => {
                                        return getChatTabState() ? true : item.name !== 'ChatGPT'
                                    })
                                    .map(item => {
                                        return (
                                            <NavLink
                                                to={item.path}
                                                key={item.name}
                                                className={isActive =>
                                                    'flex justify-center items-center w-full h-20 cursor-pointer ' +
                                                    (!isActive ? item.offIcon : item.onIcon + '  bg-toolsBgColor')
                                                }
                                                // activeClassName={`rounded-l-full bg-toolsBgColor ${item.onIcon}`}

                                                onClick={() => {
                                                    trackEvent('nav button event', { button_type: item.name })
                                                    googleTrackEvent('nav button event', item.name)
                                                }}
                                            >
                                                <div className="group w-full h-full relative flex flex-col items-center">
                                                    {/* <img src={item.onIcon} alt="" />
                                                    <img src={item.offIcon} alt="" /> */}

                                                    {/* <div className="absolute bottom-0 left-0 mb-6 hidden flex-col items-center group-hover:flex w-10 text-center">
                                                        <span className="whitespace-no-wrap leading-1 relative z-10 rounded-md bg-white p-2 text-xs text-gray-grayText shadow-lg">
                                                            ChatGPT
                                                        </span>
                                                    </div> */}
                                                </div>
                                                {/* <span className="sr-only">ChatGPT</span> */}
                                            </NavLink>
                                        )
                                    })}
                            </nav>
                        </div>
                        <div className="flex flex-col items-center space-y-5 py-6 pb-5">
                            <a className="flex justify-center items-center rounded-full w-8 h-8 cursor-pointer" href="mailto:contact@mst.xyz">
                                <div className="group relative flex flex-col items-center">
                                    <img src={emailIcon} alt="" />
                                    {/* <div className="absolute bottom-0 left-0 mb-8 hidden flex-col items-center group-hover:flex z-[100]">
                                        <img className="w-[180px] max-w-fit" src={wechatGroup} alt="" />
                                    </div> */}
                                </div>
                            </a>
                            {getOverseaUrl() ? (
                                <>
                                    <a
                                        className="flex justify-center items-center rounded-full w-8 h-8 cursor-pointer"
                                        target="_blank"
                                        href="https://twitter.com/mst__ai"
                                    >
                                        <div className="group relative flex flex-col items-center">
                                            <img src={twitterIcon} alt="" />
                                            {/* <div className="absolute bottom-0 left-0 mb-8 hidden flex-col items-center group-hover:flex z-[100]">
                                        <img className="w-[180px] max-w-fit" src={wechatGroup} alt="" />
                                    </div> */}
                                        </div>
                                    </a>
                                    <a
                                        href="https://discord.gg/ministers"
                                        target="_blank"
                                        className="flex justify-center items-center rounded-full w-8 h-8 cursor-pointer"
                                    >
                                        <div className="group relative flex flex-col items-center">
                                            <img src={discordIcon} alt="" />

                                            {/* <div className="absolute bottom-0 left-0 mb-6 hidden flex-col items-center group-hover:flex w-6 text-center">
                                        <span className="whitespace-no-wrap leading-1 relative z-10 rounded-md bg-white p-2 text-xs text-gray-grayText shadow-lg">
                                            Discord
                                        </span>
                                    </div> */}
                                        </div>
                                    </a>
                                </>
                            ) : (
                                ''
                            )}

                            <a className="flex justify-center items-center rounded-full w-8 h-8 cursor-pointer">
                                <div className="group relative flex flex-col items-center" onClick={changeLanguage}>
                                    {currentLanguage == 'zh' && <img src={languageIconCN} alt="" />}
                                    {currentLanguage == 'en' && <img src={languageIconEN} alt="" />}
                                    <div className="absolute bottom-0 left-0 mb-6 hidden flex-col items-center group-hover:flex w-max text-center">
                                        <span className="whitespace-no-wrap leading-1 relative z-10 rounded-md bg-white p-2 text-xs text-gray-grayText shadow-lg">
                                            {t('navTools.language')}
                                        </span>
                                    </div>
                                </div>
                            </a>
                            {/* <a href="/serviceTerms" target="_blank" className="flex justify-center items-center rounded-full w-8 h-8 cursor-pointer">
                                <span className="text-white text-xs text-center">{t('navTools.teramsService')}</span>
                            </a> */}
                            <a className="flex justify-center items-center rounded-full w-8 h-8 cursor-pointer">
                                <div className="group relative flex flex-col items-center" onClick={logout}>
                                    <img src={logoutIcon} alt="" />

                                    {/* <div className="absolute bottom-0 left-0 mb-6 hidden flex-col items-center group-hover:flex w-6 text-center">
                                        <span className="whitespace-no-wrap leading-1 relative z-10 rounded-md bg-white p-2 text-xs text-gray-grayText shadow-lg">
                                            Logout
                                        </span>
                                    </div> */}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
