// import { getImageUrl } from '@/helpers'
import { useAppSelector } from '@/hooks'
import {fetchCountryArea, setCountryArea, setMenuOpen} from '@/slices/AppSlice'
import { AppDispatch } from '@/store'
import React, {useEffect, useState} from 'react'
import ReactMarkdown from "react-markdown";
import footer_twitter from "@/assets/PPC/pc-footeer-twitter.png";
import footer_discord from "@/assets/PPC/pc-footer-discord.png";
import i18n, {changeLanguage, t} from "i18next";
import {getOverseaUrl} from "@/helpers";



export default function HomeDcTwit(props) {

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language.substring(0, 2))

    const changeLanguage = () => {
        if (currentLanguage == 'zh') {
            i18n.changeLanguage('en')
            setCurrentLanguage('en')
        } else if (i18n.language == 'en') {
            i18n.changeLanguage('zh')
            setCurrentLanguage('zh')
        }
    }

    const [time, setTime] = useState(0);
    useEffect(() => {
        const  InterVal = setInterval(()=> {
            setTime(time + 1)
        },800)
        // window.sessionStorage.setItem('header-serve', response.headers.server)
    }, [])

    return (
        getOverseaUrl() ? (
            <>
                <div className="flex row py-5 lg:py-0 px-10 cursor-pointer">
                    <img src={footer_twitter} alt="" />
                    <a href="https://twitter.com/mst__ai" target="_blank">
                        <span className="pl-3 text-lg font-bold">{t('home.footer.btn2')}</span>
                    </a>
                </div>
                <div className="flex row py-5 lg:py-0 px-10 cursor-pointer">
                    <img src={footer_discord} alt="" />
                    <a href="https://discord.gg/ministers" target="_blank">
                        <span className="pl-3 text-lg font-bold">{t('home.footer.btn3')}</span>
                    </a>
                </div>
            </>
        ) : (
            <></>
        )
    )
}
