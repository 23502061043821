import { Spin, Tooltip, Image, Divider, InputNumber, Dropdown, MenuProps, Checkbox } from 'antd'
import { RcFile } from 'antd/es/upload'
import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AIImageUpload from './AIImageUpload'
import IntegerSlider from './components/IntegerSlider'
import ModelListModal from './modal/ModelListModal'
import { useAppSelector, useScrollGesture } from '@/hooks'
import {
    IGenerateStateDiffusionState,
    IModelItem,
    removeSd_selectedSubModel,
    setSd_selectedModel,
    setSd_vaeModel,
    setStableDiffusionState,
    updateSd_modelConfig,
    upsertSd_selectedSubModel,
    updateSd_hiresConfig,
} from '@/slices/GenerateImageSlice'
import { AppDispatch } from '@/store'
import { useDispatch, useSelector } from 'react-redux'
import deleteIcon from '../../assets/aiimage_delete.png'
import model_modal_right_arrow from '../../assets/modelModal/model_modal_right_arrow.png'
import model_modal_title_arrow from '../../assets/modelModal/model_modal_title_arrow.png'
import model_modal_small_add from '../../assets/modelModal/model_modal_small_add.png'
import model_modal_small_delete from '../../assets/modelModal/model_modal_small_delete.png'

import { getOssAssumeRoleToken } from '@/api/request'
import { uploadFiles2OSS, blobToBase64 } from '@/api/uploadFiles'
import { getUuid } from '@/helpers'
import InputSlider from './components/InputSlider'
import VAEModal from './vaeModal/VAEModal'
import { fetchSd_defaultConfig, fetchSt_modalConfig } from '@/slices/GeneratedImageThunk'
import { useCommonSlice } from '@/slices/commonSlice'
import OpenposeModel from './components/OpenposeModel'
import { OpenposeProvider } from '@/lib/Openpose/OpenposeContext'
import ControlNetSetting from './components/ControlNetSetting'
import { useAliyunOSSContext } from '@/lib/AliyunOSS'

const TipInfo: React.FC<{ title: string; className?: string }> = ({ title, className }) => {
    return (
        <Tooltip title={title}>
            <div data-tool-target="tooltip-default" className={className}>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 16.8334C13.4978 16.8334 16.3334 13.9978 16.3334 10.5C16.3334 7.00222 13.4978 4.16669 10 4.16669C6.50222 4.16669 3.66669 7.00222 3.66669 10.5C3.66669 13.9978 6.50222 16.8334 10 16.8334Z"
                        stroke="white"
                        strokeOpacity="0.5"
                        strokeWidth="1.2"
                        strokeLinecap="square"
                    ></path>
                    <path
                        d="M9.25 7.75C9.25 7.94891 9.32902 8.13968 9.46967 8.28033C9.61032 8.42098 9.80109 8.5 10 8.5C10.1989 8.5 10.3897 8.42098 10.5303 8.28033C10.671 8.13968 10.75 7.94891 10.75 7.75C10.75 7.55109 10.671 7.36032 10.5303 7.21967C10.3897 7.07902 10.1989 7 10 7C9.80109 7 9.61032 7.07902 9.46967 7.21967C9.32902 7.36032 9.25 7.55109 9.25 7.75Z"
                        fill="white"
                        fillOpacity="0.5"
                    ></path>
                    <path
                        d="M10.375 9.5H9.625C9.55625 9.5 9.5 9.55625 9.5 9.625V13.875C9.5 13.9437 9.55625 14 9.625 14H10.375C10.4438 14 10.5 13.9437 10.5 13.875V9.625C10.5 9.55625 10.4438 9.5 10.375 9.5Z"
                        fill="white"
                        fillOpacity="0.5"
                    ></path>
                </svg>
            </div>
        </Tooltip>
    )
}

const OSS_SCALE_DOWN = '?x-oss-process=image/resize,w_700/quality,Q_80'

const SdConfigBar: React.FC = props => {
    // const state = useAppSelector(state => state.generateImageSlice.stableDiffusion)
    const historyDetailParams = useAppSelector(state => state.generateImageSlice.stableDiffusion.historyDetailParams)
    const selectedModelItem = useAppSelector(state => state.generateImageSlice.stableDiffusion.selectedModelItem)
    const modalState = useAppSelector(state => state.generateImageSlice.stableDiffusion.modalState)
    const modelConfig = useAppSelector(state => state.generateImageSlice.stableDiffusion.modelConfig)
    const hiresConfig = useAppSelector(state => state.generateImageSlice.stableDiffusion.hiresConfig)
    const uploadResponse = useAppSelector(state => state.generateImageSlice.stableDiffusion.uploadResponse)
    const vaeModel = useAppSelector(state => state.generateImageSlice.stableDiffusion.vaeModel)
    const sizeItems = useAppSelector(state => state.generateImageSlice.stableDiffusion.sizeItems)
    const xlSizeItems = useAppSelector(state => state.generateImageSlice.stableDiffusion.xlSizeItems)

    const selectedSizeItem = useAppSelector(state => state.generateImageSlice.stableDiffusion.selectedSizeItem)
    const minSize = useAppSelector(state => state.generateImageSlice.stableDiffusion.minSize)
    const maxSize = useAppSelector(state => state.generateImageSlice.stableDiffusion.maxSize)
    const xlMinSize = useAppSelector(state => state.generateImageSlice.stableDiffusion.xlMinSize)
    const xlMaxSize = useAppSelector(state => state.generateImageSlice.stableDiffusion.xlMaxSize)
    const inputSize = useAppSelector(state => state.generateImageSlice.stableDiffusion.inputSize)
    const numberImage = useAppSelector(state => state.generateImageSlice.stableDiffusion.numberImage)
    const uploadProgress = useAppSelector(state => state.generateImageSlice.stableDiffusion.uploadProgress)
    const hiresCollapsed = useAppSelector(state => state.generateImageSlice.stableDiffusion.hiresCollapsed)

    const authToken = useAppSelector(state => {
        return state.app?.authToken
    })

    const { isPendingThunk } = useCommonSlice()

    const dispatch = useDispatch<AppDispatch>()
    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))

    const pictureScrollRef = useRef<HTMLDivElement>(null)
    useScrollGesture(pictureScrollRef)

    useEffect(() => {
        if (authToken) {
            dispatch(fetchSt_modalConfig(4))
            dispatch(fetchSt_modalConfig(1))
            dispatch(fetchSt_modalConfig(2))
            dispatch(fetchSt_modalConfig(3))
            dispatch(fetchSt_modalConfig(5))
        }
    }, [authToken])

    //图片记录恢复生图
    useEffect(() => {
        const detail = historyDetailParams
        if (detail) {
            updateState({
                selectedSizeItem: undefined,
                inputSize: { width: detail.width, height: detail.height },
                uploadResponse: { url: '', base64StringOrUrl: '' },

                // uploadResponse: { url: detail.picUrl ?? '', base64StringOrUrl: detail.picUrl ?? '' },
            })

            // 模型
            let loraModels: any[] = []
            if (detail.sdUseLora) {
                detail.loraModels.map(item => {
                    loraModels.push({
                        modelId: item.loraModelId,
                        weight: item.loraWeight,
                        modelName: item.modelName,
                        modelIcon: item.icon,
                        modelDrawIcon: item.drawIcon,
                        ixXl: item.isXl,
                    })
                })
            }
            dispatch(
                setSd_selectedModel({
                    modelId: detail.taskModelId,
                    modelName: detail.modelName,
                    modelIcon: detail.icon,
                    modelDrawIcon: detail.drawIcon,
                    subUsedModels: loraModels,
                    isXl: detail.isXl,
                }),
            )

            //vae
            if (detail.vaeModel) {
                dispatch(
                    setSd_vaeModel({
                        modelId: detail.vaeModel.vaeModelId,
                        modelName: detail.vaeModel.vaeName,
                        modelIcon: detail.vaeModel.icon,
                        modelDrawIcon: detail.vaeModel.drawIcon,
                        isXl: 0,
                    }),
                )
            }

            //config
            dispatch(
                updateSd_modelConfig({
                    cfgScale: detail.cfgScale,
                    clipSkip: detail.clipSkip,
                    denoisingStrength: detail.denoisingStrength,
                    ensd: detail.ensd,
                    samplingId: detail.samplingId,
                    samplingSteps: detail.samplingStep,
                    seed: detail.seed,
                }),
            )

            //hire
            updateState({ hiresCollapsed: detail.sdUseHires != 1 })
            if (detail.sdUseHires == 1) {
                dispatch(
                    updateSd_hiresConfig({
                        upscaler: Number(detail.hiresUpscaleId),
                        hiresSteps: Number(detail.hiresUpscaleSteps),
                        denoisingStrength: Number(detail.hiresUpscaleDs),
                        upscaleBy: Number(detail.hiresUpscaleBy),
                    }),
                )
            }
        }
    }, [historyDetailParams])

    function resetHandle() {
        const id = selectedModelItem?.modelId ?? '0'
        dispatch(fetchSd_defaultConfig({ modelId: id }))
    }

    const { requestOssClient, defaultUpload } = useAliyunOSSContext()

    const samplerName = useMemo(() => {
        return modalState.samplers.find((v, i) => v.data.systemDicId == modelConfig?.samplingId)?.name ?? ''
    }, [modalState.samplers, modelConfig?.samplingId])

    const hireUpscalerName = useMemo(() => {
        return modalState.hiresUpscaler.find((v, i) => v.data.systemDicId == hiresConfig?.upscaler)?.name ?? ''
    }, [modalState.hiresUpscaler, hiresConfig?.upscaler])

    async function handleUploadFile(e) {
        const files = [e]
        if (files && files.length > 0) {
            const f = files[0]

            try {
                updateState({ uploadProgress: 1 })

                const fileExtension = f.name.split('.').pop().toLowerCase()
                const newFileName = getUuid() + '.' + fileExtension

                let ossInfo = await requestOssClient(2)
                let result = await defaultUpload({ client: ossInfo.client, file: f, filePath: ossInfo.dataDir, fileName: newFileName })
                console.log('🚀 ~ file: SdConfigBar.tsx:211 ~ handleUploadFile ~ result:', result)

                updateState({ uploadProgress: 0 })

                blobToBase64(f, base64String => {
                    updateState({ uploadResponse: { url: result.name, base64StringOrUrl: base64String } })
                })
            } catch (error) {
                console.log('🚀 ~ file: SdConfigBar.tsx:219 ~ handleUploadFile ~ error:', error)
                updateState({ uploadProgress: 0 })
            }
        }
    }

    const samplerItems: MenuProps['items'] = modalState.samplers.map((item, i) => {
        return {
            key: String(i),
            label: (
                <div className="h-5">
                    <span>{item.name}</span>
                </div>
            ),
        }
    })

    const hireItems: MenuProps['items'] = modalState.hiresUpscaler.map((item, i) => {
        return {
            key: String(i),
            label: (
                <div className="h-5">
                    <span>{item.name}</span>
                </div>
            ),
        }
    })
    const [uploadImageCollapsed, setUploadImageCollapsed] = useState<boolean>(true)
    const [advancedSettingCollapsed, setAdvancedSettingCollapsed] = useState<boolean>(true)
    // const [hiresCollapsed, setHiresCollapsed] = useState<boolean>(true)
    // const [controlNetCollapsed, setControlNetCollapsed] = useState<boolean>(true)
    const [controlNetTypePreprocessor, setControlNetTypePreprocessor] = useState<any>([])
    const controlNetTypePreprocessorItem: MenuProps['items'] = controlNetTypePreprocessor.map((item, i) => {
        return {
            key: String(i),
            label: (
                <div className="flex items-center h-5">
                    <span>{item.name}</span>
                    <TipInfo title={item.illustrate} className="inline-block" />
                </div>
            ),
        }
    })

    useEffect(() => {
        if (uploadResponse.base64StringOrUrl.length > 0) {
            setUploadImageCollapsed(false)
        }
    }, [uploadResponse.base64StringOrUrl])

    const isXl = useMemo(() => {
        const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

        const xl = (selectedModelItem?.isXl || selectedModelItem?.subUsedModels?.[0]?.isXl) ?? 0
        let size: typeof inputSize
        let items = xlSizeItems
        if (xl) {
            size = { width: clamp(inputSize.width, xlMinSize, xlMaxSize), height: clamp(inputSize.height, xlMinSize, xlMaxSize) }
            updateState({ inputSize: size })
        } else {
            items = sizeItems
            size = { width: clamp(inputSize.width, minSize, maxSize), height: clamp(inputSize.height, minSize, maxSize) }
            updateState({ inputSize: size })
        }

        let exist = false
        for (let index = 0; index < items.length; index++) {
            const element = items[index]
            if (size.width == element.width && size.height == element.height) {
                updateState({ selectedSizeItem: items[index] })
                exist = true
                break
            }
        }

        if (!exist) {
            updateState({ selectedSizeItem: undefined })
        }

        return xl
    }, [selectedModelItem])

    const vaeRender = useCallback(
        (open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
            return (
                <div>
                    {vaeModel ? (
                        <ul>
                            <li className="flex justify-between items-center">
                                <div
                                    onClick={e => {
                                        setOpen(open => !open)
                                    }}
                                    className="cursor-pointer relative h-14 flex-1 text-white/90 bg-[#2d2d2d] rounded-md flex justify-between items-center"
                                >
                                    <button
                                        onClick={e => {
                                            e.stopPropagation()
                                            updateState({ vaeModel: undefined })
                                        }}
                                        className="absolute w-10 h-10 -top-5 -right-5 flex justify-center items-center"
                                    >
                                        <img className="w-5" src={model_modal_small_delete}></img>
                                    </button>
                                    <img
                                        src={(vaeModel.modelDrawIcon ?? vaeModel.modelIcon) + OSS_SCALE_DOWN}
                                        className="ml-1 w-12 h-12 object-top object-cover"
                                    />
                                    <span className="flex-1 px-3 text-xs text-white line-clamp-2 text-ellipsis break-all text-left">
                                        {vaeModel.modelName}
                                    </span>
                                </div>
                                <div className="ml-1.5 w-11 h-14"></div>
                            </li>
                        </ul>
                    ) : (
                        <div className="flex justify-between items-center">
                            <button
                                onClick={e => {
                                    setOpen(open => !open)
                                }}
                                className="flex-1 m-bg-gradient h-7 p-[1px] rounded-md"
                            >
                                <div className="bg-menuBgColor rounded-md h-full flex justify-center items-center">
                                    <img src={model_modal_small_add}></img>
                                </div>
                            </button>
                            <div className="ml-1.5 w-11"></div>
                        </div>
                    )}
                </div>
            )
        },
        [vaeModel],
    )

    const mainModelRender = useCallback(
        (open, setOpen) => (
            <button
                className="h-14 w-full text-white/90 bg-[#2d2d2d] rounded flex justify-between items-center"
                type="button"
                onClick={e => {
                    setOpen(!open)
                }}
            >
                <img
                    src={(selectedModelItem?.modelDrawIcon ?? selectedModelItem?.modelIcon) + OSS_SCALE_DOWN}
                    className="ml-1 w-12 h-12 object-top object-cover"
                />
                <span className="flex-1 px-3 text-xs text-white line-clamp-2 text-ellipsis break-all text-left">{selectedModelItem?.modelName}</span>
                <img className="mr-2" src={model_modal_right_arrow} />
            </button>
        ),
        [selectedModelItem],
    )

    const loraRender = useCallback(
        (open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, extra: any, setExtra: React.Dispatch<React.SetStateAction<any>>) => {
            return (
                <>
                    <div className="my-2 mr-1 text-xs text-[#4A4A4A]">{t('generatedImage.FinetunedModel')}</div>
                    <ul className="space-y-2">
                        {selectedModelItem?.subUsedModels?.map((lora, index) => (
                            <li key={lora.modelId} className="flex justify-between items-center">
                                <div
                                    onClick={e => {
                                        setExtra({ changeLoraIndex: index })
                                        setOpen(!open)
                                    }}
                                    className="cursor-pointer relative h-14 flex-1 text-white/90 bg-[#2d2d2d] rounded-md flex justify-between items-center"
                                >
                                    <button
                                        onClick={e => {
                                            e.stopPropagation()
                                            dispatch(removeSd_selectedSubModel(index))
                                        }}
                                        className="absolute w-10 h-10 -top-5 -right-5 flex justify-center items-center"
                                    >
                                        <img className="w-5" src={model_modal_small_delete}></img>
                                    </button>
                                    <img
                                        src={(lora.modelDrawIcon ?? lora.modelIcon) + OSS_SCALE_DOWN}
                                        className="ml-1 w-12 h-12 object-top object-cover overflow-clip"
                                    />
                                    <span className="flex-1 px-3 text-xs text-white line-clamp-2 text-ellipsis break-all text-left">
                                        {lora.modelName}
                                    </span>
                                </div>
                                <div className="ml-1.5 w-11 h-14">
                                    <input
                                        value={lora.weight}
                                        onBlur={e => {
                                            let v = Number(e.target.value)
                                            if (v > 1) {
                                                v = 1
                                            }

                                            if (v < 0) {
                                                v = 0
                                            }
                                            const w = parseFloat(v.toFixed(2)).toString()
                                            dispatch(upsertSd_selectedSubModel({ ...lora, weight: w }))
                                        }}
                                        onChange={e => {
                                            let w = e.target.value
                                            let v = Number(w)

                                            if (v > 1) {
                                                w = '1'
                                            }

                                            if (v < 0) {
                                                w = ''
                                            }
                                            if (w.length > 4) {
                                                return
                                            }

                                            dispatch(upsertSd_selectedSubModel({ ...lora, weight: w }))
                                        }}
                                        type="number"
                                        className="bg-black rounded-md arrow-hide w-full h-full outline-none text-center"
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                    {(!selectedModelItem?.subUsedModels || selectedModelItem.subUsedModels.length < 10) && (
                        <div className="mt-2 flex justify-between items-center">
                            <button
                                onClick={e => {
                                    setExtra(undefined)
                                    setOpen(!open)
                                }}
                                className="flex-1 m-bg-gradient h-7 p-[1px] rounded-md"
                            >
                                <div className="bg-menuBgColor rounded-md h-full flex justify-center items-center">
                                    <img src={model_modal_small_add}></img>
                                </div>
                            </button>
                            <div className="ml-1.5 w-11"></div>
                        </div>
                    )}
                </>
            )
        },
        [selectedModelItem],
    )

    return (
        <div className="dark-thin-scrollbar p-4 sm:pt-6 bg-gray-bg overflow-y-auto w-full sm:w-[17rem] text-white/90 shrink-0">
            {/* <div className="block sm:hidden">{headRender()}</div> */}
            <div className="mt-4 text-sm flex items-center">
                <div className="text-sm mr-1">{t('generatedImage.dimensionsOfImage')}</div>
                <TipInfo title={t('generatedImage.dimensionsDesc')} />
            </div>
            <div
                id="picture-select"
                ref={pictureScrollRef}
                // onWheel={handleScroll}
                className="flex mt-2 py-1 pb-2 space-x-1 overflow-x-auto justify-between scrollbar-none"
            >
                {(isXl ? xlSizeItems : sizeItems).map((item, i) => {
                    let w = 0,
                        h = 0
                    if (item.width > item.height) {
                        w = 100
                        h = Math.ceil((item.height / item.width) * 100)
                    } else {
                        w = Math.ceil((item.width / item.height) * 100)
                        h = 100
                    }

                    const e = selectedSizeItem?.name == item.name
                    return (
                        <button
                            key={i}
                            className={`${
                                e ? 'm-bg-gradient text-primary-500' : 'text-white/90'
                            } flex-1 p-[2px] rounded-md !hover:bg-gradient-to-r !hover:from-btnBgColorTo/30 !hover:to-btnBgColorFrom/30`}
                            onClick={e => {
                                updateState({ selectedSizeItem: item })
                                let itemW = item.width
                                let itemH = item.height

                                updateState({ inputSize: { width: itemW, height: itemH } })

                                // const s = e.currentTarget
                                // s.scrollIntoView()
                            }}
                        >
                            <div key={item.name} className={`p-2 rounded-md bg-black flex flex-col items-center`}>
                                <div className="flex items-center justify-center w-6 h-6">
                                    <div
                                        className={`rounded border-2 ${e ? 'border-current' : ''}`}
                                        style={{ width: `${w}%`, height: `${h}%` }}
                                    ></div>
                                </div>
                                <div className="mt-2 text-center text-xs leading-none text-current">{item.name}</div>
                            </div>
                        </button>
                    )
                })}
            </div>
            <div className="bg-[#2d2d2d] p-1 rounded-md">
                <IntegerSlider
                    className="items-center"
                    min={isXl ? xlMinSize : minSize}
                    max={isXl ? xlMaxSize : maxSize}
                    title="W"
                    value={inputSize.width}
                    onChange={value => {
                        updateState({ inputSize: { ...inputSize, width: value } })

                        if (value != selectedSizeItem?.width) {
                            updateState({ selectedSizeItem: null })
                        }
                    }}
                />
                <IntegerSlider
                    className="mt-2 items-center"
                    min={isXl ? xlMinSize : minSize}
                    max={isXl ? xlMaxSize : maxSize}
                    title="H"
                    value={inputSize.height}
                    onChange={value => {
                        updateState({ inputSize: { ...inputSize, height: value } })
                        if (value != selectedSizeItem?.height) {
                            updateState({ selectedSizeItem: null })
                        }
                    }}
                />
            </div>
            <div className="mt-4">
                <div className="block text-sm">{t('generatedImage.numberOfImage')}</div>
                <div className="mt-2 flex rounded-md justify-around space-x-1 text-sm'">
                    {[1, 2, 3, 4].map((val, i) => {
                        const disable = (inputSize.width > 1024 || inputSize.height > 1024) && val != 1
                        if (disable && numberImage != 1) {
                            updateState({ numberImage: 1 })
                        }
                        // const disable = false
                        const e = numberImage == val
                        return (
                            <div
                                key={i}
                                className={`
                                ${
                                    e ? 'm-bg-gradient' : ''
                                } rounded-md p-[2px] flex-1 !hover:bg-gradient-to-r !hover:from-btnBgColorTo/30 !hover:to-btnBgColorFrom/30`}
                            >
                                <button
                                    disabled={disable}
                                    className={`w-full rounded py-0.5
                                    ${e ? 'text-primary-500 border-primary-500' : 'text-white/90 border-gray-grayText'}
                                    bg-black ${disable ? 'opacity-20' : ''}
                                    `}
                                    onClick={e => updateState({ numberImage: val })}
                                >
                                    {val}
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="mt-4">
                <Divider className="my-0" />
                <div
                    className="text-sm h-10 flex justify-between items-center"
                    onClick={e => {
                        setUploadImageCollapsed(pre => !pre)
                    }}
                >
                    <span>{t('generatedImage.uploadingReferenceImages')}</span>
                    <img className={`w-4 transition-transform ${uploadImageCollapsed ? 'rotate-0' : 'rotate-180'}`} src={model_modal_title_arrow} />
                </div>
                <Spin spinning={uploadProgress > 0}>
                    <div
                        className={`overflow-clip  flex justify-center items-center bg-black rounded-md transition-all ${
                            uploadImageCollapsed ? 'p-0 max-h-0 opacity-0' : 'p-2 max-h-[1200px] opacity-100'
                        }`}
                    >
                        {uploadResponse.url && uploadResponse.url.length > 0 ? (
                            <div className="relative">
                                <div className="absolute right-0 bottom-0 text-white/90 flex space-x-1">
                                    <button
                                        onClick={e => {
                                            updateState({ uploadResponse: { url: '', base64StringOrUrl: '' } })
                                        }}
                                        className="p-1 mr-1 bg-black/50 !hover:bg-black/80 transition rounded-sm"
                                        // onClick={e => download(val)}
                                    >
                                        <img src={deleteIcon} />
                                    </button>
                                </div>
                                <Image
                                    src={uploadResponse.base64StringOrUrl}
                                    alt=""
                                    rootClassName="block static"
                                    className="pointer-events-none object-cover group-!hover:opacity-75"
                                    preview={{ maskClassName: 'invisible' }}
                                />
                            </div>
                        ) : (
                            <div className="w-full">
                                <AIImageUpload
                                    handleFile={function (file: string | Blob | RcFile): void {
                                        handleUploadFile(file)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </Spin>
            </div>
            {/* Models */}
            <div className="mt-1 text-sm">
                <Divider className="my-0" />
                <div className="my-2">
                    <div className="mr-1">{t('generatedImage.modelSelection')}</div>
                    <div className="mr-1 text-xs text-[#4A4A4A]">{t('generatedImage.MainModel')}</div>
                </div>
                {selectedModelItem && <ModelListModal el={mainModelRender} />}

                <div>
                    <ModelListModal modelId={selectedModelItem?.modelId} el={loraRender} />
                </div>
                <div>
                    <div className="my-2 mr-1 text-xs text-[#4A4A4A]">{t('generatedImage.VAE')}</div>
                    <VAEModal el={vaeRender} />
                </div>
                {/* <div>
                    <OpenposeProvider>
                        <OpenposeModel />
                    </OpenposeProvider>
                </div> */}
            </div>
            <div className="mt-4">
                <Divider className="my-0" />
                <div
                    className="text-sm h-10 flex justify-between items-center"
                    onClick={e => {
                        setAdvancedSettingCollapsed(pre => !pre)
                    }}
                >
                    <span>{t('generatedImage.AdvancedSetting')}</span>
                    <img
                        className={`w-4 transition-transform ${advancedSettingCollapsed ? 'rotate-0' : 'rotate-180'}`}
                        src={model_modal_title_arrow}
                    />
                </div>
                <div
                    className={`space-y-1 overflow-clip transition-all ${
                        advancedSettingCollapsed ? 'max-h-0 opacity-0' : 'max-h-[32rem] opacity-100'
                    }`}
                >
                    {/* Sampler */}
                    <div className="flex justify-between items-center text-xs">
                        <span className="flex items-center whitespace-nowrap w-20">
                            <span>{t('generatedImage.Sampler')}</span>
                            <TipInfo title={t('generatedImage.SamplerTip')} className="inline-block" />
                        </span>
                        <div className="flex-1 w-full">
                            <Dropdown
                                placement="bottomLeft"
                                trigger={['click']}
                                menu={{
                                    className: 'dark-thin-scrollbar',
                                    style: { height: '12rem', overflowY: 'auto' },
                                    items: samplerItems,
                                    onClick: ({ key, keyPath, domEvent }) => {
                                        const item = modalState.samplers[parseInt(key)]
                                        dispatch(updateSd_modelConfig({ samplingId: item.data?.systemDicId }))
                                    },
                                }}
                            >
                                <div className="flex justify-between items-center bg-black rounded-md">
                                    <div className="text-xs h-[30px] leading-[30px] px-[11px]">{samplerName}</div>
                                    <div>
                                        <img className="scale-75 mr-1" src={model_modal_title_arrow} />
                                    </div>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    {/* Steps */}
                    <div className="flex justify-between items-center text-xs">
                        <span className="flex items-center whitespace-nowrap w-20">
                            <span>{t('generatedImage.Steps')}</span>
                            <TipInfo title={t('generatedImage.StepsTip')} className="inline-block" />
                        </span>
                        <InputSlider
                            min={1}
                            max={50}
                            step={1}
                            value={modelConfig.samplingSteps}
                            onChange={function (newValue: number): void {
                                dispatch(updateSd_modelConfig({ samplingSteps: newValue }))
                            }}
                        />
                    </div>
                    {/* CFG */}
                    <div className="flex justify-between items-center text-xs">
                        <span className="flex items-center whitespace-nowrap w-20">
                            <span>{t('generatedImage.CFGScale')}</span>
                            <TipInfo title={t('generatedImage.CFGScaleTip')} className="inline-block" />
                        </span>
                        <InputSlider
                            min={1}
                            max={15}
                            step={0.1}
                            value={modelConfig.cfgScale}
                            onChange={function (newValue: number): void {
                                dispatch(updateSd_modelConfig({ cfgScale: newValue }))
                            }}
                        />
                    </div>
                    {/* Clip skip */}
                    <div className="flex justify-between items-center text-xs">
                        <span className="flex items-center whitespace-nowrap w-20">
                            <span>{t('generatedImage.ClipSkip')}</span>
                            <TipInfo title={t('generatedImage.ClipSkipTip')} className="inline-block" />
                        </span>
                        <InputSlider
                            min={1}
                            max={10}
                            step={1}
                            value={modelConfig.clipSkip}
                            onChange={function (newValue: number): void {
                                dispatch(updateSd_modelConfig({ clipSkip: newValue }))
                            }}
                        />
                    </div>

                    {/* Denoising strength */}
                    {uploadResponse.url && uploadResponse.url.length > 0 && (
                        <div className="flex flex-col items-start text-xs">
                            <span className="flex items-center whitespace-nowrap w-20">
                                <span>{t('generatedImage.DenoisingStrength')}</span>
                                <TipInfo title={t('generatedImage.DenoisingStrengthTip')} className="inline-block" />
                            </span>
                            <InputSlider
                                min={0}
                                max={1}
                                step={0.01}
                                value={modelConfig.denoisingStrength}
                                onChange={function (newValue: number): void {
                                    dispatch(updateSd_modelConfig({ denoisingStrength: newValue }))
                                }}
                            />
                        </div>
                    )}

                    {/* Seed */}
                    <div className="flex justify-between items-center text-xs">
                        <span className="flex items-center whitespace-nowrap w-20">
                            <span>{t('generatedImage.Seed')}</span>
                            <TipInfo title={t('generatedImage.SeedTip')} className="inline-block" />
                        </span>
                        <div className="flex-1 w-full">
                            <InputNumber
                                className="text-xs bg-black hover:bg-black w-full"
                                bordered={false}
                                controls={false}
                                stringMode
                                value={modelConfig.seed}
                                min={-1}
                                maxLength={19}
                                onChange={newValue => {
                                    dispatch(updateSd_modelConfig({ seed: newValue ?? -1 }))
                                }}
                            />
                        </div>
                    </div>
                    {/* ENSD */}
                    <div className="flex justify-between items-center text-xs">
                        <span className="flex items-center whitespace-nowrap w-20">
                            <span>{t('generatedImage.ENSD')}</span>
                            <TipInfo title={t('generatedImage.ENSDTip')} className="inline-block" />
                        </span>
                        <div className="flex-1 w-full">
                            <InputNumber
                                className="text-xs bg-black hover:bg-black w-full"
                                bordered={false}
                                controls={false}
                                value={modelConfig.ensd}
                                min={0}
                                // max={1}
                                step={1}
                                onChange={newValue => {
                                    dispatch(updateSd_modelConfig({ ensd: newValue ?? 0 }))
                                }}
                            />
                        </div>
                    </div>
                    {/* Hires */}
                    <div
                        // className={`${!hiresCollapsed ? 'bg-menuBgColor' : ''} ${
                        //     uploadResponse.base64StringOrUrl ? 'hidden' : ''
                        // } space-y-1 p-1 rounded-md`}
                        className={`${!hiresCollapsed ? 'bg-menuBgColor' : ''} space-y-1 p-1 rounded-md`}
                    >
                        <div className="custom-checkbox">
                            <input
                                type="checkbox"
                                id="Hires"
                                onChange={e => {
                                    updateState({ hiresCollapsed: !hiresCollapsed })
                                }}
                                // value={hiresCollapsed}
                                checked={hiresCollapsed !== true}
                            />
                            <label htmlFor="Hires">{t('Hires.fix')}</label>
                        </div>
                        <div className={`${!hiresCollapsed ? 'flex flex-col' : 'hidden'} space-y-1`}>
                            <div className="flex justify-between items-center text-xs">
                                <span className="flex items-center whitespace-nowrap w-20">
                                    <span>{t('generatedImage.hires.Upscaler')}</span>
                                    {/* <TipInfo title={t('generatedImage.SamplerTip')} className="inline-block" /> */}
                                </span>
                                <div className="flex-1 w-full">
                                    <Dropdown
                                        placement="bottomLeft"
                                        trigger={['click']}
                                        menu={{
                                            className: 'dark-thin-scrollbar',
                                            style: { height: '12rem', overflowY: 'auto' },
                                            items: hireItems,
                                            onClick: ({ key, keyPath, domEvent }) => {
                                                const item = modalState.hiresUpscaler[parseInt(key)]
                                                dispatch(updateSd_hiresConfig({ upscaler: item.data?.systemDicId }))
                                            },
                                        }}
                                    >
                                        <div className=" sm:w-[160px] flex justify-between items-center bg-black rounded-md">
                                            <div className=" text-xs h-[30px] leading-[30px] px-[11px] max-w-[80%] overflow-hidden whitespace-nowrap text-ellipsis">
                                                {hireUpscalerName}
                                            </div>
                                            <div>
                                                <img className="scale-75 mr-1" src={model_modal_title_arrow} />
                                            </div>
                                        </div>
                                    </Dropdown>
                                </div>
                            </div>
                            {/* Steps */}
                            <div className="flex justify-between items-center text-xs">
                                <span className="flex items-center whitespace-nowrap w-20">
                                    <span>{t('generatedImage.hires.HiresSteps')}</span>
                                    {/* <TipInfo title={t('generatedImage.StepsTip')} className="inline-block" /> */}
                                </span>
                                <InputSlider
                                    min={0}
                                    max={50}
                                    step={1}
                                    value={hiresConfig?.hiresSteps}
                                    onChange={function (newValue: number): void {
                                        dispatch(updateSd_hiresConfig({ hiresSteps: newValue }))
                                    }}
                                />
                            </div>
                            {/* CFG */}
                            <div className="flex justify-between items-center text-xs">
                                <span className="flex items-center whitespace-nowrap w-20">
                                    <span className=" whitespace-break-spaces">{t('generatedImage.hires.DenoisingStrength')}</span>
                                    {/* <TipInfo title={t('generatedImage.CFGScaleTip')} className="inline-block" /> */}
                                </span>
                                <InputSlider
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    value={hiresConfig.denoisingStrength}
                                    onChange={function (newValue: number): void {
                                        dispatch(updateSd_hiresConfig({ denoisingStrength: newValue }))
                                    }}
                                />
                            </div>
                            {/* Clip skip */}
                            <div className="flex justify-between items-center text-xs">
                                <span className="flex items-center whitespace-nowrap w-20">
                                    <span>{t('generatedImage.hires.UpscalerBy')}</span>
                                    {/* <TipInfo title={t('generatedImage.ClipSkipTip')} className="inline-block" /> */}
                                </span>
                                <InputSlider
                                    min={1}
                                    max={selectedSizeItem && selectedSizeItem?.name == '1:1' ? 4 : 2}
                                    step={0.01}
                                    value={hiresConfig.upscaleBy}
                                    onChange={function (newValue: number): void {
                                        dispatch(updateSd_hiresConfig({ upscaleBy: newValue }))
                                    }}
                                />
                            </div>
                            <div className=" my-2 text-[#4A4A4A] whitespace-nowrap text-sm">{`resize:from ${inputSize.width}*${
                                inputSize.height
                            } to ${parseInt(inputSize.width * hiresConfig.upscaleBy + '')}*${parseInt(
                                inputSize.height * hiresConfig.upscaleBy + '',
                            )}`}</div>
                        </div>
                    </div>

                    {/* reset */}
                    <div className="flex justify-between items-center text-xs" style={{ marginTop: '2rem' }}>
                        <Spin spinning={isPendingThunk(fetchSd_defaultConfig)}>
                            <button
                                onClick={() => {
                                    resetHandle()
                                }}
                                className="rounded-md m-bg-gradient w-20 h-8"
                            >
                                {t('generatedImage.Reset')}
                            </button>
                        </Spin>
                    </div>
                </div>
            </div>
            {!isXl && <ControlNetSetting />}
        </div>
    )
}

export default SdConfigBar
