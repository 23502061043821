// import { getImageUrl } from '@/helpers'
import { useAppSelector } from '@/hooks'
import { setMenuOpen } from '@/slices/AppSlice'
import { AppDispatch } from '@/store'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

export default function Header(props) {
    const dispatch = useDispatch<AppDispatch>()
    const history = useHistory()
    const location = useLocation()
    const menuOpen = useAppSelector(state => {
        return state.app && state.app.menuOpen
    })
    const isShowWelcomePage = useAppSelector(state => {
        return state.app && state.app.isShowWelcomePage
    })
    const handleDrawerOpen = () => {
        // setOpen(false)
        dispatch(setMenuOpen(true))
    }
    const handleDrawerClose = () => {
        dispatch(setMenuOpen(false))
        // setOpen(false)
    }
    function getImageUrl(name) {
        return new URL(`../../assets/${name}.png`, import.meta.url).href
    }
    return (
        <div className="lg:hidden bg-pageBgColor">
            <div className="w-full fixed top-0 left-0 right-0 z-30">
                <div className="w-full block"></div>
                <div className="pt-4 pb-3 px-5 flex items-center justify-between bg-menuBgColor rounded-b-xl h-16">
                    {!isShowWelcomePage ? (
                        <div className="flex w-full text-white">
                            <button type="button" className="inline-flex items-center justify-center rounded-md " onClick={handleDrawerOpen}>
                                <span className="sr-only">Open sidebar</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h7"></path>
                                </svg>
                            </button>
                            <h3 className="ml-4">
                                {props.name}
                                {/* {location.pathname == '/tools/chat' ? 'ChatGPT' : 'AI Image Generator'}
{JSON.stringify(location)} */}
                            </h3>
                        </div>
                    ) : (
                        <div className="flex w-full text-white"></div>
                    )}

                    <img className="w-9 float-right" src={getImageUrl('icon')}></img>
                </div>
            </div>
            <div>
                <div className="w-full block"></div>
                <div className="w-full h-16"></div>
            </div>
        </div>
    )
}
