import { useState, useEffect, useRef, useImperativeHandle, forwardRef, momo } from 'react'
import { Modal } from 'antd'
import delete_icon from '../../assets/common/delete_tip.png'
import close_icon from '../../assets/photoPreview/close.png'
import { useTranslation } from 'react-i18next'
function Index(params) {
    const { open, title, subtitle, cancelM, sureM } = params
    const { t } = useTranslation()
    return (
        <Modal
            open={open}
            centered
            maskClosable
            transitionName=""
            footer={null}
            closeIcon={
                <div className="flex items-center justify-center p-1">
                    <img src={close_icon}></img>
                </div>
            }
            onCancel={cancelM}
        >
            <div className="flex flex-col items-center p-3 w-full space-y-4">
                <img className="w-[45px] h-[45px]" src={delete_icon} alt="" />
                <div className="w-full text-[20px] leading-7 text-center">{title}</div>
                <div className=" text-[14px] text-[#949494] text-center">{subtitle}</div>
                <div className="flex md:space-x-7 pt-5">
                    <button
                        className=" border rounded-md border-[#949494] text-[#949494] w-[169px] h-[34px]"
                        onClick={() => {
                            cancelM()
                        }}
                    >
                        {t('preview.deleteBtnCancel')}
                    </button>
                    <button
                        className=" bg-gradient-to-r from-btnBgColorFrom  to-btnBgColorTo rounded-md  w-[169px] h-[34px]"
                        onClick={() => {
                            sureM()
                        }}
                    >
                        {t('preview.deleteBtnSure')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default Index
