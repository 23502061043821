import { IGenerateStateDiffusionState, setStableDiffusionState } from '@/slices/GenerateImageSlice'
import { Modal, Spin } from 'antd'
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/hooks'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import DivBoundaryLoader from '@/pages/sdModelList/DivBoundaryLoader'
import VAEModalContent from './VAEModalContent'
import { fetchSt_modalVaeList } from '@/slices/GeneratedImageThunk'
import closeImage from '../../../assets/photoPreview/close.png'

export interface IVaeModalContextContext {
    open: boolean
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}
export const VaeModalContext = createContext<IVaeModalContextContext>({ open: false })
interface IVAEModalProps {
    el: (open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>) => JSX.Element
}

const VAEModal: React.FC<IVAEModalProps> = props => {
    const { t } = useTranslation()

    const dispatch = useDispatch<AppDispatch>()
    const updateState = (s: Partial<IGenerateStateDiffusionState>) => dispatch(setStableDiffusionState(s))

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const state = useAppSelector(state => {
        return state.generateImageSlice.stableDiffusion
    })
    const authToken = useAppSelector(state => {
        return state.app?.authToken
    })

    useEffect(() => {
        if (open && authToken && state.selectedModelItem?.modelId) {
            dispatch(fetchSt_modalVaeList({ modelId: state.selectedModelItem.modelId }))
        }
    }, [authToken, state.selectedModelItem, open])

    const providerValue = useMemo(() => {
        return { open, setOpen }
    }, [open, setOpen])

    return (
        <VaeModalContext.Provider value={providerValue}>
            {props.el(open, setOpen)}
            <Modal
                closeIcon={<img src={closeImage} />}
                title=""
                maskClosable={false}
                open={open}
                width="82rem"
                onOk={() => setOpen(false)}
                footer={<></>}
                onCancel={e => setOpen(false)}
                className="modal-zero-padding"
            >
                <div className="">
                    <Spin spinning={loading}>
                        <div className="flex flex-col h-[81vh] overflow-hidden">
                            <DivBoundaryLoader className="overflow-y-auto grow dark-thin-scrollbar px-5 pb-5">
                                <div className="z-10 sticky pt-5 pb-4 top-0 bg-menuBgColor text-lg font-bold">{t('generatedImage.VAE')}</div>
                                <div className="">
                                    <VAEModalContent modelList={state.vaeModelList} />
                                    {/* {curModelList.length < 1 && state.requestStatus[curFetchAction.typePrefix] != 'loading' && (
                                    <div className="h-[400px] flex justify-center items-center">
                                        <div className="text-white/60 text-center">{t('generatedImage.emptyText')}</div>
                                    </div>
                                )} */}
                                </div>
                            </DivBoundaryLoader>
                        </div>
                    </Spin>
                </div>
            </Modal>
        </VaeModalContext.Provider>
    )
}
export default VAEModal
