import React from "react";
import Spinner from "./Spinner";

export interface IBackdropSpinnerProps {
    show: boolean
}
const BackdropSpinner: React.FC<IBackdropSpinnerProps> = (props) => {

    return (
        <div className={`fixed ${props.show ? 'flex' : 'hidden'} justify-center items-center inset-0 bg-black/10 z-50`}>
            <Spinner />
        </div>
    )
}
export default BackdropSpinner